import React, { useContext } from 'react'
import { FaEdit,FaShareAlt, FaTrash } from 'react-icons/fa';
import { MdMenu } from 'react-icons/md';
import { AuthContext } from '../context/AuthContext';
import Swal from 'sweetalert2';
import { ItineraryContext } from '../context/ItineraryContext';

export default function DropDownButton({setUpdateDetails,mainItinerary}) {
   const {currentUser, loggedIn} = useContext(AuthContext)
   const {deleteWholeItinerary, sendItineraryLink} = useContext(ItineraryContext)

   function handleDelete(id){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          deleteWholeItinerary(id)
        }
      });
      
     }



    function handleShare() {
      Swal.fire({
        title: "Input Name and Email",
        html:
        '<input id="name" class="swal2-input" placeholder="Enter agent\'s name">'+
          '<input id="email" class="swal2-input" placeholder="Enter agent\'s email address">' ,
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        background: 'rgb(8, 50, 32,1)',
        color:'rgb(183, 159, 0)',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Submit',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          const email = document.getElementById('email').value;
          const name = document.getElementById('name').value;
          const currentUrl = window.location.href;
          Swal.fire({
            title:'Confirmation',
            text:` Name: ${name},  email: ${email}`,
            showConfirmButton:false,
            toast:true,
            timer: 5000
          });
          // Perform further actions, like submitting the comment with the email and name
          sendItineraryLink(email, name, currentUrl);
        }
      });
    }




  return (
   <div className='d-flex flex-wrap' style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' , zIndex:'999'}}>
   {/* <h2 className="mb-0 text-center" style={{ color: 'wheat', fontFamily: 'junge', margin: 0 }}>{mainItinerary?.title}</h2> */}
   {loggedIn && (
     <div className='dropdown ' style={{ position: 'absolute', top: '0.6rem', right: '2%', padding: '0' }}>
     <button className='btn btn-info shadow-sm btn-sm dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
       <MdMenu style={{ fontSize: '2rem' }} />
     </button>
     <ul className='dropdown-menu bg-secondary bg-opacity-75 ps-2' style={{cursor:'pointer'}} aria-labelledby='dropdownMenuButton'>
     <li className='dropdown-item fw-semibold'
       onClick={() => setUpdateDetails(true)}>
       <span>
     <FaEdit
       style={{ cursor: 'pointer', fontSize: '1.2rem', color: 'gold' }} 
     />
     Edit</span>
     </li>
     
     {currentUser && currentUser?.position === "ADMIN" && (<>
      <li className='dropdown-item fw-semibold'
       onClick={()=> handleShare()}>
       <span> 
      <FaShareAlt 
       className=''
       style={{ cursor: 'pointer', fontSize: '1.2rem', color: 'lightblue' }}
     />Share</span>
      </li>

       <li className='dropdown-item fw-semibold' 
       onClick={()=> handleDelete(mainItinerary?.id)}>
         <span>
       <FaTrash 
       className=''
       style={{ cursor: 'pointer', fontSize: '1.2rem', color: 'red' }}
     /> Delete</span>
       </li>
     </>)}
     </ul>
   </div>
   )}
 </div>
  )
}
