import React, { useContext, useState } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';

export default function UpdateAccommodationRates({ accommodation, setEditRates }) {
   const {updateRates} = useContext(AccommodationContext)
  const [updatedRates, setUpdatedRates] = useState({
    id: accommodation.id,
    adultRates: accommodation.rates?.adult_rates,
    adultsSharingRates: accommodation.rates?.adults_sharing_rates,
    childrenRates: accommodation.rates?.children_rates
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedRates({ ...updatedRates, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateRates(updatedRates);
    setEditRates(false);
  };

  return (
    
      <div className='container-fluid card col-md-5 my-2' style={{ backgroundColor: 'rgb(175, 238, 238,0.8)' }}>
        <h4 className='card-header text-white bg-success position-relative'>
          Update Rates:
        </h4>
        <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="adultRates">Adult Rates:</label>
          <input type="number" className="form-control" id="adultRates" name="adultRates" value={updatedRates.adultRates} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label htmlFor="adultsSharingRates">Adults Sharing Rates:</label>
          <input type="number" className="form-control" id="adultsSharingRates" name="adultsSharingRates" value={updatedRates.adultsSharingRates} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label htmlFor="childrenRates">Children Rates:</label>
          <input type="number" className="form-control" id="childrenRates" name="childrenRates" value={updatedRates.childrenRates} onChange={handleInputChange} />
        </div>
        <div className='d-flex justify-content-around pt-2'>
          <button type="submit" className="btn btn-primary btn-sm">Update</button>
          <button onClick={()=> setEditRates(false)} className="btn btn-danger btn-sm">Cancel</button>
        </div>
        </form>
      </div>
    
  );
}
