import React, { useContext } from 'react'
import Swal from 'sweetalert2';
import { FaTrash } from 'react-icons/fa';
import { ReceiptContext } from '../context/ReceiptContext';

export default function DeleteReceipt({receipt}) {
  const {deletePackageReceipt} = useContext(ReceiptContext)

  function handleDelete(id){
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      toast:true,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePackageReceipt(id)
      }
    });
  }
  return (
    <button className='btn text-danger btn-sm mx-1' onClick={()=>handleDelete(receipt?.id)}><FaTrash/></button>
  )
}
