import React from 'react';

const Unauthorised = () => {
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgb(1,50,32,1)',minHeight: '100%' }}>
      <div className="card alert bg-danger text-white p-5 shadow" style={{ maxWidth: '400px' }}>
        <h3 className="card-title text-center mb-4">Unauthorised Access</h3>
        <p className="card-text text-center">Sorry, you are not authorised to access this page.</p>
        <div className="d-grid gap-2 mt-4">
          <button className="btn btn-primary" onClick={() => window.history.back()}>Go Back</button>
        </div>
        <a href='https://beshafricanadventures.co.ke' className='m-4 text-center'>Go to the website</a>
      </div>
    </div>
  );
}

export default Unauthorised;
