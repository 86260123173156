import React, { useContext } from 'react';
import { FaTrash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { AuthContext } from '../context/AuthContext';
import DayFlightDets from './DayFlightDets';
import MainDetailAccommodation from './MainDetailAccommodation';
import MainDetailDestination from './MainDetailDestination';
import { ItineraryContext } from '../context/ItineraryContext';
import Swal from 'sweetalert2';

export default function ViewDayItinerary({ detail, index, mainItinerary }) {
  const { loggedIn } = useContext(AuthContext);
  const { deleteDay } = useContext(ItineraryContext);

  function handleDeleteLastDay(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDay(id);
      }
    });
  }

  return (
    <motion.details
      key={index}
      className="card mb-3"
      style={{ backgroundColor: 'rgb(1,50,32,1)' }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
    >
      <summary className="card-title ps-5 py-2 text-white">
        Day {detail.day} --{detail.title}
      </summary>

      <div
        className="card-body"
        style={{
          backgroundColor: 'rgba(175, 238, 238, 0.0)',
          borderRadius: '0.5rem',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}
      >
        <motion.div
          style={{
            position: 'relative',
            width: '100%',
            height: 'auto',
            minHeight: '45vh',
            maxHeight: '500px',
            backgroundImage: `radial-gradient(circle, rgba(1, 50, 33, 0.6), rgba(1, 50, 33, 0.4), rgba(255, 255, 255, 0.23)), url(${detail?.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '0.5rem',
            overflow: 'hidden'
          }}
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, delay: index * 0.3 }}
        >
          {/* Centered day and title */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              color: 'white',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
              fontWeight: 'bold',
              textAlign: 'center',
              padding: '2rem'
            }}
          >
            <motion.h4
              style={{ fontFamily: 'junge', fontSize: '2rem', marginBottom: '1rem', color: '#F0E68C' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.4 }}
            >
              Day {detail?.day}
            </motion.h4>
            <motion.h4
              style={{ fontFamily: 'junge', fontSize: '2rem' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.5 }}
            >
              {detail?.title}
            </motion.h4>
          </div>
          {/* Trash icon */}
          {index === mainItinerary.details.length - 1 && loggedIn && (
            <motion.div
              style={{ position: 'absolute', top: '10px', right: '10px' }}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, delay: index * 0.6 }}
            >
              <FaTrash
                onClick={() => handleDeleteLastDay(detail?.id)}
                style={{ cursor: 'pointer', fontSize: '1.5rem', color: 'red' }}
              />
            </motion.div>
          )}
        </motion.div>
        {/* Description */}
        <div className='d-flex justify-content-center' style={{ padding: '1rem', marginTop: '1rem' }}>
          <motion.textarea
            value={detail?.description}
            readOnly
            rows={6}
            className='fst-italic'
            style={{
              width: '95%',
              border: 'none',
              backgroundColor: 'rgb(175, 238, 238, 0.1)',
              color: 'wheat',
              padding: '1rem',
              borderRadius: '0.5rem'
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: index * 0.7 }}
          />
        </div>
        <hr className='my-5' style={{ color: 'white', borderWidth: '3px' }} />
        {/* Destination */}
        {detail?.destination && (
          <>
            <MainDetailDestination detail={detail} />
            <hr className='mt-3 mb-4' style={{ color: 'white', borderWidth: '3px' }} />
          </>
        )}
        {/* Accommodation */}
        {detail?.accommodation && <MainDetailAccommodation detail={detail} />}
        {/* Flights */}
        {detail?.flight_details && <DayFlightDets flight={detail?.flight_details} />}
      </div>
    </motion.details>
  );
}
