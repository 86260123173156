import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DestinationContext } from '../context/DestinationContext';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

export default function Destinations() {
  const { destinations, searchTerm, setSearchTerm,searchResults } = useContext(DestinationContext);
 const navigate = useNavigate()

 const [filteredItems, setFilteredItems] = useState([]);
const [search, setSearch] = useState(''); // Initialize search state
const [destinationsToDisplay, setDestinationsToDisplay] = useState([])

useEffect(() => {
 if (searchTerm !== '' && search !== '') {
    setDestinationsToDisplay(searchResults);
  }else{
    setDestinationsToDisplay(destinations)
  }
  
  // setDestinationsToDisplay(destinations)
}, [searchTerm, destinations, searchResults, search]);


  

 const handleSearch = (e) => {
  const term = e.target.value.toLowerCase();
  setSearch(term);

  if (term.length > 0) {
    const filtered = new Set(); // Use a Set to store unique search results

    destinations.forEach(item => {
      const destinationName = item.name?.toLowerCase();
      const locationName = item.location?.toLowerCase();

      if (destinationName?.includes(term)) {
        filtered.add(item.name);
      }
      if (locationName?.includes(term)) {
        filtered.add(item.location);
      }
    });

    // Convert Set to an array and sort alphabetically
    const sortedFilteredItems = [...filtered].sort((a, b) => a.localeCompare(b));
    setFilteredItems(sortedFilteredItems);
  } else {
    setFilteredItems([]);
    setDestinationsToDisplay(destinations)
  }
};
function handleSubmit(e){
  e.preventDefault()
  setSearchTerm(search)
  setFilteredItems([]);
}

const handleItemClick = (item) => {
  setSearch(item);
  setFilteredItems([]);
};

 

  return (
    <div className='container-fluid py-4' style={{ backgroundColor: 'rgb(1,50,32,1)', minHeight: '100%' }}>
      <h2 className='text-center' style={{ color: 'wheat', fontFamily: 'junge' }}>Destinations</h2>
      <div className='d-flex flex-wrap gap-4 mb-3 mx-auto justify-content-center'>
      <form className="input-group position-relative" style={{ maxWidth: '20rem' }} onSubmit={handleSubmit}>
            <input
              type="search"
              className="form-control text-center border border-success"
              placeholder="Search 🔍"
              value={search}
              onChange={handleSearch}
              style={{ fontSize: '1rem' }}
            />
            <button className="btn btn-success" type="submit">🔍</button>
            {filteredItems.length > 0 && (
              <ul className="list-group position-absolute w-100" style={{ top: '100%', zIndex: 1 }}>
                {filteredItems.map((item, index) => (
                  <li
                    key={index}
                    className="list-group-item list-group-item-action"
                    onClick={() => handleItemClick(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
        </form>
        <div className=''>
          <Link to={'/destinations/add'} className='btn btn-primary'>Add Destination</Link>
        </div>
      </div>
      {Array.isArray(destinationsToDisplay) && destinationsToDisplay.length > 0 ? (
        <motion.div className="row"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}>
          {destinationsToDisplay.map((destination) => (
            <motion.div
              key={destination.id}
              className="col-lg-4 col-md-4 col-sm-6 mb-4 px-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="card border-success" style={{ backgroundColor: 'rgb(175, 238, 238,0.88)', overflow: 'hidden',height:"20em" }}>
              
                  <motion.img
                  src={destination?.pictures[0]?.image}
                  className="card-img-top img-fluid d-block h-100"
                  style={{height:"100%"}}
                  alt={destination?.pictures[0]?.title}
                  onClick={()=>  navigate(`/destinations/${destination?.id}`)}
                  initial={{ scale: 1.2 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                />
               
                <div className="card-footer" style={{ backgroundColor: 'rgb(175, 238, 238,0.488)', position: 'absolute', bottom: 0, width: '100%' }}>
                <p className='card-title' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <strong>{destination.name}</strong>
                </p>
                    {/* <h5 className="card-title text-center text-info" style={{ textShadow: '1px 1px 2px rgba(175, 238, 238, 1)' }}>{flight.name}</h5> */}
                    <div>
                     <small className='card-title'>
                        <FaMapMarkerAlt /> <strong>{destination.location}</strong>
                     </small>
                     </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <div className="alert alert-warning" role="alert">
          No Destinations found.
        </div>
      )}
    </div>
  );
}