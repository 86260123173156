import React from 'react';
import DayDestination from './DayDestination';
import DayAccommodation from './DayAccommodation';
import FlightDets from './FlightDets';
import { motion } from 'framer-motion';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${day}${daySuffix(day)} ${month}, ${year}`;
};

const PackageDayItinerary = ({ detail, index }) => {
  const formattedDate = detail?.date ? formatDate(detail?.date) : '';

  return (
    <div className="container-fluid pb-4" style={{ backgroundColor: 'rgb(1, 50, 32, 1)' }}>
      <div key={detail?.id} className="container-fluid justify-content-center">
        <div className="col-md-12">
          <div className="mb-4 text-white">
            <motion.div
              style={{
                position: 'relative',
                width: '100%',
                height:'auto',
                minHeight: '45vh',
                maxHeight: '500px',
                backgroundImage: `radial-gradient(circle, rgba(1, 50, 33, 0.6), rgba(1, 50, 33, 0.4), rgba(255, 255, 255, 0.23)), url(${detail?.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: '0.5rem',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.3 }}
            >
              <div style={{ padding: '2vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', color: 'wheat' }}>
                <motion.h6 style={{ fontFamily: 'junge'}} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: index * 0.4 }}>
                <strong>Day:</strong> {detail?.day}
                  <small className="fst-italic px-3" style={{ color:'gold',fontSize: '80%' }}>
                    ({formattedDate})
                  </small>
                  
                </motion.h6>
                <motion.h2 className="card-title my-2" style={{ fontFamily: 'junge', fontWeight: 'bolder', color: 'beige' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: index * 0.6 }}>
                  {detail?.title}
                </motion.h2>
              </div>
            </motion.div>
            <motion.div
              className="mt-4"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '0.5rem',
                padding: '1.5rem',
                color: 'white'
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.7 }}
            >
              <textarea
                className="form-control fst-italic"
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: 'wheat',
                  // resize: 'none',
                  minHeight: '150px',
                  fontFamily: 'junge',
                  fontSize: '1rem'
                }}
                readOnly
                value={detail?.description}
              />
            </motion.div>
            {detail?.destination && (
              <>
                <DayDestination detail={detail} index={index} />
                <hr className='my-5' style={{ color: 'gold', borderWidth: '3px' }} />
              </>
            )}
            {detail?.accommodation && (
              <>
                <DayAccommodation detail={detail} index={index} />
                <hr className='my-5' style={{ color: 'gold', borderWidth: '3px' }} />
              </>
            )}
            {detail?.flight && <FlightDets flight={detail?.flight} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDayItinerary;
