import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import UpdateDestination from './UpdateDestination';
import { AuthContext } from '../context/AuthContext';
import { DestinationContext } from '../context/DestinationContext';
import Viewer from 'react-viewer';
import LoadingComponent from '../components/LoadingComponent';
import AddPicture from './AddPicture';
import UpdatePictures from './UpdatePictures';
import ImageViewer from '../utils/ImageViewer'

function DestinationDetails() {
  const { authToken } = useContext(AuthContext);
  const { onDesChange, deleteDestination } = useContext(DestinationContext);
  const { id } = useParams();
  const [destination, setDestination] = useState({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  // console(destination.destination)
  const [updatePictures, setUpdatePictures] = useState(false);
  const [addPicture, setAddPicture]= useState(false);
  const [images, setImages] = useState([])
  const [visible,setVisible] = useState(false)
  
  // ==================== IMAGES VIEWER =====================
  // const DestinationImages = destination?.pictures.map(item => item.image);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);
  // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1); // Reset scale when opening modal
    setIsOpen(true);
  };

  // ==================== IMAGES VIEWER =====================


  useEffect(() => {
    if (id && authToken) {
      setLoading(true);
      fetch(`https://beshapp.backend.safarinetics.com/destinations/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken && authToken}`,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          // console(response.pictures)
          if (response) {
            setDestination(response);
            setLoading(false);
            const imageArray = response?.pictures?.length > 0 && response?.pictures.map((picture) => (picture?.image));
            setImages(imageArray);
          }
        });
    }
  }, [id, authToken, onDesChange]);

  function handleDelete() {
    deleteDestination(destination?.id);
  }

 

  if (loading) {
    const loadingText = 'Loading......';
    return <LoadingComponent loadingText={loadingText} />;
  }

  if (!destination) {
    return <div className="alert alert-warning mt-5">Destination not found</div>;
  }

  const destinationDescription =
    destination && typeof destination.description === 'string' ? destination.description : '';
  const numberOfNewlines = (destinationDescription.match(/\n/g) || []).length + 1; // Count the number of newline characters
  const maxRows = 10; // Set a maximum number of rows to prevent excessive expansion
  const rows = Math.max(numberOfNewlines + 11, maxRows); // Ensure rows do not exceed the maximum

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  // console.log(destination?.pictures)

  return (
    <div className="container-fluid pt-4" style={{ backgroundColor: 'rgb(8, 50, 32,1)' }}>
    {!update && (
      <div className="row justify-content-center">
        <h3 className="text-center" style={{ fontFamily: 'Arial', color: 'rgb(210, 250, 232)' }}>{destination?.name}</h3>
        <div className="col-md-10">
          <div className="card" style={{ backgroundColor: 'rgb(175, 238, 238)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className="row no-gutters">

              {!updatePictures && !addPicture && (
                <div className="col-md-5">
                <div id={`carousel-`} className="carousel slide py-2 mx-auto" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    {destination?.pictures?.length > 0 && destination?.pictures.map((picture, picIndex) => (
                      <div key={picIndex} className={`carousel-item ${picIndex === 0 ? 'active' : ''}`}>
                  
                      {/* <Viewer
                        visible={visible}
                        onClose={() => setVisible(false)}
                        images={images}/> */}
                        <ImageViewer 
                        images={images}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        currentImageIndex={currentImageIndex}
                        setCurrentImageIndex={setCurrentImageIndex}
                        scale={scale}
                        setScale={setScale}
                      />
                    
                      <img  loading="lazy" 
                      onClick={()=> openModal(picIndex)}
                      src={picture?.image}
                      className="d-block w-100 " 
                      alt={picture.title} />
                      <div className="carousel-caption d-none d-md-block">
                      <p
                          className="fw-bold"
                          style={{
                            zIndex: '100',
                            color: 'wheat',
                            textShadow: '2px 2px 4px rgba(1, 50, 32,1)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            padding: '0.5rem',
                            borderRadius: '0.5rem',
                          }}
                        >{picture.title}</p>
                      </div>
                    </div>
                    ))}
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-`} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target={`#carousel-`} data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
  
                   <div className='card-footer d-flex flex-wrap justify-content-around'>
                    <button className='btn btn-primary btn-sm'
                    onClick={()=> setUpdatePictures(true)}
                    >Update</button>
                    <button className='btn btn-success btn-sm'
                    onClick={() => setAddPicture(true)}>Add</button>
                   </div>
  
                </div>
              )}
              {updatePictures && (<UpdatePictures setUpdatePictures={setUpdatePictures} destination={destination}/>)}

              {addPicture && (<AddPicture setAddPicture={setAddPicture} destination={destination}/>)}



              <div className="col-md-7 d-flex flex-column">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    {/* <h5 className="card-title" style={{ color: 'rgb(8, 50, 32)' }}>{destination.name}</h5> */}
                    <p className="card-text ms-5" style={{ color: 'rgb(8, 50, 32)' }}>
                      <strong>
                        <FaMapMarkerAlt />{' '}
                      </strong>{' '}
                      {destination.location}
                    </p>
                  </div>

                  <div className="mt-3">
                    <h6 className="card-subtitle mb-2 text-muted" style={{ color: 'rgb(8, 50, 32)' }}>Rates</h6>
                    <div className="d-flex gap-5">
                      <div>
                        <strong>Adults:</strong> ${destination.rates?.adults?.toLocaleString() || 'N/A'}
                      </div>
                      <div>
                        <strong>Children:</strong> ${destination.rates?.children?.toLocaleString() || 'N/A'}
                      </div>
                    </div>
                  </div>

                  <textarea
                    style={{
                      width: '98%',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      border: '1px solid rgb(8, 50, 32)',
                      borderRadius: '5px',
                      padding: '10px',
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      lineHeight: '1.5',
                      resize: 'none', // Disable resizing
                    }}
                    readOnly
                    rows={rows}
                    className="card-text mt-2 rounded"
                    value={destination.description}
                  />
                </div>
                <div className="mt-auto card-footer text-success d-flex justify-content-between">
                  <small>Created At: {destination.created_at}</small>
                  <small>Updated At: {destination.updated_at || 'Null'}</small>
                </div>
              </div>
            </div>
          </div>
           <div className="text-end">
           <button className="btn btn-sm btn-primary m-2" 
          //  style={{ backgroundColor: 'rgb(183, 159, 0)', border: '1px solid rgb(8, 50, 32)' }} 
           onClick={() => {
            scrollToTop()
            setUpdate(true)
           }}>Update</button>
           <button className="btn btn-sm btn-danger m-2" style={{ backgroundColor: 'rgb(128, 0, 32)', border: '1px solid rgb(128, 0, 32)' }} onClick={handleDelete}>Delete</button>
         </div>
        
        </div>
      </div>
      )}
      {update && <UpdateDestination destination={destination} setUpdate={setUpdate} />}
    </div>
  );
}

export default DestinationDetails;
