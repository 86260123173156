import React, { useState, useEffect, useContext} from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import { FaEdit} from 'react-icons/fa';
import MainItineraryBudget from './MainItineraryBudget';
import UpdateBudget from './UpdateBudget';
import { ItineraryContext } from '../context/ItineraryContext';
import UpdateDetails from './UpdateDetails';
import AddSingleDay from './AddSingleDay';
import UpdateDailyItineraries from './UpdateDailyItineraries';
import Swal from 'sweetalert2';
import { AuthContext } from '../context/AuthContext';
import LoadingComponent from '../components/LoadingComponent';
import ViewDetails from './ViewDetails';
import DropDownButton from './DropDownButton';
import ViewDayItinerary from './ViewDayItinerary';


const MainItineraryDetails = () => {
  const { onChange,loading, setLoading} = useContext(ItineraryContext);
  const { loggedIn} = useContext(AuthContext);
  const { id, title } = useParams();
  const [mainItinerary, setMainItinerary] = useState(null);
  const [updateBudget, setUpdateBudget] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [updateDayDetails, setUpdateDayDetails] = useState(false);
  const [add, setAdd] = useState(false);
  const navigate = useNavigate();
  // const [] = useState(false);
  const [image, setImage] = useState([]);

  useEffect(() => {
    if (id && title) {
      setLoading(true);

      fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/${id}/${title}`)
        .then((res) => res.json())
        .then((response) => {
          // console(response)
            // setLoading(false);
            if (!response.error) {
              setMainItinerary(response);
              setImage([{
                src:response?.overview?.image,
                alt:response?.title
              }]) 
              // setLoading(false);
            }
            if (response.error === 'Main itinerary not found' || response?.title !== title) {
              if(loggedIn){

            Swal.fire({
              icon:'error',
              text:response?.error || 'Main itinerary not found',
              showConfirmButton:false,
              toast:true,
              color:'wheat',
              background:'red',
              position:'center',
              timer:5000
            });
            navigate('/itineraries');
              }else if(!loggedIn) {
                navigate('/*');
              }
            
            }
          
        })
        .catch((error) => {
            // setLoading(false);
            console.error('Error fetching itinerary:', error);
          
        })
        .finally(()=> setLoading(false))
    

      }}, [id, title, onChange, navigate, loggedIn]);

    if (loading){
      const loadingText = 'Loading itinerary ......'
      return(
        <LoadingComponent loadingText={loadingText}/>
      )
    }
   
   
  

  return (
    <div className="container-fluid mt-0 pt-0 pb-4 mx-0 px-0" style={{ backgroundColor: 'rgb(1,50,32,0.991)', height: '100%', overflowX:'auto' }}>
      <DropDownButton setUpdateDetails={setUpdateDetails} mainItinerary={mainItinerary}/>
      {updateDetails ? (
        <UpdateDetails mainItinerary={mainItinerary} setUpdateDetails={setUpdateDetails} />
      ) : (
        <ViewDetails mainItinerary={mainItinerary}/>
      )}

      {/* details */}
      <div style={{position:'relative'}}>
      <div style={{ position: 'absolute', top: '2.0rem', left: '15px',marginLeft:'70%' }}>
      {loggedIn && (
        <FaEdit 
        onClick={() => setUpdateDayDetails(true)} 
        style={{ cursor: 'pointer', fontSize: '1.2rem', color: 'gold' }} 
         />
      )}
         </div>
         </div>
      <h2 className='text-center mt-4' style={{ color: 'wheat', fontFamily: 'junge' }}> Daily Itineraries</h2>
      
         
         {updateDayDetails ? (<UpdateDailyItineraries mainItinerary={mainItinerary} setUpdateDayDetails={setUpdateDayDetails}/>):(
      <div className="container-fluid mt-3 py-4" style={{ backgroundColor: 'rgb(175, 238, 238,0.98)' }}>
        {mainItinerary && mainItinerary.details.map((detail, index) => (
          <ViewDayItinerary detail={detail} index={index} mainItinerary={mainItinerary}/>
        ))}
        {loggedIn && (
          <>
          {!add ? (

            <button className='btn btn-primary' onClick={()=>setAdd(true)}>Add</button>) :(<AddSingleDay mainItinerary={mainItinerary} setAdd={setAdd}/>)
            }
            </>
        )}

      </div>
       )}

      {/* The budget */}
      {updateBudget ?
        <UpdateBudget mainItinerary={mainItinerary} setUpdateBudget={setUpdateBudget} />
        :
        <MainItineraryBudget mainItinerary={mainItinerary} setUpdateBudget={setUpdateBudget} />
      }

      {/* Popup form for editing day details */}
      
    </div>
  );
};

export default MainItineraryDetails;
