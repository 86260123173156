import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { AccommodationContext } from '../context/AccommodationContext';

const AddAccommodationPictures = () => {
  const { pictures, setPictures } = useContext(AccommodationContext);
  const [image, setImage] = useState(null);
  const [caption, setCaption] = useState('');
  const [errors, setErrors] = useState('');
  const [add, setAdd] = useState(true);

  // Function to validate caption
  const validateCaption = (caption) => {
    return pictures.some(picture => picture.caption.toLowerCase() === caption.toLowerCase())
      ? 'This caption already exists.'
      : '';
  };

  // Handle caption change with real-time validation
  const handleCaptionChange = (e) => {
    const newCaption = e.target.value;
    setCaption(newCaption);
    const error = validateCaption(newCaption);
    setErrors(error);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the caption already exists in the array
    if (errors) {
      Swal.fire({
        text: "Please correct the errors before submitting.",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    // Add picture to the array
    const newPicture = { image, caption };
    setPictures([...pictures, newPicture]);

    // Reset inputs
    setAdd(false);

    Swal.fire({
      text: "Saved",
      showConfirmButton: false,
      timer: 4000,
      icon: 'success',
      color: 'white',
      background: 'green',
      toast: true,
      position: 'top'
    });

    setImage(null);
    setCaption('');
    setErrors('');
  };

  const removePicture = (indexToRemove) => {
    const removedPicture = pictures[indexToRemove].caption;
    setPictures(pictures.filter((picture, index) => index !== indexToRemove));

    Swal.fire({
      text: `${removedPicture} removed`,
      showConfirmButton: false,
      timer: 4000,
      icon: 'success',
      color: 'white',
      background: 'green',
      toast: true,
      position: 'top'
    });
  };

  return (
    <div className="container-fluid mx-auto rounded mt-3 row justify-content-center">
      <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'junge' }}>Add Accommodation Pictures</h2>
      <div className='bg-white bg-opacity-50 rounded col-md-10' style={{ height: '100%' }}>
        {Array.isArray(pictures) && pictures.length > 0 && pictures.map((picture, index) => (
          <details key={index} className='rounded m-2 bg-info bg-opacity-10 p-2'>
            <summary className='d-flex justify-content-between'>
              {index + 1}. {picture.caption}
              <button className="btn btn-sm ms-auto" onClick={() => removePicture(index)} style={{ color: 'red', fontWeight: 'bold', background: 'none', border: 'none' }}>X</button>
            </summary>
          </details>
        ))}
      </div>
      <div className='mt-4 col-md-10'>
        {add ? (
          <form onSubmit={handleSubmit} className='p-3 border-info bg-light bg-opacity-50 rounded'>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id={'Image'}
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="caption" className="form-label">Caption</label>
              <input
                type="text"
                className="form-control"
                id="caption"
                name="caption"
                value={caption}
                onChange={handleCaptionChange}
                required
              />
              {errors && (
                <div className="text-danger">{errors}</div>
              )}
            </div>
            <div className='d-flex justify-content-around'>
              <button type="submit" className="btn btn-primary" disabled={!!errors}>Submit</button>
              <button type="button" onClick={() => setAdd(false)} className="btn btn-danger">Cancel</button>
            </div>
          </form>
        ) : (
          <button style={{ right: '10%' }} onClick={() => setAdd(true)} className='btn btn-primary'>Add</button>
        )}
      </div>
    </div>
  );
};

export default AddAccommodationPictures;
