import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { AccommodationContext } from '../context/AccommodationContext';

const AddAccommodationAmenities = () => {
  const [image, setImage] = useState(null);
  const [amenity, setAmenity] = useState('');
  const [errors, setErrors] = useState('');
  const { amenities, setAmenities } = useContext(AccommodationContext);
  const [add, setAdd] = useState(true);

  // Function to validate amenity
  const validateAmenity = (amenity) => {
    return amenities.some(a => a.amenity.toLowerCase() === amenity.toLowerCase())
      ? 'This amenity already exists.'
      : '';
  };

  // Handle amenity change with real-time validation
  const handleAmenityChange = (e) => {
    const newAmenity = e.target.value;
    setAmenity(newAmenity);
    const error = validateAmenity(newAmenity);
    setErrors(error);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are validation errors
    if (errors) {
      Swal.fire({
        text: "Please correct the errors before submitting.",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    // Add amenity to the array
    const newAmenity = { image, amenity };
    setAmenities([...amenities, newAmenity]);

    // Reset inputs
    setAdd(false);
    setImage(null);
    setAmenity('');
    setErrors('');

    Swal.fire({
      text: "Saved",
      showConfirmButton: false,
      timer: 4000,
      icon: 'success',
      color: 'white',
      background: 'green',
      toast: true,
      position: 'top'
    });
  };

  const removeAmenity = (indexToRemove) => {
    const removedAmenity = amenities[indexToRemove].amenity;
    setAmenities(amenities.filter((amenity, index) => index !== indexToRemove));

    Swal.fire({
      text: `${removedAmenity} removed`,
      showConfirmButton: false,
      timer: 4000,
      icon: 'success',
      color: 'white',
      background: 'green',
      toast: true,
      position: 'top'
    });
  };

  return (
    <div className="container-fluid mx-auto rounded mt-3 row justify-content-center">
      <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'junge' }}>Add Accommodation Amenities</h2>
      <div className='bg-white bg-opacity-50 rounded col-md-10' style={{ height: '100%' }}>
        {Array.isArray(amenities) && amenities.length > 0 && amenities.map((item, index) => (
          <details key={index} className='rounded m-2 bg-info bg-opacity-10 p-2'>
            <summary className='d-flex justify-content-between'>
              {index + 1}. {item.amenity}
              <button className="btn btn-sm ms-auto" onClick={() => removeAmenity(index)} style={{ color: 'red', fontWeight: 'bold', background: 'none', border: 'none' }}>X</button>
            </summary>
          </details>
        ))}
      </div>
      <div className='mt-4 col-md-10'>
        {add ? (
          <form onSubmit={handleSubmit} className='p-3 border-info bg-light bg-opacity-50 rounded'>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id={'Image'}
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="amenity" className="form-label">Amenity</label>
              <input
                type="text"
                className="form-control"
                id="amenity"
                name="amenity"
                value={amenity}
                onChange={handleAmenityChange}
                required
              />
              {errors && (
                <div className="text-danger">{errors}</div>
              )}
            </div>
            <div className='d-flex justify-content-around'>
              <button type="submit" className="btn btn-primary" disabled={!!errors}>Submit</button>
              <button type="button" onClick={() => setAdd(false)} className="btn btn-danger">Cancel</button>
            </div>
          </form>
        ) : (
          <button style={{ right: '10%' }} onClick={() => setAdd(true)} className='btn btn-primary'>Add</button>
        )}
      </div>
    </div>
  );
};

export default AddAccommodationAmenities;
