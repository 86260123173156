import React, { useContext, useState, useEffect } from 'react';
import { DestinationContext } from '../context/DestinationContext';
import Swal from 'sweetalert2';

export default function AddDestinationDetails({ setActiveTab }) {
  const { details, setDetails, destinations } = useContext(DestinationContext);
  const [formData, setFormData] = useState({
    name: details?.name || '',
    location: details?.location || '',
    description: details?.description || '',
  });
  const [nameError, setNameError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Real-time validation for name input
  useEffect(() => {
    const nameExists = destinations.some(
      (destination) => destination.name.toLowerCase() === formData.name.toLowerCase()
    );
    if (nameExists) {
      setNameError('Name already exists');
    } else {
      setNameError('');
    }
  }, [formData.name, destinations]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (nameError) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fix the errors before saving.',
      });
      return;
    }

    setDetails(formData);
    setActiveTab('rates');
    Swal.fire({
      icon: 'success',
      title: 'Details saved',
      toast: true,
      showConfirmButton: false,
      color: 'wheat',
      background: 'green',
      timer: 3000,
      position: 'top',
    });
  };

  return (
    <div className="container-fluid pt-5 pb-2" style={{ backgroundColor: 'rgb(1,50,32,1)', minHeight: '100%' }}>
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="card" style={{ backgroundColor: 'rgb(175, 238, 238,0.8)' }}>
            <div className="card-body">
              <h3 className="card-header text-success text-center mb-4" style={{ fontFamily: 'junge' }}>
                Destination Details
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {nameError && <div className="text-danger mt-2">{nameError}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="location" className="form-label">Location:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">Description:</label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    rows={6}
                    placeholder="Enter description here..."
                    spellCheck
                    autoFocus
                    style={{ resize: "vertical" }}
                    aria-label="Enter description"
                  />
                </div>
                <div>
                  <button disabled={nameError} type="submit" className="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
