import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineArrowLeft } from 'react-icons/ai';
import { AuthContext } from '../context/AuthContext';
import LoadingComponent from '../components/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const ChangePassword = () => {
  const { loading } = useContext(AuthContext);
  const {changePassword,setLoading} = useContext(UserContext)
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [strength, setStrength] = useState(0);
  const [passwordMatch, setPasswordMatch] = useState(true); // Track password match status

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const calculateStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score += 20;
    if (/[A-Z]/.test(password)) score += 20;
    if (/[a-z]/.test(password)) score += 20;
    if (/\d/.test(password)) score += 20;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 20;
    setStrength(score);
  };

  const validateForm = () => {
    if (strength < 80) {
      Swal.fire({
        title: 'Weak Password',
        text: 'Password must be strong (at least 80% strength).',
        icon: 'warning',
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        position: 'top',
        background: 'orange',
      });
      return false;
    }
    if (!passwordMatch) {
      Swal.fire({
        title: 'Passwords do not match',
        text: 'Please ensure the passwords match.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 4000,
        toast: true,
        position: 'top',
        background: 'orange',
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setPasswordMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      changePassword(newPassword);
      setNewPassword('');
      setConfirmNewPassword('');
    }
  };

  const handleBack = () => {
    navigate('/profile')
  };

  if (loading) {
    return <LoadingComponent loadingText={'Changing Password ....'} />;
  }

  return (
    <div className="container-fluid" style={styles.container}>
      <button onClick={handleBack} className='btn btn-outline-light' style={styles.backButton}>
        <AiOutlineArrowLeft style={styles.icon} /> Settings
      </button>
      <h2 style={styles.header}>Change Password</h2>
      <form onSubmit={handleSubmit} style={styles.form}>

      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>

          <div style={styles.strengthBar}>
            <div
            className='rounded'
              style={{
                height: '100%',
                width: `${strength}%`,
                backgroundColor: strength < 50 ? 'red' : strength < 80 ? 'yellow' : 'green',
                transition: 'width 0.5s ease-in-out',
              }}
            />
          </div>
          <p style={{ marginTop: '5px', color: strength < 50 ? 'red' : strength < 80 ? 'yellow' : 'green'}}>
          <small>({strength}%)</small>
          </p>
        </div>
        <div style={styles.formGroup}>
          <input
            required
            type={showPassword ? 'text' : 'password'}
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              calculateStrength(e.target.value);
            }}
            style={styles.input}
          />
          <div onClick={togglePasswordVisibility} style={styles.toggleIcon}>
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </div>
        </div>

        {!passwordMatch && confirmNewPassword && (
            <div style={styles.errorMessage}> * Passwords do not match</div>
          )}
        <div style={styles.formGroup}>
          <input
            required
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
            style={styles.input}
          />
          <div onClick={toggleConfirmPasswordVisibility} style={styles.toggleIcon}>
            {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </div>
        </div>
        <button
          type="submit"
          style={styles.submitButton}
          disabled={strength < 80 || !passwordMatch || loading}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#2B2B2B', // Charcoal grey background
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    width: '100%',
    margin: '60px auto 0', // Add margin to not touch the top
    border: '1px solid #e0e0e0',
  },
  backButton: {
    // background: 'none',
    // border: 'none',
    // color: '#ffffff',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  icon: {
    marginRight: '8px',
  },
  header: {
    fontFamily: 'Arial, sans-serif',
    color: 'lightgreen',
    marginBottom: '30px',
    textAlign: 'center',
    fontSize: '28px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    position: 'relative',
    marginBottom: '20px',
  },
  input: {
    borderRadius: '5px',
    width: '100%',
    padding: '12px',
    border: '1px solid #ccc',
    fontSize: '16px',
    transition: 'border-color 0.3s',
    backgroundColor: '#ffffff',
  },
  toggleIcon: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  strengthBar: {
    height: '5px',
    width: '100%',
    backgroundColor: '#e0e0e0',
    marginBottom: '10px',
  },
  submitButton: {
    width: '100%',
    borderRadius: '5px',
    padding: '12px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  errorMessage: {
    color: 'red',
    marginTop: '5px',
    fontSize: '14px',
  },
};

export default ChangePassword;