import React from 'react';
import '../styles/MovingCharacters.css'; 

const MovingCharacters = ({inquiry,packageInfo}) => {

  return (
    <div className="parent-container">
      <div className="container">
        
         <div className="moving-characters" >
         <span className=' mx-3 text-info'>Name :<span className='fw-semibold text-white'>{(packageInfo?.inquiry?.client_name )|| (inquiry?.client?.name)}</span></span>,
            <span className=' mx-3 text-info'>Budget :<span className='fw-semibold text-white'>{(inquiry?.budget?.toLocaleString())||(packageInfo?.inquiry?.budget?.toLocaleString())} {(inquiry?.currency )||(packageInfo?.inquiry?.currency)}</span></span>,
            <span className=' mx-3 text-info'>Adults :<span className='fw-semibold text-white'>{(inquiry?.adults )||(packageInfo?.inquiry?.adults)}</span></span>,
            <span className=' mx-3 text-info'>Children :<span className='fw-semibold text-white'>{(inquiry?.children )||(packageInfo?.inquiry?.children) || 'N/A'}</span></span>,
            <span className=' mx-3 text-info'>Adults sharing :<span className='fw-semibold text-white'>{(inquiry?.adults_sharing )||(packageInfo?.inquiry?.adults_sharing) || 'N/A'}</span></span>,
            <span className=' mx-3 text-info'>From :<span className='fw-semibold text-white'>{(inquiry?.start_date )||(packageInfo?.inquiry?.start_date)}</span></span>
            <span className=' mx-3 text-info'>To :<span className='fw-semibold text-white'>{(inquiry?.end_date )||(packageInfo?.inquiry?.end_date)}</span></span>,
            <span className=' mx-3 text-info'>Days :<span className='fw-semibold text-white'>{(inquiry?.days )||(packageInfo?.inquiry?.days)}</span></span>
         </div>
           
      </div>
    </div>
  );
};

export default MovingCharacters;
