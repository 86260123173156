import React, {useState, useEffect} from 'react'
import { FaBullseye, FaHotel, FaMapMarkerAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Viewer from 'react-viewer';
import ImageViewer from '../utils/ImageViewer'

export default function DayAccommodation({detail, index}) {
  //  const detail?.day
  const [amenityImages, setAmenityImages] = useState([]);
  const [visibleAmenity, setVisibleAmenity] = useState(false);
  const [pictureImages, setPictureImages] = useState([]);
  const [visiblePicture, setVisiblePicture] = useState(false);


   // ==================== IMAGES VIEWER =====================
  //  const images=[image1,2,3,4 ...]
   const [isOpen, setIsOpen] = useState(false);
   const [currentImageIndex, setCurrentImageIndex] = useState(0);
   const [scale, setScale] = useState(1);
   // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
   const openModal = (index) => {
     setCurrentImageIndex(index);
     setScale(1); // Reset scale when opening modal
     setIsOpen(true);
   };
 
   // ==================== IMAGES VIEWER =====================

  useEffect(() => {
    if (detail) {
      const pictureArray = detail?.accommodation?.pictures.map((picture) => (picture?.image));
      const amenityArray = detail?.accommodation?.amenities.map((amenity) => (amenity?.image));
      setAmenityImages(amenityArray);
      setPictureImages(pictureArray);
    }
  }, [detail]);

  
  return (
   <div className='container-fluid'>
   <h3 style={{ paddingLeft:'4vw',fontSize: "2rem",fontFamily:'junge' }} className='text-white px-4 pt-1 fw-semibold '>Accommodation</h3>
         <div className='flex-wrap' style={{ display: 'flex', justifyContent: 'space-around', width: '100%', maxWidth: '50vw' }}>
           <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
             <FaHotel style={{ fontSize: '1.5rem',color:"lightgreen", marginRight: '0.5em' }} />
             <p style={{ fontSize: '2vh', margin: 0 }}>{detail?.accommodation?.name}</p>
             {/* <FaTag style={{ fontSize: '2vh', marginLeft: '1em', marginRight: '0.5em' }} /> */}
             <p style={{ fontSize: '2vh',  paddingLeft: '1em',margin:'0' }}>({detail?.accommodation?.type})</p>
           </div>
           <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
             <FaMapMarkerAlt style={{ fontSize: '1.5rem',color:"lightgreen", marginRight: '0.5em' }} />
             <p style={{ fontSize: '2vh', margin: 0 }}>{detail?.accommodation?.location}</p>
           </div>
         </div>
     {/* Carousels */}
     <div className="container-fluid row">
       <div className="col-md-6">
         <h3 className='card-header text-center text-white px-4 py-3' style={{ fontFamily: 'inknut antiqua' }}>Images</h3>
         <div id={`carousel-${detail?.day}-${index}`} className="carousel slide py-2 mx-auto" data-bs-ride="carousel">
           <div className="carousel-inner">
             {detail?.accommodation?.pictures.map((picture, picIndex) => (
               <div key={picIndex} className={`carousel-item ${picIndex === 0 ? 'active' : ''}`}>
               <img
                 loading="lazy"
                 onClick={()=> {
                  setVisiblePicture(true)
                  setVisibleAmenity(false)
                  openModal(picIndex)}}
                 src={picture?.image}
                 className="d-block w-100 rounded"
                 alt={picture?.caption}
                 style={{height:'25em'}}
               />
              
               {visiblePicture && !visibleAmenity && (
                 <ImageViewer 
                 images={pictureImages}
                 isOpen={isOpen}
                 setIsOpen={setIsOpen}
                 currentImageIndex={currentImageIndex}
                 setCurrentImageIndex={setCurrentImageIndex}
                 scale={scale}
                 setScale={setScale}
               />
                //  <Viewer visible={visiblePicture} onClose={() => setVisiblePicture(false)} images={pictureImages} />
               )}
               <div className="carousel-caption d-none d-md-block">
                 <p
                   className="fw-bold"
                   style={{
                     zIndex: '100',
                     color: 'wheat',
                     textShadow: '2px 2px 4px rgba(1, 50, 32,1)',
                     fontSize: '1rem',
                     backgroundColor: 'rgba(0, 0, 0, 0.5)',
                     padding: '0.5rem',
                     borderRadius: '0.5rem',
                   }}
                 >
                   {picture.caption}
                 </p>
               </div>
             </div>
             ))}
           </div>
           <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${detail?.day}-${index}`} data-bs-slide="prev">
             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Previous</span>
           </button>
           <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${detail?.day}-${index}`} data-bs-slide="next">
             <span className="carousel-control-next-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Next</span>
           </button>
         </div>
       </div>
       <div className="col-md-6">
         <h3 className='card-header text-center text-white px-4 py-3' style={{ fontFamily: 'inknut antiqua' }}>Amenities</h3>
         <div id={`amenities-carousel-${detail?.day}-${index}`} className="carousel slide py-2 mx-auto" data-bs-ride="carousel">
           <div className="carousel-inner">
             {detail?.accommodation?.amenities.map((amenity, amenityIndex) => (
               <div key={amenityIndex} className={`carousel-item ${amenityIndex === 0 ? 'active' : ''}`}>
               <img
                 loading="lazy"
                 onClick={() => {
                  setVisibleAmenity(true)
                  setVisiblePicture(false)
                  openModal(amenityIndex)}}
                 src={amenity?.image}
                 className="d-block w-100 rounded"
                 alt={amenity?.amenity}
                 style={{height:'25em'}}
               />
               
               {visibleAmenity && !visiblePicture && (
                <ImageViewer 
                images={amenityImages}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
                //  <Viewer visible={visibleAmenity} onClose={() => setVisibleAmenity(false)} images={amenityImages} />
               )}
               <div className="carousel-caption d-none d-md-block">
                 <p
                   className="fw-bold"
                   style={{
                     zIndex: '100',
                     color: 'wheat',
                     textShadow: '2px 2px 4px rgba(1, 50, 32,1)',
                     backgroundColor: 'rgba(0, 0, 0, 0.5)',
                     padding: '0.5rem',
                     borderRadius: '0.5rem',
                   }}
                 >
                   {amenity.amenity}
                 </p>
               </div>
             </div>
             ))}
           </div>
           <button className="carousel-control-prev" type="button" data-bs-target={`#amenities-carousel-${detail?.day}-${index}`} data-bs-slide="prev">
             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Previous</span>
           </button>
           <button className="carousel-control-next" type="button" data-bs-target={`#amenities-carousel-${detail?.day}-${index}`} data-bs-slide="next">
             <span className="carousel-control-next-icon" aria-hidden="true"></span>
             <span className="visually-hidden">Next</span>
           </button>
         </div>
         </div>
         </div>

     </div>
  )
}
