import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { ItineraryContext } from '../context/ItineraryContext';
import { VehicleGuideContext } from '../context/VehicleGuideContext';

export default function AddOverview({ mainItinerary, setActiveTab }) {
  const { setOverviewDetails, overViewDetails } = useContext(ItineraryContext);
  const { vehicleGuides } = useContext(VehicleGuideContext);

  // Initialize text state
  const [text, setText] = useState('');

  // Update text state when overViewDetails or mainItinerary change
  useEffect(() => {
    setText(overViewDetails?.overview || mainItinerary?.overview?.text || '');
  }, [overViewDetails, mainItinerary]);

  const [selectedVehicles, setSelectedVehicles] = useState(overViewDetails?.vehicles || []);
  const [image, setImage] = useState(overViewDetails?.image || '');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleVehicleChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedVehicles(selectedIds);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Check if selectedVehicles is an array and if it's empty
    if (Array.isArray(selectedVehicles) && selectedVehicles.length < 1) {
      // If selectedVehicles is empty, display a message
      Swal.fire({
        text: 'Please select a vehicle',
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
    } else {
      // If selectedVehicles is not empty, proceed with form submission
      const formData = {
        overview: text,
        vehicles: selectedVehicles,
        image: image ? image : mainItinerary?.overview?.image,
        image_id: mainItinerary?.overview?.image_id
      };

      // Log the formData
      setOverviewDetails(formData);

      // Display a 'saved' message
      Swal.fire({
        text: "Saved",
        showConfirmButton: false,
        timer: 4000,
        icon: 'success',
        color: 'white',
        background: 'green',
        toast: true,
        position: 'top'
      });
      setActiveTab('itinerary');
    }
  };

  const vehicleOptions = Array.isArray(vehicleGuides) && vehicleGuides.length > 0 && vehicleGuides.map(vehicle => ({
    value: vehicle.id,
    label: `${vehicle.capacity} seater (${vehicle.category}) Guide: ${vehicle.guide.name} Rates: ${vehicle.rates} $`
  }));

  return (
    <div className="container mt-2">
      <div className="row justify-content-center">
        <h2 className="text-center fw-bold mb-2" style={{ color: 'wheat', fontFamily: 'junge' }}>Overview</h2>
        <div className="card col-12 col-md-10 col-lg-8" style={{ backgroundColor: 'rgb(175, 238, 238,0.4)' }}>
          <div className="card-body rounded">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="text" className="form-label">Text:</label>
                <textarea
                  id="text"
                  className="form-control"
                  value={text}
                  autoComplete="on"
                  spellCheck={true}
                  rows="5"
                  onChange={handleTextChange}
                  required
                  autoFocus
                />
              </div>
              <div className="mb-3">
                <label htmlFor="vehicles" className="form-label">Vehicles:</label>
                <Select
                  id="vehicles"
                  options={vehicleOptions}
                  isMulti
                  value={vehicleOptions && vehicleOptions.length > 0 && vehicleOptions.filter(option => selectedVehicles.includes(option.value))}
                  onChange={handleVehicleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="image" className="form-label">Image:</label>
                <input
                  type="file"
                  id="image"
                  className="form-control"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange}
                />
              </div>
              <button style={{ width: '100%' }} type="submit" className="btn btn-success">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
