import React, { useContext, useState, useEffect} from 'react';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
export default function Vehicles() {
  const { vehicleGuides, searchTerm, setSearchTerm, searchResults } = useContext(VehicleGuideContext);
  const navigate = useNavigate()
  
  

  
 const [filteredItems, setFilteredItems] = useState([]);
 const [search, setSearch] = useState(''); // Initialize search state
 const [vehiclesToDisplay, setVehiclesToDisplay] = useState([])
 
 useEffect(() => {
  if (searchTerm !== '' && search !== '') {
     setVehiclesToDisplay(searchResults);
   }else{
     setVehiclesToDisplay(vehicleGuides)
   }
   
   // setVehiclesToDisplay(vehicleGuides)
 }, [searchTerm, vehicleGuides, searchResults, search]);
 
 
   
 
  const handleSearch = (e) => {
   const term = e.target.value.toLowerCase();
   setSearch(term);
 
   if (term.length > 0) {
     const filtered = new Set(); // Use a Set to store unique search results
 
     vehicleGuides.forEach(item => {
       const guideName = item?.guide?.name?.toLowerCase();
       const vehicleModel = item?.model?.toLowerCase();
       const vehiclePlates = item?.plates?.toLowerCase();
 
       if (guideName?.includes(term)) {
         filtered.add(item?.guide?.name);
       }
       if (vehicleModel?.includes(term)) {
         filtered.add(item?.model);
       }
       if (vehiclePlates?.includes(term)) {
         filtered.add(item?.plates);
       }
     });
 
     // Convert Set to an array and sort alphabetically
     const sortedFilteredItems = [...filtered].sort((a, b) => a.localeCompare(b));
     setFilteredItems(sortedFilteredItems);
   } else {
     setFilteredItems([]);
     setVehiclesToDisplay(vehicleGuides)
   }
 };
 function handleSubmit(e){
   e.preventDefault()
   setSearchTerm(search)
   setFilteredItems([]);
 }
 
 const handleItemClick = (item) => {
   setSearch(item);
   setFilteredItems([]);
 };
 

  return (
    <div className='container-fluid py-4' style={{ backgroundColor: 'rgb(1,50,32,1)', minHeight: '100%' }}>
      <h2 className='text-center' style={{ color: 'wheat', fontFamily: 'junge' }}>Vehicles and Guides</h2>
      <div className='d-flex gap-4 flex-wrap mb-3 mx-auto justify-content-center'>
      <form className="input-group position-relative" style={{ maxWidth: '20rem' }} onSubmit={handleSubmit}>
            <input
              type="search"
              className="form-control text-center border border-success"
              placeholder="Search 🔍"
              value={search}
              onChange={handleSearch}
              style={{ fontSize: '1rem' }}
            />
            <button className="btn btn-success" type="submit">🔍</button>
            {filteredItems.length > 0 && (
              <ul className="list-group position-absolute w-100" style={{ top: '100%', zIndex: 1 }}>
                {filteredItems.map((item, index) => (
                  <li
                    key={index}
                    className="list-group-item list-group-item-action"
                    onClick={() => handleItemClick(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
        </form>
        <div className=''>
          <Link to={'/vehicles/add'} className='btn btn-primary'>Add Vehicle</Link>
        </div>
      </div>
      {Array.isArray(vehiclesToDisplay) && vehiclesToDisplay.length > 0 ? (
        <div className='row'>
          {vehiclesToDisplay.map((vehicle) => (
            <div key={vehicle.id} className='col-lg-4 col-md-4 col-sm-6 mb-4 px-4'>
              <motion.div
                className='card border-success'
                onClick={()=> navigate(`/vehicles/${vehicle.id}/details`)}
                style={{ overflow: 'hidden', position: 'relative',height:"20em" }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <img
                  loading="lazy"
                  src={vehicle?.image}
                  className='card-img-top img-fluid'
                  style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(70%)' }}
                  alt={vehicle.title}
                />
                <div
                  className='card-img-overlay d-flex flex-column justify-content-end'
                  style={{ color: 'white' }}
                >
                  <h5 className='card-title'>{vehicle.guide.name}</h5>
                  <small className='card-text'>
                    Vehicle:<small className='ms-3'>{vehicle.model} ({vehicle.category})</small>
                  </small>
                  <small className='card-text'>
                    capacity:<small className='ms-3'>{vehicle.capacity} seater</small>
                  </small>
                </div>
              </motion.div>
            </div>
          ))}
        </div>
      ) : (
        <div className="alert alert-warning" role="alert">
          No vehicles found.
        </div>
      )}
    </div>
  );
}
