import React, { useContext, useState } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';

export default function UpdateAccommodationInfo({ accommodation, setEditDetails}) {

   const {updateDetails} = useContext(AccommodationContext)
  const [updatedAccommodation, setUpdatedAccommodation] = useState({
    accommodation: accommodation.accommodation,
    category: accommodation.category,
    location: accommodation.location,
    email: accommodation.email,
    phone: accommodation.phone,
    id: accommodation.id
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedAccommodation({ ...updatedAccommodation, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateDetails(updatedAccommodation);
    setEditDetails(false);
  };

  return (
    <div id='card' className='container-fluid card col-md-5 my-2' style={{ backgroundColor: 'rgb(175, 238, 238,0.8)' }}>
      <h4 className='card-header text-white bg-success position-relative'>
        Update Details:
      </h4>
      <form onSubmit={handleSubmit}>
        <div className='container-fluid row'>
          <div className="form-group col-md-8">
            <label htmlFor="accommodation">Accommodation:</label>
            <input type="text" className="form-control" id="accommodation" name="accommodation" value={updatedAccommodation.accommodation} onChange={handleInputChange} />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="category">Category:</label>
            <select className="form-control" id="category" name="category" value={updatedAccommodation.category} onChange={handleInputChange}>
              <option value="Hotel">Hotel</option>
              <option value="Camp">Camp</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="location">Location:</label>
          <input type="text" className="form-control" id="location" name="location" value={updatedAccommodation.location} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" className="form-control" id="email" name="email" value={updatedAccommodation.email} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input type="tel" className="form-control" id="phone" name="phone" value={updatedAccommodation.phone} onChange={handleInputChange} />
        </div>
        <div className='d-flex justify-content-around pt-2'>
          <button type="submit" className="btn btn-primary btn-sm">Update</button>
          <button onClick={()=> setEditDetails(false)} className="btn btn-danger btn-sm">Cancel</button>
        </div>
      </form>
    </div>
  );
}
