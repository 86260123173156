import React, { useState, useContext, useEffect } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import { PackageContext } from '../context/PackageContext';
import { FlightContext } from '../context/FlightContext';

export default function AddSingleDay({ packageInfo, details,  setAddEntry }) {
  const { addSingleDay } = useContext(PackageContext);
  const { accommodations } = useContext(AccommodationContext);
  const { destinations } = useContext(DestinationContext);
  const { routes } = useContext(FlightContext);
  const [detail, setDetail] = useState({ day: '', date: '', title: '', description: '', accommodation: '', destination: '', route_id: '', image: null, image_id: '' });
  const [errors, setErrors] = useState({ day: '', date: '', title: '' });

  useEffect(() => {
    validateInputs();
  }, [detail]);

  const validateInputs = () => {
    let newErrors = { day: '', date: '', title: '' };

    // Case-insensitive check if the day already exists
    if (details.some(data => data.day.toString() === detail.day.toString())) {
      newErrors.day = 'Day already exists';
    } else if (detail.day > packageInfo?.inquiry?.days) {
      newErrors.day = 'Cannot add extra days';
    }

    // Case-insensitive check if the title already exists
    if (details.some(data => data.title.toLowerCase() === detail.title.toLowerCase())) {
      newErrors.title = 'Title already exists';
    }

    // Check if the date already exists or is outside the inquiry period
    const inputDate = new Date(detail.date);
    const startDate = new Date(packageInfo?.inquiry?.start_date);
    const endDate = new Date(packageInfo?.inquiry?.end_date);

    if (details.some(data => new Date(data.date).getTime() === inputDate.getTime())) {
      newErrors.date = 'Date already exists';
    } else if (inputDate < startDate || inputDate > new Date(endDate.getTime() + 24 * 60 * 60 * 1000)) {
      newErrors.date = `Date must be between ${startDate.toLocaleDateString()} and ${endDate.toLocaleDateString()}`;
    }

    setErrors(newErrors);
  };

  const handleAdd = (e) => {
    e.preventDefault();

    if (!errors.day && !errors.date && !errors.title && detail.day && detail.title && detail.description && detail.accommodation) {
      addSingleDay(packageInfo?.id, detail);
      setDetail({ day: '', date: '', title: '', description: '', destination: '', accommodation: '', route_id: '', image_id: '', image: null });
      // setUpdatePackage(false);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setDetail({ ...detail, image: file });
  };

  return (
    <div>
      <form onSubmit={handleAdd} style={{backgroundColor: 'rgb(8, 50, 32,0.91)', color:'wheat'}} className='mt-4 card border border-success rounded p-3'>
        <h4 className='text-center mb-2 text-info fw-semibold'>Add Day's Itinerary</h4>
        <div className="container-fluid row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Day:</label>
            <input
              type="number"
              min={1}
              className={`form-control border-success ${errors.day ? 'is-invalid' : ''}`}
              value={detail.day}
              onChange={(e) => setDetail({ ...detail, day: e.target.value })}
              required
            />
            {errors.day && <div className="text-danger">{errors.day}</div>}
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Date</label>
            <input
              type="date"
              className={`form-control border-success ${errors.date ? 'is-invalid' : ''}`}
              value={detail.date}
              onChange={(e) => setDetail({ ...detail, date: e.target.value })}
              required
            />
            {errors.date && <div className="text-danger">{errors.date}</div>}
          </div>
        </div>
        <div className="col-auto mb-3">
          <label className="form-label">Title:</label>
          <input
            type="text"
            className={`form-control border-success ${errors.title ? 'is-invalid' : ''}`}
            value={detail.title}
            onChange={(e) => setDetail({ ...detail, title: e.target.value })}
            required
          />
          {errors.title && <div className="text-danger">{errors.title}</div>}
        </div>
        <div className="col-auto mb-3">
          <label className="form-label">Description:</label>
          <textarea
            className="form-control border-success"
            value={detail.description}
            onChange={(e) => setDetail({ ...detail, description: e.target.value })}
            required
            rows={5}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Accommodation:</label>
          <select
            className="form-select border-success"
            value={detail.accommodation}
            onChange={(e) => setDetail({ ...detail, accommodation: e.target.value })}
            required
          >
            <option value="" disabled>Select Accommodation</option>
            <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
            {Array.isArray(accommodations) && accommodations.length > 0 && accommodations.map((accommodation) => (
              <option
                key={accommodation.id}
                value={accommodation.accommodation}
                style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
              >
                {accommodation.accommodation} ({accommodation.category}) in "{accommodation.location}"
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Destination(site):</label>
          <select
            className="form-select border-success"
            value={detail?.destination}
            onChange={(e) => setDetail({ ...detail, destination: e.target.value })}
            required
          >
            <option value="" disabled>Select destination</option>
            <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
            {Array.isArray(destinations) && destinations.length > 0 && destinations.map((destination) => (
              <option
                key={destination.id}
                value={destination.name}
                style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
              >
                {destination.name} ({destination.location})
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="route_id" className="form-label">Flight(Route)</label>
          <select
            className="form-select"
            id="route_id"
            value={detail?.route_id}
            onChange={(e) => setDetail({ ...detail, route_id: e.target.value })}
          >
            <option value="" disabled>Select flight</option>
            {Array.isArray(routes) && routes.length > 0 && routes.map((route) => (
              <option
                key={route.id}
                value={route.id}
                style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
              >
                {route?.origin?.location} - {route?.destination?.location} ({route?.flight_name}) ${route?.rates}
              </option>
            ))}
            <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
          </select>
        </div>
        <div className="col-md-8 mb-3">
          <label htmlFor="image" className="form-label">Image:</label>
          <input
            type="file"
            id="image"
            className="form-control border-success"
            accept=".png, .jpg, .jpeg"
            onChange={handleImageChange}
            required
          />
        </div>
        <div className='card-footer d-flex flex-wrap justify-content-around pt-3'>
          <button
            style={{ width: '15vh' }}
            type="submit"
            className="btn btn-success"
            disabled={errors.day || errors.date || errors.title}
          >
            Add
          </button>
          <button
            style={{ width: '15vh' }}
            type="button"
            className="btn btn-danger"
            onClick={() => setAddEntry(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
