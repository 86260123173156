import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import Swal from 'sweetalert2'


export const UserContext = createContext()
export default function UserProvider({children}) {

   const {loadingCounter, setLoadingCounter, authToken,onUserChange, setOnUserChange, currentUser} = useContext(AuthContext)
   const [users, setUsers] = useState([])
   const [onchange,setOnchange] = useState(false)
   const [loading, setLoading] = useState(false)

// fetch all users 
useEffect(() => {
  if (authToken && currentUser?.position === 'ADMIN' && [12, 'use'].includes(loadingCounter)) {
    const timer = setTimeout(() => {
      fetch(`https://beshapp.backend.safarinetics.com/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken && authToken}`
        }
      })
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          setUsers(response);
          loadingCounter === 12 ? setLoadingCounter(13) : loadingCounter === 'use' ? setLoadingCounter('log') : setLoadingCounter(null)
          
        }
      });
    }, 1000); // 1 second delay

    // Clean up the timer if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }
}, [authToken, onchange, currentUser, loadingCounter, setLoadingCounter]);


//add user 
function addUser(addedUser){
   setLoading(true)
   fetch(`https://beshapp.backend.safarinetics.com/user/register`, {
     method: "POST",
     headers: {
       Accept: "application/json",
       'Content-Type': 'application/json',
       Authorization: `Bearer ${authToken && authToken}`
     },
     body: JSON.stringify(addedUser)
   })
   .then((res) => res.json())
   .then(response => {
     if(response.success){
      setLoading(false)
      setOnchange(!onchange)
      setLoadingCounter('use')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
     }else{
      setLoading(false)
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
   })
 }




// update details
function updateUser(updatedUser){
   fetch(`https://beshapp.backend.safarinetics.com/user/update`, {
     method: "PUT",
     headers: {
       Accept: "application/json",
       'Content-Type': 'application/json',
       Authorization: `Bearer ${authToken && authToken}`
     },
     body: JSON.stringify(updatedUser)
   })
   .then((res) => res.json())
   .then(response => {
     if(response.success){
      setOnchange(!onchange)
      setLoadingCounter('use')
      setOnUserChange(!onUserChange)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
     }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
   })
 }
 
// update the picture 

function updateUserPicture(formData){
   fetch(`https://beshapp.backend.safarinetics.com/user/update/picture`, {
     method: "PATCH",
     headers: {
      'Content-Type': 'application/json',
      Accept: "application/json",
       Authorization: `Bearer ${authToken && authToken}`
     },
     body: JSON.stringify(formData)
   })
   .then((res) => res.json())
   .then(response => {
    // console(response)
     if(response.success){
      setOnchange(!onchange)
      setLoadingCounter('use')
      setOnUserChange(!onUserChange)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
     }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
   })
 }

//change password

function changePassword(newPassword){
   setLoading(true)
   fetch(`https://beshapp.backend.safarinetics.com/user/update/password`, {
     method: "PATCH",
     headers: {
       Accept: "application/json",
       'Content-Type': 'application/json',
       Authorization: `Bearer ${authToken && authToken}`
     },
     body: JSON.stringify({newPassword})
   })
   .then((res) => res.json())
   .then(response => {
     if(response.success){
      setLoadingCounter('log')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      setLoading(false)
     }
     setLoading(false)
   })
 }




   const contextData = {

      users,onchange,setOnchange,
      updateUser,addUser,updateUserPicture,changePassword,
      loading, setLoading,

   }
  return (
   <UserContext.Provider value={contextData} >
      {children}
   </UserContext.Provider>
  )
}
