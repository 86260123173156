import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { TextField,Grid, Button, MenuItem, Select, InputLabel, FormControl, Container, Typography, IconButton, Snackbar, FormHelperText } from '@mui/material';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import { ItineraryContext } from '../context/ItineraryContext';
import { FlightContext } from '../context/FlightContext';
import { AiOutlineClose } from 'react-icons/ai';

const AddDailyItineraries = () => {
  const { dailyItineraries, setDailyItineraries } = useContext(ItineraryContext);
  const { accommodations } = useContext(AccommodationContext);
  const { destinations } = useContext(DestinationContext);
  const { routes } = useContext(FlightContext);

  const [day, setDay] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [accommodationId, setAccommodationId] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [flightRouteId, setFlightRouteId] = useState('');
  const [add, setAdd] = useState(true);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    validateForm();
  }, [day, title]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if the day already exists
    const isDayExist = dailyItineraries.some(itinerary => itinerary.day === day);
    if (isDayExist) {
      newErrors.day = 'This day already exists in the itinerary.';
    }

    // Check if the number of days is not skipped
    if (parseInt(day) > dailyItineraries.length + 1) {
      newErrors.day = 'Days cannot be skipped in the itinerary.';
    }

    // Check if the title already exists (case-insensitive)
    const isTitleExist = dailyItineraries.some(itinerary => itinerary.title.toLowerCase() === title.toLowerCase());
    if (isTitleExist) {
      newErrors.title = 'This title already exists in the itinerary.';
    }

    setErrors(newErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(errors).length > 0) {
      return;
    }

    // Add new itinerary to the array
    const newItinerary = { day:dailyItineraries?.length + 1, title, description, image, accommodationId, destinationId, flightRouteId };
    setDailyItineraries([...dailyItineraries, newItinerary]);

    // Reset inputs
    setAdd(false);
    setDay('');
    setImage(null);
    setTitle('');
    setDescription('');
    setAccommodationId('');
    setDestinationId('');
    setFlightRouteId('');

    setSnackbarMessage('Saved');
    setSnackbarOpen(true);
  };

  const removeItinerary = (indexToRemove) => {
    const removedPicture = dailyItineraries[indexToRemove].title;
    setDailyItineraries(dailyItineraries.filter((_, index) => index !== indexToRemove));

    setSnackbarMessage(`${removedPicture} removed`);
    setSnackbarOpen(true);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" color='wheat' gutterBottom>
        Add Daily Itineraries
      </Typography>

      <div className="bg-white bg-opacity-50 rounded col-md-12" style={{ height: '100%' }}>
          {dailyItineraries.map((dailyItinerary, index) => (
              <details key={index} className="rounded m-2 bg-info p-4">
                  <summary className="d-flex justify-content-between">
                      Day {dailyItinerary?.day} - {dailyItinerary?.title}
                      {index === dailyItineraries.length - 1 && (  // Check if it's the last item
                          <IconButton onClick={() => removeItinerary(index)} style={{ color: 'red' }}>
                              <AiOutlineClose />
                          </IconButton>
                      )}
                  </summary>
                  <p>day: {dailyItinerary?.day}</p>
                  <p>title: {dailyItinerary?.title}</p>
                  <p>description: {dailyItinerary?.description}</p>
                  <p>accommodationId: {dailyItinerary?.accommodationId}</p>
                  <p>destinationId: {dailyItinerary?.destinationId}</p>
              </details>
          ))}
      </div>


      <div className="mt-4">
        {add ? (
          <form onSubmit={handleSubmit} style={{backgroundColor:'rgb(175, 238, 238,0.9)'}} className="p-4 border-info  rounded">
            <FormControl fullWidth margin="normal">
              <TextField
                type="number"
                id="day"
                name="day"
                label="Day"
                variant="outlined"
                value={dailyItineraries?.length + 1}
                onChange={(e) => setDay(e.target.value)}
                error={!!errors.day}
                helperText={errors.day}
                required
              />
            </FormControl>

            

            <FormControl fullWidth margin="normal">
              <TextField
                type="text"
                id="title"
                name="title"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!!errors.title}
                helperText={errors.title}
                required
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              {/* <InputLabel htmlFor="description">Description</InputLabel> */}
              <TextField
                id="description"
                name="description"
                label="Description"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                required
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="accommodationId">Accommodation</InputLabel>
              <Select
                id="accommodationId"
                value={accommodationId}
                label="Accommodation"
                variant="outlined"
                onChange={(e) => setAccommodationId(e.target.value)}
                required
                // displayEmpty
              >
                <MenuItem value="" disabled>Select accommodation</MenuItem>
                {Array.isArray(accommodations) && accommodations.length > 0 && accommodations.map(accommodation => (
                  <MenuItem key={accommodation.id} value={accommodation.id}>
                    {accommodation?.accommodation} ({accommodation?.category}) = {accommodation?.location}
                  </MenuItem>
                ))}
                <MenuItem value="None">None</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="destinationId">Destination(site)</InputLabel>
              <Select
                id="destinationId"
                value={destinationId}
                label="Destination(site)"
                variant="outlined"
                onChange={(e) => setDestinationId(e.target.value)}
                // displayEmpty
              >
                <MenuItem value="" disabled>Select the category</MenuItem>
                {Array.isArray(destinations) && destinations.length > 0 && destinations.map(destination => (
                  <MenuItem key={destination?.id} value={destination?.id}>
                    {destination?.name} ({destination?.location})
                  </MenuItem>
                ))}
                <MenuItem value="None">None</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="flightRouteId">Flight(Route)</InputLabel>
              <Select
                id="flightRouteId"
                label="Flight(Route)"
                value={flightRouteId}
                onChange={(e) => setFlightRouteId(e.target.value)}
                // displayEmpty
                variant="outlined"
                required
              >
                <MenuItem value="" disabled>Select Route</MenuItem>
                {Array.isArray(routes) && routes.length > 0 && routes.map(route => (
                  <MenuItem key={route.id} value={route.id}>
                    {route?.origin?.location} - {route?.destination?.location} ({route?.flight_name}) ${route?.rates}
                  </MenuItem>
                ))}
                <MenuItem value="None">None</MenuItem>
              </Select>
            </FormControl>
            
            <Grid item md={12}>
            <Button variant="contained" component="label" >
              Upload Image
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
                required

              />
            </Button>
          </Grid>

            <div className="d-flex justify-content-around mt-4">
              <Button disabled={Object.keys(errors).length > 0} type="submit" variant="contained" color="primary">Submit</Button>
              <Button onClick={() => setAdd(false)} variant="contained" color="secondary">Cancel</Button>
            </div>
          </form>
        ) : (
          <Button onClick={() => setAdd(true)} variant="contained" color="primary">Add</Button>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <AiOutlineClose fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default AddDailyItineraries;
