import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="footer" style={{ backgroundColor: '#C0D8B5', padding: '30px 0', boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <p style={{ fontSize: '1rem', margin: '10px 0 0', color: '#333' }}>
              © {new Date().getFullYear()} Besh African Adventures. All rights reserved.
            </p>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0', fontSize: '0.8rem', color: '#555' }}>
              <li style={{ display: 'inline', margin: '0 15px' }}>
                <Link to="/terms" style={{ color: '#007bff', textDecoration: 'none' }}>Terms and Conditions</Link>
              </li>
              <li style={{ display: 'inline'}}>|</li>
              <li style={{ display: 'inline', margin: '0 15px' }}>
                <span style={{ color: '#333' }}>Powered by:</span>
                <a 
                  href='https://safarinetics.com' 
                  rel="noopener noreferrer" 
                  style={{ color: '#007bff', textDecoration: 'none', marginLeft: '5px' }} 
                  onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'} 
                  onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                >
                  Safari Netics
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
