import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Viewer from 'react-viewer';
import ImageViewer from '../utils/ImageViewer'

export default function DayDestination({ detail, index }) {
  const destination = detail?.destination;
  const [images, setImages] = useState([]);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);


    // ==================== IMAGES VIEWER =====================
    // const images=[image1,2,3,4 ...]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================



  useEffect(() => {
    if (destination) {
      const imageArray = destination.pictures.map((picture) => (picture?.image));
      setImages(imageArray);
    }
  }, [destination]);

 

  const destinationDescription =
    destination && typeof destination.description === 'string'
      ? destination.description
      : '';
  const numberOfNewlines = (destinationDescription.match(/\n/g) || []).length + 1; // Count the number of newline characters
  const maxRows = 10; // Set a maximum number of rows to prevent excessive expansion
  const rows = Math.max(numberOfNewlines + 11, maxRows); // Ensure rows do not exceed the maximum

  return (
    <div
      className="container-fluid pt-3"
      style={{ backgroundColor: 'rgba(1, 50, 32, 1)', minHeight: '100%' }}
    >
      <div className="row justify-content-center">
        <h3
          style={{
            paddingLeft: '4vw',
            fontSize: '2rem',
            fontFamily: 'junge',
          }}
          className="text-center text-white px-4 pb-3 fw-medium"
        >
          Destination (site)
        </h3>
        <div className="col-md-11">
          <div
            className="card"
            style={{ backgroundColor: 'rgba(175, 238, 238, 0.97)' }}
          >
            <div className="row no-gutters">
              <div className="col-md-5">
                <div
                  id={`carousel-${detail?.day}-${index}`}
                  className="carousel slide py-2 mx-auto"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {destination?.pictures?.length > 0 &&
                      destination?.pictures.map((picture, picIndex) => (
                        <div
                          key={picIndex}
                          className={`carousel-item ${
                            picIndex === 0 ? 'active' : ''
                          }`}
                        >
                          {/* <Viewer
                            visible={visible}
                            onClose={() => setVisible(false)}
                            images={images}
                            activeIndex={activeIndex}
                          /> */}
                           <ImageViewer 
                            images={images}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            currentImageIndex={currentImageIndex}
                            setCurrentImageIndex={setCurrentImageIndex}
                            scale={scale}
                            setScale={setScale}
                          />
                          <img
                            loading="lazy"
                            onClick={()=> openModal(picIndex)}
                            src={picture?.image}
                            className="d-block w-100 "
                            alt={picture.title}
                          />
                          <div className="carousel-caption d-none d-md-block">
                            <p
                              className="fw-bold"
                              style={{
                                zIndex: '100',
                                color: 'wheat',
                                textShadow: '2px 2px 4px rgba(1, 50, 32,1)',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                padding: '0.5rem',
                                borderRadius: '0.5rem',
                              }}
                            >
                              {picture.title}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target={`#carousel-${detail?.day}-${index}`}
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target={`#carousel-${detail?.day}-${index}`}
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>

              <div className="col-md-7 d-flex flex-column text-dark">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <h5 className="card-title">{destination.name}</h5>
                    <p className="card-text ms-5">
                      <strong>
                        <FaMapMarkerAlt />{' '}
                      </strong>{' '}
                      {destination.location}
                    </p>
                  </div>
                  <textarea
                    style={{
                      width: '98%',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      border: '1px solid rgb(8, 50, 32)',
                      borderRadius: '5px',
                      padding: '10px',
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      lineHeight: '1.5',
                      resize: 'none', // Disable resizing
                    }}
                    readOnly
                    rows={rows}
                    className="card-text mt-2 rounded"
                    value={destination.description}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
