import React, { useContext, useState } from 'react';
import { ItineraryContext } from '../context/ItineraryContext';
import Swal from 'sweetalert2';

export default function PackageDetails({mainItinerary,setActiveTab}) {
  const {setPackageDetails, packageDetails } = useContext(ItineraryContext);

  // State variables for includes and excludes
  const [includes, setIncludes] = useState(Array.isArray(packageDetails?.includes) ? packageDetails.includes.join('\n') :  mainItinerary?.includes?.join('\n')  || '');
  const [excludes, setExcludes] = useState(Array.isArray(packageDetails?.excludes) ? packageDetails.excludes.join('\n') :  mainItinerary?.excludes?.join('\n')  || '');

  
  

  // Function to handle form changes
  const handleChange = (event, setter) => {
    const { value } = event.target;
    setter(value);
  };

  // Function to convert textarea data to array
  const convertToArray = (data) => {
    return data.split('\n').filter(item => item.trim() !== ''); // Split data by newline and remove empty lines
  };

  // Function to handle saving data
  const handleSave = (e) => {
    e.preventDefault();
    const includesArray = convertToArray(includes);
    const excludesArray = convertToArray(excludes);

    
      // Replace existing data with new arrays
      setPackageDetails({includes:includesArray, excludes:excludesArray});
      
   Swal.fire({
    text:"Saved",
    showConfirmButton:false,
    timer:4000,
    icon:'success',
    color:'white',
    background:'green',
    toast:true,
    position:'top'
   });
   setActiveTab('prices')
    

    // Do something with the packageDetails array, for example, you can update state or send it to the server
  
  };



  return (
    <div className="container mt-1 mb-5">
      <div className="row justify-content-center">
        <h2 className='m-2 text-center fw-semibold ' style={{ color:'wheat',fontFamily:'junge'}}>Package Details</h2>
          <div className="card col-md-12 border border-success shadow-lg" style={{ backgroundColor: 'rgb(175, 238, 238,0.4)' }}>
            <div className='container-fluid row  my-4'>
              <form onSubmit={handleSave} >
               <details className="p-3 rounded " style={{backgroundColor: 'rgb(8, 50, 32,1)'}}>
                  <summary className="form-label text-white ps-3 fs-6 fw-semibold">Includes:</summary>
                  <textarea
                    className="form-control "
                    name="includes"
                    value={includes}
                    onChange={(e) => handleChange(e, setIncludes)}
                    autoComplete = {'on'}
                    spellCheck={true}
                    rows='10'
                    cols='100'
                  ></textarea>
                </details>

                <details className="p-3 my-4 rounded " style={{backgroundColor: 'rgb(8, 50, 32,1)'}}>
                  <summary className="form-label text-white ps-3 fs-6 fw-semibold">Excludes:</summary>
                  <textarea
                    className="form-control"
                    name="excludes"
                    value={excludes}
                    onChange={(e) => handleChange(e, setExcludes)}
                    autoComplete = {'on'}
                    spellCheck={true}
                    rows='5'
                  ></textarea>
                </details>
                <div className='text-center'>
                <button style={{width:'20vw'}} type="submit" className="btn btn-success" >Save</button>
                </div>
              </form>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}
