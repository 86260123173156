import React from 'react'

export default function IncludesAndExcludes({packageInfo}) {
  return (
   <div className="container-fluid py-4" >
   <div className="row justify-content-center">
 <h5 className="py-4 fs-3 text-center " style={{
   fontFamily:'inknut antiqua', 
 fontWeight:'bold',
  color:'white',
  }}>Package Includes and Excludes</h5>
     <div className="col-md-4 mt-3">
       <div className="card " style={{color: 'rgb(1, 50, 32,1)', backgroundColor: 'rgb(175, 238, 238,0.98)',height:'100%'}}>
         <div className="card-body">
           <h5 className="card-header bg-success text-light" style={{fontFamily:'junge'}}>Includes</h5>
           { packageInfo?.itinerary?.includes.map((include, index) => (
              <ul key={index}   >
                  <li className="">{include}</li>
              </ul>
          ))}
         </div>
       </div>
     </div>
     <div className="col-md-4 mt-3" >
       <div className="card" style={{color: 'rgb(1, 50, 32,1)', backgroundColor: 'rgb(175, 238, 238,0.98)',height:'100%'}}>
         <div className="card-body">
           <h5 className="card-header bg-success text-light" style={{fontFamily:'junge'}}>Excludes</h5>
           { packageInfo?.itinerary?.excludes.map((exclude, index) => (
              <ul key={index}>
                  <li>{exclude}</li>
              </ul>
          ))}
         </div>
       </div>
     </div>
   </div>
 </div>
  )
}
