import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FlightContext } from '../context/FlightContext';
import { motion } from 'framer-motion';

export default function Flights() {
  const { flights } = useContext(FlightContext);
  const navigate = useNavigate()

  

 

  return (
    <div className="container-fluid py-4" style={{ backgroundColor: 'rgb(1,50,32,1)', minHeight: '100%' }}>
      <h2 className="text-center" style={{ color: 'wheat', fontFamily: 'junge' }}>
        Flights
      </h2>
      <div className="row mb-3 mx-auto justify-content-center">
        <div className="col-md-auto">
          <Link to={'/flights/add'} className="btn btn-primary">
            Add flight
          </Link>
        </div>
      </div>
      {Array.isArray(flights) && flights.length > 0 ? (
        <motion.div className="row"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}>
          {flights.map((flight) => (
            <motion.div
              key={flight.id}
              className="col-lg-3 col-md-4 col-sm-6 mb-4 px-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="card border-success" style={{ backgroundColor: 'rgb(175, 238, 238,0.8)', overflow: 'hidden',height:"15em" }}>
                <motion.img
                  src={flight.image}
                  className="card-img-top img-fluid"
                  style={{height:"100%"}}
                  alt={flight.category}
                  onClick={()=>  navigate(`/flights/${flight.id}/details`)}
                  initial={{ scale: 1.2 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                />
                <div className="card-footer" style={{ position: 'absolute', bottom: 0, width: '100%', backgroundColor:'rgb(0,0,0,0.5)' }}>
                    <h5 className="card-title text-center" style={{ color:'wheat' }}>{flight.name}</h5>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <div className="alert alert-warning" role="alert">
          No flights found.
        </div>
      )}
    </div>
  );
}
