import React, { useContext, useState, useEffect } from 'react';
import { ItineraryContext } from '../context/ItineraryContext';

const UpdateBudget = ({ mainItinerary, setUpdateBudget }) => {
  const { updateBudget } = useContext(ItineraryContext);
  const [includes, setIncludes] = useState('');
  const [excludes, setExcludes] = useState('');

  // Set default values for includes and excludes when mainItinerary is available
  useEffect(() => {
    if (Array.isArray(mainItinerary.includes)) {
      setIncludes(mainItinerary.includes.join('\n'));
    }
    if (Array.isArray(mainItinerary.excludes)) {
      setExcludes(mainItinerary.excludes.join('\n'));
    }
  }, [mainItinerary]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Convert the text areas into arrays
    const includesArray = includes.split('\n').filter(item => item.trim() !== '');
    const excludesArray = excludes.split('\n').filter(item => item.trim() !== '');
    
    // Handle form submission logic here
    const formData = {
      id:mainItinerary.id,
      includes: includesArray,
      excludes: excludesArray
    }
    updateBudget(formData);
    setUpdateBudget(false)

  };

  

  return (
    <div className="container-fluid justify-content-center row pt-4">
      <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'junge' }}>Update Budget</h2>
      <form onSubmit={handleSubmit} className="p-4 col-md-10 border-info bg-light bg-opacity-50 rounded">
        <details className="rounded m-2 bg-info bg-opacity-75 p-2">
          <summary htmlFor="includes" className="form-summary">Includes</summary>
          <textarea
            className="form-control"
            id="includes"
            placeholder='Enter includes here each separated by a new line'
            rows="6"
            value={includes}
            onChange={(e) => setIncludes(e.target.value)}
            required
          ></textarea>
        </details>
        <details className="rounded m-2 bg-info bg-opacity-75 p-2">
          <summary htmlFor="excludes" className="form-summary">Excludes</summary>
          <textarea
            className="form-control"
            id="excludes"
            rows="6"
            placeholder='Enter excludes here each separated by a new line'
            value={excludes}
            onChange={(e) => setExcludes(e.target.value)}
            required
          ></textarea>
        </details>
        <div className="d-flex justify-content-around">
          <button type="submit" className="btn btn-primary">Submit</button>
          <button type="button" className="btn btn-danger" onClick={()=>setUpdateBudget(false)}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default UpdateBudget;
