import React, { useContext, useState,useRef, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import defaultProfile from '../images/defaultProfile.png'
import ProfileDetails from './ProfileDetails';
import { MdCameraAlt } from 'react-icons/md';
import AddUser from './AddUser';
import AllUsers from './AllUsers';
import { UserContext } from '../context/UserContext';
import Viewer from 'react-viewer';
import {uploadImage} from '../images/ImageUploader'
import ImageViewer from '../utils/ImageViewer'

const Profile = () => {
   const {currentUser} = useContext(AuthContext)
   const {updateUserPicture} = useContext(UserContext)
   const [addUser,setAddUser] = useState(false)
   const [viewUsers,setViewUsers] = useState(false)
  const fileInputRef = useRef(null);
  const [visible,setVisible] = useState(false)
  const [image,setImage] = useState([])


    // ==================== IMAGES VIEWER =====================
    const images=[currentUser?.pic || defaultProfile]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================

  useEffect(() => {
   if (currentUser){
      setImage([{
         src:currentUser?.pic || defaultProfile,
         alt:currentUser?.name
      }])
   }
    
  }, [currentUser])
  


  const handleFileInputChange  = async (event) => {
   const file = event.target.files[0];
   if (file){
      try {
         // console(file);
         const result = await uploadImage(file);
         // console(`results, ${result.url}, ${result.id}`);
         // const formData = 
         updateUserPicture({image:result.url,image_id:result.id});
     } catch (error) {
         console.error("Error uploading image:", error);
     }

       
      }

 };

  return (
  <div className='container-fluid  mx-auto pb-4'
   style={{ backgroundColor: 'rgb(1, 50, 32,1)', minHeight: '100%',overflowX:'auto' }}>
   <h2 style={{color:'wheat',fontFamily:'junge'}} className='p-4 text-center fw-bold'>Profile</h2>
    {!addUser && ! viewUsers && (
      <div className="card container-fluid py-5  text-center shadow-lg border border-info"
      style={{ backgroundColor: 'rgb(175, 238, 238,0.2)', minHeight: 'fit-content',width:'80%',minWidth:'fit-content'}}>
       <div className="row container-fluid justify-content-center   no-gutters" style={{}}>
         {/* Main itinerary details */}
         <div className="col-md-6 justify-content-center text-center">
             <h4 className='fw-semibold' style={{ color: "wheat", fontFamily: 'junge' }}>Profile Picture</h4>
             <div style={{ position: 'relative', display: 'inline-block' }}>
             <ImageViewer 
                images={images}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
             <img
               onClick={()=> openModal(currentImageIndex)}
                src={currentUser?.image_id && currentUser.pic ? currentUser?.pic : defaultProfile}
                style={{ width: '10rem', height: '10rem', borderRadius: '50%' }}
                className="card-img img-fluid mb-2 bg-light bg-opacity-50"
                alt={currentUser?.name}
                />
                {/* <Viewer
                  visible={visible}
                  onClose={() => setVisible(false)}
                  images={image}/> */}
 
                <label
                   htmlFor="profilePictureInput"
                   style={{
                      position: 'absolute',
                      bottom: '5%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      cursor: 'pointer',
                   }}
                >
                   <MdCameraAlt size={30} color='olive'/>
                   <input
                      id="profilePictureInput"
                      ref={fileInputRef}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                   />
                </label>
             </div>
          </div>
          <ProfileDetails />
       </div>
       {currentUser?.position === "ADMIN" && (
    <div className='container-fluid card-footer mx-auto row'>
      <div className='col-md-5 my-1 text-center'>
         <button 
         onClick={()=> setAddUser(true)}
      className='btn btn-outline-info'>Add User</button>
      </div>
      
      <div className='col-md-5 my-1 text-center'>
      <button 
      onClick={()=> setViewUsers(true)}
   className='btn btn-outline-info'>View Users</button>
   </div>
    </div>
    )}
     </div>
    )}
   {addUser && (<AddUser setAddUser={setAddUser}/>)}
   {viewUsers && (<AllUsers setViewUsers={setViewUsers}/>)}
   </div>
  );
};

export default Profile;
