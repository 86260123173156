import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { DestinationContext } from '../context/DestinationContext';

const AddDestinationPictures = () => {
  const { pictures, setPictures } = useContext(DestinationContext);
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [add, setAdd] = useState(true);
  const [error, setError] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);

    // Check if the title already exists in the array (case insensitive)
    if (pictures.some(picture => picture.title.toLowerCase() === value.toLowerCase())) {
      setError('This title already exists.');
    } else {
      setError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (error) {
      Swal.fire({
        icon: 'warning',
        toast: true,
        text: error,
        timer: 4000,
        showConfirmButton: false,
        color: 'white',
        background: 'orange',
        position: 'top',
      });
      return;
    }

    // Add picture to the array
    const newPicture = { image, title };
    setPictures([...pictures, newPicture]);

    // Reset inputs
    setAdd(false);
    Swal.fire({
      icon: 'success',
      text: 'Picture saved',
      toast: true,
      showConfirmButton: false,
      color: 'white',
      background: 'green',
      timer: 4000,
      position: 'top',
    });
    setImage('');
    setTitle('');
  };

  const removePicture = (indexToRemove) => {
    const removedPicture = pictures[indexToRemove].title;
    setPictures(pictures.filter((picture, index) => index !== indexToRemove));

    Swal.fire({
      icon: 'success',
      text: `${removedPicture} removed`,
      toast: true,
      showConfirmButton: false,
      color: 'wheat',
      background: 'green',
      timer: 4000,
      position: 'top',
    });
  };

  return (
    <div className="container-fluid mx-auto rounded mt-3 row justify-content-center">
      <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'junge' }}>Add Pictures</h2>
      <div className='bg-white bg-opacity-50 rounded col-md-10' style={{ height: '100%' }}>

        {Array.isArray(pictures) && pictures.length > 0 && pictures.map((picture, index) => (
          <details key={index} className='rounded m-2 bg-info bg-opacity-10 p-2'>
            <summary className='d-flex justify-content-between'>
              {index + 1}. {picture.title}
              <button className="btn btn-sm ms-auto" onClick={() => removePicture(index)} style={{ color: 'red', fontWeight: 'bold', background: 'none', border: 'none' }}>X</button>
            </summary>
          </details>
        ))}

      </div>
      <div className='mt-4 col-md-10'>
        {add ? (
          <form onSubmit={handleSubmit} className='p-3 border-info bg-light bg-opacity-50 rounded'>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={title}
                onChange={handleTitleChange}
                required
              />
              {error && <small className="text-danger">{error}</small>}
            </div>
            <div className='d-flex justify-content-around'>
              <button type="submit" className="btn btn-primary" disabled={!!error}>Save</button>
              <button onClick={() => setAdd(false)} className="btn btn-danger">Cancel</button>
            </div>
          </form>
        ) : (
          <button style={{ right: '10%' }} onClick={() => setAdd(true)} className='btn btn-primary'>Add Picture</button>
        )}
      </div>
    </div>
  );
};

export default AddDestinationPictures;
