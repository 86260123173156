import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function ProfileDetails() {
   const {currentUser} = useContext(AuthContext)
  //  const [update, setUpdate] = useState(false)
  const navigate = useNavigate()
  return (
   <div className="col-md-6 shadow-lg pb-3 d-flex justify-content-center flex-column rounded" 
         style={{minWidth:'fit-content',maxWidth:'45%',backgroundColor: 'rgb(175, 238, 238,0.8)'}}>
         
            <h4 className='card-header fw-semibold'
             style={{color:"green", fontFamily:'junge'}}>Profile Details</h4>
          <div className="card-body ">
                       
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>Name:</strong></h6>
              <p className="card-text m-2">{currentUser?.name}</p>
            </div>      
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>Position:</strong></h6>
              <p className="card-text m-2">{currentUser?.position}</p>
            </div>      
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>Email:</strong></h6>
              <p className="card-text m-2">{currentUser?.email}</p>
            </div>        
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>Personal Email:</strong></h6>
              <p className="card-text m-2">{currentUser?.personal_email}</p>
            </div>      
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>Phone:</strong></h6>
              <p className="card-text m-2">{currentUser?.phone}</p>
            </div>      
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>Registered:</strong></h6>
              <p className="card-text m-2">{currentUser?.registered_at}</p>
            </div>    
            <div className='d-flex align-items-center'>
              <h6 className="card-text mt-2"><strong>last Update:</strong></h6>
              <p className="card-text m-2">{currentUser?.updated_at|| 'Not updated'}</p>
            </div>
          </div>
         <div className="card-footer text-center">

         {/* <ChangePassword/> */}
         <button 
      // style={{right:'10%'}} 
      onClick={() => navigate('/auth/change-password')}
       className='btn btn-success'
       style={{width:'fit-content'}}
       >Change Password</button>
         </div>
        </div>
  )
}
