import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { AccommodationContext } from '../context/AccommodationContext';
import { TextField, Button, MenuItem, Container, Typography, Box, Grid, FormHelperText } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddAccommodationDetails = ({ setActiveTab }) => {
  const { setDetails, details, accommodations } = useContext(AccommodationContext);
  const [accommodation, setAccommodation] = useState(details?.accommodation || '');
  const [location, setLocation] = useState(details?.location || '');
  const [category, setCategory] = useState(details?.category || '');
  const [email, setEmail] = useState(details?.email || '');
  const [phone, setPhone] = useState(details?.phone || '');

  const [errors, setErrors] = useState({
    accommodation: '',
    email: '',
    phone: ''
  });

  const validateAccommodation = (accommodation) => {
    const accommodationExists = accommodations.some(acc => acc.accommodation.toLowerCase() === accommodation.toLowerCase());
    return accommodationExists ? 'This accommodation already exists.' : '';
  };

  const validateEmail = (email) => {
    const emailExists = accommodations.some(acc => acc.email.toLowerCase() === email.toLowerCase());
    return emailExists ? 'This email is already in use.' : '';
  };

  const validatePhone = (phone) => {
    if (!phone) return ''; // Early return for empty input
  
    // Check if the phone number already exists
    const phoneExists = accommodations.some(acc => acc.phone === phone);
  
    // Validate phone length
    const isValidLength = phone.length >= 7 && phone.length <= 15;
    
    // Return error messages based on conditions
    if (phoneExists) {
      return 'This phone number is already in use.';
    } else if (!isValidLength) {
      return 'Invalid phone number. It should be between 7 and 15 digits.';
    } else {
      return '';
    }
  };

  const handleAccommodationChange = (e) => {
    const value = e.target.value;
    setAccommodation(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      accommodation: validateAccommodation(value)
    }));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setErrors(prevErrors => ({
      ...prevErrors,
      email: validateEmail(value)
    }));
  };

  const handlePhoneChange = (value) => {
    setPhone(`+${value}`);
    setErrors(prevErrors => ({
      ...prevErrors,
      phone: validatePhone(value)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAccommodationChange({ target: { value: accommodation } }); // Validate accommodation
    handleEmailChange({ target: { value: email } }); // Validate email
    handlePhoneChange(phone); // Validate phone

    if (Object.values(errors).some(error => error !== '')) {
      Swal.fire({
        text: "Please correct the errors before submitting.",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'black',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    const formData = { accommodation, location, category, email, phone };
    setDetails(formData);

    Swal.fire({
      text: "Saved",
      showConfirmButton: false,
      timer: 4000,
      icon: 'success',
      color: 'white',
      background: 'green',
      toast: true,
      position: 'top'
    });

    setActiveTab('itinerary');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom style={{color:'wheat', fontFamily:'junge'}}>
        Add Accommodation Details
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="on" 
      sx={{
          backgroundColor: 'rgba(175, 238, 238, 0.9)', // Using rgba for background color with transparency
          p: 3,
          borderRadius: 2,
        }}>
        <TextField
          label="Accommodation"
          variant="outlined"
          fullWidth
          margin="normal"
          value={accommodation}
          onChange={handleAccommodationChange}
          error={!!errors.accommodation}
          helperText={errors.accommodation}
          required
        />
        <TextField
          label="Location"
          variant="outlined"
          fullWidth
          margin="normal"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          margin="normal"
          select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          <MenuItem value="" disabled>Select the category</MenuItem>
          <MenuItem value="Hotel">Hotel</MenuItem>
          <MenuItem value="Camp">Camp</MenuItem>
        </TextField>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
          error={!!errors.email}
          helperText={errors.email}
          required
        />
        <Grid item xs={12} sm={6}>
          <PhoneInput
            country={'ke'}
            value={phone}
            onChange={handlePhoneChange}
            inputStyle={{ width: '100%', height: '56px' }}
            buttonStyle={{ height: '56px' }}
            dropdownStyle={{ color: '#000' }}
            dropdownClass="custom-dropdown"
            required
          />
          {errors.phone && (
            <FormHelperText error>{errors.phone}</FormHelperText>
          )}
        </Grid>
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default AddAccommodationDetails;
