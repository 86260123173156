import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { ItineraryContext } from '../context/ItineraryContext';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Container, Grid, Typography } from '@mui/material';

const AddDetails = ({ setActiveTab }) => {
  const { details, setDetails, mainItineraries } = useContext(ItineraryContext);

  const [title, setTitle] = useState(details?.title || '');
  const [budget, setBudget] = useState(details?.budget || '');
  const [category, setCategory] = useState(details?.category || '');
  const [overview, setOverview] = useState(details?.overview || '');
  const [image, setImage] = useState(details?.image || '');
  const [titleError, setTitleError] = useState(''); // State for title validation error

  // Validate title in real-time
  useEffect(() => {
    const validateTitle = (title) => {
      if (mainItineraries.some(itinerary => itinerary.title.toLowerCase() === title.toLowerCase())) {
        setTitleError('Title already exists.');
      } else {
        setTitleError('');
      }
    };

    validateTitle(title);
  }, [title, mainItineraries]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (image) {
      if (!titleError) {
        setDetails({ title, budget, category, overview, image });
      

        Swal.fire({
          text: "Saved",
          showConfirmButton: false,
          timer: 4000,
          icon: 'success',
          color: 'white',
          background: 'green',
          toast: true,
          position: 'top'
        });
        setActiveTab('itinerary');
      } else {
        Swal.fire({
          text: titleError,
          showConfirmButton: false,
          timer: 4000,
          icon: 'warning',
          color: 'black',
          background: 'orange',
          toast: true,
          position: 'top'
        });
      }
    } else {
      Swal.fire({
        text: 'Add an image first',
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
    }
  };

  return (
    <Container maxWidth="md" className="mt-4 rounded" style={{ backgroundColor: 'rgb(175, 238, 238,0.9)' }}>
      <Typography variant="h4" align="center" gutterBottom style={{ color: 'forestgreen', fontFamily: 'junge' }}>
        Add Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              error={!!titleError} // Show error if titleError has a value
              helperText={titleError} // Display error message
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Budget"
              variant="outlined"
              inputProps={{ min: 100 }}
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Category"
                required
              >
                <MenuItem value="" disabled>
                  Select the category
                </MenuItem>
                <MenuItem value="Executive">Executive</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
                <MenuItem value="Customized">Customized</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Overview"
              variant="outlined"
              multiline
              rows={4}
              value={overview}
              onChange={(e) => setOverview(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" component="label" fullWidth>
              Upload Image
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
                required
              />
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" disabled={titleError} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddDetails;
