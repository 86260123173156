import React, { createContext, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import { uploadImage } from '../images/ImageUploader';

export const AccommodationContext = createContext()
export default function AccommodationProvider({children}) {

  const navigate = useNavigate()
  const {loadingCounter, setLoadingCounter, authToken} = useContext(AuthContext)
   const [accommodations, setAccommodations] = useState([])
   const [category,setCategory] = useState('all')
  const [searchTerm, setSearchTerm] = useState('')
  const [loading,setLoading] = useState(false)
  const [onAccChange, setOnAccChange] = useState(false)
  const [categoryResults, setCategoryResults] = useState([])
  const [searchResults,setSearchResults] = useState([])

  // add accommodation states 
  // const [details,setDetails] = useState({})

  //details
const [details, setDetails] = useState(() => {
  const savedDetails = localStorage.getItem('details');
  return savedDetails ? JSON.parse(savedDetails) : {};
});

useEffect(() => {
  localStorage.setItem('details', JSON.stringify(details));
}, [details]);

  // const [rates, setRates] = useState({})
  //rates
  const [rates, setRates] = useState(() => {
    const savedRates = localStorage.getItem('rates');
    return savedRates ? JSON.parse(savedRates) : {};
  });
  
  useEffect(() => {
    localStorage.setItem('rates', JSON.stringify(rates));
  }, [rates]);


  // const [pictures,setPictures] = useState([])
    //pictures
    const [pictures, setPictures] = useState(() => {
      const savedPictures = localStorage.getItem('pictures');
      return savedPictures ? JSON.parse(savedPictures) : [];
    });
    
    useEffect(() => {
      localStorage.setItem('pictures', JSON.stringify(pictures));
    }, [pictures]);

  // const [amenities, setAmenities] = useState([])
  //amenities
  const [amenities, setAmenities] = useState(() => {
    const savedAmenities = localStorage.getItem('amenities');
    return savedAmenities ? JSON.parse(savedAmenities) : [];
  });
  
  useEffect(() => {
    localStorage.setItem('amenities', JSON.stringify(amenities));
  }, [amenities]);


/// function to add a new accommodation 
async function addAccommodation() {
  setLoading(true)
  if (!details || Object.values(details).some(value => !value)) {
    showAlert('Please add accommodation details');
  } else if (!rates || Object.values(rates).some(value => !value)) {
    showAlert('Please add accommodation rates');
  } else if (!pictures || pictures.length === 0) {
    showAlert('Please add accommodation pictures');
  } else if (!amenities || amenities.length === 0) {
    showAlert('Please add accommodation amenities');
  } else {
    const picturesUrl = [];
    const amenitiesUrl = [];

    try {
      for (const picture of pictures) {
        const result = await uploadImage(picture.image);
        picturesUrl.push({ image: result.url, image_id: result.id, caption: picture.caption });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showAlert('Error uploading pictures');
      return;
    }

    try {
      for (const amenity of amenities) {
        const result = await uploadImage(amenity.image);
        amenitiesUrl.push({ image: result.url, image_id: result.id, amenity: amenity.amenity });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showAlert('Error uploading amenities');
      return;
    }

    const formData = new FormData();
    formData.append('details', JSON.stringify(details));
    formData.append('rates', JSON.stringify(rates));
    formData.append('pictures', JSON.stringify(picturesUrl));
    formData.append('amenities', JSON.stringify(amenitiesUrl));

    try {
      const response = await fetch(`https://beshapp.backend.safarinetics.com/accommodations/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        body: formData
      });
      const result = await response.json();

      if (result.success) {
        setOnAccChange(!onAccChange);
        setLoadingCounter('acc');
        showAlert(result.success, 'success');
        navigate(`/accommodations/${result.id}`);

        // Clearing objects
        setDetails({});
        setRates({});
        setPictures([]);
        setAmenities([]);

        // Clearing local storage
        localStorage.removeItem('details');
        localStorage.removeItem('rates');
        localStorage.removeItem('pictures');
        localStorage.removeItem('amenities');
      } else {
        showAlert(result.error, 'error');
      }
    } catch (error) {
      console.error(error);
      showAlert('An unexpected error occurred');
    }finally {
      setLoading(false);
    }
  }
}

function showAlert(message, type = 'info') {
  const colors = {
    success: 'green',
    error: 'red',
    info: 'lightblue'
  };
  Swal.fire({
    text: message,
    color: 'white',
    background: colors[type],
    icon: type,
    toast: true,
    showConfirmButton: false,
    timer: 4000,
    position: 'top-end'
  });
}


//======================================= GET / FETCH ======================================

// +++++++++++++++++++++++ GET ALL ACCOMMODATIONS +++++++++++++++++++++
useEffect(() => {
  if (authToken && searchTerm === '' && category === 'all' && [5, 'acc'].includes(loadingCounter)) {
    setLoading(true);

    const timer = setTimeout(() => {
      fetch('https://beshapp.backend.safarinetics.com/accomodations', {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`
        }
      })
      .then(res => res.json())
      .then(response => {
        loadingCounter === 5 ? setLoadingCounter(6) : setLoadingCounter(null);
        if (!response.error) {
          setAccommodations(response);
          loadingCounter === 5 ? setLoadingCounter(6) : setLoadingCounter(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    }, 1000); // 1-second delay

    // Clean up the timer if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }
}, [searchTerm, category, authToken, onAccChange, loadingCounter, setLoadingCounter]);



// +++++++++++++++++++++++ GET ACCOMMODATIONS BY NAME AND LOCATION +++++++++++++++++++++

useEffect(() => {
  if (authToken && searchTerm !== '') {
    setLoading(true);
    setCategory('all'); // Assuming you want to reset the category to 'all' when searching
    
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/search/${searchTerm}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`
      }
    })
    .then(res => res.json())
    .then(response => {
      setSearchResults(response);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }
}, [authToken, searchTerm]);

// +++++++++++++++++++++++ GET ACCOMMODATIONS BY CATEGORY +++++++++++++++++++++

useEffect(() => {
  if (authToken && category !== 'all') {
    setLoading(true);
    
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/category/${category}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`
      }
    })
    .then(res => res.json())
    .then(response => {
      setCategoryResults(response);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }
}, [authToken, category]);






  //  function to update accommodation details 
  function updateDetails(updatedDetails){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/details/${updatedDetails?.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: JSON.stringify(updatedDetails)
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
        setLoadingCounter('acc')
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }


  //  function to update accommodation rates 
  function updateRates(updatedRates){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/rates/${updatedRates?.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: JSON.stringify(updatedRates)
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }


  //  function to update accommodation picture 
  function updatePicture(id,updatedPicture){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/pictures/update/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: updatedPicture
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }

   //  function to add an accommodation picture 
   async function addSinglePicture(id, addedPicture) {
    try {
      const response = await fetch(`https://beshapp.backend.safarinetics.com/accommodations/pictures/add/${id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken && authToken}`
        },
        body: addedPicture // Assuming this is a FormData or JSON, no need for JSON.stringify if it's FormData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setOnAccChange(!onAccChange);
        Swal.fire({
          icon: 'success',
          toast: true,
          text: data.success,
          timer: 4000,
          showConfirmButton: false,
          color: 'white',
          background: 'green',
          position: 'top-end'
        });
      } else {
        Swal.fire({
          icon: 'error',
          toast: true,
          text: data.error,
          timer: 4000,
          showConfirmButton: false,
          color: 'white',
          background: 'red',
          position: 'top-end'
        });
      }
    } catch (error) {
      console.error("Error adding picture:", error);
      Swal.fire({
        icon: 'error',
        toast: true,
        text: 'An unexpected error occurred',
        timer: 4000,
        showConfirmButton: false,
        color: 'white',
        background: 'red',
        position: 'top-end'
      });
    }
  }
  

  //  function to update accommodation amenity 
  function updateAmenity(id, updatedAmenity){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/amenities/update/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body:updatedAmenity
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }

   //  function to add an accommodation amenity 
   async function addSingleAmenity(id, addedAmenity) {
    try {
      const response = await fetch(`https://beshapp.backend.safarinetics.com/accommodations/amenities/add/${id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken && authToken}`,
        },
        body: addedAmenity // Convert the body to JSON
      });
      
      const data = await response.json();
      
      if (data.success) {
        setOnAccChange(!onAccChange);
        Swal.fire({
          icon: 'success',
          toast: true,
          text: data.success,
          timer: 4000,
          showConfirmButton: false,
          color: 'white',
          background: 'green',
          position: 'top-end'
        });
      } else {
        Swal.fire({
          icon: 'error',
          toast: true,
          text: data.error,
          timer: 4000,
          showConfirmButton: false,
          color: 'white',
          background: 'red',
          position: 'top-end'
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        toast: true,
        text: 'An unexpected error occurred',
        timer: 4000,
        showConfirmButton: false,
        color: 'white',
        background: 'red',
        position: 'top-end'
      });
    }
  }
  

  //  function to delete an amenity 
  function deleteAmenity(id){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/amenities/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }

  //  function to delete a picture 
  function deletePicture(id){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/pictures/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }

   //  function to delete an accommodation 
   function deleteAccommodation(id){
    fetch(`https://beshapp.backend.safarinetics.com/accommodations/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnAccChange(!onAccChange)
        setLoadingCounter('acc')
      // Swal.fire(response.success)
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      navigate('/accommodations')
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }
   

   const contextData = {
      accommodations,addAccommodation,deleteAccommodation,
      details,setDetails,
      rates, setRates,
      pictures,setPictures,
      amenities, setAmenities,
      category,setCategory,
      searchTerm,setSearchTerm,loading,
      updateDetails,onAccChange, setOnAccChange,
      updateRates,updatePicture,addSinglePicture,
      updateAmenity,addSingleAmenity,deleteAmenity,deletePicture,
      searchResults,setSearchResults,
      categoryResults, setCategoryResults

   }
  return (
    <AccommodationContext.Provider value={contextData} >
      {children}
    </AccommodationContext.Provider>
  )
}
