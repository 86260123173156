import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import { useParams } from 'react-router-dom';
import { uploadImage } from '../images/ImageUploader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const UpdateVehicle = () => {
  const { vehicleGuide, updateVehicle, vehicleGuides } = useContext(VehicleGuideContext);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    model: vehicleGuide?.model || '',
    category: vehicleGuide?.category || '',
    capacity: vehicleGuide?.capacity || 0,
    rates: vehicleGuide?.rates || '',
    plates: vehicleGuide?.plates || '',
    image: vehicleGuide?.image || '',
    image_id: vehicleGuide?.image_id || '',
    name: vehicleGuide?.guide?.name || '',
    email: vehicleGuide?.guide?.email || '',
    phone: vehicleGuide?.guide?.phone || ''
  });
  const [errors, setErrors] = useState({});

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'plates'){

      setFormData((prevState) => ({
        ...prevState,
        [name]: value?.toUpperCase(),
      }));
    }else {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  };

  useEffect(() => {
    const validationErrors = {};

    // Email validation
    if (formData.email) {
      const emailExists = vehicleGuides.some(vehicle =>
        vehicle?.guide?.email?.toLowerCase() === formData.email.toLowerCase() &&
        vehicle?.id !== vehicleGuide?.id // Exclude the current vehicle's email
      );
      if (emailExists) validationErrors.email = 'Email already exists';
    }

    // Phone validation
    if (formData.phone) {
      const phoneExists = vehicleGuides.some(vehicle =>
        vehicle?.guide?.phone === formData.phone &&
        vehicle?.id !== vehicleGuide?.id // Exclude the current vehicle's phone
      );
      if (phoneExists) validationErrors.phone = 'Phone number already exists';
      const isValid = isValidPhone(formData.phone);
      if (!isValid) validationErrors.phone = 'Invalid Phone number';
    }

    // Name validation
    if (formData.name) {
      const namePattern = /^[A-Za-z]+(\s[A-Za-z]+)+$/;
      if (!namePattern.test(formData.name.trim())) validationErrors.name = 'Please Enter Your full Name';
    }

    // Plates validation
    if (formData.plates) {
      const platesExists = vehicleGuides.some(vehicle =>
        vehicle.plates.toUpperCase() === formData.plates.toUpperCase() &&
        vehicle?.id !== vehicleGuide?.id // Exclude the current vehicle's plates
      );
      if (platesExists) validationErrors.plates = 'Plates already exist';
    }

    setErrors(validationErrors);
  }, [formData, vehicleGuides, id]);

  async function handleSubmit(e) {
    e.preventDefault();

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        text: 'Please fix the errors before submitting',
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    try {
      if (formData.image instanceof File) {
        const result = await uploadImage(formData.image);
        formData.image = result.url;
        formData.image_id = result.id;
      }

      updateVehicle(id, formData);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const isValidPhone = (phone) => {
    const phoneRegex = /^\+[\d\s()-]{7,20}$/;
    return phoneRegex.test(phone);
  };
  const handlePhoneChange = (value) => {

    setFormData((prevState) => ({
      ...prevState,
      ['phone']: `+${value}`,
    }));
    
    };

  return (
    <div className="container justify-content-center mt-5">
      <div className="col-md-6 mx-auto">
        <div className="card p-4" style={{ backgroundColor: 'rgb(1,50,32,1)', borderRadius: '10px' }}>
          <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'inknut antiqua' }}>Update Vehicle and Guide</h2>
          <form onSubmit={handleSubmit} className='text-white'>
            <h5 style={{ color: 'wheat' }}>Guide details</h5>
            <div className="mb-3 col-md-8">
              <label htmlFor="guide_name" className="form-label">Name:</label>
              <input
                type="text"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div className='container-fluid row'>
              <div className="mb-3 col-md-6">
                <label htmlFor="guide_email" className="form-label">Email:</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="guide_phone" className="form-label">Phone:</label>
                <PhoneInput
                    country={'ke'}
                    required
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: 'fit-content' }}
                    // buttonStyle={{ height: '56px' }}
                    dropdownStyle={{ color: '#000' }}
                    dropdownClass="custom-dropdown"
                    className={` ${errors.phone ? 'is-invalid' : ''}`}
                  />
                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
              </div>
            </div>

            <h5 style={{ color: 'wheat' }}>Vehicle details</h5>
            <div className="container-fluid row">
              <div className="mb-3 col-md-6">
                <label htmlFor="model" className="form-label">Model (Type):</label>
                <input
                  type="text"
                  className="form-control"
                  id="model"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="category" className="form-label">Category:</label>
                <select
                  className="form-control"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select a category</option>
                  <option value="safari">Safari</option>
                  <option value="transfer">Transfer</option>
                </select>
              </div>
            </div>

            <div className="container-fluid row mb-4">
              <div className="mb-3 col-md-4">
                <label htmlFor="capacity" className="form-label">Capacity:</label>
                <input
                  type="number"
                  className="form-control"
                  id="capacity"
                  name="capacity"
                  value={formData.capacity}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="rates" className="form-label">Rates/day ($):</label>
                <input
                  type="number"
                  className="form-control"
                  id="rates"
                  name="rates"
                  value={formData.rates}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="plates" className="form-label">Plates:</label>
                <input
                  type="text"
                  className={`form-control ${errors.plates ? 'is-invalid' : ''}`}
                  id="plates"
                  name="plates"
                  value={formData.plates}
                  onChange={handleChange}
                  required
                />
                {errors.plates && <div className="invalid-feedback">{errors.plates}</div>}
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="image" className="form-label">Image:</label>
              <input
                type="file"
                className="form-control"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>

            <div className='d-flex flex-wrap justify-content-around gap-4'>
            <button
              type="submit"
              className="btn"
              disabled={Object.keys(errors).length > 0}
              style={{
                backgroundColor: 'green',
                color: 'white',
                borderRadius: '8px',
                width: '40%',
                padding:'5px 10px'
              }}
            >
              Update Vehicle
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => window.history.back()}
              style={{
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '8px',
                width: '40%',
              }}
            >
              cancel
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateVehicle;
