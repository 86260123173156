import React, { useContext, useEffect, useState } from 'react';
import { ItineraryContext } from '../context/ItineraryContext';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import { FlightContext } from '../context/FlightContext';
import { motion } from 'framer-motion';
import PricesCalculationFormula from '../components/PricesCalculationFormula';

export default function AddPrices({ inquiry, setActiveTab, triggerAddPrice, setTriggerAddPrice }) {
   const { setPrices, prices, newItinerary, overViewDetails } = useContext(ItineraryContext);
   const { accommodations } = useContext(AccommodationContext);
   const { destinations } = useContext(DestinationContext);
   const { vehicleGuides } = useContext(VehicleGuideContext);
   const { flights } = useContext(FlightContext);

   const [transferDays, setTransferDays] = useState(prices?.transferDays || 0);
   const [safariDays, setSafariDays] = useState(prices?.safariDays || inquiry?.days);

   // State variables to hold form data
   const [parkAdults, setParkAdults] = useState(prices?.parkAdults || 0);
   const [parkChildren, setParkChildren] = useState(prices?.parkChildren || 0);
   const [hotelAdults, setHotelAdults] = useState(prices?.hotelAdults || 0);
   const [hotelAdultsSharing, setHotelAdultsSharing] = useState(prices?.hotelAdultsSharing || 0);
   const [hotelChildren, setHotelChildren] = useState(prices?.hotelChildren || 0);
   const [transport, setTransport] = useState(prices?.transport || 0);
   const [commission, setCommission] = useState(prices?.commission || 20);

   // State to track completion of the first useEffect
   const [firstEffectComplete, setFirstEffectComplete] = useState(false);


   const [adultsTotal, setAdultsTotal] = useState(0)
   const [adultsSharingTotal, setAdultsSharingTotal] = useState(0)
   const [childrenTotal, setChildrenTotal] = useState(0)
   const [grandTotal, setGrandTotal] = useState(0)

   useEffect(() => {
      let totalAdultRates = 0;
      let totalAdultsSharingRates = 0;
      let totalChildrenRates = 0;
      let totalDestinationAdults = 0;
      let totalDestinationChildren = 0;
      let totalVehicles = 0;
      let totalTransfers = 0;
      let totalFlights = 0;

      if (newItinerary?.length > 0) {
         newItinerary.forEach(i => {
            const matchedAccommodation = accommodations?.find(acc => acc.accommodation === i.accommodation);
            if (matchedAccommodation) {
               totalAdultRates += matchedAccommodation.rates?.adult_rates || 0;
               totalAdultsSharingRates += matchedAccommodation.rates?.adults_sharing_rates || 0;
               totalChildrenRates += matchedAccommodation.rates?.children_rates || 0;
            }

            const matchedDestination = destinations?.find(des => des.name === i.destination);
            if (matchedDestination) {
               totalDestinationAdults += matchedDestination.rates?.adults || 0;
               totalDestinationChildren += matchedDestination.rates?.children || 0;
            }

            flights?.forEach(flight => {
               const matchedRoute = flight.routes.find(r => r.id === parseInt(i?.flight_route));
               if (matchedRoute) {
                  totalFlights += matchedRoute.rates || 0;
               }
            });
         });

         const vehicles = overViewDetails?.vehicles;
         vehicles?.forEach(id => {
            const matchedVehicle = vehicleGuides?.find(veh => veh.id === id);
            if (matchedVehicle?.category === 'Safari') {
               totalVehicles += (matchedVehicle.rates * safariDays) / (inquiry?.adults + inquiry?.children + inquiry?.adults_sharing);
            } else if (matchedVehicle?.category === 'Transfer') {
               totalTransfers += (matchedVehicle.rates * transferDays) / (inquiry?.adults + inquiry?.children + inquiry?.adults_sharing);
            }
         });

         // Update the state variables
         setHotelAdults(totalAdultRates);
         setHotelAdultsSharing(inquiry?.adults_sharing ? totalAdultsSharingRates : 0);
         setHotelChildren(inquiry?.children ? totalChildrenRates : 0);

         setParkAdults(totalDestinationAdults);
         setParkChildren(inquiry?.children ? totalDestinationChildren : 0);
         setTransport(totalFlights + totalTransfers + totalVehicles);

         // Indicate that the first effect is complete
         setFirstEffectComplete(true);
         setTriggerAddPrice(true);
      }
   }, [newItinerary, accommodations, destinations, inquiry, flights, transferDays, safariDays, vehicleGuides, overViewDetails,triggerAddPrice,setTriggerAddPrice]);

   useEffect(() => {
      // Only run the second effect after the first one is complete
      if (firstEffectComplete) {
         const adultsTotal = inquiry?.adults > 0 ? ((parkAdults + hotelAdults + transport) + ((commission / 100) * (parkAdults + hotelAdults + transport))).toFixed(2) : 0;
         const adultsSharingTotal = inquiry?.adults_sharing > 0 ? ((parkAdults + hotelAdultsSharing + transport) + ((commission / 100) * (parkAdults + hotelAdultsSharing + transport))).toFixed(2) : 0;
         const childrenTotal = inquiry?.children > 0 ? ((parkChildren + hotelChildren + transport) + ((commission / 100) * (parkChildren + hotelChildren + transport))).toFixed(2) : 0;

         const grandTotal = (
            (parseFloat(adultsTotal) * (inquiry?.adults || 0)) +
            (parseFloat(adultsSharingTotal) * (inquiry?.adults_sharing || 0)) +
            (parseFloat(childrenTotal) * (inquiry?.children || 0))
         ).toFixed(2);

         setAdultsTotal(adultsTotal)
         setAdultsSharingTotal(adultsSharingTotal)
         setChildrenTotal(childrenTotal)
         setGrandTotal(grandTotal)

         // Check if totals are not all zero
         if (parseFloat(adultsTotal) > 0 || parseFloat(adultsSharingTotal) > 0 || parseFloat(childrenTotal) > 0) {
            setPrices({
               parkAdults,
               parkChildren,
               hotelAdults,
               hotelAdultsSharing,
               hotelChildren,
               transport: parseFloat(transport),
               commission,
               adultsTotal: parseFloat(adultsTotal),
               adultsSharingTotal: parseFloat(adultsSharingTotal),
               childrenTotal: parseFloat(childrenTotal),
               currency: inquiry?.currency,
               transferDays,
               safariDays,
               grandTotal: parseFloat(grandTotal),
            });

            setTriggerAddPrice(true);
         }
      }
   }, [firstEffectComplete, parkAdults, parkChildren, hotelAdults, hotelAdultsSharing, hotelChildren, transport, commission, inquiry, setPrices, setTriggerAddPrice,triggerAddPrice,safariDays, transferDays]);

   // changes 1

   return (
      <div className="container mt-2">
         <div className="row justify-content-center">
            <h2 className="text-center fw-bold mb-2" style={{ color: 'wheat', fontFamily: 'junge' }}>Add Prices</h2>
            <div className="card col-md-12 shadow-lg" style={{ backgroundColor: 'rgb(175, 238, 238,0.4)', overflowX: 'auto' }}>
               <div className="container pt-3">
                  <motion.div
                     style={{ maxWidth: '90%', margin: 'auto' }}
                     className="mb-4"
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ duration: 0.5 }}
                  >
                     <table
                        className="table table-bordered rounded px-4"
                        style={{
                           backgroundColor: 'rgb(8, 50, 32,1)',
                           color: '#333',
                           borderColor: 'lightblue',
                           fontSize: 'calc(0.875rem + 0.2vw)',
                        }}
                     >
                        <thead>
                           <tr className='text-center'>
                              <th>
                                 <h6 style={{ color: 'rgb(175, 238, 238)', fontFamily: 'fantasy', fontSize: 'calc(1.25rem + 0.3vw)' }}>No. Vehicle Days</h6>
                              </th>
                              <th>
                                 <h4 style={{ color: 'rgb(175, 238, 238)', fontFamily: 'fantasy', fontSize: 'calc(1.25rem + 0.3vw)', padding: '0.375rem 0.75rem' }}>Commission</h4>
                              </th>
                              <th>
                                 <h4 style={{ color: 'rgb(175, 238, 238)', fontFamily: 'fantasy', fontSize: 'calc(1.475rem + 0.3vw)', padding: '0.375rem 0.75rem' }}>Grand Total</h4>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr className='text-center'>
                              <td>
                                 <table
                                    className="table table-bordered rounded p-4"
                                    style={{
                                       backgroundColor: 'rgb(0,0,0,0)',
                                       color: 'lightblue',
                                       borderColor: '',
                                       fontSize: 'calc(0.875rem + 0.2vw)',
                                    }}
                                 >
                                    <thead>
                                       <tr className='text-center'>
                                          <th>safari</th>
                                          <th>Transfer</th>
                                       </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                       <tr>
                                          <td>
                                             <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="formSafariDays"
                                                name="safariDays"
                                                value={safariDays}
                                                onChange={(e) => setSafariDays(e.target.value)}
                                                placeholder="Enter number of safari days"
                                                min="0"
                                                max={inquiry?.days}
                                                style={{
                                                   width: '80px',
                                                   fontSize: 'calc(0.875rem + 0.2vw)',
                                                   padding: '0.375rem 0.75rem',
                                                }}
                                             />
                                          </td>
                                          <td>
                                             <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="formTransferDays"
                                                name="transferDays"
                                                value={transferDays}
                                                onChange={(e) => setTransferDays(e.target.value)}
                                                placeholder="Enter number of travel days"
                                                min="0"
                                                max={inquiry?.days}
                                                style={{
                                                   width: '80px',
                                                   fontSize: 'calc(0.875rem + 0.2vw)',
                                                   padding: '0.375rem 0.75rem',
                                                }}
                                             />
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </td>
                              <td colSpan="3" style={{ color: 'white', fontSize: 'calc(0.875rem + 0.2vw)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                 <input
                                    type="number"
                                    className="form-control form-control-sm "
                                    id="formCommission"
                                    name="commission"
                                    value={commission}
                                    onChange={(e) => setCommission(e.target.value)}
                                    placeholder="Enter commission percentage"
                                    min="1"
                                    max="100"
                                    style={{
                                       width: '80px',
                                       fontSize: 'calc(0.875rem + 0.2vw)',
                                       padding: '0.375rem 0.75rem',
                                       marginRight: '5px'
                                    }}
                                 />
                                 %
                              </td>
                              <td className='text-center m-auto align-items-center'>
                                 <h4 className='fw-bold m-auto' style={{ fontFamily: 'fantasy', color: 'orangered', fontSize: 'calc(0.875rem + 0.5vw)', padding: '0.375rem 0.75rem' }}>{grandTotal?.toLocaleString()} $</h4>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <small className='text-dark'><strong>NOTE:</strong>The commission is adjustable in the Price Rates Breakdown table</small>

                     <h5 style={{ color: 'black', fontFamily: 'monospace' }} className='fw-bold text-center mt-4'>Price Rates breakdown</h5>
                     <table
                        className="table table-bordered mt-1"
                        style={{
                           backgroundColor: '#fff',
                           color: '#333',
                           borderColor: '#003d00',
                           fontSize: 'calc(0.875rem + 0.2vw)',
                        }}
                     >
                        <thead className="thead-dark" style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: '#fff' }}>
                           <tr className='text-center'>
                              <th scope="col">Fee</th>
                              <th scope="col">Adults</th>
                              <th scope="col">Adults Sharing</th>
                              <th scope="col">Children</th>
                           </tr>
                        </thead>
                        <tbody className="text-center">
                           <tr style={{ backgroundColor: '#f4f4f4' }}>
                              <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Accommodations</th>
                              <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{hotelAdults?.toLocaleString()} $</td>
                              <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{hotelAdultsSharing?.toLocaleString()} $</td>
                              <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{hotelChildren?.toLocaleString()} $</td>
                           </tr>
                           <tr style={{ backgroundColor: '#fff' }}>
                              <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Destination(Site)</th>
                              <td colSpan="2" style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{parkAdults?.toLocaleString()} $</td>
                              <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{parkChildren?.toLocaleString()} $</td>
                           </tr>
                           <tr style={{ backgroundColor: '#f4f4f4' }}>
                              <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Transportation</th>
                              <td colSpan="3" style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{transport?.toLocaleString()} $</td>
                           </tr>
                           <tr className='text-center' style={{ backgroundColor: '#fff' }}>
                              <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>Commission</th>
                              <td colSpan="3" style={{ fontSize: 'calc(0.875rem + 0.2vw)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                 <input
                                    type="number"
                                    className="form-control form-control-sm bg-info"
                                    id="formCommission"
                                    name="commission"
                                    value={commission}
                                    onChange={(e) => setCommission(e.target.value)}
                                    placeholder="Enter commission percentage"
                                    min="1"
                                    max="100"
                                    style={{
                                       width: '80px',
                                       fontSize: 'calc(0.875rem + 0.2vw)',
                                       padding: '0.375rem 0.75rem',
                                       marginRight: '5px'
                                    }}
                                 />
                                 %
                              </td>
                           </tr>
                           <tr style={{ backgroundColor: '#f4f4f4' }}>
                              <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Total</th>
                              <td style={{ fontFamily: 'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{adultsTotal?.toLocaleString()} $</td>
                              <td style={{ fontFamily: 'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{adultsSharingTotal?.toLocaleString()} $</td>
                              <td style={{ fontFamily: 'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{childrenTotal?.toLocaleString()} $</td>
                           </tr>
                           <tr style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: 'lightblue' }}>
                              <th scope="row">Grand Total</th>
                              <td colSpan="3" className='fs-4' style={{ color: 'orangered', fontFamily: 'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{grandTotal?.toLocaleString()} $</td>
                           </tr>
                        </tbody>
                     </table>

                     <details className='p-4 rounded border border-success' style={{ backgroundColor: 'lightblue', width: '100%', color: 'rgb(8, 50, 32,1)' }}>
                        <summary>Formula info</summary>
                        <PricesCalculationFormula/>
                     </details>
                  </motion.div>
               </div>
            </div>
         </div>
      </div>
   );
}
