// PackageItinerary.js
import React from 'react';
import '../styles/PackageItinerary.css';
import PackageDayItinerary from './PackageDayItinerary';

export default function PackageItinerary({packageInfo}) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
  
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${day}${daySuffix(day)} ${month}, ${year}`;
  };


  return (
    <div className="container-fluid pb-5" style={{ borderBottom: '1px solid green', backgroundColor: 'rgb(175, 238, 238,0.96)' }}>
      <h2 className="py-4 text-center" style={{
        fontFamily: 'inknut antiqua',
        fontWeight: 'bold',
        // fontSize: '4vw',
        color: 'rgb(8, 50, 32)',
      }}>Itinerary</h2>

      <div className="row">
        {/* Cards go here */}
        {packageInfo?.itinerary?.details.map((itinerary, index) => (
          <div key={index} className=" mx-auto mb-3">
            <details className="card-container py-4 border-success rounded" style={{backgroundColor: 'rgb(8, 50, 32,1)'}}>
              <summary style={{color:'white',fontFamily: 'junge'}} className='text-center fw-semibold'>
                
                <strong>Day:</strong> {itinerary?.day}
                  <small className="fst-italic px-3" style={{ color:'gold',fontSize: '80%' }}>
                    ({formatDate(itinerary?.date)})
                  </small>
                </summary>
              <PackageDayItinerary detail={itinerary} index={index} day={itinerary.day} client={packageInfo?.inquiry?.client_name} inquiryId={packageInfo?.inquiry?.id} />
            </details>
          </div>
        ))}
      </div>
    </div>
  );
}
