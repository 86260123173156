import React from 'react'
import IncludesAndExcludes from './IncludesAndExcludes'

export default function PackageRates({packageInfo}) {

    const adultsTotal = (packageInfo?.prices?.adultsTotal) * parseInt(packageInfo?.inquiry?.adults)
    const adultsSharingTotal = (packageInfo?.prices?.adultsSharingTotal) * parseInt(packageInfo?.inquiry?.adults_sharing)
    const childrenTotal = (packageInfo?.prices?.childrenTotal) * parseInt(packageInfo?.inquiry?.children)

  const grandTotal = (adultsTotal + adultsSharingTotal + childrenTotal)
  return (<>
   <div className="container py-3 row" >
   <h5 className="py-4 fs-3 text-center " style={{
     fontFamily:'inknut antiqua', 
   fontWeight:'bold',
    color:'white',
    }}>Package Rates Summary</h5>
     <div className='col-md-5 mx-auto'>
     <table 
     style={{backgroundColor:'rgb(175, 238, 238,0.98)'}}
     className="table rounded border-dark table-responsive table-striped">
          <tbody>
          {packageInfo?.prices?.adultsTotal !== 0 && (
              <tr>
                  <th>@ Adult</th>
                  <td>{packageInfo?.prices?.adultsTotal !== 0 ?  packageInfo?.prices?.adultsTotal?.toLocaleString()  + ' ' +  packageInfo?.prices?.currency : 'N/A'}</td>
              </tr>
              )}
          {packageInfo?.prices?.adultsSharingTotal !== 0 && (
              <tr>
                  <td>@ Sharing Adult</td>
                  <td>{packageInfo?.prices?.adultsSharingTotal !== 0 ?  packageInfo?.prices?.adultsSharingTotal?.toLocaleString()  + ' ' +   packageInfo?.prices?.currency   : 'N/A'}</td>
              </tr>
              )}
          
          {packageInfo?.prices?.childrenTotal !== 0 && (
              <tr>
                  <td>@ Child</td>
                  <td>{packageInfo?.prices?.childrenTotal !== 0 ? packageInfo?.prices?.childrenTotal?.toLocaleString()  + ' ' +   packageInfo?.prices?.currency   : 'N/A'}</td>
              </tr>
              )}
              <tr>
                  <td className=''><strong>Grand Total</strong></td>
                  <th> {grandTotal?.toLocaleString()} {''}{ packageInfo?.prices?.currency }</th>
              </tr>
          </tbody>
      </table>
     </div>
     
   </div>

   <hr className='my-1' style={{ color: 'white', borderWidth: '3px' }} />

   {/* Section displaying what prices include and exclude */}
   <IncludesAndExcludes packageInfo={packageInfo}/>
   </>
  )
}
