import React, { useContext, useState, useEffect } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import { ItineraryContext } from '../context/ItineraryContext';
import { FlightContext } from '../context/FlightContext';
import { uploadImage } from '../images/ImageUploader';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField, 
  MenuItem, 
  FormControl, 
  Select, 
  InputLabel, 
  Typography 
} from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import Swal from 'sweetalert2';

const UpdateDailyItineraries = ({ mainItinerary, setUpdateDayDetails }) => {
  const { updateDay } = useContext(ItineraryContext);
  const { accommodations } = useContext(AccommodationContext);
  const { destinations } = useContext(DestinationContext);
  const { routes } = useContext(FlightContext);

  const [image, setImage] = useState('');
  const [formData, setFormData] = useState({});
  const [details, setDetails] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (mainItinerary && mainItinerary.details) {
      const newFormData = {};
      mainItinerary.details.forEach(detail => {
        newFormData[detail.day] = {
          title: detail.title,
          description: detail.description,
          accommodationId: detail.accommodation?.id,
          destinationId: detail.destination?.id,
          flightRouteId: detail.flight_details?.id
        };
      });
      setFormData(newFormData);
      setDetails(mainItinerary.details);
    } else {
      setDetails([]);
    }
  }, [mainItinerary]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFormChange = (day, field, value) => {
    const newFormData = {
      ...formData,
      [day]: {
        ...formData[day],
        [field]: value
      }
    };
    setFormData(newFormData);

    if (field === 'title') {
      validateTitle(day, value);
    }
  };

  const validateTitle = (day, title) => {
    const existingTitles = mainItinerary.details.map(detail => detail.title.toLowerCase());
    const currentTitle = mainItinerary.details.find(detail => detail.day === day)?.title.toLowerCase();
    if (existingTitles.includes(title.toLowerCase()) && title.toLowerCase() !== currentTitle) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [day]: 'This title already exists. Please choose a different title.'
      }));
    } else {
      setErrors(prevErrors => {
        const { [day]: _, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleClose = () => {
    setUpdateDayDetails(false);
  };

  const handleSubmit = async (day) => {
    if (errors[day]) {
      Swal.fire({
        position:'top',
        icon:'warning',
        timer:8000,
        timerProgressBar:true,
        toast:true,
        background:'red',
        color:'wheat',
        showConfirmButton:false,
        title:'Please correct the highlighted errors to continue',
        customClass: {
          popup: {zIndex:9999} // Add a custom class for the popup
      }    
      })
      return
    }

    let detail = {
      ...formData[day],
      day,
      image: formData[day]?.image || details.find(detail => detail.day === day)?.image,
      image_id: formData[day]?.image_id || details.find(detail => detail.day === day)?.image_id,
    };

    if (image && image instanceof File) {
      try {
        const result = await uploadImage(image);
        // console(`Image uploaded: ${result.url}, ${result.id}`);
        detail.image = result.url;
        detail.image_id = result.id;
      } catch (error) {
        console.error("Error uploading image:", error);
        return;
      }
    }

    updateDay(details.find(detail => detail.day === day)?.id, detail);
    setUpdateDayDetails(false);
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Update Daily Itineraries</Typography>
        <FaTimes onClick={handleClose} style={{ cursor: 'pointer', color: 'red' }} />
      </DialogTitle>
      <DialogContent>
        {mainItinerary.details.map(detail => (
          <details key={detail.id} open={false} style={{backgroundColor:'rgb(175, 238, 238,0.9)'}} className='p-3 my-2 rounded'>
            <summary>Day {detail.day} - {detail.title}</summary>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(detail.day); }}>
              <TextField
                fullWidth
                label="Title"
                value={formData[detail.day]?.title || detail.title || ""}
                onChange={(e) => handleFormChange(detail.day, 'title', e.target.value)}
                error={Boolean(errors[detail.day])}
                helperText={errors[detail.day]}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Description"
                value={formData[detail.day]?.description || detail.description || ""}
                onChange={(e) => handleFormChange(detail.day, 'description', e.target.value)}
                margin="normal"
                multiline
                rows={4}
                required
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Accommodation</InputLabel>
                <Select
                  value={formData[detail.day]?.accommodationId || detail.accommodation?.id || ""}
                  onChange={(e) => handleFormChange(detail.day, 'accommodationId', e.target.value)}
                  // displayEmpty
                  label="Accommodation"
                >
                  <MenuItem value="" disabled>Select accommodation</MenuItem>
                  {accommodations.map(accommodation => (
                    <MenuItem key={accommodation.id} value={accommodation.id}>
                      {accommodation.accommodation} ({accommodation.category}) - {accommodation.location}
                    </MenuItem>
                  ))}
                  <MenuItem value='None'>None</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Destination (site)</InputLabel>
                <Select
                  value={formData[detail.day]?.destinationId || detail.destination?.id || ""}
                  onChange={(e) => handleFormChange(detail.day, 'destinationId', e.target.value)}
                  // displayEmpty
                label="Destination (site)"
                >
                  <MenuItem value="" disabled>Select destination</MenuItem>
                  {destinations.map(destination => (
                    <MenuItem key={destination.id} value={destination.id}>
                      {destination.name} ({destination.location})
                    </MenuItem>
                  ))}
                  <MenuItem value='None'>None</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Flight (Route)</InputLabel>
                <Select
                  value={formData[detail.day]?.flightRouteId || detail.flight_details?.id || ""}
                  onChange={(e) => handleFormChange(detail.day, 'flightRouteId', e.target.value)}
                  // displayEmpty
                label="Flight (Route)"
                >
                  <MenuItem value="" disabled>Select route</MenuItem>
                  {routes.map(route => (
                    <MenuItem key={route.id} value={route.id}>
                      {route.origin.location} - {route.destination.location} ({route.flight_name}) ${route.rates}
                    </MenuItem>
                  ))}
                  <MenuItem value='None'>None</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                type="file"
                label="Image"
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: ".png, .jpg, .jpeg" }}
                onChange={handleImageChange}
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Submit
              </Button>
            </form>
          </details>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateDailyItineraries;
