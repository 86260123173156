import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { motion } from 'framer-motion';
import ImageViewer from '../utils/ImageViewer'

export default function ViewDetails({ mainItinerary }) {
  const { loggedIn } = useContext(AuthContext);

    // ==================== IMAGES VIEWER =====================
    // const images=[image1,2,3,4 ...]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================


  return (
    <div className="card" style={{ backgroundColor: 'rgba(175, 238, 238, 1)', minHeight: 'fit-content', borderRadius: '0.5rem', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      {/* Full-width image section */}
      <div style={{ position: 'relative' }}>
        <div style={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          maxHeight: '81vh',
          backgroundImage: `radial-gradient(circle, rgba(1, 50, 33, 0.38), rgba(1, 50, 33, 0.25), rgba(255, 255, 255, 0.3)), url(${mainItinerary?.overview?.image})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: '0.5rem',
          overflow: 'hidden'
        }}>
          <img
            loading="lazy"
            src={mainItinerary?.overview?.image}
            style={{ width: '100%', height: 'auto', display: 'block', visibility: 'hidden' }}
            alt={mainItinerary?.title}
            onClick={()=> openModal(currentImageIndex)} 
          />
          <ImageViewer 
                images={[mainItinerary?.overview?.image]}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'wheat',
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
            textAlign: 'center',
            width: '100%'
          }}>
            <motion.h1 className="fw-medium"
             initial={{ opacity: 0, y: 100 }}
             animate={{ opacity: 1, y: 0 }}
             transition={{ duration: 2.5 }}
            style={{ fontFamily: 'junge', fontSize: 'clamp(1rem, 3.5vw + 1.5rem, 3.5rem)' }}>
              {mainItinerary?.title}</motion.h1>
          </div>
        </div>
      </div>

      {/* Content section */}
      <div className="card-body container" style={{ paddingTop: '20px' }}>
        <div className="px-4 mx-4 d-flex flex-column flex-md-row gap-4">
          <p style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Category: <strong>{mainItinerary?.category}</strong></p>
          <p className="card-text mt-md-0" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>@ Budget: <strong>{mainItinerary?.budget?.toLocaleString()} $</strong></p>
        </div>
        <div className='d-flex flex-column align-items-center text-center mt-3'>
          <h4 className="card-text text-center mt-2"><strong>Overview</strong></h4>
          <textarea
            value={mainItinerary?.overview?.overview}
            readOnly
            rows={10}
            className='fst-italic text-center rounded p-3'
            style={{
              backgroundColor: 'rgb(8, 50, 32, 0.8)',
              color: 'wheat',
              width: '90%',
              border: 'none',
              // resize: 'none'
            }}
          />
        </div>
      </div>

      {/* Footer section */}
      {loggedIn && (
        <div className="card-footer d-flex justify-content-between">
          <small style={{ fontWeight: 'bold' }}>Created At: {mainItinerary?.created_at}</small>
          <small style={{ fontWeight: 'bold' }}>Updated At: {(mainItinerary?.updated_at) || 'Null'}</small>
        </div>
      )}
    </div>
  );
}
