import React, { createContext, useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import { ItineraryContext } from './ItineraryContext'
import { InquiryContext } from './InquiryContext'
import { uploadImage } from '../images/ImageUploader';


export const PackageContext = createContext()

export default function PackageProvider({children}) {
  const {onsend,setOnsend} = useContext(InquiryContext)
  const {loadingCounter, setLoadingCounter,authToken} = useContext(AuthContext)
  const{packageChange, setPackageChange} = useContext(ItineraryContext)
const [inquiryId, setInquiryId] = useState()
const [client, setClient] = useState()
const [onChange, setOnChange] = useState(false)
const navigate = useNavigate()

const [status, setStatus] = useState('all')
const [searchTerm, setSearchTerm] = useState('')
const [loading,setLoading] = useState(false)
const [packages, setPackages] = useState([])
const [searchResults, setSearchResults] = useState([])
const [categoryResults, setCategoryResults] = useState([])

//================================= GET/FETCH ====================================
//+++++++++++++++++++ FETCH ALL ++++++++++++++++++++++
useEffect(() => {
  if (authToken && status === 'all' && searchTerm === '' && [3, 'pac'].includes(loadingCounter)) {
    setLoading(true);
    
    // Introduce a delay of 1 second before making the fetch request
    const fetchTimeout = setTimeout(() => {
      fetch(`https://beshapp.backend.safarinetics.com/all_packages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      .then((res) => res.json())
      .then((response) => {
        if (!response.error) {
          setPackages(response);
          setLoading(false);
          
          loadingCounter === 3 ? setLoadingCounter(4) : setLoadingCounter(null);
        } else {
          Swal.fire({
            icon: 'error',
            toast: true,
            text: "Couldn't find the Package",
            timer: 4000,
            showConfirmButton: false,
            color: 'white',
            background: 'red',
            position: 'top-end'
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    }, 1000); // 1 second delay

    // Clear timeout if the component unmounts or dependencies change
    return () => clearTimeout(fetchTimeout);
  }
}, [onChange, packageChange, status, searchTerm, authToken, loadingCounter, setLoadingCounter]);


//+++++++++++++++++++ GET PACKAGES BY status  ++++++++++++++++++++++
useEffect(() => {
  if (authToken && status !== 'all') {
    setLoading(true);
    fetch(`https://beshapp.backend.safarinetics.com/packages_by_status/${status}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    .then((res) => res.json())
    .then((response) => {
      if (!response.error) {
        setCategoryResults(response);
        setLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          toast: true,
          text: "Couldn't find the Package",
          timer: 4000,
          showConfirmButton: false,
          color: 'white',
          background: 'red',
          position: 'top-end'
        });
      }
    })
    .catch((error) => console.error(error));
  }
}, [authToken, status]);


//+++++++++++++++++++ GET PACKAGES BY SEARCH(NAME/TOUR/PACKAGE)  ++++++++++++++++++++++
useEffect(() => {
  if (authToken && searchTerm !== '') {
    setLoading(true);
    fetch(`https://beshapp.backend.safarinetics.com/packages_by_search/${searchTerm}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken}`
      }
    })
    .then((res) => res.json())
    .then((response) => {
      if (!response.error) {
        setSearchResults(response);
        setLoading(false);
      } else {
        Swal.fire({
          icon: 'error',
          toast: true,
          text: "Couldn't find the Package",
          timer: 4000,
          showConfirmButton: false,
          color: 'white',
          background: 'red',
          position: 'top-end'
        });
      }
    })
    .catch((error) => console.error(error));
  }
}, [authToken, searchTerm]);


//===================================== UPDATE ======================
// function to update prices 
function updatePrices(packageId,formData){
  fetch(`https://beshapp.backend.safarinetics.com/package/${packageId}/prices`, {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify(formData)
})
.then((res) => res.json())
.then((response) => {
  if (response.success){
  setOnChange(!onChange)
}
})
.catch((error) => console.error(error))
}


// =========================== UPDATE OVERVIEW ===========================

// function to update overview 
async function updateOverview(packageId, updatedOverview) {
  try {
    // Start the loading process
    setLoading(true);

    // Check if the image is a File and needs to be uploaded
    if (updatedOverview?.image instanceof File) {
      try {
        const result = await uploadImage(updatedOverview.image);
        updatedOverview.image = result.url;
        updatedOverview.image_id = result.id;
      } catch (error) {
        console.error("Error uploading image:", error);
        showAlert("An error occurred while uploading the image. Please try again.", "error");
        return; // Stop the process if image upload fails
      }
    }
    // Prepare the form data
    const formData = new FormData();
    formData.append('updatedOverview', JSON.stringify(updatedOverview));

    // Send the PUT request to update the overview
    const response = await fetch(`https://beshapp.backend.safarinetics.com/package/${packageId}/overview/update`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((res) => res.json());

    // Handle the server response
    if (response.success) {
      showAlert(response.success, "success");
      setOnChange(!onChange);
      setPackageChange(!packageChange);
    } else {
      showAlert(response.error, "error");
    }
  } catch (error) {
    console.error("Error updating overview:", error);
    showAlert("An error occurred while updating the overview. Please try again.", "error");
  } finally {
    // End the loading process
    setLoading(false);
  }
}

function showAlert(message, icon) {
  Swal.fire({
    icon,
    toast: true,
    text: message,
    timer: 4000,
    showConfirmButton: false,
    color: "white",
    background: icon === "success" ? "green" : icon === "error" ? "red" : "lightblue",
    position: "top-end",
  });
}



// =========================== END OF UPDATE OVERVIEW ===========================


// function to update includes and excludes 
function updateIncludes_Excludes(packageId,formData){
  fetch(`https://beshapp.backend.safarinetics.com/package/${packageId}/includes_excludes`, {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify(formData)
})
.then((res) => res.json())
.then((response) => {
  if (response.success){
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
  setOnChange(!onChange)
  setPackageChange(!packageChange)
}
})
.catch((error) => console.error(error))
}

// =========================== ADD SINGLE DAY ===========================


// function to add single dayItinerary details
async function addSingleDay(packageId, newday) {
  try {
    // Start the loading process
    setLoading(true);

    let formData;

    // Check if the image is a File and needs to be uploaded
    if (newday?.image instanceof File) {
      const result = await uploadImage(newday.image);
      newday.image = result.url;
      newday.image_id = result.id;
      
      formData = new FormData();
      formData.append('newDay', JSON.stringify(newday));
    } else {
      // If the image is not a File, return early and stop the process
      return;
    }

    // Post the form data to the server
    const response = await fetch(`https://beshapp.backend.safarinetics.com/package/${packageId}/itinerary_details/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((res) => res.json());

    // Handle the response from the server
    if (response.success) {
      showAlert(response.success, 'success');
      setOnChange(!onChange);
      setPackageChange(!packageChange);
    } else {
      showAlert(response.error, 'error');
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    showAlert('An error occurred while uploading the image. Please try again.', 'error');
  } finally {
    // End the loading process
    setLoading(false);
  }
}

function showAlert(message, icon) {
  Swal.fire({
    icon,
    toast: true,
    text: message,
    timer: 4000,
    showConfirmButton: false,
    color: 'white',
    background: icon === 'success' ? 'green' : icon === 'error' ? 'red' : 'lightblue',
    position: 'top-end',
  });
}

// =========================== END OF ADD SINGLE DAY LOGIC ===========================


// ===========================  UPDATE SINGLE DAY LOGIC ===========================

// function to update dayItinerary details
async function updateDayDetails(packageId, newDetails) {
  try {
    // Start the loading process
    setLoading(true);

    // Check if the image is a File and needs to be uploaded
    if (newDetails?.image instanceof File) {
      try {
        const result = await uploadImage(newDetails.image);
        newDetails.image = result.url;
        newDetails.image_id = result.id;
      } catch (error) {
        console.error("Error uploading image:", error);
        showAlert("An error occurred while uploading the image. Please try again.", "error");
        return; // Stop the process if image upload fails
      }
    }
    
    // Prepare the form data
    const formData = new FormData();
    formData.append("newDetails", JSON.stringify(newDetails));

    // Send the PUT request to update day details
    const response = await fetch(`https://beshapp.backend.safarinetics.com/package/${packageId}/itinerary_details/update`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((res) => res.json());

    // Handle the server response
    if (response.success) {
      showAlert(response.success, "success");
      setOnChange(!onChange);
      setPackageChange(!packageChange);
    } else {
      showAlert(response.error, "error");
    }
  } catch (error) {
    console.error("Error updating day details:", error);
    showAlert("An error occurred while updating day details. Please try again.", "error");
  } finally {
    // End the loading process
    setLoading(false);
  }
}

function showAlert(message, icon) {
  Swal.fire({
    icon,
    toast: true,
    text: message,
    timer: 4000,
    showConfirmButton: false,
    color: "white",
    background: icon === "success" ? "green" : icon === "error" ? "red" : "lightblue",
    position: "top-end",
  });
}




// =========================== END OF UPDATE SINGLE DAY LOGIC ===========================



// funtion to delete single day 
function deleteSingleDay(id){
  fetch(`https://beshapp.backend.safarinetics.com/package/${id}/itinerary_details/delete`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    },
   
})
.then((res) => res.json())
.then((response) => {
 if (response.success){
  Swal.fire({
    icon:'success',
    toast:true,
    text:response.success,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'green',
    position:'top-end'
  });
  setPackageChange(!packageChange)
 }else{
  Swal.fire({
    icon:'error',
    toast:true,
    text:response.error,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'red',
    position:'top-end'
  });
 }
})
.catch((error) => console.error(error))
}

// funtion to delete the package
function deletePackage(packageId){
  fetch(`https://beshapp.backend.safarinetics.com/package/${packageId}/delete`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    },
   
})
.then((res) => res.json())
.then((response) => {
 if (response.success){
  Swal.fire({
    icon:'success',
    toast:true,
    text:response.success,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'green',
    position:'top-end'
  });
  navigate('/packages')
  setPackageChange(!packageChange)
  setLoadingCounter('pac')
 }else{
  Swal.fire({
    icon:'error',
    toast:true,
    text:response.error,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'red',
    position:'top-end'
  });
 }
})
.catch((error) => console.error(error))
}

//function to approve package
function approvePackage(id,client,email){
  fetch(`https://beshapp.backend.safarinetics.com/package/approve`,{
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify({id,client,email})
})
  .then((res) => res.json())
  .then((response) => {
    if (response.success){
      setOnChange(!onChange)
      setPackageChange(!packageChange)
      Swal.fire({
        icon:'success',
        toast:true,
        text:'package approved',
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
  })
  .catch((error) => console.error(error))
}

// function to send package_link
function sendPackage(id,currentUrl){
  fetch(`https://beshapp.backend.safarinetics.com/send_package/${id}/${currentUrl}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    }
})
.then((res) => res.json())
.then((response) => {
  if (response.success){
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
  setOnsend(!onsend)
  setLoadingCounter('inq')
  setTimeout(() => {
    
  setLoadingCounter('pac')
  }, 4000);
  setPackageChange(!packageChange)
  navigate('/packages')
}else if (response.error){
  Swal.fire({
    icon:'error',
    toast:true,
    text:response.error,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'red',
    position:'top-end'
  });
}
})
.catch((error) => console.error(error))
}

// function to send package_comment
function sendPackageComment(id,comment){
  fetch(`https://beshapp.backend.safarinetics.com/package/comment`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify({id,comment})
})
.then((res) => res.json())
.then((response) => {
  if (response.success){
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
  
}else if (response.error){
  Swal.fire({
    icon:'error',
    toast:true,
    text:response.error,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'red',
    position:'top-end'
  });
}
})
.catch((error) => console.error(error))
}

//function to send package_receipt
function sendPackageReceipt(id,formData){
  fetch(`https://beshapp.backend.safarinetics.com/package/${id}/payment/receipt`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify(formData)
})
.then((res) => res.json())
.then((response) => {
  if (response.success){
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
  
}else if (response.error){
  Swal.fire({
    icon:'error',
    toast:true,
    text:response.error,
    timer:4000,
    showConfirmButton:false,
    color:'white',
    background:'red',
    position:'top-end'
  });
}
})
.catch((error) => console.error(error))
}

   const contextData = {
      setInquiryId,inquiryId,
      setClient,client,
      updatePrices,updateOverview,updateIncludes_Excludes,updateDayDetails,
      deletePackage,approvePackage,sendPackage,sendPackageComment,
      deleteSingleDay,addSingleDay,
      packages,
      status,setStatus,searchTerm, setSearchTerm,loading,
      setPackageChange,packageChange,
      sendPackageReceipt,
      searchResults, setSearchResults,
      categoryResults, setCategoryResults,

   }
  return (
    <PackageContext.Provider value={contextData} >
      {children}
    </PackageContext.Provider>
  )
}
