import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function UpdateUser({ setUpdateUser, user }) {
  const { updateUser, loading, users } = useContext(UserContext);
  const [userData, setUserData] = useState({
    id: user?.id,
    name: user?.name,
    position: user?.position,
    email: user?.email,
    personal_email: user?.personal_email || '',
    active: user?.active,
    phone: user?.phone,
  });
  const [isActive, setIsActive] = useState(user?.active);
  const [errors, setErrors] = useState({
    email: '',
    personal_email: '',
    phone: ''
  });

  useEffect(() => {
    const validateFields = () => {
      const { email, phone, personal_email } = userData;
      const newErrors = { email: '', personal_email: '', phone: '' };

      // Validate main email
      if (users.some(existingUser => existingUser.email.toLowerCase() === email.toLowerCase() && existingUser.id !== userData.id)) {
        newErrors.email = 'Email already exists';
      }

      // Validate personal email
      if (users.some(existingUser => existingUser?.personal_email?.toLowerCase() === personal_email.toLowerCase() && existingUser.id !== userData.id)) {
        newErrors.personal_email = 'Personal email already exists';
      }

      // Validate phone
      if (users.some(existingUser => existingUser.phone === phone && existingUser.id !== userData.id)) {
        newErrors.phone = 'Phone number already exists';
      }

      setErrors(newErrors);
    };

    validateFields();
  }, [userData, users, userData.id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    if (name === 'position') {
      setUserData({ ...userData, [name]: newValue?.toUpperCase() });
    } else {
      setUserData({ ...userData, [name]: newValue });
    }
  };

  const handleToggle = () => {
    setIsActive(!isActive);
    setUserData({ ...userData, active: !isActive });
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^\+[\d\s()-]{7,20}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /@beshafricanadventures\.co\.ke$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, phone, personal_email } = userData;

    if (!isValidEmail(email)) {
      Swal.fire({
        text: "Please enter the company email address",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    if (!isValidPhone(phone)) {
      Swal.fire({
        text: "Please enter a valid phone number",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    // Handle form submission here
    if (!errors.email && !errors.personal_email && !errors.phone) {
      updateUser(userData);
      setUpdateUser(false); // Hide the form
    }
  };


  const handlePhoneChange = (value) => {
    setUserData({ ...userData, ['phone']: `+${value}` });
      
  }

  const { email: emailError, personal_email: personalEmailError, phone: phoneError } = errors;

  return (
    <div className="card" style={{ backgroundColor: 'rgb(175, 238, 238,0)' }}>
      <h4 className='card-header fw-semibold text-center' style={{ color: 'rgb(175, 238, 238,1)', fontFamily: 'junge' }}>Update User </h4>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <label htmlFor="name" className="form-label text-white"><strong>Name:</strong></label>
              <input type="text" className="form-control" id="name" name="name" value={userData?.name} onChange={handleChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor="position" className="form-label text-white"><strong>Position:</strong></label>
              <input type="text" className="form-control" id="position" name="position" value={userData?.position} onChange={handleChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor="email" className="form-label text-white"><strong>Email:</strong></label>
              <input type="email" className="form-control" id="email" name="email" value={userData?.email} onChange={handleChange} required />
              {emailError && <div className="text-danger">{emailError}</div>}
            </div>
            <div className='mb-3'>
              <label htmlFor="personal_email" className="form-label text-white"><strong>Personal Email:</strong></label>
              <input type="email" className="form-control" id="personal_email" name="personal_email" value={userData?.personal_email} onChange={handleChange} required />
              {personalEmailError && <div className="text-danger">{personalEmailError}</div>}
            </div>
            <div className='mb-3'>
              <label htmlFor="phone" className="form-label text-white"><strong>Phone:</strong></label>
              <PhoneInput
                country={'ke'}
                value={userData?.phone}
                name='phone'
                id='phone'
                onChange={handlePhoneChange}
                dropdownStyle={{ color: '#000' }}
                dropdownClass="custom-dropdown"
              />
              {/* <input type="text" className="form-control" id="phone" name="phone" value={userData?.phone} onChange={handleChange} required /> */}
              {phoneError && <div className="text-danger">{phoneError}</div>}
            </div>
            <div className="mb-3 text-white">
              <label className="form-label text-white"><strong>Status:</strong></label>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name="active" id="active" checked={isActive} onChange={handleToggle} />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </div>
            <div className="mt-3 card-footer d-flex justify-content-around">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <button type="submit" className='btn btn-success' style={{ width: 'fit-content' }}>Update</button>
              )}
              <button disabled={loading} onClick={() => setUpdateUser(false)} className='btn btn-danger' style={{ width: 'fit-content' }}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
