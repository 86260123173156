import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

export const FlightContext = createContext()
export default function FlightProvider({children}) {

   const [flights, setFlights] = useState([])
   const {loadingCounter, setLoadingCounter, authToken, loggedIn} = useContext(AuthContext)
   const [onChange, setOnchange] = useState(false)
   const navigate = useNavigate()
   const [flightDestinations, setFlightDestinations] = useState()
   const [routes, setRoutes] = useState([])


// function to add a flight route by flight_id 
function addFlightRoute(flight_id,origin, destination, rate){
  const formData = new FormData()
  formData.append('origin',origin)
  formData.append('destination',destination)
  formData.append('rate',rate)
  fetch(`https://beshapp.backend.safarinetics.com/flights/${flight_id}/route/add`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken && authToken}`
    },
    body: formData
  })
   .then(res => res.json())
   .then(response => {
    if(response.success){
    setOnchange(!onChange)
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
      // add a state variable that caouses the destinations to be refetched
    
  }else{
    Swal.fire({
      icon:'error',
      toast:true,
      text:response.error,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'red',
      position:'top-end'
    });
  }
     
   })
   .catch((error) => console.error(error))
 }
// function to add a flight Destination 
function addFlightDestination(location,country){
  const formData = new FormData()
  formData.append('country', country)
  formData.append('location', location)

  fetch('https://beshapp.backend.safarinetics.com/flights/destination/add', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken && authToken}`
    },
    body: formData
  })
   .then(res => res.json())
   .then(response => {
    if(response.success){
    setOnchange(!onChange)
    setLoadingCounter('fld')
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
      // add a state variable that caouses the destinations to be refetched
    
  }else{
    Swal.fire({
      icon:'error',
      toast:true,
      text:response.error,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'red',
      position:'top-end'
    });
  }
     
   })
   .catch((error) => console.error(error))
 }

// function to add a fight 
function addFlight(formData){
  fetch('https://beshapp.backend.safarinetics.com/flights/add', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken && authToken}`
    },
    body: formData
  })
   .then(res => res.json())
   .then(response => {
    if(response.success){
    setOnchange(!onChange)
    setLoadingCounter('fli')
    Swal.fire({
      icon:'success',
      toast:true,
      text:response.success,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'green',
      position:'top-end'
    });
    if(loggedIn){
      navigate(`/flights/${response.id}/details`)
    }
  }else{
    Swal.fire({
      icon:'error',
      toast:true,
      text:response.error,
      timer:4000,
      showConfirmButton:false,
      color:'white',
      background:'red',
      position:'top-end'
    });
  
  }
     
   })
   .catch((error) => console.error(error))
 }

//  fetch all the flight destinations 
useEffect(() => {
  if ([9, 'fld'].includes(loadingCounter)) {
    const timer = setTimeout(() => {
      fetch('https://beshapp.backend.safarinetics.com/flight/destinations')
        .then((res) => res.json())
        .then((response) => {
          setFlightDestinations(response);
          if (!response.error) {
            loadingCounter === 9 ? setLoadingCounter(10) : setLoadingCounter('fld') ;
            
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 1000); // 1-second delay

    // Clean up the timer if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }
}, [onChange, authToken, loadingCounter, setLoadingCounter]);



   // fetch all flights 
   useEffect(() => {
    if (authToken && [8, 'fli'].includes(loadingCounter)) {
      const timer = setTimeout(() => {
        fetch('https://beshapp.backend.safarinetics.com/flights', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken && authToken}`
          }
        })
        .then(res => res.json())
        .then(response => {
          if (!response.error) {
            setFlights(response);
            loadingCounter === 8 ? setLoadingCounter(9) : setLoadingCounter('fld');
          }
        })
        .catch((error) => console.error(error));
      }, 1000); // 1-second delay
  
      // Clean up the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  }, [authToken, onChange, loadingCounter, setLoadingCounter]);
  

  //  fetch all routes 
  useEffect(() => {
    if ([10, 'flr'].includes(loadingCounter)) {
      const timer = setTimeout(() => {
        fetch('https://beshapp.backend.safarinetics.com/flights/routes')
          .then(res => res.json())
          .then(response => {
            if (!response.error) {
              setRoutes(response);
              if (loadingCounter === 10) {
                setLoadingCounter(11);
              }
            }
          })
          .catch((error) => console.error(error));
      }, 1000); // 1-second delay
  
      // Clean up the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  }, [authToken, onChange, loadingCounter, setLoadingCounter]);
  

   // function to delete flight 
   function deleteFlight(id){
      fetch(`https://beshapp.backend.safarinetics.com/flights/delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken && authToken}`
        }
      })
       .then(res => res.json())
       .then(response => {
        if(response.success){
        setOnchange(!onChange)
        setLoadingCounter('fli')
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
        if(loggedIn){
          navigate(`/flights`)
        }
      }else{
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
         
       })
       .catch((error) => console.error(error))
       
     }


     //  function to update flight 
  function updateFlight(id, updatedFlight){
   fetch(`https://beshapp.backend.safarinetics.com/flights/update/${id}`, {
     method: "PUT",
     headers: {
       Authorization: `Bearer ${authToken && authToken}`
     },
     body: updatedFlight
   })
   .then((res) => res.json()) 
   .then(response => {
     if(response.success){
       setOnchange(!onChange)
       setLoadingCounter('fli')
       Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      
     }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
   })
   .catch((error) => console.error(error))
 }

//  function to delete route 
function deleteRoute(id){
  fetch(`https://beshapp.backend.safarinetics.com/flights/route/${id}/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken && authToken}`
        }
      })
       .then(res => res.json())
       .then(response => {
        if(response.success){
        setOnchange(!onChange)
        setLoadingCounter('flr')
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
        
      }else{
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
         
       })
       .catch((error) => console.error(error))
       
     }


   const contextData = {
      flights,
      addFlight,
      deleteFlight,
      updateFlight,
      addFlightDestination,
      flightDestinations,
      addFlightRoute,
      onChange,
      deleteRoute,
      routes,
       setRoutes,

   }
  return (
    <FlightContext.Provider value = {contextData} >
      {children}
    </FlightContext.Provider>
  )
}
