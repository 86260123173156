import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InquiryContext } from '../context/InquiryContext';
import { Link } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';

const Inquiries = () => {
  const { inquiries, updateView, searchTerm, setSearchTerm, loading, searchResults, setSearchResults } = useContext(InquiryContext);
  const navigate = useNavigate();

  const handleRowClick = (id, viewed) => {
    navigate(`/inquiries/${id}`);
    if (!viewed) {
      updateView(id);
    }
  };

  const [filteredItems, setFilteredItems] = useState([]);
  const [search, setSearch] = useState(''); // Initialize search state

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearch(term);
  
    if (term.length > 0) {
      const filtered = new Set(); // Use a Set to store unique search results
  
      inquiries.forEach(item => {
        const clientName = item.client?.name?.toLowerCase();
        const clientCountry = item.client?.country?.toLowerCase();
        const tourName = item.tour?.toLowerCase();
  
        if (clientName?.includes(term)) {
          filtered.add(item.client.name);
        }
        if (clientCountry?.includes(term)) {
          filtered.add(item.client.country);
        }
        if (tourName?.includes(term)) {
          filtered.add(item.tour);
        }
      });
  
      // Convert Set to an array and sort alphabetically
      const sortedFilteredItems = [...filtered].sort((a, b) => a.localeCompare(b));
      setFilteredItems(sortedFilteredItems);
    } else {
      setFilteredItems([]);
    }
  };

  const handleItemClick = (item) => {
    setSearch(item);
    setFilteredItems([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(search);
  };

  const loadingText = 'Loading ....';

  const [dataToDisplay, setDataToDisplay] = useState([]);
  useEffect(() => {
    if (search === '') {
      setSearchResults(inquiries);
    }
    setDataToDisplay(search ? searchResults : inquiries);
  }, [searchTerm, search, searchResults, inquiries]);

  const tableStyle = {
    backgroundColor: 'lightgrey',
    overflowX: 'auto'
  };

  const tableHeaderStyle = {
    color: 'rgb(1, 50, 32,1)',
    fontFamily: 'junge',
    fontSize: '1rem'
  };

  const tableCellStyle = {
    fontSize: '0.85em',
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 576) {
      tableCellStyle.fontSize = '0.75em'; // Adjust font size for smaller screens
    } else {
      tableCellStyle.fontSize = '0.85em'; // Default font size
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call on initial render

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container-fluid pb-4" style={{ backgroundColor: 'rgb(8, 50, 32,1)', minHeight: '100%' }}>
      <h2 className="text-center pt-4 mb-4" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', color: 'wheat' }}>
        Inquiries
      </h2>
      {/* Search Input Field */}
      <div className="container d-flex flex-nowrap justify-content-center mb-3" style={{ maxWidth: '100%', margin: 'auto' }}>
        <form className="input-group position-relative" style={{ maxWidth: '20rem' }} onSubmit={handleSubmit}>
          <input
            type="search"
            className="form-control text-center border border-success"
            placeholder="Search 🔍"
            value={search}
            onChange={handleSearch}
            style={{ fontSize: '1rem' }}
          />
          <button className="btn btn-success" type="submit">🔍</button>
          {filteredItems.length > 0 && (
            <ul className="list-group position-absolute w-100" style={{ top: '100%', zIndex: 1 }}>
              {filteredItems.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleItemClick(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </form>
        <Link to={'/inquiries/add'} className="btn btn-info ms-3" style={{ textDecoration: 'none', color: '#fff' }}>
          Add
        </Link>
      </div>

      {loading ? (
        <LoadingComponent loadingText={loadingText} />
      ) : (
        dataToDisplay && dataToDisplay.length === 0 ? (
          <div className="alert alert-info" role="alert">
            No inquiries found.
          </div>
        ) : (
          <div style={tableStyle}>
            <table className="table table-striped rounded table-hover text-center" style={{ width: '100%' }}>
              <thead>
                <tr className='fs-5' style={tableHeaderStyle}>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Country</th>
                  <th>Tour</th>
                  <th>Duration (days)</th>
                  <th>Budget @</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(dataToDisplay) && dataToDisplay.length > 0 && dataToDisplay.map((inquiry, index) => (
                  <tr
                    key={inquiry.id}
                    onClick={() => handleRowClick(inquiry.id, inquiry.viewed)}
                    style={{
                      backgroundColor: (!inquiry.viewed && !inquiry.sent) ? 'lightgreen' : (inquiry.viewed && inquiry.sent ? 'lightblue' : '')
                    }}>
                    <td style={tableCellStyle}>{index + 1}</td>
                    <td style={tableCellStyle}>{inquiry.client?.name || 'N/A'}</td>
                    <td style={tableCellStyle}>{inquiry.client?.email || 'N/A'}</td>
                    <td style={tableCellStyle}>{inquiry.client?.country || 'N/A'}</td>
                    <td style={tableCellStyle}>{inquiry.tour || 'N/A'}</td>
                    <td style={tableCellStyle}>{inquiry.days}</td>
                    <td style={tableCellStyle}>{inquiry?.budget?.toLocaleString() || 'N/A'} {inquiry.currency}</td>
                    <td style={tableCellStyle}>{inquiry.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
};

export default Inquiries;
