import React from 'react';

export default function EmailSetup() {
  return (
   <div className="p-5 bg-info bg-opacity-25">
     <h2 className="text-center mb-4">Email Setup Guide</h2>
      <p>
        To connect your mobile device to send and receive emails using your domain(company email) email, you need to set up your email account on your mobile's email app.
        Here's a step-by-step guide:
      </p>

      <h4>Step 1: Gather Necessary Information</h4>
      <p>Before setting up your email, you need to know your email server settings. These are typically provided by your hosting provider. The information you need includes:</p>
      <ul>
        <li><strong>Email address:</strong>your email account </li>
        <li><strong>Password:</strong> The password for your email account</li>
        <li><strong>Incoming mail server (IMAP/POP3):</strong>mail.beshafricanadventures.co.ke</li>
        <li><strong>Outgoing mail server (SMTP):</strong>mail.beshafricanadventures.co.ke</li>
        <li><strong>Incoming port:</strong> MAP Port: 993 POP3 Port: 995</li>
        <li><strong>Outgoing port:</strong> SMTP Port: 465</li>
        <li><strong>Security type:</strong> SSL/TLS for secure connections</li>
      </ul>

      <h4>Step 2: Setting Up Email on Android</h4>
      <ol>
        <li>Open the Email app (or Gmail app) on your Android device.</li>
        <li>Go to Settings and select Add Account.</li>
        <li>Select Personal (IMAP/POP) and then Next.</li>
        <li>Enter your full email address and tap Next.</li>
        <li>Enter your email account password and tap Next.</li>
        <li>Choose either IMAP or POP3 for your incoming server settings:
          <ul>
            <li><strong>IMAP:</strong> Incoming server - mail.beshafricanadventures.co.ke, Port - 993, Security type - SSL/TLS</li>
            <li><strong>POP3:</strong> Incoming server - mail.beshafricanadventures.co.ke, Port - 995, Security type - SSL/TLS</li>
          </ul>
        </li>
        <li>Enter the outgoing server settings:
          <ul>
            <li>Outgoing server - mail.beshafricanadventures.co.ke, Port - 465, Security type - SSL/TLS</li>
          </ul>
        </li>
        <li>Tap Next, and the app will verify your settings.</li>
        <li>Once verified, you can configure your sync settings and other preferences.</li>
        <li>Tap Next to complete the setup.</li>
      </ol>

      <h4>Step 3: Setting Up Email on iPhone (iOS)</h4>
      <ol>
        <li>Go to Settings on your iPhone.</li>
        <li>Scroll down and tap on Mail.</li>
        <li>Tap Accounts, then Add Account.</li>
        <li>Choose Other, then Add Mail Account.</li>
        <li>Enter your name, email address, password, and a description (e.g., Besh African Adventures Email).</li>
        <li>Tap Next.</li>
        <li>Choose either IMAP or POP for your incoming mail server settings:
          <ul>
            <li><strong>IMAP:</strong> Hostname - mail.beshafricanadventures.co.ke, Username - your full email address, Password - your email password</li>
            <li><strong>POP3:</strong> Hostname - mail.beshafricanadventures.co.ke, Username - your full email address, Password - your email password</li>
          </ul>
        </li>
        <li>Enter the outgoing mail server settings:
          <ul>
            <li>Hostname - mail.beshafricanadventures.co.ke, Username - your full email address, Password - your email password</li>
          </ul>
        </li>
        <li>Tap Next, and your iPhone will verify the settings.</li>
        <li>Once verified, you can save the account and adjust any additional settings if needed.</li>
      </ol>

      <h4>Additional Tips</h4>
      <ul>
        <li>If you encounter issues, double-check the server settings with your hosting provider.</li>
        <li>Ensure your mobile device is connected to the internet.</li>
        <li>Some hosting providers might require specific settings or authentication methods; refer to their support documentation.</li>
      </ul>

      <p>If you need further assistance or run into any issues, feel free to contact <a href="mailto:info@safarinetics.com">info@safarinetics.com</a></p>
    </div>
  );
}
