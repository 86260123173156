import React, { useContext, useState, useEffect } from 'react';
import { ItineraryContext } from '../context/ItineraryContext';
import AddItineraryDay from './AddItineraryDay';
import { AccommodationContext } from '../context/AccommodationContext';
import Swal from 'sweetalert2';
import { DestinationContext } from '../context/DestinationContext';
import DayFlight from './DayFlight';
import { useParams } from 'react-router-dom';

export default function AddItineraryDetails({mainItinerary}) {
  const { newItinerary, setNewItinerary } = useContext(ItineraryContext);
  const { accommodations } = useContext(AccommodationContext);
  const { destinations } = useContext(DestinationContext);
  const [image, setImage] = useState();
  const [image_id, setImageId] = useState();
  const [formData, setFormData] = useState({});
  const [details, setDetails] = useState([]);
  const [addEntry, setAddEntry] = useState(false);
  const { days } = useParams();
  const [displayedItineraries, setDisplayedItineraries] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (newItinerary && mainItinerary?.details) {
      setDisplayedItineraries(prevItineraries => {
        const updatedItineraries = [...prevItineraries];
  
        mainItinerary.details.forEach((i) => {
          if (parseInt(i.day) > newItinerary.length) {
            // Check for duplicates
            const existingIndex = updatedItineraries.findIndex(j => j.id === i.id);
            if (existingIndex === -1) {
              updatedItineraries.push(i); // Add if not present
            } else {
              // If present, compare dates and keep the one with the latest date
              const existingItinerary = updatedItineraries[existingIndex];
              if (new Date(i.date) > new Date(existingItinerary.date)) {
                updatedItineraries[existingIndex] = i; // Replace with the latest date
              }
            }
          }
        });
  
        // Sort by day
        return updatedItineraries.sort((a, b) => parseInt(a.day) - parseInt(b.day));
      });
    }
  }, [mainItinerary, newItinerary]);
  

  useEffect(() => {
    if (mainItinerary && mainItinerary?.details) {
      setDetails(mainItinerary.details);
    } else {
      setDetails([]);
    }
  }, [mainItinerary]);

  useEffect(() => {
    if (!newItinerary || newItinerary === null) {
      setNewItinerary([]);
    }
  }, [newItinerary, setNewItinerary]);

  useEffect(() => {
    validateForm();
  }, [formData, newItinerary, details]);

  useEffect(() => {
    if (mainItinerary?.inquiry?.start_date) {
      const startDate = new Date(mainItinerary.inquiry.start_date);

      displayedItineraries.forEach(detail => {
        const dayIndex = parseInt(detail.day, 10) - 1;
        const calculatedDate = new Date(startDate);
        calculatedDate.setDate(startDate.getDate() + (dayIndex + 1));

        setFormData(prevFormData => ({
          ...prevFormData,
          [detail.day]: {
            ...prevFormData[detail.day],
            date: calculatedDate.toISOString().split('T')[0] // Format as yyyy-mm-dd
          }
        }));
      });
    }
  }, [displayedItineraries, mainItinerary?.inquiry?.start_date]);

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    displayedItineraries.forEach(detail => {
      const day = detail.day;
      const date = formData[day]?.date;
      const title = formData[day]?.title?.trim().toLowerCase();
      
      if (date) {
        const selectedDate = new Date(date);
        const startDate = new Date(mainItinerary?.inquiry?.start_date);
        const endDate = new Date(mainItinerary?.inquiry?.end_date);
        endDate.setDate(endDate.getDate() + 1);

        if (selectedDate < startDate || selectedDate > endDate) {
          tempErrors[day] = tempErrors[day] || {};
          tempErrors[day].date = 'Please select a date within the specified dates.';
          isValid = false;
        }
      }

      if (title && newItinerary.some(item => item.title?.trim().toLowerCase() === title && item.day !== day)) {
        tempErrors[day] = tempErrors[day] || {};
        tempErrors[day].title = 'Title is already used';
        isValid = false;
      }
    });

    setErrors(tempErrors);
    setIsSubmitting(isValid);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFormChange = (day, field, value) => {
    setFormData({
      ...formData,
      [day]: {
        ...formData[day],
        [field]: value
      }
    });
  };

  const handleSaveDetail = (day) => {
    if (!isSubmitting) return;

    const detail = {
      ...formData[day],
      day,
      date: formData[day]?.date ,
      image: image ? image : newItinerary.find(item => item.day === day)?.image || formData[day]?.image || details.find(detail => detail.day === day)?.image,
      image_id: image_id ? image_id : newItinerary.find(item => item.day === day)?.image_id || formData[day]?.image_id || details.find(detail => detail.day === day)?.image_id,
      title: formData[day]?.title || details.find(detail => detail.day === day)?.title,
      description: formData[day]?.description || details.find(detail => detail.day === day)?.description,
      accommodation: formData[day]?.accommodation || details.find(detail => detail.day === day)?.accommodation,
      destination: formData[day]?.destination || details.find(detail => detail.day === day)?.destination,
      flight_route: formData[day]?.flight_route || details.find(detail => detail.day === day)?.flight_route,
    };

    const existingIndex = newItinerary.findIndex(item => item.day === day);
    if (existingIndex !== -1) {
      const updatedNewItinerary = [...newItinerary];
      updatedNewItinerary[existingIndex] = detail;
      setNewItinerary(updatedNewItinerary);

      Swal.fire({
        text: "Updated",
        showConfirmButton: false,
        timer: 4000,
        icon: 'success',
        color: 'white',
        background: 'green',
        toast: true,
        position: 'top'
      });
    } else {
      if (newItinerary.length <= parseInt(days)) {
        setNewItinerary([...newItinerary, detail]);

        Swal.fire({
          text: "Saved",
          showConfirmButton: false,
          timer: 4000,
          icon: 'success',
          color: 'white',
          background: 'green',
          toast: true,
          position: 'top'
        });
      }
    }
  };

  const handleRemoveDetail = (day) => {
    const updatedNewItinerary = newItinerary.filter(item => item.day !== day);
    setNewItinerary(updatedNewItinerary);
    const updatedNewDetails = details.filter(item => item.day !== day);
    setDetails(updatedNewDetails);

    Swal.fire({
      text: "Removed",
      showConfirmButton: false,
      timer: 4000,
      icon: 'success',
      color: 'white',
      background: 'green',
      toast: true,
      position: 'top'
    });
  };
  // console(displayedItineraries)

  return (
    <div className="container mt-2">
      <div className="row justify-content-center">
        <h2 className='m-2 text-center fw-semibold ' style={{ color: 'wheat', fontFamily: 'junge' }}>Daily Details</h2>
        <div className="card col-md-12" style={{ backgroundColor: 'rgb(175, 238, 238,0.4)' }}>
          <div className="card-body rounded">
            {(!mainItinerary || !mainItinerary?.details || details.length === 0) && (
              <div className="alert alert-warning" role="alert">
                No details found. Please add some details.
              </div>
            )}
            {displayedItineraries && displayedItineraries.length > 0 && displayedItineraries.map(detail => (
              <div key={detail?.day} className="card mb-3">
                <details className="card-body rounded" style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: 'wheat' }}>
                  <summary className="card-title">Day: {detail.day}</summary>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveDetail(detail.day);
                  }}>
                    <div className="container-fluid row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor={`day-${detail.day}`} className="form-label">Date:</label>
                        <input
                          id={`day-${detail.day}`}
                          type="date"
                          className="form-control border-success"
                          value={ formData[detail.day]?.date || ""}
                          onChange={(e) => handleFormChange(detail.day, 'date', e.target.value)}
                          required
                          disabled
                        />
                        {errors[detail.day]?.date && <small className="text-danger">{errors[detail.day].date}</small>}
                      </div>
                      <div className="col-md-8 mb-3">
                        <label htmlFor={`title-${detail.day}`} className="form-label">Title:</label>
                        <input
                          id={`title-${detail.day}`}
                          type="text"
                          className="form-control border-success"
                          value={formData[detail.day]?.title || (displayedItineraries && displayedItineraries.find(item => item.day === detail.day)?.title) || detail.title || ""}
                          onChange={(e) => handleFormChange(detail.day, 'title', e.target.value)}
                          required
                        />
                        {errors[detail.day]?.title && <small className="text-danger">{errors[detail.day].title}</small>}
                      </div>
                      </div>
                      <div className='container-fluid row'>
                      <div className="col-md-12 mb-3">
                      <label htmlFor={`description-${detail.day}`} className="form-label">Description:</label>
                      <textarea
                      id={`description-${detail.day}`}
                      className="form-control border-success"
                      value={formData[detail.day]?.description || (newItinerary && newItinerary.find(item => item.day === detail.day)?.description) || detail.description || ""}
                      onChange={(e) => handleFormChange(detail.day, 'description', e.target.value)}
                      required
                      rows={4}
                      />
                      </div>
                      </div>
                      <div className="container-fluid row">
                      <div className=" mb-3">
                      <label htmlFor={`accommodation-${detail.day}`} className="form-label">Accommodation:</label>
                      <select
                      id={`accommodation-${detail.day}`}
                      className="form-control border-success"
                      value={formData[detail.day]?.accommodation || details.find(detailItem => detailItem.day === detail.day)?.accommodation ||(newItinerary && newItinerary.find(item => item.day === detail.day)?.accommodation) ||  "None"}
                      onChange={(e) => handleFormChange(detail.day, 'accommodation', e.target.value)}
                      required
                      >
                      {Array.isArray(accommodations) && accommodations.length > 0 && accommodations.map((accommodation) => (
                      <option
                      key={accommodation.id}
                      value={accommodation.accommodation}
                      style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
                      >
                      {accommodation.accommodation} ({accommodation.category}) in "{accommodation.location}"
                      </option>
                      ))}
                      <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
                      </select>
                      </div>

                  <div className=" mb-3">
                    <label htmlFor={`destination-${detail.day}`} className="form-label">Destination(site):</label>
                    <select
                      id={`destination-${detail.day}`}
                      className="form-control border-success"
                      value={formData[detail.day]?.destination ||  details.find(detailItem => detailItem.day === detail.day)?.destination || ""}
                      onChange={(e) => handleFormChange(detail.day, 'destination', e.target.value)}
                      required
                    >
                      {Array.isArray(destinations) && destinations.length > 0 && destinations.map((destination) => (
                        <option
                          key={destination.id}
                          value={destination.name}
                          style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
                        >
                          {destination.name} ({destination.location})
                        </option>
                      ))}
                      <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
                    </select>
                  </div>

                  <DayFlight handleFormChange={handleFormChange} detail={detail} newItinerary={newItinerary} details={details} formData={formData} />

                  <div className=" mb-3">
                    <label htmlFor="image" className="form-label">Image:</label>
                    <input
                      type="file"
                      id="image"
                      className="form-control border-success"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex flex-wrap flex-row justify-content-around">
                  <button
                    type="submit"
                    className="btn btn-success me-1"
                    disabled={!isSubmitting}
                    title={!isSubmitting ? "Please fix the errors before submitting" : ""}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveDetail(detail.day)}
                  >
                    Remove
                  </button>
                </div>
              </form>
            </details>
          </div>
        ))}
        {newItinerary?.length < mainItinerary?.inquiry?.days && (
          !addEntry ? (
            <button
              onClick={() => setAddEntry(true)}
              type="button"
              className="btn btn-success"
              style={{ transition: 'opacity 0.3s ease-in-out' }}
            >
              Add
            </button>
          ) : (
            <div style={{ transition: 'opacity 0.3s ease-in-out' }}>
              <AddItineraryDay inquiry={mainItinerary?.inquiry} setDetails={setDetails} setAddEntry={setAddEntry} mainItinerary={mainItinerary} />
            </div>
          )
        )}
      </div>
    </div>
  </div>
</div>
);
}