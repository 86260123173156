import React, { useContext, useEffect, useState } from 'react';
import {useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingComponent from '../components/LoadingComponent';
import ActionButtons from './ActionButtons';

export default function InquiryDetails() {
  const { authToken } = useContext(AuthContext);
  const { id } = useParams();
  const [inquiry, setInquiry] = useState({});
  const [loading,setLoading] = useState(false)
 
  
  

  useEffect(() => {
    if (id && authToken){
      setLoading(true);
    fetch(`https://beshapp.backend.safarinetics.com/inquiries/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
      .then((res) => res.json())
      .then((response) => {
        if(!response.error){

        setInquiry(response);
        }
        setLoading(false)
        // console(response)
        
      })
      .catch((error) => console.error(error))
    }
  }, [authToken, id, setInquiry]);

  
 

  if(loading){
    const loadingText= 'Loading....'
    return(<LoadingComponent loadingText={loadingText}/>)
  }


  return (
    <div className="container-fluid pb-5 pt-2" style={{ backgroundColor:(!loading? 'rgb(1,50,32,1)' : 'rgb(175, 238, 238,0.9)') ,minHeight:'100%'}}>
      <div className="row justify-content-center">
        <div className="col-md-8">
        <h2 className="text-center mb-4" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', color: 'wheat' }}>
        Inquiry Details
        </h2>
          <div className="card" style={{ backgroundColor: 'rgb(175, 238, 238,0.8)'}}>
            <div className="card-header bg-success text-white">
              <h4 className="mb-0">{inquiry.tour}</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <p><strong>Adults:</strong> {inquiry.adults}</p>
                  <p><strong>Adults Sharing(couples):</strong> {inquiry?.adults_sharing || 'N/A'}</p>
                  <p><strong>Children:</strong> {inquiry?.children || 'N/A'}</p>
                  <p><strong>From:</strong> {inquiry.start_date}</p>
                  <p><strong>To:</strong> {inquiry.end_date}</p>
                  <p><strong>Duration:</strong> {inquiry.days} days</p>
                  
                  
                </div>
                <div className="col-md-6">
                <p><strong>Budget @:</strong> {inquiry?.budget?.toLocaleString()} {inquiry.currency}</p>
                  <h6>Message :</h6>
                  <textarea readOnly className="form-control" disabled value={inquiry.message} rows="3" />
                  <p><strong>Inquired at:</strong> {inquiry.created_at}</p>
                  <p><strong>Feedback :</strong> {inquiry.feedback}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <details className='bg-success p-3 rounded'>
                  <summary className='text-white fw-bold fs-6'>Client Details:</summary>
                 <div className='p-3 rounded' style={{ backgroundColor: 'rgb(175, 238, 238,0.8)'}}>
                 <p><strong>Name:</strong> {inquiry.client && inquiry.client.name}</p>
                  <p><strong>Email:</strong> <a href={`mailto:${inquiry.client && inquiry.client.email}`}>{inquiry.client && inquiry.client.email}</a>  </p>
                  <p><strong>Phone:</strong> <a href={`tel:${inquiry.client && inquiry.client.phone}`}>{inquiry.client && inquiry.client.phone}</a></p>
                  <p><strong>Country:</strong> {inquiry.client && inquiry.client.country}</p>
                 </div>
                  </details>
                </div>
              </div>
            </div>
            <ActionButtons id={id} inquiry={inquiry}/>
          </div>
        </div>
      </div>
    </div>
  );
}
