import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { FaEdit } from 'react-icons/fa';
import { ReceiptContext } from '../context/ReceiptContext';

export default function UpdateReceipt({ receipt }) {
  const {updatePackageReceipt} = useContext(ReceiptContext)
  function handleUpdate(id) {
    // Get the date parts
    const dateObj = new Date(receipt.received_date);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(dateObj.getUTCDate() + 1).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    Swal.fire({
      title: 'Update Receipt',
      html:
        `<input id="amount" type="number" class="swal2-input" placeholder="Enter the Amount Received" value="${receipt.amount}">` +
        `<input id="receiptDate" type="date" class="swal2-input" placeholder="Enter the Receipt Date" value="${formattedDate}">`,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      background: 'rgb(8, 50, 32,1)',
      toast: true,
      color: 'wheat',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      scrollbarPadding: false, // Remove the scrollbar
      preConfirm: () => {
        const amount = document.getElementById('amount').value;
        const receiptDate = document.getElementById('receiptDate').value;
        // Validate inputs
        if (!amount || !receiptDate) {
          Swal.showValidationMessage('Amount and Receipt Date are required');
        }

        // Validate receiptDate
        const today = new Date().toISOString().split('T')[0];
        if (receiptDate > today) {
          Swal.showValidationMessage('Receipt Date cannot be a future date');
        }

        return { amount: amount, receiptDate: receiptDate };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { amount, receiptDate } = result.value;
        // Perform further actions, like submitting the receipt data
        updatePackageReceipt(id, { amount, receiptDate });
      }
    });
  }

  return (
    <button className='btn btn-sm mx-1' onClick={() => handleUpdate(receipt?.id)}>
      <FaEdit />
    </button>
  );
}
