import React, { useContext, useState, useEffect } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import AddSingleDay from './AddSingleDay';
import { PackageContext } from '../context/PackageContext';
import UpdateDayFlight from './UpdateDayFlight';
import Swal from 'sweetalert2';

export default function UpdatePackageItinerary({ packageInfo, setUpdatePackage, setActiveTab }) {
  const { updateDayDetails, deleteSingleDay } = useContext(PackageContext);
  const { accommodations } = useContext(AccommodationContext);
  const { destinations } = useContext(DestinationContext);
  const [image, setImage] = useState();
  const [formData, setFormData] = useState({});
  const [details, setDetails] = useState([]);
  const [addEntry, setAddEntry] = useState(false);
  const [dateError, setDateError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  // Assuming inquiry.start_date and inquiry.end_date are available in packageInfo
  const startDate = new Date(packageInfo?.inquiry?.start_date);
  const endDate = new Date(packageInfo?.inquiry?.end_date);

  // Update details when packageInfo changes
  useEffect(() => {
    if (packageInfo && packageInfo.itinerary && packageInfo?.itinerary?.details) {
      const newFormData = {};
      packageInfo.itinerary.details.forEach(detail => {
        newFormData[detail.day] = {
          date: detail?.date,
          title: detail?.title,
          image: detail?.image,
          image_id: detail?.image_id,
          description: detail?.description,
          flight_route: detail?.flight?.id || "None",
          accommodation: detail?.accommodation?.name || "None",
          destination: detail?.destination?.name || "None"
        };
      });
      setFormData(newFormData);
      setDetails(packageInfo?.itinerary?.details);
    } else {
      setDetails([]);
    }
  }, [packageInfo]);

  // Function to handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Function to handle form data changes
  const handleFormChange = (day, field, value) => {
    const updatedData = {
      ...formData,
      [day]: {
        ...formData[day],
        [field]: value
      }
    };
    setFormData(updatedData);

    // Reset errors
    setDateError('');
    setTitleError('');
    setIsSaveDisabled(false);

    // Validate for duplicates and date range
    const existingDetail = details.find(detail => 
      detail.day !== day && 
      (detail.date === updatedData[day].date || detail.title.toLowerCase() === updatedData[day].title.toLowerCase())
    );

    if (existingDetail) {
      if (existingDetail.title.toLowerCase() === updatedData[day].title.toLowerCase()) {
        setTitleError('This title already exists.');
      }
      if (existingDetail.date === updatedData[day].date) {
        setDateError('This date already exists.');
      }
      setIsSaveDisabled(true);
    }

    // Date range validation
    const selectedDate = new Date(updatedData[day].date);
    if (selectedDate < startDate || selectedDate > new Date(endDate.getTime() + 24 * 60 * 60 * 1000)) {
      setDateError(`Date must be between ${startDate.toLocaleDateString()} and ${endDate.toLocaleDateString()}`);
      setIsSaveDisabled(true);
    }
  };

  // Function to handle saving or updating a detail
  const handleSaveDetail = (day) => {
    const detail = {
      ...formData[day],
      day,
      image: image ? image : formData[day]?.image || details.find(detail => detail.day === day)?.image,
    };
    updateDayDetails(packageInfo?.id, detail);
  };

  function handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSingleDay(id);
      }
    });
  }

  return (
    <div className="container mt-2">
      <div className="row justify-content-center">
        <h2 className='m-2 text-center fw-semibold' style={{ color: 'wheat', fontFamily: 'junge' }}>Daily Details</h2>
        <div className="card col-md-12" style={{ backgroundColor: 'rgb(175, 238, 238,0.4)' }}>
          <div className="container-fluid card-body rounded">
            {(!packageInfo || !packageInfo.itinerary || !packageInfo.itinerary.details || details.length === 0) && (
              <div className="alert alert-warning" role="alert">
                No details found. Please add some details.
              </div>
            )}
            {packageInfo && packageInfo.itinerary && packageInfo.itinerary.details && details.map(detail => (
              <div key={detail.day} className="card mb-3">
                <details className="card-body rounded" style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: 'wheat' }}>
                  <summary className="card-title">Day: {detail.day}</summary>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    handleSaveDetail(detail.day);
                  }}>
                    <div className="container row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor={`day-${detail.day}`} className="form-label">Date:</label>
                        <input
                          id={`day-${detail.day}`}
                          type="date"
                          className="form-control border-success"
                          value={formData[detail.day]?.date || detail?.date || ""}
                          onChange={(e) => handleFormChange(detail.day, 'date', e.target.value)}
                          required
                        />
                        {dateError && <div className="text-danger">{dateError}</div>}
                      </div>
                      <div className="col-md-8 mb-3">
                        <label htmlFor={`title-${detail.day}`} className="form-label">Title:</label>
                        <input
                          id={`title-${detail.day}`}
                          type="text"
                          className="form-control border-success"
                          value={formData[detail.day]?.title || detail.title || ""}
                          onChange={(e) => handleFormChange(detail.day, 'title', e.target.value)}
                          required
                        />
                        {titleError && <div className="text-danger">{titleError}</div>}
                      </div>
                    </div>
                    <div className='container-fluid row'>
                      <div className="col-md-12 mb-3">
                        <label htmlFor={`description-${detail.day}`} className="form-label">Description:</label>
                        <textarea
                          id={`description-${detail.day}`}
                          className="form-control border-success"
                          value={formData[detail.day]?.description || detail.description || ""}
                          onChange={(e) => handleFormChange(detail.day, 'description', e.target.value)}
                          required
                          rows={4}
                        />
                      </div>
                    </div>
                    <UpdateDayFlight formData={formData} detail={detail} handleFormChange={handleFormChange} />
                    <div className="container-fluid row">
                      <div className=" mb-3">
                        <label htmlFor={`accommodation-${detail.day}`} className="form-label">Accommodation:</label>
                        <select
                          id={`accommodation-${detail.day}`}
                          className="form-control border-success"
                          value={formData[detail.day]?.accommodation || detail?.accommodation?.name || ""}
                          onChange={(e) => handleFormChange(detail.day, 'accommodation', e.target.value)}
                          required
                        >
                          <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
                          {Array.isArray(accommodations) && accommodations.length > 0 && accommodations.map((accommodation) => (
                            <option
                              key={accommodation.id}
                              value={accommodation.accommodation}
                              style={{ fontWeight: 'semibold', fontFamily: 'junge' }} // Style for the name
                            >
                              {accommodation.accommodation} ({accommodation.category}) in " {accommodation.location} "
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className=" mb-3">
                        <label htmlFor={`destination-${detail.day}`} className="form-label">Destination(site):</label>
                        <select
                          id={`destination-${detail.day}`}
                          className="form-control border-success"
                          value={formData[detail.day]?.destination || detail?.destination?.name || ""}
                          onChange={(e) => handleFormChange(detail.day, 'destination', e.target.value)}
                          required
                        >
                          <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
                          {Array.isArray(destinations) && destinations.length > 0 && destinations.map((destination) => (
                            <option
                              key={destination.id}
                              value={destination.name}
                              style={{ fontWeight: 'semibold', fontFamily: 'junge' }} // Style for the name
                            >
                              {destination.name} ({destination.location})
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className=" mb-3">
                        <label htmlFor="image" className="form-label">Image:</label>
                        <input
                          type="file"
                          id="image"
                          className="form-control border-success"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 d-flex flex-wrap flex-row justify-content-around">
                      <button style={{ minWidth: '10vw' }} type="submit" className="btn btn-success me-1" disabled={isSaveDisabled}>Update</button>
                      <button style={{ minWidth: '10vw' }} type="button" className="btn btn-danger" onClick={() => handleDelete(detail.id)}>Delete</button>
                    </div>
                  </form>
                </details>
              </div>
            ))}
            {packageInfo?.inquiry?.days !== details?.length && (
              !addEntry ? (
                <button
                  onClick={() => setAddEntry(true)}
                  type="button"
                  className="btn btn-success"
                  style={{ transition: 'opacity 0.3s ease-in-out' }}
                >
                  Add an entry
                </button>
              ) : (
                <div style={{ transition: 'opacity 0.3s ease-in-out' }}>
                  <AddSingleDay
                    packageInfo={packageInfo}
                    details={details}
                    setDetails={setDetails}
                    setUpdatePackage={setUpdatePackage}
                    setAddEntry={setAddEntry}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
