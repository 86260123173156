import React,{ useContext, useState} from 'react'
import UpdateOverview from './UpdateOverview'
import UpdatePrices from './UpdatePrices'
import UpdatePackageItinerary from './UpdatePackageItinerary'
import UpdatePackageDetails from './UpdatePackageDetails'
import MovingCharacters from '../components/MovingCharacters'
import { ItineraryContext } from '../context/ItineraryContext'
import Swal from 'sweetalert2'

export default function UpdatePackage({setUpdatePackage, packageInfo}) {
  // Initialize activeTab state from session storage or default to 'overview'
  const [activeTab, setActiveTab] = useState(sessionStorage.getItem('activeTab') || 'overview');
  const { packageChange, setPackageChange } = useContext(ItineraryContext);
  const [triggerPricesUpdate, setTriggerPricesUpdate] = useState(false);

  // Update activeTab state and session storage
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem('activeTab', tab); // Save to session storage
  };

  async function handleUpdate() {
    setActiveTab('prices')

    // Delay for 1 second (1000 milliseconds)
    await new Promise((resolve) => setTimeout(resolve, 3000));
    Swal.fire({
      toast:true,
      position:'top',
      title:'Updating the prices ...',
      timer:4000,
      timerProgressBar:true,
      showConfirmButton:false,
      background:'green',
      color:'wheat'
    })

    setTriggerPricesUpdate(true);
    
    // Delay for 1 second (1000 milliseconds)
    await new Promise((resolve) => setTimeout(resolve, 3000));
  
    setPackageChange(!packageChange);
    setUpdatePackage(false);
  }

  return (
    <div className='card ' style={{ backgroundColor: 'rgb(8, 50, 32,1)',minHeight:'100%'}}>
    
      <h2 className="text-center mt-3 mb-4" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', color: 'wheat' }}>
        Update Package
        </h2>

        <div className='container card-body border border-success rounded col-md-8' >
          <h3 className='text-center text-light fw-semibold card-header bg-success' style={{fontFamily:'junge'}}>
          {packageInfo && packageInfo.inquiry && packageInfo.inquiry.tour}
           </h3>
          
        <MovingCharacters packageInfo={packageInfo}/>
          <div className="col-lg-12 rounded" style={{ backgroundColor: 'rgb(34, 139, 34,0.6)', padding: '20px' }}>
          <div className="d-flex flex-wrap justify-content-around ">
          <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'overview' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('overview')}
            >
              overview
            </p>

            <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'itinerary' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('itinerary')}
            >
              itinerary
            </p>
            
            <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'details' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('details')}
            >
              details
            </p>
            
            <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'prices' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('prices')}
            >
              prices
            </p>
            
            <div className=' text-center'>
        <button onClick={handleUpdate} type='button' className='btn btn-primary btn-sm' >View Package</button>
        </div>
      </div>
          <div className='overflow-auto pt-1 pb-3 mb-0 ' style={{ height: 'fitcontent'}}>
            {activeTab === 'overview' && <UpdateOverview  packageInfo = {packageInfo}  setActiveTab={handleTabChange}/>}
            {activeTab === 'itinerary' && <UpdatePackageItinerary packageInfo = {packageInfo} setUpdatePackage={setUpdatePackage} setActiveTab={handleTabChange}/>}
            {activeTab === 'details' && <UpdatePackageDetails packageInfo = {packageInfo} setUpdatePackage={setUpdatePackage}/>}
            {activeTab === 'prices' && <UpdatePrices packageInfo = {packageInfo} triggerPricesUpdate={triggerPricesUpdate}  setTriggerPricesUpdate={setTriggerPricesUpdate}/>}
           
          </div>
          </div>
       
        </div>

    </div>
  )
}
