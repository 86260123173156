import React from 'react';
import Swal from 'sweetalert2';

export default function ClientDets({packageInfo}) {
  
  const name = packageInfo?.inquiry?.client_name
  const budget = packageInfo?.inquiry?.budget?.toLocaleString()
  const adults = packageInfo?.inquiry?.adults
  const adultsSharing =  packageInfo?.inquiry?.adults_sharing
  const children = packageInfo?.inquiry?.children 
  const from = packageInfo?.inquiry?.start_date
  const to = packageInfo?.inquiry?.end_date

  function showClient(){
  Swal.fire({
    showCloseButton:true,
    html: `
    <div class="text-start">
    <h5 style="color:gold;">Client and Inquiry Details</h5>
    <p><strong>Name:</strong><span style="margin:0 10px; color:wheat;">${name}</span></p>
    <p><strong>Budget:</strong><span style="margin:0 10px; color:wheat;">${budget} $</span></p>
    <h6 style="color:gold;"> Inqury Details</h6>
    <p><strong>Adults:</strong><span style="margin:0 10px; color:wheat;">${adults}</span></p>
    <p><strong>Adults Sharing:</strong><span style="margin:0 10px; color:wheat;">${adultsSharing}</span></p>
    <p><strong>Children:</strong><span style="margin:0 10px; color:wheat;">${children}</span></p>
    <p><strong>From:</strong><span style="margin:0 10px; color:wheat;">${from}</span></p>
    <p><strong>To:</strong><span style="margin:0 10px; color:wheat;">${to}</span></p>
    </div>

      
    `,
    background: 'rgb(18, 150, 132,0.8)',
    // toast: true,
    color: 'white',
    showConfirmButton:false,
    showCancelButton: false,
    scrollbarPadding: false,
    // position:'center'   
    
  })
}

  return (
    <button
    onClick={showClient}
    className = "btn btn-sm btn-info"
    style={{position:'absolute', bottom:0,left:4}}
    >
      Client Details</button>
  );
}
