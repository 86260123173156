import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { uploadImage } from '../images/ImageUploader';

export const ItineraryContext = createContext()

export default function ItineraryProvider({children}) {

  const navigate = useNavigate();
  const {loadingCounter, setLoadingCounter,authToken} = useContext(AuthContext)
  // const [mainItinerary, setMainItinerary] = useState({})
  const [availableItineraries, setAvailableItineraries] = useState([])


  const [packageChange, setPackageChange] = useState(false)

  const [mainItineraries, setMainItineraries] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [category,setCategory] = useState('all')
  const [onChange,setOnchange] = useState(false)
  const [loading, setLoading] = useState(false)

const [categoryResults, setCategoryResults] = useState([])
const [searchResults,setSearchResults] = useState([])
  
// LOCAL STORACGE
//budget
const [budget, setBudget] = useState(() => {
  const savedBudget = localStorage.getItem('budget');
  return savedBudget ? JSON.parse(savedBudget) : null;
});

useEffect(() => {
  localStorage.setItem('budget', JSON.stringify(budget));
}, [budget]);



//details
const [details, setDetails] = useState(() => {
  const savedDetails = localStorage.getItem('details');
  return savedDetails ? JSON.parse(savedDetails) : null;
});

useEffect(() => {
  localStorage.setItem('details', JSON.stringify(details));
}, [details]);




//dailyitineraries
const [dailyItineraries, setDailyItineraries] = useState(() => {
  const savedDailyItineraries = localStorage.getItem('dailyItineraries');
  return savedDailyItineraries ? JSON.parse(savedDailyItineraries) : [];
});

useEffect(() => {
  localStorage.setItem('dailyItineraries', JSON.stringify(dailyItineraries));
}, [dailyItineraries]);




/// function to add a new main itinerary 
async function addMainItinerary() {
  const baseSwalConfig = {
    icon: 'info',
    toast: true,
    timer: 10000,
    showConfirmButton: false,
    color: 'white',
    position: 'top',
  };

  const errorConfig = {
    ...baseSwalConfig,
    icon: 'error',
    background: 'red',
    timer: 4000,
    position: 'top-end',
  };

  const successConfig = {
    ...baseSwalConfig,
    icon: 'success',
    background: 'green',
    timer: 4000,
    position: 'top-end',
  };

  try {
    // Start loading state
    setLoading(true);

    // Validate input details
    if (!details || Object.values(details).some(value => !value)) {
      Swal.fire({ ...baseSwalConfig, text: 'Please add details' });
      setLoading(false);
      return;
    } 

    if (!dailyItineraries || dailyItineraries.length === 0) {
      Swal.fire({ ...baseSwalConfig, text: 'Please add daily itineraries' });
      setLoading(false);
      return;
    } 

    if (!budget || Object.values(budget).some(value => !value)) {
      Swal.fire({ ...baseSwalConfig, text: 'Please add accommodation includes and excludes' });
      setLoading(false);
      return;
    }

    let correctDetails;

    // Upload main itinerary image
    if(details.image instanceof File) {
      const mainImageResult = await uploadImage(details.image);
      if (!mainImageResult || !mainImageResult.url || !mainImageResult.id) {
        throw new Error('Failed to upload main itinerary image');
      }
      correctDetails = {
        image: mainImageResult.url,
        image_id: mainImageResult.id,
        title: details.title,
        budget: details.budget,
        category: details.category,
        overview: details.overview,
      };
    } else {
      Swal.fire({ ...errorConfig, text: "Please reload the details image" });
      setLoading(false);
      return;
    }

    // Sequentially upload daily itineraries images
    const correctedItineraries = [];
    for (const i of dailyItineraries) {
      if (i.image instanceof File) {
        const result = await uploadImage(i.image);
        if (!result || !result.url || !result.id) {
          throw new Error(`Failed to upload image for day ${i.day}`);
        }
        correctedItineraries.push({
          day: i.day,
          title: i.title,
          description: i.description,
          image: result.url,
          image_id: result.id,
          accommodationId: i.accommodationId,
          destinationId: i.destinationId,
          flightRouteId: i.flightRouteId,
        });
      } else {
        Swal.fire({ ...errorConfig, text: `Please reload the image for day ${i.day}` });
        setLoading(false);
        return;
      }
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('details', JSON.stringify(correctDetails));
    formData.append('budget', JSON.stringify(budget));
    formData.append('dailyItineraries', JSON.stringify(correctedItineraries));

    // Send the request
    const response = await fetch(`https://beshapp.backend.safarinetics.com/itineraries/add`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((res) => res.json());

    // Handle the response
    if (response.success) {
      setOnchange(!onChange);
      setLoadingCounter('iti');
      Swal.fire({ ...successConfig, text: response.success });
      navigate('/itineraries');
      setDetails(null);
      setBudget(null);
      setDailyItineraries([]);
    } else {
      throw new Error(response.error || 'Failed to create itinerary');
    }
  } catch (error) {
    console.error("Error during the process:", error);
    Swal.fire({ ...errorConfig, text: `An error occurred: ${error.message}` });
  } finally {
    // Stop loading state
    setLoading(false);
  }
}

// ================================= GET/ FETCH ===============================

// +++++++++++++++++ GET ALL ITINERARIES +++++++++++++++++++++++
useEffect(() => {
  if (authToken && searchTerm === '' && category === 'all' && [4, 'iti'].includes(loadingCounter)) {
    setLoading(true);

    // Introduce a 1-second delay before starting the fetch request
    const delayFetch = setTimeout(() => {
      fetch('https://beshapp.backend.safarinetics.com/main/itineraries', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setLoading(false);
          if (!response.error) {
            setMainItineraries(response);
            loadingCounter === 4 ? setLoadingCounter(5) : setLoadingCounter(null);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }, 1000); // 1-second delay

    // Clean up the timeout if the component unmounts before the delay
    return () => clearTimeout(delayFetch);
  }
}, [authToken, searchTerm, category, onChange, loadingCounter, setLoadingCounter]);


// +++++++++++++++++ GET  ITINERARIES BY CATEGORY +++++++++++++++++++++++

useEffect(() => {
  if (authToken && category !== 'all') {
    setLoading(true);
    fetch(`https://beshapp.backend.safarinetics.com/main/itineraries/sort/${category}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        setCategoryResults(response);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
}, [authToken, category]);


// +++++++++++++++++ GET  ITINERARIES BY TITLES +++++++++++++++++++++++

useEffect(() => {
  if (authToken && searchTerm !== '') {
    setLoading(true);
    fetch(`https://beshapp.backend.safarinetics.com/main/itineraries/search/${searchTerm}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        setSearchResults(response);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
}, [authToken, searchTerm]);





  //  function to update itinerary budget includes and excludes 
  function updateBudget(updatedBudget){
    fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/update/budget/${updatedBudget?.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: JSON.stringify(updatedBudget)
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnchange(!onChange)
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
      }else{
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }
  

  //  function to update  details 
  function updateDetails(id, updatedDetails){
    const formData = new FormData();
    formData.append('details', JSON.stringify(updatedDetails))
    fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/update/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: formData
    })
    .then((res) => res.json())
    .then(response => {
      // console(response)
      if(response.success){
        setOnchange(!onChange)
        setLoadingCounter('iti')
        navigate(`/itineraries/${id}/${response?.title}`)
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }


  /// function to add a new day 
function addSingleDay(id, newDay){
    fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/${id}/day/add`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: newDay
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnchange(!onChange);
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
      } else {
        // Swal.fire(response.error);
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  
}


   //  function to update a single day 
   function updateDay(id, updatedDetails){

    const formData = new FormData()
    formData.append('details', JSON.stringify(updatedDetails))

    fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/day/update/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: formData
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnchange(!onChange)
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }



  //  function to delete a single day 
  function deleteDay(id){
    fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/day/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnchange(!onChange)
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }


  //  function to delete the whole itinerary 
  function deleteWholeItinerary(id){
    fetch(`https://beshapp.backend.safarinetics.com/main/itinerary/delete/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
    .then((res) => res.json())
    .then(response => {
      if(response.success){
        setOnchange(!onChange)
        setLoadingCounter('iti')
        navigate('/itineraries')
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
      }else{
        // Swal.fire(response.error)
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
  }

//overviewDetails
const [overViewDetails, setOverviewDetails] = useState(() => {
  const savedOverviewDetails = localStorage.getItem('overViewDetails');
  return savedOverviewDetails ? JSON.parse(savedOverviewDetails) : null;
});

useEffect(() => {
  localStorage.setItem('overViewDetails', JSON.stringify(overViewDetails));
}, [overViewDetails]);


// console(`overViewDetails: ${overViewDetails.overview}`)

//new Itinerary
const [newItinerary, setNewItinerary] = useState(() => {
  const savedNewItinerary = localStorage.getItem('newItinerary');
  return savedNewItinerary ? JSON.parse(savedNewItinerary) : [] ;
});

useEffect(() => {
  localStorage.setItem('newItinerary', JSON.stringify(newItinerary));
}, [newItinerary]);

// console(`newItinerary: ${(newItinerary)}`); 

//prices
const [prices, setPrices] = useState(() => {
  const savedPrices = localStorage.getItem('prices');
  return savedPrices ? JSON.parse(savedPrices) : null;
});

useEffect(() => {
  localStorage.setItem('prices', JSON.stringify(prices));
}, [prices]);


// console(`prices: ${prices}`)



//package details
const [packageDetails, setPackageDetails] = useState(() => {
  const savedPackageDetails = localStorage.getItem('packageDetails');
  return savedPackageDetails ? JSON.parse(savedPackageDetails) : null;
});

useEffect(() => {
  localStorage.setItem('packageDetails', JSON.stringify(packageDetails));
}, [packageDetails]);


// console(`packageDetails: ${packageDetails}`)



// ============================= ADD A NEW PACKAGE =============================

  // function to create a new package
  async function createNewPackage(inquiryId, client, days, tour) {
    try {
      // Start the loading process
      setLoading(true);
  
      // Validate input details
      if (!overViewDetails || overViewDetails.length === 0) {
        showAlert('Please add or save the overview', 'info');
        setLoading(false);
        return;
      }
  
      if (newItinerary?.length !== parseInt(days)) {
        showAlert('Please check the number of days or save them', 'info');
        setLoading(false);
        return;
      }
  
      if (!prices || prices.length === 0) {
        showAlert('Please add or save the prices', 'info');
        setLoading(false);
        return;
      }
  
      if (!packageDetails || packageDetails.length === 0) {
        showAlert('Please add or save the details', 'info');
        setLoading(false);
        return;
      }
  
      // Process overview and itineraries
      const correctOverview = await processOverview(overViewDetails);
      const correctItineraries = await processItineraries(newItinerary);
  
      // console(newItinerary)
      // console(correctItineraries)
      // console(prices)
      // Prepare form data
      const formData = new FormData();
      formData.append('overViewDetails', JSON.stringify(correctOverview));
      formData.append('inquiryId', inquiryId);
      formData.append('packageDetails', JSON.stringify(packageDetails));
      formData.append('prices', JSON.stringify(prices));
      formData.append('dailyItineraries', JSON.stringify(correctItineraries));
  
      // Post the form data to the server
      const response = await fetch('https://beshapp.backend.safarinetics.com/package/add', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      }).then((res) => res.json());
      // console(response)
  
      if (response.success) {
        showAlert(response.success, 'success');
        navigate(`/package/${inquiryId}/view/${client}/${days}/${tour}`);
        setPackageChange(!packageChange);
        setTimeout(() => {
          setLoadingCounter('pac');
        }, 3000);
  
        // Clear state and local storage
        resetState();
      } else {
        showAlert(response.error, 'error');
      }
    } catch (error) {
      console.error('Error creating package:', error);
      showAlert('An error occurred while creating the package. Please try again.', 'error');
    } finally {
      // End the loading process
      setLoading(false);

    // localStorage.removeItem('newItinerary');
    }
  }
  
  async function processOverview(details) {
    try {
      if (details?.image instanceof File) {
        const result = await uploadImage(details.image);
        return {
          image: result.url,
          image_id: result.id,
          overview: details.overview,
          vehicles: details.vehicles,
        };
      }
      return details;
    } catch (error) {
      setLoading(false);
      throw error; // Rethrow the error to be caught in the main function
    }
  }
  
  async function processItineraries(itineraries) {
    try {
      const correctItineraries = [];
      for (const itinerary of itineraries) {
        if (itinerary.image instanceof File) {
          const result = await uploadImage(itinerary.image);
          correctItineraries.push({
            image: result.url,
            image_id: result.id,
            accommodation:itinerary.accommodation,
            day:itinerary.day,
            date:itinerary.date,
            description:itinerary.description,
            destination:itinerary.destination,
            flight_route:itinerary?.flight_route || 'None',
            title:itinerary.title
          });
        } else {
          // console(itinerary)
          correctItineraries.push(itinerary);
          // console(correctItineraries)
        }
      }
      return correctItineraries;
    } catch (error) {
      setLoading(false);
      throw error; // Rethrow the error to be caught in the main function
    }
  }
  
  function showAlert(message, icon) {
    Swal.fire({
      icon,
      toast: true,
      text: message,
      timer: 4000,
      showConfirmButton: false,
      color: 'white',
      background: icon === 'success' ? 'green' : icon === 'error' ? 'red' : 'lightblue',
      position: 'top-end',
    });
  }
  
  function resetState() {
    setNewItinerary([]);
    setPrices({});
    setOverviewDetails({});
    setPackageDetails({});
    localStorage.removeItem('newItinerary');
    localStorage.removeItem('prices');
    localStorage.removeItem('overviewDetails');
    localStorage.removeItem('packageDetails');
  }
  
// ============================= END OF CREATING A NEW PACKAGE =============================

  
  // check if package exists
  function checkPackageExists(inquiryId,days,tour,client){
    if(tour === 'Other(Customize my travel)'){
      Swal.fire({
        icon:'info',
        text:'Please create a new specific itinerary for the client and then update the inquiry package to proceed'
      })
      return
    }
    fetch(`https://beshapp.backend.safarinetics.com/package_exists/${inquiryId}`)
    .then(res => res.json())
    .then((response) => {
      if (response.exists ) {
        Swal.fire({
          title: "Package already exists for this inquiry",
          text: "Would you like to view the package?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, view package"
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/package/${inquiryId}/view/${client}/${days}/${tour}`);
          }
        });
      }
      else {
        navigate(`/create/${days}/${inquiryId}/package/${tour}`)
      }
    })
    .catch((error) => console.error(error))
  }

  //fetch all available itinerary titles
  useEffect(() => {
    fetch('https://beshapp.backend.safarinetics.com/get_all_titles')
      .then((res) => res.json())
      .then((response) => {
        if(!response.error){
        setAvailableItineraries(response);
        }
      })
      .catch((error) => console.error(error))
  }, [onChange]);

  // function to send itinerary link
function sendItineraryLink(email, name, currentUrl){
  fetch(`https://beshapp.backend.safarinetics.com/itinerary/send/${email}/${currentUrl}/${name}`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
    }
    })
    .then((res) => res.json())
    .then((response) => {
      if (response.success){
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
    }else if (response.error){
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
    })
    .catch((error) => console.error(error))
    }
 

  const contextData = {
    
    mainItineraries,
    searchTerm, setSearchTerm,category,setCategory,
    dailyItineraries, setDailyItineraries,details,setDetails,budget,setBudget,
    addMainItinerary,updateBudget,updateDetails,deleteWholeItinerary,
    addSingleDay,deleteDay,updateDay,
    onChange,


    newItinerary, setNewItinerary,
    prices, setPrices,
    overViewDetails, setOverviewDetails,
    packageDetails, setPackageDetails,
    createNewPackage,
    checkPackageExists,
    availableItineraries,
    packageChange, setPackageChange,sendItineraryLink,
    loading, setLoading,
    categoryResults, setCategoryResults,
    searchResults,setSearchResults,



  }
  return (
   <ItineraryContext.Provider value={contextData} >
    {children}
   </ItineraryContext.Provider>
  )
}
