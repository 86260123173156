import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AuthContext } from '../context/AuthContext';
import { ReceiptContext } from '../context/ReceiptContext';

export default function PackageReceipt({ packageInfo }) {
  const { currentUser, authToken } = useContext(AuthContext);
  const { onReceiptChange } = useContext(ReceiptContext);

  const [packageReceipts, setPackageReceipts] = useState([]);
  // console(packageReceipts);

  useEffect(() => {
    if (authToken && packageInfo && currentUser?.position === 'ADMIN') {
      fetch(`https://beshapp.backend.safarinetics.com/package/payment/receipts/${packageInfo?.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: `Bearer ${authToken && authToken}`,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response.error) {
            setPackageReceipts(response);
          } 
        })
        .catch((error) => console.error(error));
    }
  }, [onReceiptChange, authToken, currentUser, packageInfo]);

  function showClientReceipts() {
    // Calculate the grand total
    const grandTotal = packageReceipts.reduce((sum, receipt) => sum + receipt.amount, 0).toLocaleString();
  
    // Create table rows
    const tableRows = packageReceipts.map(receipt => `
      <tr>
        <td>${receipt?.id}</td>
        <td>${receipt?.amount?.toLocaleString()} $</td>
        <td>${receipt?.received_date}</td>
      </tr>
    `).join('');
  
    // Add the grand total row
    const grandTotalRow = `
      <tr style="font-weight: bold; border-top: 2px solid white;">
        <td colspan="2">Total :</td>
        <td>${grandTotal} $</td>
      </tr>
    `;
  
    Swal.fire({
      title: 'Package Receipts',
      html: `
        <table style="width:100%; color:wheat; border: 1px solid white; border-collapse: collapse;">
          <thead>
            <tr style="border-bottom: 1px solid white; color:gold;">
              <th>Receipt ID</th>
              <th>Amount</th>
              <th>Received Date</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
            ${grandTotalRow}
          </tbody>
        </table>
      `,
      background: 'rgb(18, 150, 132,0.768)',
      color: 'white',
      showConfirmButton: false,
      showCancelButton: false,
      scrollbarPadding: false,
      showCloseButton: true,
    });
  }

  return (
    <>
      {packageReceipts.length > 0 && (
        <button
          onClick={showClientReceipts}
          className="btn btn-sm btn-info"
          style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)' }}
        >
          Receipts
        </button>
      )}
    </>
  );
}
