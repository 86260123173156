import React, { useContext, useState, useEffect } from 'react';
import { TextField, Grid, Button, MenuItem, Select, InputLabel, FormControl, Container, Typography, Snackbar } from '@mui/material';
import { ItineraryContext } from '../context/ItineraryContext';
import { uploadImage } from '../images/ImageUploader';
import LoadingComponent from '../components/LoadingComponent';

const UpdateDetails = ({ mainItinerary, setUpdateDetails }) => {
  const { updateDetails, mainItineraries, loading, setLoading } = useContext(ItineraryContext);

  const [title, setTitle] = useState('');
  const [budget, setBudget] = useState('');
  const [category, setCategory] = useState('');
  const [overview, setOverview] = useState('');
  const [image, setImage] = useState('');
  const [imageId, setImageId] = useState('');
  const [titleError, setTitleError] = useState('');

  useEffect(() => {
    if (mainItinerary) {
      setTitle(mainItinerary.title || '');
      setBudget(mainItinerary.budget || '');
      setCategory(mainItinerary.category || '');
      setOverview(mainItinerary.overview?.overview || '');
      setImage(mainItinerary.overview?.image || '');
      setImageId(mainItinerary.overview?.image_id || '');
    }
  }, [mainItinerary]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const validateTitle = (newTitle) => {
    const isDuplicate = mainItineraries.some(
      itinerary =>
        itinerary.title.toLowerCase() === newTitle.toLowerCase() &&
        itinerary.id !== mainItinerary?.id // Allow the current title
    );
    if (isDuplicate) {
      setTitleError('This title already exists in the itinerary.');
    } else {
      setTitleError('');
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    validateTitle(newTitle);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (titleError) return;

    setLoading(true); // Start loading

    const formData = { title, budget, category, overview, image, image_id: imageId };

    try {
      if (image instanceof File) {
        const result = await uploadImage(image);
        // console(`Image uploaded: ${result.url}, ${result.id}`);
        formData.image = result.url;
        formData.image_id = result.id;
      }

      // Submit the form data
      await updateDetails(mainItinerary?.id, formData);
    } catch (error) {
      console.error("Error during form submission:", error);
      setLoading(false); // Stop loading
    } finally {
      setLoading(false); // Stop loading
      setUpdateDetails(false); // Ensure this is executed after all processes
    }
  };

  if (loading) {
    return <LoadingComponent loadingText={'Updating itinerary Details ....'} />;
  }

  return (
    <Container maxWidth="sm" style={{backgroundColor:'rgb(175, 238, 238,0.9)' , borderRadius:'5px', padding:'20px'}} sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Update Details
      </Typography>

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Title"
            value={title}
            onChange={handleTitleChange}
            error={!!titleError}
            helperText={titleError}
            required
          />
        </FormControl>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl fullWidth margin="normal" sx={{ mr: 1 }}>
            <TextField
              type="number"
              label="Budget"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              inputProps={{ min: 100 }}
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal" sx={{ ml: 1 }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              label="Category"
              labelId="category-label"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <MenuItem value="" disabled>Select the category</MenuItem>
              <MenuItem value="Executive">Executive</MenuItem>
              <MenuItem value="Premium">Premium</MenuItem>
              <MenuItem value="Customized">Customized</MenuItem>
            </Select>
          </FormControl>
        </div>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Overview"
            multiline
            rows={4}
            value={overview}
            onChange={(e) => setOverview(e.target.value)}
            required
          />
        </FormControl>

        <Grid item sm={12} sx={{ m: 4 }}>
            <Button variant="contained" component="label" fullWidth>
              Upload New Image
              <input
                name="image"
                type="file"
                hidden
                accept=".png, .jpg, .jpeg"
                onChange={handleImageChange}
              />
            </Button>
          </Grid>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="submit" variant="contained" color="primary" disabled={!!titleError}>
            Submit
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setUpdateDetails(false)}>
            Cancel
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default UpdateDetails;
