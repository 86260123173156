import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsAndConditions() {
  return (
    <div className="p-5 bg-info bg-opacity-25">
      <h2 className="text-center mb-4">Terms and Conditions</h2>
      <div style={{ fontSize: '16px' }}>
        <p><strong>1. Introduction</strong></p>
        <p>Welcome to Besh African Adventures. By accessing or using our App, you agree to comply with and be bound by these Terms and Conditions. Please review these Terms carefully. If you do not agree to these Terms, you should not use the website.</p>

        <p><strong>2. Data Collection and Use</strong></p>
        <p className="ms-2"><strong>2.1 Information We Collect</strong></p>
        <p>When you fill out our inquiry form, we collect the following information:</p>
        <ul>
          <li>Full Name: For personalized communication and booking purposes.</li>
          <li>Email Address: For sending updates, booking confirmations, newsletters, and other communication.</li>
          <li>Phone Number: For contact purposes, including booking updates and customer support.</li>
          <li>Nationality: For travel-related requirements and preferences.</li>
          <li>Travel Dates (From and To): For booking and itinerary planning.</li>
          <li>Number of Adults: For booking and package customization.</li>
          <li>Adults Sharing: For accommodation arrangements.</li>
          <li>Number of Children: For booking and package customization.</li>
          <li>Target Budget: To provide suitable package options.</li>
          <li>Selected Package: To tailor your travel experience.</li>
          <li>Specifications/Preferences (Optional): To customize your travel experience according to your needs.</li>
        </ul>
        <p className="ms-2"><strong>2.2 How We Use Your Information</strong></p>
        <p>The information we collect through the inquiry form is used to:</p>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Personalize user experience.</li>
          <li>Facilitate bookings and travel arrangements.</li>
          <li>Communicate with you, including responding to inquiries and sending service-related updates.</li>
          <li>Ensure compliance with legal obligations.</li>
        </ul>

        <p><strong>3. Data Protection</strong></p>
        <p className="ms-2"><strong>3.1 Security Measures</strong></p>
        <p>We implement appropriate technical and organizational measures to protect your data against unauthorized access, alteration, disclosure, or destruction.</p>
        <p className="ms-2"><strong>3.2 Data Retention</strong></p>
        <p>We will retain your personal information only for as long as necessary to fulfill the purposes for which we collected it, including to satisfy any legal, accounting, or reporting requirements.</p>

        <p><strong>4. User Responsibilities</strong></p>
        <p>By providing your personal information through the inquiry form, you agree that:</p>
        <ul>
          <li>The information you provide is accurate and up-to-date.</li>
          <li>You will notify us of any changes to your information.</li>
          <li>You consent to collecting, using, and processing your information as described in these Terms.</li>
        </ul>

        <p><strong>5. User Rights</strong></p>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal information.</li>
          <li>Request correction of any inaccurate information.</li>
          <li>Request deletion of your personal information under certain conditions.</li>
          <li>Object to the processing of your personal information in certain circumstances.</li>
          <li>Withdraw your consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.</li>
        </ul>

        <p><strong>6. Cookies and Tracking Technologies</strong></p>
        <p>Our App may use cookies and similar tracking technologies to enhance user experience and analyze usage. By using the App, you consent to our use of cookies as described in our <Link>Cookie Policy</Link>.</p>

        <p><strong>7. Third-Party Services</strong></p>
        <p>We may use third-party services to help us operate our App and manage our data. These third parties are obligated to protect your information and are prohibited from using your data for any purpose other than those specified by us.</p>

        <p><strong>8. Modifications to Terms</strong></p>
        <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page and updating the effective date. Your continued use of the App after any modifications indicates your acceptance of the new Terms.</p>

        <p><strong>9. Governing Law</strong></p>
        <p>These Terms are governed by and construed per the laws of Kenya, without regard to its conflict of law principles.</p>

        <p><strong>10. Confirmation Of Booking</strong></p>
        <p>A booking will only be confirmed upon receipt of the deposit payment.</p>
        <p>A 50% deposit must secure bookings within 48 hours upon confirmation with the balance payable by cash on arrival / 14 days before arrival. Bookings secured by the appropriate deposit may be released with prior notification.</p>
        <p className="ms-2"><strong>10.1 Release Periods & Cancellation Policies For Confirmed Bookings</strong></p>
        <ul>
          <li>Between the booking date & 46 days before arrival 20% cancellation charges</li>
          <li>Between 45 & 21 days before arrival 50% cancellation charges</li>
          <li>Within 20 days of the date of arrival 100% cancellation charges</li>
        </ul>

        <p><strong>11. Amendment in Rates</strong></p>
        <p>Prices are subject to change without prior notification. Prices are also subject to change in the event of changes in government-imposed taxes, fuel prices, hotel charges, tourism levies, and or the introduction of national, regional, or local taxes or levies of whatever nature.</p>

        <p><strong>12. Levies & Taxes</strong></p>
        <p>Rates are inclusive of current taxes.</p>

        <p><strong>13. Contact Us</strong></p>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <ul>
          <li>Email: <a href="mailto:info@beshafricanadventures.co.ke">info@beshafricanadventures.co.ke</a></li>
          <li>Phone: <a href="tel:+254702799149">+254 702799149</a></li>
          <li>Address: Cassia Court, ground floor B2 P.O Box 12371, Upperhill. Nairobi, Kenya.</li>
        </ul>
        <p><strong>Effective Date: 01.01.2024</strong></p>
      </div>
    </div>
  );
}
