import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { ItineraryContext } from '../context/ItineraryContext';

const AddBudget = () => {
   const {setBudget, budget} = useContext(ItineraryContext)
  const [includes, setIncludes] = useState(budget?.includes.join('\n') || '');
  const [excludes, setExcludes] = useState(budget?.excludes.join('\n') || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Convert the text areas into arrays
    const includesArray = includes.split('\n').filter(item => item.trim() !== '');
    const excludesArray = excludes.split('\n').filter(item => item.trim() !== '');
    
    // Handle form submission logic here
    const formData = {
      includes: includesArray,
      excludes: excludesArray
    }
    setBudget(formData);
    
   Swal.fire({
    text:"Saved",
    showConfirmButton:false,
    timer:4000,
    icon:'success',
    color:'white',
    background:'green',
    toast:true,
    position:'top'
   });
  };

  return (
    <div className="container-fluid justify-content-center row pt-4">
      <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'junge' }}>Add  includes and excludes</h2>
      <form onSubmit={handleSubmit} className="p-4 border-info bg-light bg-opacity-50 rounded">
        <details className="rounded m-2 bg-info bg-opacity-75 p-2">
          <summary htmlFor="includes" className="form-summary">Includes</summary>
          <textarea
            className="form-control"
            id="includes"
            placeholder='Enter includes here each seperated by a new line'
            rows="8"
            value={includes}
            onChange={(e) => setIncludes(e.target.value)}
            required
          ></textarea>
        </details>
        <details className="rounded m-2 bg-info bg-opacity-75 p-2">
          <summary htmlFor="excludes" className="form-summary">Excludes</summary>
          <textarea
            className="form-control"
            id="excludes"
            rows="8"
            placeholder='Enter excludes here each seperated by a new line'
            value={excludes}
            onChange={(e) => setExcludes(e.target.value)}
            required
          ></textarea>
        </details>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default AddBudget;
