import React from 'react'
import Inquiries from '../inquiries/Inquiries'

export default function Home() {
  return (
    <div>
  <Inquiries/>
    </div>
  )
}
