import React, { useContext, useState } from 'react';
import UpdateUser from './UpdateUser';
import {  BsX } from 'react-icons/bs'; 
import { UserContext } from '../context/UserContext';

const AllUsers = ({setViewUsers}) => {
  const {users} = useContext(UserContext)
  const [updateUser, setUpdateUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user

  

  // Function to handle row click
  const handleRowClick = (user) => {
    setSelectedUser(user);
    setUpdateUser(true);
  };

  return (
    <div className="container-fluid card col-md-6 my-4 p-3 shadow-lg rounded border border-info" style={{ backgroundColor: 'rgb(175, 238, 238,0.1)' ,minWidth:'fit-content'}}>
      {updateUser ? (
        <UpdateUser setUpdateUser={setUpdateUser} user={selectedUser} />
      ) : (
        <>
          <div className='' style={{ position: 'relative' }}>
            <h4 className='card-header fw-semibold text-center' style={{ color: "wheat", fontFamily: 'junge', margin: 0 }}>
               All users
               <span style={{ position: 'absolute', right: '0' }}>
                  <BsX onClick={() => setViewUsers(false)} size={30} color='red' />
               </span>
            </h4>
         </div>
          <table className="table table-bordered table-info table-hover rounded">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Position</th>
                <th>Status</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user?.id} onClick={() => handleRowClick(user)}> {/* Add onClick event handler */}
                  <td>{index + 1}</td>
                  <td>{user?.name}</td>
                  <td>{user?.position}</td>
                  <td>{user?.active ? 'Active' : 'Inactive'}</td> {/* Display 'Active' if true, 'Inactive' if false */}
                  <td>{user?.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AllUsers;
