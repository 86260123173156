import React, { useContext} from 'react'
// import { AccommodationContext } from '../context/AccommodationContext'
import { FlightContext} from '../context/FlightContext';

export default function AddDayFlight({detail, setDetail}) {
  //  const {accommodations} = useContext(AccommodationContext)
   const {routes} = useContext(FlightContext)

   
  return (
  
        
        <div className=" mb-3 col-md-11 ">
         <label className="form-label">Flight(Route):</label>
         <select
           className="form-select border-success"
           value={detail.route_id}
           onChange={(e) => setDetail({ ...detail, flight_route: e.target.value })}
           
         >
           <option value="" disabled>Select Route</option>
           <option style={{backgroundColor:'lightblue'}} value='None'>None</option>
           {Array.isArray(routes) && routes.length > 0 && routes.map((route) => (
             <option
               key={route.id}
               value={route.id}
               style={{ fontWeight: 'semibold',fontFamily:'junge' }} // Style for the name
             >
               {route?.origin?.location} - {route?.destination?.location} ({route?.flight_name}) ${route?.rates}
             </option>
           ))}
         </select>
       </div>

  )
}
