import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { InquiryContext } from '../context/InquiryContext';
import { ItineraryContext } from '../context/ItineraryContext';
import { AuthContext } from '../context/AuthContext';
import { FaCheckCircle } from 'react-icons/fa';
import LoadingComponent from '../components/LoadingComponent';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddInquiry = () => {
  const { AddInquiry, loading } = useContext(InquiryContext);
  const { loggedIn } = useContext(AuthContext);
  const { availableItineraries } = useContext(ItineraryContext);

  const filteredCategory = availableItineraries && availableItineraries.filter(itinerary => itinerary.category !== 'Customized');

  const [submited, setSubmited] = useState(false);
  const initialFormData = {
    tour: '',
    adults: '',
    adults_sharing: '',
    children: '',
    start_date: '',
    end_date: '',
    budget: '',
    currency: '',
    message: '',
    feedback: '',
    client: {
      name: '',
      email: '',
      phone: '',
      country: ''
    },
  };

  const getInitialFormData = () => {
    const savedFormData = localStorage.getItem('formData');
    return savedFormData ? JSON.parse(savedFormData) : initialFormData;
  };

  const [formData, setFormData] = useState(getInitialFormData());

  // Error states
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [adultsError, setAdultsError] = useState('');
  const [adultsSharingError, setAdultsSharingError] = useState('');
  const [childrenError, setChildrenError] = useState('');
  const [adultsAndSharingError, setAdultsAndSharingError] = useState('');
  const [budgetError, setBudgetError] = useState('');

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = parseInt(value, 10);

    // Update form data
     // Update formData state
    setFormData((prevState) => {
      const [field, subField] = name.split('.');
      if (subField) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [subField]: value,
          },
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });


    // Handle name validation
    if (name === 'client.name') {
      const namePattern = /^[A-Za-z]+(\s[A-Za-z]+)+$/;
      if (!namePattern.test(value.trim())) {
        setNameError('Please enter at least two names.');
      } else {
        setNameError('');
      }
    }

    // Handle email validation
    if (name === 'client.email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setEmailError('Invalid email address. Please enter a valid email.');
      } else {
        setEmailError('');
      }
    }

    

    // Handle start date validation
    if (name === 'start_date') {
      const today = new Date();
      today.setDate(today.getDate() + 1); // Set to tomorrow's date
      const selectedStartDate = new Date(value);

      today.setHours(0, 0, 0, 0);

      if (selectedStartDate < today) {
        setStartDateError('Start date must be at least 1 day ahead (tomorrow or later).');
      } else {
        setStartDateError('');
      }
    }

    // Handle end date validation
if (name === 'end_date') {
  const selectedEndDate = new Date(value);
  const selectedStartDate = new Date(formData.start_date);

// Add 1 day to the selected start date
  selectedStartDate.setDate(selectedStartDate.getDate() + 1);

  if (selectedEndDate < selectedStartDate) {
    setEndDateError('End date cannot be before or equal to start date.');
  } else {
    setEndDateError('');
  }
}


    // Handle number validations
    if (name === 'adults' || name === 'adults_sharing' || name === 'children') {
      if (numericValue < 0) {
        if (name === 'adults') {
          setAdultsError('Number of adults cannot be less than 0.');
        } else if (name === 'adults_sharing') {
          setAdultsSharingError('Number of adults sharing cannot be less than 0.');
        } else if (name === 'children') {
          setChildrenError('Number of children cannot be less than 0.');
        }
      } else {
        if (name === 'adults') {
          setAdultsError('');
        } else if (name === 'adults_sharing') {
          if (numericValue === 1) {
            setAdultsSharingError('Adults sharing cannot be exactly 1. It must be 0 or greater than 1.');
          } else {
            setAdultsSharingError('');
          }
        } else if (name === 'children') {
          setChildrenError('');
        }
      }

      if (name === 'adults' && numericValue === 0 && formData.adults_sharing === 0) {
        setAdultsAndSharingError('Either number of adults or adults sharing must be greater than 0.');
      } else {
        setAdultsAndSharingError('');
      }

    }

    // Handle budget validation
    if (name === 'budget') {
      if (numericValue <= 0) {
        setBudgetError('Estimated budget must be a positive number.');
      } else {
        setBudgetError('');
      }
    }

    
  };

  const handlePhoneChange = (value, countryData) => {
    setFormData(prevState => ({
      ...prevState,
      client: {
        ...prevState.client,
        phone: `+${value}`,
        country: countryData.name // Store the country code
      }
    }));
    // Handle phone number validation
    
      const phonePattern = /^\+?\d{10,15}$/;
      if (!phonePattern.test(value)) {
        setPhoneError('Invalid phone number. Please enter a valid phone number.');
      } else {
        setPhoneError('');
      }
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add validation logic here...

    // If all validations pass, proceed with inquiry submission
    if (nameError || emailError || phoneError || startDateError || endDateError || adultsError || adultsSharingError || childrenError || adultsAndSharingError || budgetError) {
      showAlert('Please correct the errors in the form.');
      return;
    }
    if(formData.adults < 1 && formData.adults_sharing < 1){
      showAlert('There should be atleast 1 adult.');
      return;
    }

    await AddInquiry(formData); // Assuming AddInquiry is an async function
    setSubmited(true);
    localStorage.removeItem('formData');
    setFormData(initialFormData);
  };

  const showAlert = (text) => {  
    Swal.fire({
      icon: 'error',
      title: text,
      timer:5000,
      timerProgressBar:true,
      showConfirmButton: false,
      toast: true,
      position: 'center',
      background:'red',
      color:'wheat'
    });
  };

  return (
    <Container maxWidth="" style={{ backgroundColor: 'rgb(8, 50, 32)', minHeight: '100%', padding: '20px' }}>
      {loading && <LoadingComponent loadingText={'Submitting....'} />}

      {!loggedIn && submited && !loading && (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '85vh', position: 'relative' }}>
        <Grid item xs={12} md={6}>
          <div style={{ backgroundColor: 'green', color: 'beige', padding: '20px', textAlign: 'center', position: 'relative', zIndex:11}}>
            <FaCheckCircle style={{ marginRight: '10px' }} size={50} />
            <Typography variant="h5" gutterBottom style={{ marginBottom: '20px' }}>Thank You!</Typography>
            <Typography variant="body1" paragraph>Your inquiry has been sent successfully.</Typography>
            <Typography variant="body1" paragraph>If you don't see our email, check your spam folder and mark it as not spam.</Typography>
            <Button className='text-decoration-none' style={{ zIndex:'999' }} href='https://beshafricanadventures.co.ke' variant='contained' color='primary'>Go to Website</Button>
            
            {/* Celebration GIFs */}
            <img
              src="https://i.pinimg.com/originals/9e/bf/a7/9ebfa7a509fb8a748c61497ac95a0ac3.gif"  // Example fireworks GIF URL
              alt="Fireworks"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 'auto',
                zIndex: -1, // Ensure GIF is behind content
                opacity: 0.0925, // Adjust opacity if needed
              }}
            />
            <img
              src="https://i.pinimg.com/originals/e5/83/3e/e5833e1bea7d379f0f4e4ae250b7cf81.gif"  // Example confetti GIF URL
              alt="Confetti"
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '100%',
                height: 'auto',
                zIndex: 0, // Ensure GIF is behind content
                opacity: 0.0925, // Adjust opacity if needed
              }}
            />
          </div>
        </Grid>
      </Grid>
      )}

      {!submited && !loading && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box sx={{ backgroundColor: 'rgb(175, 238, 238,0.9)', color: 'black', padding: '20px', borderRadius: '8px' }}>
              <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>Enquire</Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>

                   {/* Client Details Section */}
                   <Grid item xs={12}>
                    <Typography variant="h6" color='green' gutterBottom>Personal Details</Typography>
                  </Grid>

                  <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Your Name"
          name="client.name"
          value={formData?.client?.name}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(nameError)}
          helperText={nameError}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Your Email"
          name="client.email"
          type="email"
          value={formData?.client?.email}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(emailError)}
          helperText={emailError}
        />
      </Grid>

                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      country={'ke'}
                      name='phone'
                      id='phone'
                      required
                      value={formData.client.phone}
                      onChange={handlePhoneChange}
                      inputStyle={{ width: '100%', height: '56px' }}
                      buttonStyle={{ height: '56px' }}
                      dropdownStyle={{ color: '#000' }}
                      dropdownClass="custom-dropdown"
                    />
                    {phoneError && (
                      <FormHelperText error>{phoneError}</FormHelperText>
                    )}
                  </Grid>
    </Grid>

                  {/* Travel Package Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6" color='green' gutterBottom>Travel Package</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="tour-label">Travel package</InputLabel>
                      <Select
                        labelId="tour-label"
                        id="tour"
                        name="tour"
                        value={formData.tour}
                        onChange={handleChange}
                        label="Travel package"
                        
                      >
                        <MenuItem value="" disabled>Select a package</MenuItem>
                        {loggedIn && Array.isArray(availableItineraries) && availableItineraries.length > 0 && availableItineraries.map((itinerary, index) => (
                          <MenuItem key={index} value={itinerary?.title}>{itinerary?.title} ({itinerary?.category})</MenuItem>
                        ))}
                        {!loggedIn && Array.isArray(filteredCategory) && filteredCategory.length > 0 && filteredCategory.map((itinerary, index) => (
                          <MenuItem key={index} value={itinerary?.title}>{itinerary?.title} ({itinerary?.category})</MenuItem>
                        ))}
                        <MenuItem value={'Other(Customize my travel)'} style={{ backgroundColor: 'lightblue' }}>Other(Customize my travel)</MenuItem>
                      </Select>
                    </FormControl>
                    {formData?.tour === "Other(Customize my travel)" && (
                      <Typography variant="body2" style={{ paddingTop: '10px', fontWeight: '500' }}>NOTE: Write all your Preferences and Specifications down below</Typography>
                    )}
                  </Grid>


                  {/* Travel Dates Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6" color='green' gutterBottom>Travel Dates</Typography>
                  </Grid>
                  {/* Start Date */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Start Date"
                      name="start_date"
                      type="date"
                      value={formData.start_date}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                    {startDateError && (
                      <FormHelperText error>{startDateError}</FormHelperText>
                    )}
                  </Grid>

                  {/* End Date */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="End Date"
                      name="end_date"
                      type="date"
                      value={formData.end_date}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                    {endDateError && (
                      <FormHelperText error>{endDateError}</FormHelperText>
                    )}
                  </Grid>

                  {/* Group Size Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6" color='green' gutterBottom>Group Size</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Number of Adults"
                      name="adults"
                      type="number"
                      value={formData.adults}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={Boolean(adultsError)}
                      helperText={adultsError}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Adults Sharing"
                      name="adults_sharing"
                      type="number"
                      value={formData.adults_sharing}
                      onChange={handleChange}
                      fullWidth
                      error={Boolean(adultsSharingError)}
                      helperText={adultsSharingError}
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Number of Children"
                      name="children"
                      type="number"
                      value={formData.children}
                      onChange={handleChange}
                      fullWidth
                      error={Boolean(childrenError)}
                      helperText={childrenError}
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>


                  {/* Budget Section */}
              <Grid item xs={12}>
                <Typography variant="h6" color='green' gutterBottom>Budget</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                    <TextField
                      label="Estimated Budget"
                      name="budget"
                      type="number"
                      value={formData.budget}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={Boolean(budgetError)}
                      helperText={budgetError}
                    />
                  </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="currency"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                    label="Currency"
                    required
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    {/* <MenuItem value="KES">KES</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>

              {/* Message */}
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>

              {/* Feedback */}
              <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>

              <InputLabel id="feedback-label">How did you hear about us?</InputLabel>

              <Select
                label="How did you hear about us?"
                id="feedback"
                name="feedback"
                value={formData.feedback}
                onChange={handleChange}
              >
                {/* <MenuItem value="" disabled>Select Currency</MenuItem> */}
                <MenuItem value="Google search">Google search</MenuItem>
                <MenuItem value="Website">Website</MenuItem>
                <MenuItem value="Trip Advisor">Trip Advisor</MenuItem>
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="Website">Word of Mouth (Friend)</MenuItem>
              </Select>
              </FormControl>
                
              </Grid>


              {/* Terms and Conditions */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox required />}
                  label={
                    <span>
                      I agree to the <Link to="/terms" style={{ textDecoration: 'underline', color: 'blue' }}>Terms and Conditions</Link>
                    </span>
                  }
                />
              </Grid>

            </Grid>
            <Box mt={4} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ fontWeight: 'bold', fontSize: '18px', backgroundColor: 'green', color: 'wheat' }}
              >
                Submit Inquiry
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  )}
</Container>
);
};

export default AddInquiry;