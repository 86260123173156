import React, { useContext, useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ItineraryContext } from '../context/ItineraryContext';
// import Swal from 'sweetalert2';
import LoadingComponent from '../components/LoadingComponent';
import { motion } from 'framer-motion';


export default function Itineraries() {
  const {mainItineraries,category,searchTerm,setSearchTerm,setCategory, loading, categoryResults, searchResults,} = useContext(ItineraryContext)
const navigate = useNavigate()

const [filteredItems, setFilteredItems] = useState([]);
const [search, setSearch] = useState(''); // Initialize search state
const [itinerariesToDisplay, setItinerariesToDisplay] = useState([])

useEffect(() => {
  if (category !== 'all' ) {
    setItinerariesToDisplay(categoryResults);
  } else if (searchTerm !== '' && search !== '') {
    setItinerariesToDisplay(searchResults);
  }else{
    setItinerariesToDisplay(mainItineraries)
  }
  
  // setItinerariesToDisplay(mainItineraries)
}, [category, searchTerm, mainItineraries, categoryResults, searchResults, search]);



  

    if (loading){
      const loadingText = 'Loading itineraries ......'
      return(
        <LoadingComponent loadingText={loadingText}/>
      )
    }
    const handleSearch = (e) => {
      const term = e.target.value.toLowerCase();
      setSearch(term);
    
      if (term.length > 0) {
        const filtered = new Set(); // Use a Set to store unique search results
    
        mainItineraries.forEach(item => {
          const title = item.title?.toLowerCase();
    
          if (title?.includes(term)) {
            filtered.add(item?.title);
          }
        });
    
        // Convert Set to an array and sort alphabetically
        const sortedFilteredItems = [...filtered].sort((a, b) => a.localeCompare(b));
        setFilteredItems(sortedFilteredItems);
      } else {
        setFilteredItems([]);
        setItinerariesToDisplay(mainItineraries)
      }
    };
    function handleSubmit(e){
      e.preventDefault()
      setSearchTerm(search)
      setCategory('all')
    }
  
    const handleItemClick = (item) => {
      setSearch(item);
      setFilteredItems([]);
    };
  

  return (
    <div className='container-fluid py-4' style={{backgroundColor: 'rgb(1,50,32,1)', minHeight:'100%'}}>
      <h2 className='text-center' style={{color:'wheat', fontFamily:'junge'}}>Itineraries</h2>
      <div className='d-flex flex-wrap gap-4 mb-3 mx-auto justify-content-center'>
      <form className="input-group position-relative" style={{ maxWidth: '20rem' }} onSubmit={handleSubmit}>
          <input
            type="search"
            className="form-control text-center border border-success"
            placeholder="Search 🔍"
            value={search}
            onChange={handleSearch}
            style={{ fontSize: '1rem' }}
          />
          <button className="btn btn-success" type="submit">🔍</button>
          {filteredItems.length > 0 && (
            <ul className="list-group position-absolute w-100" style={{ top: '100%', zIndex: 9 }}>
              {filteredItems.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleItemClick(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </form>
        <div className="form-group ">
            <select 
            className="form-control"
            value={category}
            id="dropdownSelect"
            onChange={(e) => setCategory(e.target.value)}>
               <option value="all">All</option>
               <option value="Premium">Premium</option>
               <option value="Executive">Executive</option>
               <option value="Customized">Customized</option>
            </select>
         </div>
        <div className=''>
          <Link to={'/itineraries/add'} className='btn btn-sm btn-primary'>Add Itinerary</Link>
        </div>
      </div>

      {Array.isArray(itinerariesToDisplay) && itinerariesToDisplay.length > 0 ? (
        <motion.div className="row"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}>
          {itinerariesToDisplay.map((itinerary) => (
            <motion.div
              key={itinerary.id}
              className="col-lg-4 col-md-4 col-sm-6 mb-4 px-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="card border-success" style={{ backgroundColor: 'rgb(175, 238, 238,0.88)', overflow: 'hidden',height:"20em" }}>
              
                  <motion.img
                  src={itinerary?.overview_image}
                  className="card-img-top img-fluid d-block h-100"
                  style={{height:"100%"}}
                  alt={itinerary?.title}
                  onClick={()=>  navigate(`/itineraries/${itinerary?.id}/${itinerary?.title}`)}
                  initial={{ scale: 1.2 }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                />
               
                <div className="card-footer" style={{ backgroundColor: 'rgb(175, 238, 238,0.58)', position: 'absolute', bottom: 0, width: '100%' }}>
                <p className='card-title' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <strong>{itinerary?.title}</strong>
                </p>
                    {/* <h5 className="card-title text-center text-info" style={{ textShadow: '1px 1px 2px rgba(175, 238, 238, 1)' }}>{flight.name}</h5> */}
                    
                <div className="overview-container" style={{ height: '2.2rem', overflowY: 'hidden' }}>
                  <small className="overview-text" style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '1.2', height: '3.6em' }}>
                      {itinerary?.overview}
                  </small>
                </div>
                <div className='d-flex flex-wrap justify-content-between px-2' >
                  <small className='card-text'>
                    <strong className='fw-semibold text-dark fs-6'>
                      Budget@:</strong>
                      <strong> {itinerary?.budget?.toLocaleString()} $</strong>
                    </small>
                  <small className='card-text '>
                      <strong className='text-dark'> {itinerary?.category}</strong>
                    </small>
                </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <div className="alert alert-warning" role="alert">
          No Itineraries found.
        </div>
      )}
    </div>
  );
}
