import React, { useContext } from 'react';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

import Dashboard from '../components/Dashboard';
import { AuthContext } from '../context/AuthContext';
import Footer from './Footer';

export default function Layout() {
  const { loggedIn } = useContext(AuthContext);

  return (
    <div className='container-fluid' style={{backgroundColor: 'rgb(175, 238, 238,0.9)', height: '100vh', width: '100%', padding: '0', overflowY: 'scroll', scrollbarWidth: 'none', /* Firefox */ msOverflowStyle: 'none' /* IE 11 */ }}>
 
      <Navbar />
      {loggedIn ? (
        <div className="d-flex" style={{ width: '100%', overflowX: 'auto', height:'88vh', overflowY:'auto', scrollbarWidth: 'none',position:'relative',msOverflowStyle: 'none' }}>
          <Dashboard />
          <div style={{ overflowY: 'auto', flex: '1' }}> {/* Apply overflow styling here */}
            <Outlet />
          </div>
        </div>
      ) : (
        <div style={{ overflowY: 'auto',height:'88vh', width:'100%' }}> {/* Apply overflow styling here */}
          <Outlet />
        </div>
      )}
      <Footer />
    </div>
  );
}
