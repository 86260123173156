import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import LoadingComponent from '../components/LoadingComponent';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import Viewer from 'react-viewer';
import ImageViewer from '../utils/ImageViewer'

const containerStyle = {
  backgroundColor: 'rgb(1,50,32,1)',
  padding: '20px',
  borderRadius: '8px',
  minHeight: '100%',
};

const cardStyle = {
  backgroundColor: 'rgb(175, 238, 238,0.9)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
};

const buttonContainerStyle = {
  marginTop: '20px',
};



export default function VehicleDetails() {
  const { id } = useParams();
  const { authToken } = useContext(AuthContext);
  const [vehicle, setVehicle] = useState({});
  const navigate = useNavigate()
  const {setVehicleGuide, deleteVehicle} = useContext(VehicleGuideContext)
  const [loading, setLoading] = useState(true)
  const [visible,setVisible] = useState(false)
  const [image,setImage] = useState([])


    // ==================== IMAGES VIEWER =====================
    // const images=[image1,2,3,4 ...]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================


  useEffect(() => {
    if (id && authToken){
      setLoading(true)
    fetch(`https://beshapp.backend.safarinetics.com/vehicle/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if(!response?.error){
          setVehicle(response);
          setVehicleGuide(response)
          setLoading(false)}
          setImage([{
            src:response?.image,
            alt:'vehicle'
          }])
      })
      .catch((error) => console.error(error))
    }
    setLoading(false)
  }, [authToken, id]);

  if(loading){
    const loadingText ='Loading vehicle and guide info....'
    return(
      <LoadingComponent loadingText={loadingText} />
    )
  }

  const handleUpdate = () => {
    navigate(`/vehicles/${id}/update`);
  };

  const handleDelete = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVehicle(id)
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your vehicle and guide is safe :)",
          icon: "info"
        });
      }
    });
  
  };

  return (
    <div className="container-fluid py-4" style={containerStyle}>

      <div className="row">
         <h3 className='text-center py-4 fw-bold' style={{fontFamily:'junge',color:'wheat'}}> Vehicle and Guide details</h3>
         <motion.div
              className="col-md-6"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
          <div className="card" style={cardStyle}>
            <img  loading="lazy" 
            src={vehicle?.image}
            style={{maxHeight:'60vh'}} 
            className="card-img-top  " 
            alt="Vehicle"
            onClick={()=> openModal(currentImageIndex)} 
             />
             <ImageViewer 
                images={[vehicle?.image]}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
            {/* <Viewer
              visible={visible}
              onClose={() => setVisible(false)}
              images={image}/> */}
          </div>
        
          </motion.div>
          <motion.div
              className="col-md-6"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
          <div className="card" style={cardStyle}>
            <div className="card-body">
              <h4 className='card-header'>Vehicle Details</h4>
              <ul className="list-group list-group-flush ">
                <li className="list-group-item bg-secondary bg-opacity-25">Model: {vehicle.model}</li>
                <li className="list-group-item bg-secondary bg-opacity-25">Category: {vehicle.category} vehicle</li>
                <li className="list-group-item bg-secondary bg-opacity-25">Capacity: {vehicle.capacity}</li>
                <li className="list-group-item bg-secondary bg-opacity-25">Plates: {vehicle.plates}</li>
                <li className="list-group-item bg-secondary bg-opacity-25">Rates: {vehicle?.rates?.toLocaleString()} $</li>
                {vehicle.guide && (
                  <div>
                    <h4 className='card-header'>Guide details</h4>
                  <p className="list-group-item bg-secondary bg-opacity-25">
                    <strong>Guide:</strong> {vehicle.guide.name} |{' '}
                    <a href={`mailto:${vehicle.guide.email}`}>{vehicle.guide.email}</a> |{' '}
                    <a href={`tel:${vehicle.guide.phone}`}>{vehicle.guide.phone}</a>
                  </p>
                  </div>
                )}
              </ul>
            </div>
            <div className="card-footer text-muted d-flex justify-content-around">
              <h6>Added at: {vehicle.added_at}</h6>
              <h6>Updated at: {vehicle.updated_at}</h6>
            </div>
          </div>
          <div className="text-center mt-3 d-flex justify-content-around" style={buttonContainerStyle}>
            <button className="btn btn-primary mr-2" style={{ backgroundColor: 'forestgreen', borderColor: 'forestgreen' }} onClick={handleUpdate}>
              Update
            </button>
            <button className="btn btn-danger" onClick={handleDelete}>
              Delete
            </button>
          </div>
          </motion.div>
      </div>
    </div>
  );
}
