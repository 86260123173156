import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function AddUser({ setAddUser }) {
  const { addUser, loading, users } = useContext(UserContext);
  const [userData, setUserData] = useState({
    name: '',
    position: '',
    personalEmail: '',
    companyEmail: '',
    phone: ''
  });
  const [errors, setErrors] = useState({
    personalEmail: '',
    companyEmail: '',
    phone: ''
  });

  useEffect(() => {
    // Validate email and phone on each change
    const validateFields = () => {
      const { personalEmail, companyEmail, phone } = userData;
      const newErrors = { personalEmail: '', companyEmail: '', phone: '' };

      if (users.some(user => user?.personal_email?.toLowerCase() === personalEmail.toLowerCase())) {
        newErrors.personalEmail = 'Personal email already exists';
      }
      
      if (users.some(user => user?.email?.toLowerCase() === companyEmail.toLowerCase())) {
        newErrors.companyEmail = 'Company email already exists';
      }

      if (users.some(user => user.phone === phone)) {
        newErrors.phone = 'Phone number already exists';
      }
      if(!isValidPhone(phone)){
        newErrors.phone = 'Invalid Phone number';
      }

      setErrors(newErrors);
    };

    validateFields();
  }, [userData, users]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'position') {
      setUserData({ ...userData, [name]: value?.toUpperCase() });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^\+[\d\s()-]{11,12}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /@beshafricanadventures\.co\.ke$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { companyEmail, phone } = userData;
    
    if (!isValidEmail(companyEmail)) {
      Swal.fire({
        text: "Please enter the company email address",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    if (!isValidPhone(phone)) {
      Swal.fire({
        text: "Please enter a valid phone number",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }
    
    // Handle form submission here
    addUser(userData);

    setTimeout(() => {
      setUserData({
        name: '',
        position: '',
        personalEmail: '',
        companyEmail: '',
        phone: ''
      });
      setAddUser(false);
    }, 4000);
  };
  const handlePhoneChange = (value) => {
    setUserData({ ...userData, ['phone']: `+${value}` });
      
  }

  const { name, position, personalEmail, companyEmail, phone } = userData;
  const { personalEmail: personalEmailError, companyEmail: companyEmailError, phone: phoneError } = errors;

  return (
    <div className="container-fluid card col-md-5 my-4 p-3 shadow-lg rounded border border-info"
      style={{ backgroundColor: 'rgb(175, 238, 238,0.1' }}>
      <h4 className='card-header fw-semibold text-center' style={{ color: 'rgb(175, 238, 238,1', fontFamily: 'junge' }}>Add User </h4>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <label htmlFor="name" className="form-label text-white"><strong>Name:</strong></label>
              <input type="text" className="form-control" placeholder='John Doe' id="name" name="name" value={name} onChange={handleChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor="position" className="form-label text-white"><strong>Position:</strong></label>
              <input type="text" className="form-control" id="position" placeholder='SECRETARY' name="position" value={position} onChange={handleChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor="personalEmail" className="form-label text-white"><strong>Personal Email:</strong></label>
              <input type="email" className="form-control" id="personalEmail" placeholder='John@example.com' name="personalEmail" value={personalEmail} onChange={handleChange} required />
              {personalEmailError && <div className="text-danger">{personalEmailError}</div>}
            </div>
            <div className='mb-3'>
              <label htmlFor="companyEmail" className="form-label text-white"><strong>Company Email:</strong></label>
              <input type="email" className="form-control" id="companyEmail" placeholder='John@beshafricanadventures.co.ke' name="companyEmail" value={companyEmail} onChange={handleChange} required />
              {companyEmailError && <div className="text-danger">{companyEmailError}</div>}
            </div>
            <div className='mb-3'>
              <label htmlFor="phone" className="form-label text-white"><strong>Phone:</strong></label>
              <PhoneInput
                country={'ke'}
                value={phone}
                name='phone'
                id='phone'
                onChange={handlePhoneChange}
                dropdownStyle={{ color: '#000' }}
                dropdownClass="custom-dropdown"
              />
              {/* <input type="text" className="form-control" id="phone" placeholder='+2547.....' name="phone" value={phone} onChange={handleChange} required /> */}
              {phoneError && <div className="text-danger">{phoneError}</div>}
            </div>
            <div className="mt-3 card-footer d-flex justify-content-around">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-info " role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <button type="submit" className='btn btn-success' style={{ width: 'fit-content' }}>Add</button>
              )}
              <button
                disabled={loading}
                onClick={() => setAddUser(false)}
                className='btn btn-danger'
                style={{ width: 'fit-content' }}
              >Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
