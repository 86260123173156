import React, { useContext, useState } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import Swal from 'sweetalert2';

const AddAccommodationRates = ({setActiveTab}) => {
   const {setRates,rates} = useContext(AccommodationContext)
  const [adults, setAdults] = useState(rates?.adults || '');
  const [adultsSharing, setAdultsSharing] = useState(rates?.adultsSharing || '');
  const [children, setChildren] = useState(rates?.children || '');

  const handleAdultsChange = (e) => {
    setAdults(parseInt(e.target.value));
  };

  const handleAdultsSharingChange = (e) => {
    setAdultsSharing(parseInt(e.target.value));
  };

  const handleChildrenChange = (e) => {
    setChildren(parseInt(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(adults !== 0 && children !== 0){
      // Add logic here to submit the form data
    setRates({ adults, adultsSharing, children });
    
   Swal.fire({
    text:"Rates Saved",
    showConfirmButton:false,
    timer:4000,
    icon:'success',
    color:'white',
    background:'green',
    toast:true,
    position:'top'
   });
    setActiveTab('prices')
    }
    // Reset the form after submission
    
  };

  return (
    <div className="container-fluid mx-auto rounded mt-3 row justify-content-center">
      <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'junge' }}>Add Accommodation Rates</h2>
      <form onSubmit={handleSubmit} className='py-5 justify-content-center col-md-7 border-info bg-light bg-opacity-50 rounded row'>
        <div className='container-fluid row'>
          <div className="mb-3 col-md-12">
            <label htmlFor="adults" className="form-label">Adults <strong className='text-dark'> ($)</strong></label>
            <input type="number" min={0} className="form-control" id="adults" name="adults" value={adults} onChange={handleAdultsChange} required />
          </div>
          <div className="mb-3 col-md-12">
            <label htmlFor="adultsSharing" className="form-label">Adults Sharing <strong className='text-dark'> ($)</strong></label>
            <input type="number" min={0} className="form-control" id="adultsSharing" name="adultsSharing" value={adultsSharing} onChange={handleAdultsSharingChange} required />
          </div>
          <div className="mb-3 col-md-12">
            <label htmlFor="children" className="form-label">Children <strong className='text-dark'> ($)</strong></label>
            <input type="number" min={0} className="form-control" id="children" name="children" value={children} onChange={handleChildrenChange} required />
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddAccommodationRates;
