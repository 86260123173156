import React from 'react';

export default function PricesCalculationFormula() {
  const containerStyle = {
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '100%',
    minWidth: 'fit-content',
    margin: '20px auto',
    fontFamily: 'Arial, sans-serif',
  };

  const titleStyle = {
    fontSize: '1.8rem',
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
    fontWeight: 'bold',
  };

  const sectionTitleStyle = {
    fontSize: '1.5rem',
    color: '#007bff',
    margin: '20px 0 10px',
    borderBottom: '2px solid #007bff',
    paddingBottom: '5px',
  };

  const listStyle = {
    listStyleType: 'none',
    padding: '0',
  };

  const listItemStyle = {
    fontSize: '1rem',
    color: '#555',
    margin: '10px 0',
    lineHeight: '1.5',
  };

  const strongStyle = {
    color: '#007bff',
    fontWeight: 'bold',
  };

  const noteStyle = {
    fontSize: '0.9rem',
    color: '#888',
    marginTop: '15px',
    fontStyle: 'italic',
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Price Calculation Formulas</h2>
      <div style={noteStyle}>
        <em>Note: All calculations are per person, except for the Grand Total, which aggregates totals for all individuals.</em>
      </div>

      <div>
        <h3 style={sectionTitleStyle}>Accommodations</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Adults Accommodations:</strong> 
            <span> adultsAccommodations = Σ(all accommodations[adults] in the itinerary)</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Adult Sharing Accommodations:</strong> 
            <span> adultSharingAccommodations = Σ(all accommodations[adultsSharing] in the itinerary)</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Children Accommodations:</strong> 
            <span> childrenAccommodations = Σ(all accommodations[children] in the itinerary)</span>
          </li>
        </ul>
      </div>

      <div>
        <h3 style={sectionTitleStyle}>Destinations</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Adults Destinations:</strong> 
            <span> adultsDestinations = Σ(all destinations[adults] in the itinerary)</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Children Destinations:</strong> 
            <span> childrenDestinations = Σ(all destinations[children] in the itinerary)</span>
          </li>
        </ul>
      </div>

      <div>
        <h3 style={sectionTitleStyle}>Transport</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Vehicle Transport Total:</strong> 
            <span> vehicle transport = ((transfer × transferDays) + (safari × safariDays)) / number of people</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Flights Total:</strong> 
            <span> flights total = Σ(all flights in the itinerary)</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Transport Total:</strong> 
            <span> transport = vehicle + flight</span>
          </li>
        </ul>
      </div>

      <div>
        <h3 style={sectionTitleStyle}>Totals</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Adult Total:</strong> 
            <span> adultsTotal = adultsAccommodations + adultsDestinations + transport + (% commission)</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Adult Sharing Total:</strong> 
            <span> adultSharingTotal = adultSharingAccommodations + adultsDestinations + transport + (% commission)</span>
          </li>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Child Total:</strong> 
            <span> childrenTotal = childrenAccommodations + childrenDestinations + transport + (% commission)</span>
          </li>
        </ul>
      </div>

      <div>
        <h3 style={sectionTitleStyle}>Grand Total</h3>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong style={strongStyle}>Grand Total:</strong> 
            <span> grandTotal = ((adultsTotal × no of adults) + (adultSharingTotal × no of adultsSharing) + (childrenTotal × no of children))</span>
          </li>
        </ul>
      </div>

    </div>
  );
}
