import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import { ItineraryContext } from '../context/ItineraryContext';
import { FlightContext } from '../context/FlightContext';
import { uploadImage } from '../images/ImageUploader';

const AddSingleDay = ({mainItinerary, setAdd}) => {
   const {addSingleDay, loading, setLoading} = useContext(ItineraryContext)
   const {accommodations} = useContext(AccommodationContext)
   const {destinations} = useContext(DestinationContext)
   const {routes} = useContext(FlightContext)
   
  const [day, setDay] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [accommodationId, setAccommodationId] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [flightRouteId, setFlightRouteId] = useState('');


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file){
            setImage(file);
        };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setLoading(true); // Start loading
  
    try {
      // Check if the day already exists
      if (mainItinerary?.details.some(itinerary => itinerary.day === day)) {
        Swal.fire({
          title: 'Invalid Day',
          text: 'This day already exists in the itinerary.',
          showConfirmButton: false,
          timer: 4000,
          icon: 'warning',
          color: 'white',
          background: 'orange',
          toast: true,
          position: 'top',
        });
        setLoading(false); // Stop loading
        return;
      }
  
      // Check if the number of days is not skipped
      if (parseInt(day) > (mainItinerary?.details.length + 1)) {
        Swal.fire({
          title: 'Invalid Day',
          text: 'Days cannot be skipped in the itinerary.',
          showConfirmButton: false,
          timer: 4000,
          icon: 'warning',
          color: 'white',
          background: 'orange',
          toast: true,
          position: 'top',
        });
        setLoading(false); // Stop loading
        return;
      }
  
      const formData = new FormData();
      formData.append('day', day);
      formData.append('title', title);
      formData.append('description', description);
  
      // Upload the image
      try {
        const result = await uploadImage(image);
        formData.append('image', result.url);
        formData.append('image_id', result.id);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false); // Stop loading
        return;
      }
  
      formData.append('accommodationId', accommodationId);
      formData.append('destinationId', destinationId);
      formData.append('flightRouteId', flightRouteId);
  
      // Add new itinerary to the array
      await addSingleDay(mainItinerary?.id, formData);
  
      // Reset inputs
      setDay('');
      setImage('');
      setTitle('');
      setDescription('');
      setAccommodationId('');
      setDestinationId('');
      setFlightRouteId('');
  
      setAdd(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      setLoading(false); // Stop loading
    } finally {
      setLoading(false); // Stop loading
    }
  };
  

  

  return (
    <div className="container-fluid mx-auto rounded mt-3 row justify-content-center">
      <h2 className="text-center mb-4" style={{ color: 'blue', fontFamily: 'junge' }}>Add Daily Itinerary</h2>
      <div className="bg-white bg-opacity-50 rounded col-md-10" style={{ height: '100%' }}>
        
      </div>
      <div className="mt-4 col-md-10">
          <form onSubmit={handleSubmit} className="p-4 border-info bg-success bg-opacity-50 rounded">
            <div className='container-fluid row'>

            <div className="mb-3 col-md-4">
              <label htmlFor="day" className="form-label">Day</label>
              <input type="number" min={1} className="form-control" id="day" name="day" value={day} onChange={(e) => setDay(e.target.value)} required />
            </div>

            <div className="mb-3 col-md-8">
              <label htmlFor="image" className="form-label">Image</label>
              <input 
              type="file" 
              className="form-control" 
              id="image"
               onChange={handleImageChange}
               accept=".png, .jpg, .jpeg" 
               required />
            </div>

            </div>
            
            <div className="mb-3">
              <label htmlFor="title" className="form-label">Title</label>
              <input type="text" className="form-control" id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
            </div>
                  <div className="mb-3">
               <label htmlFor="description" className="form-label">Description</label>
               <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
            </div>
            
            <div className="mb-3">
               <label htmlFor="accommodationId" className="form-label">Accommodation</label>
               <select className="form-select" id="accommodationId" value={accommodationId} onChange={(e) => setAccommodationId(e.target.value)} >
                  <option value="" disabled>Select accommodation</option>
                  <option value="" disabled>name | type | location </option>
                  {Array.isArray(accommodations) && accommodations.length > 0 && accommodations.map(( accommodation => (
                     <option key={accommodation.id} value={accommodation.id}>
                        {accommodation?.accommodation}  
                         ({accommodation?.category} ) =
                         {accommodation?.location} 
                         </option>
                  )))}
                  <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
                     
               </select>
            </div>

            <div className="mb-3">
               <label htmlFor="destinationId" className="form-label">Destination(site)</label>
               <select className="form-select" id="destinationId" value={destinationId} onChange={(e) => setDestinationId(e.target.value)} >
                  <option value="" disabled>Select the category</option>
                  <option value="" disabled>Destination(location)</option>
                  {Array.isArray(destinations) && destinations.length > 0 &&  destinations.map((destination) => (
                  <option key={destination?.id} value={destination?.id}>
                     {destination?.name}({destination?.location})
                  </option>
                  ))}
                  <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
               </select>
            </div>

            <div className="mb-3">
               <label htmlFor="flightRouteId" className="form-label">Flight(Route)</label>
               <select className="form-select" id="flightRouteId" value={flightRouteId} onChange={(e) => setFlightRouteId(e.target.value)} >
                  <option value="" disabled>Select flight</option>
                  {Array.isArray(routes) && routes.length > 0 && routes.map((route) => (
                  <option
                    key={route.id}
                    value={route.id}
                    style={{ fontWeight: 'semibold',fontFamily:'junge' }} // Style for the name
                  >
                    {route?.origin?.location} - {route?.destination?.location}   ({route?.flight_name}) ${route?.rates}
                  </option>
                ))}
                  <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
               </select>
            </div>
            
            <div className="d-flex justify-content-around">
              <button type="submit" className="btn btn-primary">Submit</button>
              <button onClick={() => setAdd(false)} className="btn btn-danger">Cancel</button>
            </div>
          </form>
       
      </div>
    </div>
  );
};

export default AddSingleDay;
