import React, { useContext, useState, useEffect } from 'react';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import Swal from 'sweetalert2';
import Select from 'react-select';
// import { FlightContext } from '../context/FlightContext';
import { PackageContext } from '../context/PackageContext';

export default function UpdateOverview({ packageInfo}) {
  const {updateOverview} = useContext(PackageContext)
  const { vehicleGuides } = useContext(VehicleGuideContext);
  const [text, setText] = useState('');
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (packageInfo) {
      const vehicles = packageInfo?.itinerary?.overview?.vehicles;
      const selectedVehicleIds = vehicles.map(vehicle => vehicle.id);
      setSelectedVehicles(selectedVehicleIds);
      setText(packageInfo?.itinerary?.overview?.overview || '');
    }
  }, [packageInfo]);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleVehicleChange = (selectedOptions) => {
    const newSelectedIds = selectedOptions.map((option) => option.value);
    setSelectedVehicles(newSelectedIds);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file){
            setImage(file);
        };
  };

  const handleSubmit = () => {// Prevent the default form submission behavior
    
    // Check if selectedVehicles is an array and if it's empty
    if (Array.isArray(selectedVehicles) && selectedVehicles.length < 1) {
      // If selectedVehicles is empty, display a message
      Swal.fire({
        text:'add a vehicle',
        showConfirmButton:false,
        timer:4000,
        icon:'warning',
        color:'white',
        background:'orange',
        toast:true,
        position:'top'
       });
    } else {
      // If selectedVehicles is not empty, proceed with form submission
      const formData = {
        overview: text,
        vehicles: selectedVehicles,
        image: image ? image: packageInfo.itinerary.overview.image,
        mime_type: packageInfo.itinerary.overview.mime_type,
      };

      // Log the formData
      updateOverview(packageInfo?.id,formData);
      
     
    }
  };

  const vehicleOptions = Array.isArray(vehicleGuides) && vehicleGuides.length > 0 &&  vehicleGuides.map((vehicle) => ({
    value: vehicle.id,
    label: `${vehicle.capacity} seater (${vehicle.category}) Guide: ${vehicle.guide.name} Rates: ${vehicle.rates} $`,
  }));

  return (
    <div className="container mt-2">
      <div className="row justify-content-center">
        {/* <div className=""> */}
        <h2 className="text-center fw-bold mb-2" style={{ color: 'wheat', fontFamily: 'junge' }}>Overview</h2>
          <div className="card col-md-12 " style={{ backgroundColor: 'rgb(175, 238, 238,0.4)' }}>
            <div className="card-body rounded">
              <form onSubmit={(event) => {
                handleSubmit()
                event.preventDefault()
              }}>
                <div className="mb-3">
                  <label htmlFor="text" className="form-label">
                    Text:
                  </label>
                  <textarea
                    id="text"
                    className="form-control"
                    value={text}
                    autoComplete="on"
                    spellCheck={true}
                    rows="5"
                    onChange={handleTextChange}
                    required
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="vehicles" className="form-label">
                    Vehicles:
                  </label>
                  <Select
                    id="vehicles"
                    options={vehicleOptions}
                    isMulti
                    value={vehicleOptions && vehicleOptions.length > 0 && vehicleOptions.filter((option) => selectedVehicles.includes(option.value))}
                    onChange={handleVehicleChange}
                  />
                </div>
              
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <input type="file" id="image" className="form-control" accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
                </div>
                <button style={{ width: '15vw' }} type="submit" className="btn btn-success">
                  Save
                </button>
              </form>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}
