import React, { useContext, useState, useEffect } from 'react';
import { PackageContext } from '../context/PackageContext';

export default function UpdatePackageDetails({ packageInfo}) {
  const {updateIncludes_Excludes} = useContext(PackageContext)

  // State variables for includes and excludes
  const [includes, setIncludes] = useState('');
  const [excludes, setExcludes] = useState('');

  useEffect(() => {
    if (packageInfo) {
      setIncludes(packageInfo?.itinerary?.includes?.join('\n'));
      setExcludes(packageInfo.itinerary?.excludes?.join('\n'));
    }
  }, [packageInfo]);

  // Function to handle form changes
  const handleChange = (event, setter) => {
    const { value } = event.target;
    setter(value);
  };

  // Function to handle saving data
  const handleUpdate = (e) => {
    e.preventDefault();

    // Convert textarea data to arrays
    const includesArray = includes.split('\n').filter(item => item.trim() !== '');
    const excludesArray = excludes.split('\n').filter(item => item.trim() !== '');

   const formData = {
    includes: includesArray,
    excludes: excludesArray
   }
   updateIncludes_Excludes(packageInfo?.id,formData)
  //  setUpdatePackage(false)
// console(formData)
  };

  return (
    <div className="container mt-1 mb-5">
      <div className="row justify-content-center">
        <h2 className='m-2 text-center fw-semibold ' style={{ color:'wheat',fontFamily:'junge'}}>Update Package Details</h2>
          <div className="card col-md-12 border border-success shadow-lg" style={{ backgroundColor: 'rgb(175, 238, 238,0.4)' }}>
            <div className='container-fluid row  my-4'>
              <form onSubmit={handleUpdate} >
               <details className="p-3 rounded " style={{backgroundColor: 'rgb(8, 50, 32,1)'}}>
                 <summary className="form-label text-white ps-3 fs-6 fw-semibold">Includes:</summary>
                  <textarea
                    className="form-control "
                    name="includes"
                    value={includes}
                    onChange={(e) => handleChange(e, setIncludes)}
                    autoComplete={'on'}
                    spellCheck={true}
                    rows='10'
                  ></textarea>
                </details>

                <details className="p-3 my-4 rounded " style={{backgroundColor: 'rgb(8, 50, 32,1)'}}>
                 <summary className="form-label text-white ps-3 fs-6 fw-semibold">Excludes:</summary>
                  <textarea
                    className="form-control"
                    name="excludes"
                    value={excludes}
                    onChange={(e) => handleChange(e, setExcludes)}
                    autoComplete={'on'}
                    spellCheck={true}
                    rows='5'
                  ></textarea>
                </details>
                <div className='text-center'>
                  <button style={{ width: '20vw' }} type="submit" className="btn btn-success" >Update</button>
                </div>
              </form>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}
