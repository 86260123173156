import React, { useContext, useState } from 'react';
import { FlightContext } from '../context/FlightContext';
import { uploadImage } from '../images/ImageUploader';

const AddFlights = () => {
   const { addFlight, flights } = useContext(FlightContext);
   const [formData, setFormData] = useState({
      name: '',
      notes: '',
      image: null
   });
   const [nameError, setNameError] = useState('');

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         setFormData((prevState) => ({
            ...prevState,
            image: file,
         }));
      }
   };

   const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'name') {
         // Real-time validation for name
         const nameExists = flights.some(flight => flight.name.toLowerCase() === value.toLowerCase());
         if (nameExists) {
            setNameError('Flight name already exists');
         } else {
            setNameError('');
         }
      }

      setFormData((prevState) => ({
         ...prevState,
         [name]: value
      }));
   };

   async function handleSubmit(e) {
      e.preventDefault();

      if (nameError) {
         return; // Prevent submission if there's an error
      }

      const newFormData = new FormData();
      newFormData.append('name', formData.name);
      newFormData.append('notes', formData.notes);

      try {
         const result = await uploadImage(formData.image); // Ensure file is defined in the scope
         newFormData.append('image', result.url); // Append the uploaded image URL
         newFormData.append('image_id', result.id); // Append the uploaded image id
      } catch (error) {
         console.error("Error uploading image:", error);
         return; // Exit the function if there's an error
      }

      addFlight(newFormData); // Call to addFlight after successful upload
   }

   return (
      <div className="container justify-content-center mt-5">
         <div className="col-md-8 mx-auto">
            <div className="card p-4" style={{ backgroundColor: 'rgb(1,50,32,1)', borderRadius: '10px' }}>
               <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'inknut antiqua' }}>Add Flight</h2>
               <form onSubmit={handleSubmit} className='text-white'>
                  <div className='container-fluid row'>
                     <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name:</label>
                        <input
                           type="text"
                           className={`form-control ${nameError ? 'is-invalid' : ''}`}
                           id="name"
                           name="name"
                           value={formData.name}
                           onChange={handleChange}
                           required
                        />
                        {nameError && <div className="invalid-feedback">{nameError}</div>}
                     </div>
                  </div>

                  <div className='container-fluid row mb-4'>
                     <div className="mb-3">
                        <label htmlFor="notes" className="form-label">Notes:</label>
                        <textarea
                           className="form-control"
                           id="notes"
                           name="notes"
                           value={formData.notes}
                           onChange={handleChange}
                           required
                           rows={3}
                           placeholder="Enter notes here..."
                           spellCheck
                           autoFocus
                           style={{ resize: "vertical" }}
                           aria-label="Enter notes"
                        />
                     </div>

                     <div className="mb-3">
                        <label htmlFor="image" className="form-label">Image:</label>
                        <input
                           type="file"
                           className="form-control"
                           id="image"
                           name="image"
                           accept=".png, .jpg, .jpeg"
                           onChange={handleImageChange}
                           required
                        />
                     </div>
                  </div>

                  <button type="submit" className="btn btn-primary">Submit</button>
               </form>
            </div>
         </div>
      </div>
   );
};

export default AddFlights;
