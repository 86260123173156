import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { DestinationContext } from '../context/DestinationContext';
import { uploadImage } from '../images/ImageUploader';

export default function UpdatePictures({ destination, setUpdatePictures }) {
  const { updatePicture, deletePicture } = useContext(DestinationContext);
  const [updatedPictures, setUpdatedPictures] = useState(destination?.pictures.map(picture => ({ 
    newImage: picture.image, 
    newTitle: picture.title, 
    error: '' 
  })));

  const handleImageChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setUpdatedPictures(prevState => {
        const updated = [...prevState];
        updated[index].newImage = file;
        return updated;
      });
    }
  };

  const handleCaptionChange = (event, index) => {
    const newTitle = event.target.value;
    setUpdatedPictures(prevState => {
      const updated = [...prevState];
      updated[index].newTitle = newTitle;

      // Check if the new title already exists in destination.pictures except for itself
      const isDuplicate = destination.pictures.some((picture, i) =>
        i !== index && picture.title.toLowerCase() === newTitle.toLowerCase()
      );

      if (isDuplicate) {
        updated[index].error = 'This title already exists.';
      } else {
        updated[index].error = '';
      }

      return updated;
    });
  };

  const handleUpdate = async (event, id, index) => {
    event.preventDefault();
    if (updatedPictures[index].error) {
      return;
    }

    try {
      const result = await uploadImage(updatedPictures[index].newImage);
      const formData = { image: result.url, image_id: result.id, title: updatedPictures[index].newTitle };
      updatePicture(id, formData);
      setUpdatePictures(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  function handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deletePicture(id);
        setUpdatePictures(false);
      }
    });
  }

  return (
    <div className="container-fluid col-md-5 pt-2">
      <h3 className="text-center mb-4" style={{ fontFamily: 'inknut antiqua', color: 'forestgreen', position: 'relative' }}>
        Update Pictures
        <button className="btn btn-danger text-white" onClick={() => setUpdatePictures(false)} style={{ position: 'absolute', top: '50%', right: '10%', transform: 'translateY(-50%)', cursor: 'pointer' }}>×</button>
      </h3>

      <div className="row row-cols-1 g-4">
        {destination?.pictures.map((picture, index) => (
          <div key={index} className="col">
            <details className='rounded px-2 text-light' style={{ backgroundColor: 'rgb(8, 50, 32,1)' }}>
              <summary className='d-flex justify-content-between align-items-center'>
                <p className="mt-1 text-center text-info">{picture.title}</p>
                <button onClick={() => handleDelete(picture.id)} className='btn btn-sm btn-danger align-self-end mb-1'>Delete</button>
              </summary>
              <form onSubmit={(event) => handleUpdate(event, picture.id, index)}>
                <div className="mb-3">
                  <label htmlFor={`newImage-${index}`} className="form-label">New Image</label>
                  <input
                    type="file"
                    className="form-control"
                    id={`newImage-${index}`}
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => handleImageChange(event, index)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor={`newTitle-${index}`} className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id={`newTitle-${index}`}
                    value={updatedPictures[index].newTitle}
                    onChange={(event) => handleCaptionChange(event, index)}
                  />
                  {updatedPictures[index].error && (
                    <small className="text-danger">{updatedPictures[index].error}</small>
                  )}
                </div>
                <button type="submit" className="btn btn-primary" disabled={!!updatedPictures[index].error}>Update</button>
              </form>
            </details>
          </div>
        ))}
      </div>
    </div>
  );
}
