import React, { useContext } from 'react';
import { FlightContext } from '../context/FlightContext';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

export default function AddFlightRoute({ routes }) {
  const { flightDestinations, addFlightRoute } = useContext(FlightContext);
  const { flight_id } = useParams();

  function handleAddRoute() {
    const originOptions = flightDestinations.map((destination) => (
      `<option value="${destination.id}">${destination.location}, ${destination.country}</option>`
    )).join('');

    Swal.fire({
      title: "Add Route",
      html: `
        <div style="margin-bottom: 15px; text-align: left;">
          <label for="origin" style="color: wheat; font-size: 1.1em; margin-bottom: 5px; display: block;">Origin</label>
          <select id="origin" class="swal2-input" style="width: 100%; padding: 0; border-radius: 5px; border: 1px solid #ccc; background: #f9f9f9;">
            <option value="" disabled selected>Select the origin location</option>
            ${originOptions}
          </select>
        </div>
        <div style="margin-bottom: 15px; text-align: left;">
          <label for="destination" style="color: wheat; font-size: 1.1em; margin-bottom: 5px; display: block;">Destination</label>
          <select id="destination" class="swal2-input" style="width: 100%; padding: 0; border-radius: 5px; border: 1px solid #ccc; background: #f9f9f9;">
            <option value="" disabled selected>Select the destination location</option>
          </select>
        </div>
        <div style="margin-bottom: 15px; text-align: left;">
          <label for="rate" style="color: wheat; font-size: 1.1em; margin-bottom: 5px; display: block;">Rate</label>
          <input id="rate" type="number" class="swal2-input" placeholder="Enter the rate" style="width: 60%; color: black; padding: 0; border-radius: 5px; border: 1px solid #ccc; background: #f9f9f9;">
        </div>
      `,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      background: 'rgb(8, 50, 32,1)',
      toast: true,
      color: 'wheat',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      scrollbarPadding: false,
      didOpen: () => {
        const originSelect = document.getElementById('origin');
        const destinationSelect = document.getElementById('destination');

        function updateDestinationOptions() {
          const selectedOrigin = originSelect.value;
          const filteredDestinations = flightDestinations
            .filter(destination => {
              // Exclude destinations where the selected origin is the same as the origin in any route
              const isDestinationInUse = routes.some(route => route.origin.id === parseInt(selectedOrigin) && route.destination.id === destination.id);
              return destination.id !== parseInt(selectedOrigin) && !isDestinationInUse;
            });

          const destinationOptions = filteredDestinations.map((destination) => (
            `<option value="${destination.id}">${destination.location}, ${destination.country}</option>`
          )).join('');

          destinationSelect.innerHTML = `<option value="" disabled selected>Select the destination location</option>${destinationOptions}`;
        }

        // Initialize destination options
        updateDestinationOptions();

        // Update destination options when origin changes
        originSelect.addEventListener('change', updateDestinationOptions);
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const origin = document.getElementById('origin').value;
        const destination = document.getElementById('destination').value;
        const rate = document.getElementById('rate').value;

        if (!origin || !destination || !rate) {
          Swal.fire({
            title: 'Warning!',
            text: 'All fields are required!',
            icon: 'warning',
            confirmButtonText: 'OK',
            background: 'orange',
            color: 'rgb(8, 50, 32,0.8)',
            confirmButtonColor: '#3085d6'
          });
        } else if (origin === destination) {
          Swal.fire({
            title: 'Warning!',
            text: 'Origin and destination cannot be the same!',
            icon: 'warning',
            confirmButtonText: 'OK',
            background: 'orange',
            color: 'rgb(8, 50, 32,0.8)',
            confirmButtonColor: '#3085d6'
          });
        } else {
          // Check if the route already exists
          const routeExists = routes.some(route => route?.origin?.id == origin && route?.destination?.id == destination);

          if (routeExists) {
            Swal.fire({
              title: 'Error!',
              text: 'This route already exists!',
              icon: 'warning',
              confirmButtonText: 'OK',
              background: 'red',
              color: 'wheat',
              confirmButtonColor: '#3085d6',
            });
          } else {
            if (flight_id) {
              addFlightRoute(flight_id, origin, destination, rate);
            }
          }
        }
      }
    });
  }

  return (
    <button
      style={{ right: '10%' }}
      onClick={handleAddRoute}
      className='btn btn-outline-primary btn-sm'
    >
      Add Route
    </button>
  );
}
