import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { InquiryContext } from '../context/InquiryContext';
import { ItineraryContext } from '../context/ItineraryContext';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { TextField, MenuItem, Button, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';

const UpdateInquiry = () => {
  const { updateInquiry } = useContext(InquiryContext);
  const { authToken } = useContext(AuthContext);
  const { availableItineraries } = useContext(ItineraryContext);
  const { id } = useParams();
  const [inquiry, setInquiry] = useState({});
  const [formData, setFormData] = useState({
    tour: '',
    adults: '',
    adults_sharing: '',
    children: '',
    start_date: '',
    end_date: '',
    budget: '',
    currency: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (authToken && id) {
      fetch(`https://beshapp.backend.safarinetics.com/inquiries/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          setInquiry(response);
          setFormData({
            tour: response.tour || '',
            adults: response.adults || '',
            adults_sharing: response.adults_sharing || '',
            children: response.children || '',
            start_date: formatDate(response.start_date) || '',
            end_date: formatDate(response.end_date) || '',
            budget: response.budget || '',
            currency: response.currency || '',
          });
        })
        .catch((error) => console.error(error))
    }
  }, [authToken, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm({ ...formData, [name]: value });
  };

  const validateForm = (data) => {
    let tempErrors = {};
    
    // Validate adults and children
    if (data.adults < 1 && data.adults_sharing === 0 ) {
      tempErrors.adults = "At least one adult must be specified.";
    } else if (data.adults === '0' && data.adults_sharing === '0') {
      tempErrors.adults = "At least one adult must be specified.";
    }

    if (data.adults_sharing === '1') {
      tempErrors.adults_sharing = "Adults sharing must be at least 2.";
    }

    // Validate dates
    const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    
    if (data.start_date === '' || data.end_date === '') {
      tempErrors.date = "Please select start and end dates.";
    } else if (new Date(data.start_date) < new Date(today)) {
      tempErrors.start_date = "Start date cannot be in the past.";
    } else if (new Date(data.start_date) >= new Date(data.end_date)) {
      tempErrors.date = "End date should be after start date.";
    }

    setErrors(tempErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Final validation check
    if (Object.keys(errors).length === 0) {
      updateInquiry(id, formData);
    } else {
      Swal.fire({
        icon: 'warning',
        toast: true,
        text: "Please correct the errors in the form.",
        timer: 4000,
        showConfirmButton: false,
        color: 'white',
        background: 'orange',
        position: 'top',
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return `${year}-${month}-${day}`;
  };

  return (
    <div style={{ padding: '20px', backgroundColor: 'rgb(1,50,32,1)',height:'100%' }}>
      <div style={{ maxWidth: '800px', margin: '0 auto' }}>
        <div style={{ padding: '20px', backgroundColor: 'rgb(175, 238, 238,0.9)', borderRadius: '10px', color: 'green' }}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'inknut antiqua' }}>Update Inquiry</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '20px' }}>Client: {inquiry && inquiry.client?.name}</div>

            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Travel package</InputLabel>
              <Select
                id="tour"
                name="tour"
                value={formData.tour}
                onChange={handleChange}
                label="Travel package"
                error={!!errors.tour}
              >
                <MenuItem value="" disabled>Select a package</MenuItem>
                <MenuItem value="">Other (Customize my travel)</MenuItem>
                {Array.isArray(availableItineraries) && availableItineraries.length > 0 &&
                  availableItineraries.map((itinerary, index) => (
                    <MenuItem key={index} value={itinerary?.title}>
                      {itinerary?.title} ({itinerary?.category})
                    </MenuItem>
                  ))}
              </Select>
              {errors.tour && <FormHelperText error>{errors.tour}</FormHelperText>}
            </FormControl>

            <div style={{ display: 'flex', gap: '20px' }}>
              <TextField
                type="number"
                label="Adults"
                id="adults"
                name="adults"
                value={formData.adults}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ inputProps: { min: 0 } }}
                error={!!errors.adults}
                helperText={errors.adults}
                required
              />
              <TextField
                type="number"
                required
                label="Adults Sharing"
                id="adults_sharing"
                name="adults_sharing"
                value={formData.adults_sharing}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ inputProps: { min: 0 } }}
                error={!!errors.adults_sharing}
                helperText={errors.adults_sharing}
              />
              <TextField
                type="number"
                label="Children"
                id="children"
                name="children"
                value={formData.children}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                InputProps={{ inputProps: { min: 0 } }}
              />
            </div>

            <div style={{ display: 'flex', gap: '20px' }}>
              <TextField
                type="date"
                label="Start Date"
                id="start_date"
                name="start_date"
                value={formData.start_date}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={!!errors.start_date}
                helperText={errors.start_date}
                required
              />
              <TextField
                type="date"
                label="End Date"
                id="end_date"
                name="end_date"
                value={formData.end_date}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={!!errors.date}
                helperText={errors.date}
                required
              />
            </div>

            <div style={{ display: 'flex', gap: '20px' }}>
              <TextField
                type="number"
                label="Budget"
                id="budget"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                InputProps={{ inputProps: { min: 5 } }}
              />
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Currency</InputLabel>
                <Select
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                  label="Currency"
                  required
                  error={!!errors.currency}
                >
                  <MenuItem value="" disabled>Select Currency</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
                {errors.currency && <FormHelperText error>{errors.currency}</FormHelperText>}
              </FormControl>
            </div>

            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px', width:'100%' }}>
              Update
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateInquiry;
