import React, { useContext, useState } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import { uploadImage } from '../images/ImageUploader';
import LoadingComponent from '../components/LoadingComponent';

export default function AddSingleAmenity({ accommodation, setAddAmenity }) {
  const { addSingleAmenity } = useContext(AccommodationContext);
  const [image, setImage] = useState(null);
  const [amenity, setAmenity] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)

  // Function to validate if the amenity already exists
  const validateAmenity = (newAmenity) => {
    return accommodation?.amenities.some(existingAmenity => 
      existingAmenity?.amenity?.toLowerCase() === newAmenity.toLowerCase()
    )
      ? 'This amenity already exists.'
      : '';
  };

  // Handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Handle amenity change with validation
  const handleAmenityChange = (event) => {
    const newAmenity = event.target.value;
    setAmenity(newAmenity);
    setError(validateAmenity(newAmenity));
  };

  // Handle add amenity
  async function handleAdd(e) {
    e.preventDefault();
    setLoading(true);
  
    // Validate the amenity before proceeding
    const amenityError = validateAmenity(amenity);
    if (amenityError) {
      setError(amenityError);
      setLoading(false);
      return;
    }
  
    const formData = new FormData();
    formData.append('amenity', amenity);
  
    try {
      const result = await uploadImage(image);
      formData.append('image', result.url);
      formData.append('image_id', result.id);
  
      // Call addSingleAmenity only after successfully uploading the image
      await addSingleAmenity(accommodation?.id, formData);
  
      // Resetting state after successful addition
      setImage(null);
      setAmenity('');
      setError('');
    } catch (error) {
      console.error("Error uploading image or adding amenity:", error);
      // Optionally show an alert or message to the user here
    } finally {
      setAddAmenity(false)
      setLoading(false); // Ensure loading state is reset in case of an error
    }
  }
  if(loading){
    return(<LoadingComponent loadingText={'Adding amenity ....'}/>)
  }

  return (
    <div className="row row-cols-1 g-4">
      <form
        onSubmit={(event) => handleAdd(event)}
        style={{ backgroundColor: 'rgb(175, 238, 238,0.8)' }}
        className='container border-info rounded col-md-11 mt-3 p-3'
      >
        <h4 className='pb-2'>Add New Amenity</h4>
        <div className="mb-3">
          <label htmlFor={`newImage`} className="form-label">New Image</label>
          <input
            type="file"
            className="form-control"
            id={`newImage`}
            accept=".png, .jpg, .jpeg"
            onChange={(event) => handleImageChange(event)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor={`newAmenity`} className="form-label">New Amenity</label>
          <input
            type="text"
            className="form-control"
            id={`newAmenity`}
            value={amenity}
            onChange={(event) => handleAmenityChange(event)}
            required
          />
          {error && <div className="text-danger">{error}</div>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={!!error}>
          Add
        </button>
        <button onClick={() => setAddAmenity(false)} className="btn btn-danger ms-5">
          Cancel
        </button>
      </form>
    </div>
  );
}
