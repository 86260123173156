import React, { useState, useEffect, useContext } from 'react'
import AddFlightDestination from './AddFlightDestination';
import AddFlightRoute from './AddFlightRoute';
import { useParams } from 'react-router-dom';
import { FlightContext } from '../context/FlightContext';
import Swal from 'sweetalert2';
import LoadingComponent from '../components/LoadingComponent';

export default function FlightRoutes() {
   const [routes, setRoutes] = useState([])
   const {flight_id} = useParams()
   const {onChange, deleteRoute} = useContext(FlightContext)
   const [loading,setLoading] = useState(false)
   // console(routes)

   useEffect(() => {
      if (flight_id) {
        setLoading(true)
        fetch(`https://beshapp.backend.safarinetics.com/flights/${flight_id}/routes`)
          .then(res => res.json())
          .then(response => {
            // console(response);
            if(!response.error){
            setRoutes(response);
            }
            setLoading(false)
          })
          .catch(error => {
            console.error(error);
          });
      }
    }, [flight_id,onChange]);

    function handleDelete(id){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        toast:true,
      }).then((result) => {
        if (result.isConfirmed) {
         deleteRoute(id)
        }
      });
    }

    if (loading){
      const loadingText = 'loading routes ....'
      return(
        <LoadingComponent loadingText={loadingText} />
      )
    }

   
  return (
   <div className='col-md-6'>
   <h4 style={{fontFamily:'junge'}} className="card-title text-success text-center">Available Routes</h4>
   <div className="card shadow border-info shadow " style={{ width: 'fit-content',minWidth:'80%', margin: '10px auto', padding: '10px 0 0 0', backgroundColor:'rgb(1,50,32)' }}>
      <div className="card-body">
         <table className="table table-info table-striped rounded table-hover " >
            <thead className='text-center fw-semibold px-4 table-dark'>
               <tr className='text-info bg-dark' style={{backgroundColor:'red'}}>
               <td> Routes</td>
               <td> Rates</td>
               <td></td>
               </tr>
            </thead>
            <tbody>
            {Array.isArray(routes) && routes.length > 0 && routes.map((route, index) => (
              <tr key={index}>
                <td>{index + 1}. {route?.origin?.location} <span className='text-dark px-3 fw-semibold'>-</span> {route?.destination?.location}</td>
                <td>{route?.rates?.toLocaleString()} $</td>
                <td>
                  <button
                    className=""
                    onClick={() => handleDelete(route?.id)}
                    style={{ color: 'red', fontWeight: 'bold', background: 'rgb(175, 238, 238,0.9)', border: 'none' }}
                  >
                    X
                  </button>
                </td>
              </tr>
            ))}
            {routes.length === 0 && (
              <tr>
                <td colSpan="3">
                  <div className="alert alert-warning" role="alert">
                    No routes available.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
         </table>
      </div>
      <div className='text-center card-footer d-flex flex-row flex-wrap justify-content-around'>
      <AddFlightRoute routes={routes}/>
      <AddFlightDestination/> 
       </div>
       
   </div>
   {/* {flightDetails?.routes?.map(route => (
     <div key={route.id} className="card mb-3" style={{ backgroundColor: 'rgb(240, 240, 240)', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}>
       <div className="card-body">
         <p className="card-text"><strong>Origin:</strong> {route.origin.location}, {route.origin.country}</p>
         <p className="card-text"><strong>Destination:</strong> {route.destination.location}, {route.destination.country}</p>
         <p className="card-text"><strong>Rates:</strong> {route.rates}</p>
         <p className="card-text"><strong>Added At:</strong> {route.added_at}</p>
         <p className="card-text"><strong>Updated At:</strong> {route.updated_at}</p>
       </div>
     </div>
   ))} */}
   </div>
  )
}
