import React, { useContext } from 'react';
import { FaEdit } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';

export default function MainItineraryBudget({ mainItinerary,setUpdateBudget }) {
  const {loggedIn} = useContext(AuthContext)
  return (
    <div className='mt-3' style={{ width: '100%', height: 'fit-content' }}>
       <div 
       className='d-flex align-items-center justify-content-center flex-wrap' 
       style={{ paddingLeft: '4vw', paddingRight: '4vw', fontSize: '2rem', fontFamily: 'junge', color: 'wheat' }}>
        <h3  className="text-center px-4 py-3 fw-medium" >The Budget</h3>
        {loggedIn && (
          <FaEdit 
          onClick={()=> setUpdateBudget(true)}
          style={{ cursor: 'pointer', fontSize: '1.0rem',color:'gold' }} />
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4" style={{ backgroundColor: 'rgb(175, 238, 238,0.97)', height: '100%' }}>
            <div className="card-body">
              <h5 className="card-header">Budget Includes</h5>
              <ul className="list-group list-group-flush rounded" >
                {mainItinerary?.includes.map((include, index) => (
                  <li key={index}
                    className="list-group-item"
                    style={{ backgroundColor: 'rgb(1,50,32,1)', color: 'rgb(175, 238, 238,1)' }}
                  >
                    {include}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4" style={{ backgroundColor: 'rgb(175, 238, 238,0.97)', height: '100%' }}>
            <div className="card-body">
              <h5 className="card-title">Budget Excludes</h5>
              <ul className="list-group list-group-flush rounded">
                {mainItinerary?.excludes.map((exclude, index) => (
                  <li key={index}
                    className="list-group-item"
                    style={{ backgroundColor: 'rgb(1,50,32,1)', color: 'rgb(175, 238, 238,1)' }}
                  >{exclude}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
