import React from 'react';
import VehiclesDets from './VehiclesDets';

export default function TransportationDetails({packageInfo}) {
  return (
    <div className="container-fluid p-4">
    <h2 className="mb-3 text-center">Transportation Details</h2>
      <div className="card mb-3 pt-3 px-3 pb-4 shadow-sm container" style={{color: 'rgb(1, 50, 32,1)', backgroundColor: 'rgb(175, 238, 238,0.1)'}}>
        
        <VehiclesDets packageInfo={packageInfo} />
      </div>
    </div>
  );
}
