import React, { useState, useContext, useEffect } from 'react';
import { ItineraryContext } from '../context/ItineraryContext';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import AddDayFlight from './AddDayFlight';
import { useParams } from 'react-router-dom';

export default function AddItineraryDay({ inquiry, setDetails, setAddEntry, mainItinerary }) {
  const { newItinerary, setNewItinerary } = useContext(ItineraryContext);
  const { accommodations } = useContext(AccommodationContext);
  const { destinations } = useContext(DestinationContext);
  const { days } = useParams();
  const [detail, setDetail] = useState({ day: '', date: '', title: '', description: '', accommodation: '', destination: '', flight_route: '', image: '', image_id: '' });
  const [errors, setErrors] = useState({ day: '', date: '', title: '' });

  // Update date field based on day and start date
  useEffect(() => {
    if (detail.day && inquiry?.start_date) {
      const startDate = new Date(inquiry.start_date);
      const calculatedDate = new Date(startDate);
      calculatedDate.setDate(startDate.getDate() + parseInt(detail.day));
      setDetail(prevDetail => ({
        ...prevDetail,
        date: calculatedDate.toISOString().split('T')[0] // Format as yyyy-mm-dd
      }));
    }
  }, [detail.day, inquiry?.start_date]);

  // Function to validate the day field
  const validateDay = (day) => {
    if (newItinerary.some(data => data.day === day)) {
      return 'This day already exists.';
    } else if (day > newItinerary.length + 1 || day <= 0) {
      return 'Cannot skip a day.';
    } else if (day > inquiry?.days) {
      return 'Cannot add an extra day.';
    }
    return '';
  };

  // Function to validate the date field
  const validateDate = (date) => {
    const selectedDate = new Date(date);
    const startDate = new Date(inquiry.start_date);
    const endDate = new Date(inquiry.end_date);
    endDate.setDate(endDate.getDate() + 1);

    if (selectedDate < startDate || selectedDate >= endDate) {
      return 'Please select a date within the specified dates.';
    } else if (newItinerary.some(data => new Date(data.date).toDateString() === selectedDate.toDateString())) {
      return 'This date is already in the itinerary.';
    }
    return '';
  };

  // Function to validate the title field
  const validateTitle = (title) => {
    if (!title) {
      return 'Title is required.';
    }

    // Case-insensitive check for existing titles
    const normalizedTitle = title.trim().toLowerCase();
    if (newItinerary.some(data => data.title.trim().toLowerCase() === normalizedTitle)) {
      return 'Title is already used.';
    }
    return '';
  };

  // Handle input change with validation
  const handleInputChange = (field, value) => {
    setDetail({ ...detail, [field]: value });

    let error = '';
    if (field === 'day') error = validateDay(value);
    if (field === 'date') error = validateDate(value);
    if (field === 'title') error = validateTitle(value);

    setErrors({ ...errors, [field]: error });
  };

  // Handle form submission
  const handleAdd = (e) => {
    e.preventDefault();

    const dayError = validateDay(detail.day);
    const dateError = validateDate(detail.date);
    const titleError = validateTitle(detail.title);

    setErrors({ day: dayError, date: dateError, title: titleError });

    if (!dayError && !dateError && !titleError) {
      if (newItinerary.length <= parseInt(days)) {
        setNewItinerary(prevItinerary => [...prevItinerary, { ...detail }]);
        setDetails(prevItinerary => [...prevItinerary, { ...detail }]);
        mainItinerary?.details.push(detail);

        setDetail({ day: '', date: '', title: '', description: '', destination: '', accommodation: '', flight_route: '', image: '', image_id: '' });
        setAddEntry(false);
      } else {
        setErrors({ ...errors, day: "Cannot add an extra day's itinerary" });
      }
    }
  };

  // Handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDetail({ ...detail, image: file });
    }
  };

  return (
    <div>
      <form onSubmit={handleAdd} style={{ backgroundColor: 'rgba(192, 216, 181, 1.0)' }} className='mt-4 card border border-success rounded p-3'>
        <h4 className='text-center mb-2 text-success fw-semibold'>Add Day Details</h4>
        <div className="container-fluid row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Day:</label>
            <input
              type="number"
              min={1}
              max={inquiry?.days}
              className="form-control border-success"
              value={detail.day}
              onChange={(e) => handleInputChange('day', e.target.value)}
              required
              title={errors.day || 'Enter the day number'}
            />
            {errors.day && <div className="text-danger mt-1">{errors.day}</div>}
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Date:</label>
            <input
              type="date"
              className="form-control border-success"
              value={detail.date}
              onChange={(e) => handleInputChange('date', e.target.value)}
              required
              disabled
              title={errors.date || 'Select the date'}
            />
            {errors.date && <div className="text-danger mt-1">{errors.date}</div>}
          </div>
        </div>
        <div className="col-auto mb-3">
          <label className="form-label">Title:</label>
          <input
            type="text"
            className="form-control border-success"
            value={detail.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            required
            title={errors.title || 'Enter the title'}
          />
          {errors.title && <div className="text-danger mt-1">{errors.title}</div>}
        </div>

        <div className="col-auto mb-3">
          <label className="form-label">Description:</label>
          <textarea
            className="form-control border-success"
            value={detail.description}
            onChange={(e) => setDetail({ ...detail, description: e.target.value })}
            required
            title='Enter the description'
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Accommodation:</label>
          <select
            className="form-select border-success"
            value={detail.accommodation}
            onChange={(e) => setDetail({ ...detail, accommodation: e.target.value })}
            required
            title='Select accommodation'
          >
            <option value="" disabled>Select Accommodation</option>
            <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
            {Array.isArray(accommodations) && accommodations.length > 0 && accommodations.map((accommodation) => (
              <option
                key={accommodation.id}
                value={accommodation.accommodation}
                style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
              >
                {accommodation.accommodation} ({accommodation.category}) in " {accommodation.location} "
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Destination(site):</label>
          <select
            className="form-select border-success"
            value={detail.destination}
            onChange={(e) => setDetail({ ...detail, destination: e.target.value })}
            required
            title='Select destination'
          >
            <option value="" disabled>Select destination</option>
            <option style={{ backgroundColor: 'lightblue' }} value='None'>None</option>
            {Array.isArray(destinations) && destinations.length > 0 && destinations.map((destination) => (
              <option
                key={destination.id}
                value={destination.name}
                style={{ fontWeight: 'semibold', fontFamily: 'junge' }}
              >
                {destination.name} ({destination.location})
              </option>
            ))}
          </select>
        </div>

        <AddDayFlight detail={detail} setDetail={setDetail} />

        <div className="col-md-11 mb-3">
          <label htmlFor="image" className="form-label">Image:</label>
          <input
            type="file"
            id="image"
            className="form-control border-success"
            accept=".png, .jpg, .jpeg"
            onChange={handleImageChange}
            required
            title='Select an image (png, jpg, jpeg)'
          />
        </div>
        <div className='card-footer d-flex flex-wrap justify-content-around pt-3'>
          <button
            style={{ width: '15vh' }}
            type="submit"
            className="btn btn-success"
            disabled={errors.day || errors.date || errors.title}
            title={errors.day || errors.date || errors.title ? 'Please fix errors before submitting' : 'Add the itinerary day'}
          >
            Add
          </button>
          <button
            style={{ width: '15vh' }}
            type="button"
            className="btn btn-danger"
            onClick={() => setAddEntry(false)}
            title='Cancel the addition'
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
