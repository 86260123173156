import React, { useContext } from 'react'
import { FlightContext} from '../context/FlightContext';

export default function DayFlight({handleFormChange,formData, detail, details}) {
   const {routes} = useContext(FlightContext)
  //  const [routes,setRoutes] = useState([])
   
   
  return (
   
        
         <div className=" mb-3 ">
            <label htmlFor={`flight_route-${detail.day}`} className="form-label">Flight (Route):</label>
            <select
            id={`flight_route-${detail.day}`}
            className="form-control border-success"
            value={details.find(detailItem => detailItem.day === detail.day)?.flight_route || formData[detail.day]?.flight_route}
            onChange={(e) => handleFormChange(detail.day, 'flight_route', e.target.value)}
            
            >
           {/* <option value="" disabled>Select Route</option> */}
            <option style={{backgroundColor:'lightblue'}} value='None'>None</option>
           {Array.isArray(routes) && routes.length > 0 && routes.map((route) => (
             <option
               key={route.id}
               value={route.id}
               style={{ fontWeight: 'semibold',fontFamily:'junge' }} // Style for the name
             >
               {route?.origin?.location} - {route?.destination?.location}   ({route?.flight_name}) ${route?.rates}
             </option>
           ))}

         </select>
       </div>

  )
}
