import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaCalendarAlt, FaHotel, FaCar, FaMapMarkerAlt, FaBox, FaCog, FaSignOutAlt, FaPlane, FaReceipt,FaQuestionCircle } from 'react-icons/fa';
import {AiOutlineMail} from 'react-icons/ai'
import { AuthContext } from '../context/AuthContext';
import defaultProfile from '../images/defaultProfile.png';
import Viewer from 'react-viewer';
import ImageViewer from '../utils/ImageViewer';

export default function Dashboard() {
  const [isDashboardCollapsed, setIsDashboardCollapsed] = useState(true);
  const { logout, currentUser } = useContext(AuthContext);
  const [visible, setVisible] = useState(false)
  const [image,setImage] = useState([{src:defaultProfile, alt:currentUser?.name}])

    // ==================== IMAGES VIEWER =====================
    const images=[currentUser?.pic ? currentUser?.pic : defaultProfile]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================

  useEffect(() => {
    if(currentUser?.pic){
      setImage([{
        src:currentUser?.pic,
        alt:currentUser?.name
      }])
    }
  }, [currentUser])
  

  const toggleDashboard = () => {
    setIsDashboardCollapsed(!isDashboardCollapsed);
  };
  

  return (
    <div
      className={`col-auto ${isDashboardCollapsed ? 'collapsed' : ''}`}
      style={{
        transition: 'width 0.5s',
        width: isDashboardCollapsed ? '3.5rem' : '10rem',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'lightgrey',
        // minHeight:'fit-content',
        height:'88vh',overflowY:'scroll', scrollbarWidth: 'none',position:'relative',msOverflowStyle: 'none',
      }}
    >
      <div
        className="p-3 fw-semibold cursor-pointer"
        onClick={toggleDashboard}
        style={{ textAlign: 'center', color: '#000000' }}
      >
        {isDashboardCollapsed ? <FaBars /> : <Link className='text-decoration-none text-dark fw-bold '>Dashboard</Link>}
      </div >
      <ul 
      style={{
        flex: '1',
        // marginBottom: '20px',
        listStyle: 'none',
        paddingLeft: '0',
        transition: '90s',
      }}>
        {!isDashboardCollapsed && (
          <>
            <li style={{ textAlign: 'center' }}>
              {/* <Viewer
              visible={visible}
              onClose={() => setVisible(false)}
              images={image}
            /> */}
            <ImageViewer 
                images={images}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
              
              <img
              onClick={()=> openModal(0)}
                src={currentUser?.pic ? currentUser?.pic : defaultProfile}
                style={{ maxWidth: '60%', borderRadius: '50%', display: 'block', margin: 'auto' }}
                className="card-img img-fluid p-2 bg-ino bg-opacity-25"
                alt={currentUser?.name}
              />
            </li>
            <li className='ps-3' style={{ marginTop:'0.5rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/inquiries"><AiOutlineMail /> Inquiries</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/packages"><FaBox /> Packages</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to={'/itineraries'} ><FaCalendarAlt /> Itineraries</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to={'/accommodations'} ><FaHotel /> Accommodations</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/destinations" ><FaMapMarkerAlt /> Destinations</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/vehicles" ><FaCar /> Vehicles</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/flights" ><FaPlane /> Flights</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/receipts" >< FaReceipt/> Receipts</Link></li>
            <li className='ps-3' style={{ marginTop:'2rem', fontSize: '0.9rem' }}><Link className='text-decoration-none text-dark fw-semibold ' to="/faqs" >< FaQuestionCircle/> FAQs</Link></li>
          </>
        )}{isDashboardCollapsed && (
          <>
            <li >
              {/* <Viewer
              visible={visible}
              onClose={() => setVisible(false)}
              images={image}
            /> */}
            <ImageViewer 
                images={images}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
            
              <img
                 onClick={()=> openModal(0)}
                src={currentUser?.pic ? currentUser?.pic : defaultProfile}
               style={{ width: '50%', borderRadius: '50%', display: 'block', margin: 'auto' }}
               className="card-img mb-2 bg-ifo bg-opacity-25"
               alt={currentUser?.name || 'Default Profile'}
            />
            </li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium'  to="/inquiries"><AiOutlineMail style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium'  to="/packages"><FaBox style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium'  to={'/itineraries'} ><FaCalendarAlt style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium'  to={'/accommodations'} ><FaHotel style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium'  to="/destinations" ><FaMapMarkerAlt style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium' to="/vehicles" ><FaCar style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium' to="/flights" ><FaPlane style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium' to="/receipts" ><FaReceipt style={{ marginTop: '2rem' }} /></Link></li>
            <li className='ps-3'><Link className='text-decoration-none text-dark fw-medium' to="/faqs" ><FaQuestionCircle style={{ marginTop: '2rem' }} /></Link></li>
          </>
        )}
      </ul>
      <ul className='ps-4'
      style={{
        // marginBottom: '20px',
        listStyle: 'none',
        paddingLeft: '0',
        transition: '30s',
      }}>
        {isDashboardCollapsed ? (
          <>
            <li><Link to="/profile" className='text-decoration-none text-dark fw-semibold ' ><FaCog style={{ marginTop: '1.5rem' }}/> </Link></li>
            <li onClick={logout}><Link className='text-decoration-none text-danger fw-semibold ' ><FaSignOutAlt style={{ marginTop: '1.5rem' }}/> </Link></li>
          </>
        ) : (
          <>
           <li  style={{ marginTop: '1.5rem',fontSize:'0.9rem' }}><Link to="/profile" className='text-decoration-none text-dark fw-semibold ' ><FaCog /> Profile</Link></li>
           <li onClick={logout} style={{ marginTop: '1.5rem',fontSize:'0.9rem' }}><Link className='text-decoration-none text-danger fw-semibold ' ><FaSignOutAlt /> Logout</Link></li>
          </>
        )}
      </ul>
    </div>
  );
}
