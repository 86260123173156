import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PackageContext } from '../context/PackageContext';
import LoadingComponent from '../components/LoadingComponent';

const Packages = () => {
  const navigate = useNavigate();
  const {packages,status,setStatus, searchTerm, setSearchTerm,loading,searchResults, categoryResults} = useContext(PackageContext)
  
  const [filteredItems, setFilteredItems] = useState([]);
  const [search, setSearch] = useState(''); // Initialize search state
  const [packagesToDisplay, setPackagesToDisplay] = useState([])
 
  const handleRowClick = (id) => {
    navigate(`/inquiries/${id}`);
    
  };

  useEffect(() => {
    if (status !== 'all' && searchTerm === '') {
      setPackagesToDisplay(categoryResults);
    } else if (searchTerm !== '' && search !== '') {
      setPackagesToDisplay(searchResults);
    }else{
      setPackagesToDisplay(packages)
    }
    
    // setPackagesToDisplay(packages)
  }, [status, searchTerm, packages, categoryResults, searchResults, search]);
  


  if (loading){
  const loadingText = 'Loading packages....'
  return (
    <LoadingComponent loadingText={loadingText}/>
  )
  }

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearch(term);
  
    if (term.length > 0) {
      const filtered = new Set(); // Use a Set to store unique search results
  
      packages.forEach(item => {
        const clientName = item.name?.toLowerCase();
        const tourName = item.tour?.toLowerCase();
  
        if (clientName?.includes(term)) {
          filtered.add(item.name);
        }
        if (tourName?.includes(term)) {
          filtered.add(item.tour);
        }
      });
  
      // Convert Set to an array and sort alphabetically
      const sortedFilteredItems = [...filtered].sort((a, b) => a.localeCompare(b));
      setFilteredItems(sortedFilteredItems);
    } else {
      setFilteredItems([]);
      setPackagesToDisplay(packages)
    }
  };
  function handleSubmit(e){
    e.preventDefault()
    setSearchTerm(search)
  }

  const handleItemClick = (item) => {
    setSearch(item);
    setFilteredItems([]);
  };

  

  

  return (
    <div className="container-fluid pb-4" style={{backgroundColor:'rgb(8, 50, 32,1)',minHeight:'100%'}}>
    <h2 className="text-center pt-4 mb-4" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', color: 'wheat' }}>
        packages
      </h2>
      {/* Search Input Field */}
      <div className="container d-flex flex-wrap justify-content-center mb-3" style={{ maxWidth: '100%', margin: 'auto' }}>
      <form className="input-group position-relative" style={{ maxWidth: '20rem' }} onSubmit={handleSubmit}>
          <input
            type="search"
            className="form-control text-center border border-success"
            placeholder="Search 🔍"
            value={search}
            onChange={handleSearch}
            style={{ fontSize: '1rem' }}
          />
          <button className="btn btn-success" type="submit">🔍</button>
          {filteredItems.length > 0 && (
            <ul className="list-group position-absolute w-100" style={{ top: '100%', zIndex: 1 }}>
              {filteredItems.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleItemClick(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </form>
        <div className="form-group ms-3 ">
            <select 
            className="form-control bg-success text-white" 
            id="dropdownSelect"
            value={status}
            onChange={(e) => setStatus(e.target.value)}>
               <option value="all">All</option>
               <option value="approved">Approved</option>
               <option value="sent">Sent</option>
               <option value="unsent">Unsent</option>
            </select>
         </div>
      </div>
      <div style={{backgroundColor:(!loading? 'rgb(1,50,32,1)' : 'rgb(175, 238, 238,0.9)') }}>

      {packagesToDisplay && packagesToDisplay.length === 0 ? (
        <div className="alert alert-info" role="alert">
          No packages found.
        </div>
      ) : (
        <div className="table-responsive rounded" 
        style={{backgroundColor:'lightgrey'}}>
        <table className="table table-striped rounded table-hover text-center" >
            <thead>
              <tr className='fs-5' style={{color:'rgb(1, 50, 32,1)',fontFamily:'junge'}} >
                <th></th>
                <th>client</th>
                <th>Email</th>
                <th>Package</th>
                <th>status</th>
                <th>Time</th>
              
              </tr>
            </thead>
            <tbody>
              {packagesToDisplay.length > 0 && packagesToDisplay && packagesToDisplay.map((pack, index) => (
               <tr 
               key={pack.id} 
               onClick={() => handleRowClick(pack.inquiry_id)} 
               style={{ 
                backgroundColor: (pack.status === 'Sent') ? 'lightgreen' : (pack.status === 'Approved' ? 'khaki' : '') 
                }}>
                  <td style={{ fontSize: '0.85em' }}>{index + 1}</td>
                  <td style={{ fontSize: '0.85em' }}>{pack.name}</td>
                  <td style={{ fontSize: '0.85em' }}>{pack.email}</td>
                  <td style={{ fontSize: '0.85em' }}>{pack.tour}</td>
                  <td style={{ fontSize: '0.85em' }}>{pack.status}</td>
                  <td style={{ fontSize: '0.85em' }}>{pack.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      </div>
    </div>
  );
};

export default Packages;
