import React, { createContext,useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


export const AuthContext= createContext()
export default function AuthProvider({children}) {

  const navigate = useNavigate();
  const [onchange, setOnchange] = useState(false);
  const [authToken, setAuthToken] = useState(() => sessionStorage.getItem("authToken") ? sessionStorage.getItem("authToken") : null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onUserChange, setOnUserChange] = useState(false)
  const [loadingCounter, setLoadingCounter] = useState(1)

  //login
  function login(userData) {
    fetch("https://beshapp.backend.safarinetics.com/login", {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify(userData)
    })
    .then(res => res.json() )
    .then(response => {
      setLoading(false)
      if (response.access_token) {
        sessionStorage.setItem("authToken", response.access_token);
        setAuthToken(response.access_token);
        setLoggedIn(true);
        setLoadingCounter(1)
        navigate("/inquiries");
        setLoading(false)
        Swal.fire({
          icon:'success',
          toast:true,
          text:'Login success',
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
        setOnchange(!onchange);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          text:response.error,
          showConfirmButton: false,
          timer: 4000,
          toast:true,
          color:'white',
          background:'red'
        });

      }
      setLoading(false)
      setLoadingCounter(1)
    })
    .catch((error) => console.error(error))

  }

  //logged staff
  useEffect(() => {
  if (authToken && [1, 'log'].includes(loadingCounter)) {
    const timer = setTimeout(() => {
      fetch("https://beshapp.backend.safarinetics.com/logged", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken && authToken}`
        }
      })
      .then(res => res.json())
      .then(response => {
        if (response.error || response.msg) {
          setCurrentUser(null);
          setLoggedIn(false);
        } else {
          setCurrentUser(response);
          setLoggedIn(true);
        }
        loadingCounter === 1 ? setLoadingCounter(2) : setLoadingCounter(null)
        
      })
      .catch((error) => console.error(error));
    }, 1000); // 1-second delay

    // Clean up the timer if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }
}, [authToken, onchange, onUserChange, loadingCounter]);




  //logout
  function logout() {
    fetch('https://beshapp.backend.safarinetics.com/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
    .then(res => res.json())
    .then(response => {      
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      navigate('/');
      setLoggedIn(false);
      sessionStorage.removeItem("authToken");

    })
    .catch((error) => console.error(error))

    
  }

  //reset password
  function resetPassword(name, email) {
    setLoading(true)
    fetch("https://beshapp.backend.safarinetics.com/auth/password/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: email
      })
    })
    .then(res => res.json() )
    .then(response => {
      setLoading(false)
      if (response.success) {
        Swal.fire({
          icon:'success',
          toast:true,
          text:response.success,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'green',
          position:'top-end'
        });
        navigate('/auth/login');
      } else {
        Swal.fire({
          icon:'error',
          toast:true,
          text:response.error,
          timer:4000,
          showConfirmButton:false,
          color:'white',
          background:'red',
          position:'top-end'
        });
      }
    })
    .catch((error) => console.error(error))
 
  }

     //  Reset change Password 
function resetChangePassword(formData){
  fetch("https://beshapp.backend.safarinetics.com/auth/reset/password/change", {
      method: "PATCH",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
  })
  .then(res => res.json())
  .then((response) => {
      if(response.success){
       //    setAuthChange(!authChange)
          setLoading(false)
          navigate('/auth/login')
        Swal.fire({
          icon:'success',
          text:response.success,
          position:'top-end',
          timer:2000,
          toast:true,
          showConfirmButton:false,
          background:'#228B22',
          color:'wheat'
        })
      } else {
          Swal.fire({
              icon:'warning',
              text:response.error || response.msg,
              position:'top-end',
              timer:2000,
              toast:true,
              showConfirmButton:false,
              background:'red',
              color:'wheat'
            })
      }
      setLoading(false)
    })
  .catch((error) => {
      console.error('Error:', error);
  });
}

  



   const contextData = {
    login,
    currentUser,
    loggedIn,
    authToken,
    onchange,setOnchange,
    logout,
    resetPassword,
    loading,setLoading,
    onUserChange, setOnUserChange,
    loadingCounter, setLoadingCounter,
    resetChangePassword,


   }

  return (
    <AuthContext.Provider value = {contextData}>
      {children}
    </AuthContext.Provider>
  )
}

