import React, { useContext, useState } from 'react';
import { DestinationContext } from '../context/DestinationContext';
import { uploadImage } from '../images/ImageUploader';

export default function AddPicture({ destination, setAddPicture }) {
  const { addSinglePicture } = useContext(DestinationContext);
  const [image, setImage] = useState();
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);

    // Check if the title already exists in destination.pictures (case insensitive)
    if (destination.pictures.some(picture => picture.title.toLowerCase() === value.toLowerCase())) {
      setError('This title already exists.');
    } else {
      setError('');
    }
  };

  async function handleAdd(e) {
    e.preventDefault();
    if (error) {
      return;
    }

    try {
      //// console(image);
      const result = await uploadImage(image);
      //// console(`results, ${result.url}, ${result.id}`);

      const formData = { image: result.url, image_id: result.id, title: title };
      addSinglePicture(destination.id, formData);

      setTimeout(() => {
        setAddPicture(false);
      }, 4000);

      setImage('');
      setTitle('');
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  return (
    <div className="row col-md-5">
      <form onSubmit={(event) => handleAdd(event)} style={{ backgroundColor: 'rgb(8, 50, 32,0.61)', height: 'fit-content' }}
        className='container border-info rounded col-md-11 mt-3 p-3 '>
        <h4 className='pb-2'>Add New Picture</h4>
        <div className="mb-3">
          <label htmlFor={`newImage`} className="form-label">Image</label>
          <input
            type="file"
            className="form-control"
            id={`newImage`}
            accept=".png, .jpg, .jpeg"
            onChange={(event) => handleImageChange(event)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor={`newCaption`} className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id={`newCaption`}
            value={title}
            onChange={handleTitleChange}
            required
          />
          {error && <small className="text-danger">{error}</small>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={!!error}>Add</button>
        <button onClick={() => setAddPicture(false)} className="btn btn-danger ms-5">Cancel</button>
      </form>
    </div>
  );
}
