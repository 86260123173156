import React, { useContext, useState, useEffect } from 'react';
import AddOverview from './AddOverview';
import AddItineraryDetails from './AddItineraryDetails';
import AddPrices from './AddPrices';
import PackageDetails from '../package/PackageDetails';
import { ItineraryContext } from '../context/ItineraryContext';
import MovingCharacters from '../components/MovingCharacters';
import { AuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoadingComponent from '../components/LoadingComponent';

export default function CreatePackage() {
  const { authToken } = useContext(AuthContext);
  const { createNewPackage, prices } = useContext(ItineraryContext);
  const [mainItinerary, setMainItinerary] = useState({});
  const [activeTab, setActiveTab] = useState('overview');
  const { inquiryId, days, tour } = useParams();
  const [loading, setLoading] = useState(false);
  const [triggerAddPrice, setTriggerAddPrice] = useState(true)

  // Fetching single main Itinerary using itinerary title and the duration (days)
  useEffect(() => {
    if (authToken && tour && days && inquiryId) {
      setLoading(true);
      const encodedTour = encodeURIComponent(tour); // Encode the tour parameter to avoid %20

      fetch(`https://beshapp.backend.safarinetics.com/main_itinerary/${inquiryId}/${encodedTour}/${days}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            // Handle error response
            window.history.back();
            Swal.fire({
              title: response.error,
              icon: 'error',
              showConfirmButton: false,
              toast: true,
              timer: 4000,
              background: 'red',
              color: 'white'
            });
          } else {
            setMainItinerary(response);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [authToken, tour, days, inquiryId]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  async function handleCreate() {
    handleTabChange('prices');

    setTriggerAddPrice(!triggerAddPrice);

  
    // Check if prices are valid
    if (!prices || Object.keys(prices).length === 0) {
      setTriggerAddPrice(!triggerAddPrice);
      Swal.fire({
        toast: true,
        position: 'top',
        title: 'Please adjust the commission and vehicle days!',
        timer: 8000,
        timerProgressBar: true,
        showConfirmButton: false,
        background: 'orange',
        color: 'black',
        icon: 'warning'
      });
      return; // Exit if prices are not available
    }
  
    // Check if totals are valid
    if (prices.adultsTotal <= 0 && prices.adultsSharingTotal <= 0) {
      Swal.fire({
        toast: true,
        position: 'top',
        title: 'Please adjust the commission and vehicle days!',
        timer: 8000,
        timerProgressBar: true,
        showConfirmButton: false,
        background: 'orange',
        color: 'black',
        icon: 'warning'
      });
      return; // Exit if totals are not valid
    }
  
    // Proceed to save prices
    Swal.fire({
      toast: true,
      position: 'top',
      title: 'Saving the prices ...',
      timer: 4000,
      timerProgressBar: true,
      showConfirmButton: false,
      background: 'green',
      color: 'wheat'
    });
  
    // Wait for 2 seconds before calling createNewPackage
    await new Promise(resolve => setTimeout(resolve, 2000));
  
    createNewPackage(inquiryId, mainItinerary?.inquiry?.client?.name, days, tour);
  }
  
  
  
  
  

  if (loading) {
    return (
      <LoadingComponent loadingText={'Loading'}/>
    );
  }

  return (
    <div className="card" style={{ backgroundColor: 'rgb(8, 50, 32,1)', minHeight: '100%' }}>
      <h2 className="text-center mt-3 mb-4" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', color: 'wheat' }}>
        Create Package
      </h2>

      <div className="container card-body border border-success rounded col-md-8">
        <h3 className="text-center text-light fw-semibold card-header bg-success" style={{ fontFamily: 'junge' }}>
          {tour} ({days} days)
        </h3>
        <MovingCharacters inquiry={mainItinerary?.inquiry} />
        <div className="col-lg-12 rounded" style={{ backgroundColor: 'rgba(34, 139, 34, 0.6)', padding: '20px' }}>
          <div className="d-flex flex-wrap justify-content-around">
            <p
              className="rounded px-3 mb-2"
              style={{ backgroundColor: activeTab === 'overview' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('overview')}
            >
              Overview
            </p>

            <p
              className="rounded px-3 mb-2"
              style={{ backgroundColor: activeTab === 'itinerary' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('itinerary')}
            >
              Itinerary
            </p>

            <p
              className="rounded px-3 mb-2"
              style={{ backgroundColor: activeTab === 'details' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('details')}
            >
              Details
            </p>

            <p
              className="rounded px-3 mb-2"
              style={{ backgroundColor: activeTab === 'prices' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('prices')}
            >
              Prices
            </p>

            <div className="text-center">
              <button type="button" onClick={handleCreate} className="btn btn-primary btn-sm">
                Create
              </button>
            </div>
          </div>
          <div className="overflow-auto pt-1 pb-3 mb-0" style={{ minHeight: '100%' }}>
            {activeTab === 'overview' && <AddOverview mainItinerary={mainItinerary} setActiveTab={handleTabChange} />}
            {activeTab === 'itinerary' && <AddItineraryDetails mainItinerary={mainItinerary} setActiveTab={handleTabChange} />}
            {activeTab === 'details' && <PackageDetails mainItinerary={mainItinerary} setActiveTab={handleTabChange} />}
            {activeTab === 'prices' && <AddPrices inquiry={mainItinerary?.inquiry} setActiveTab={handleTabChange} setTriggerAddPrice={setTriggerAddPrice} triggerAddPrice={triggerAddPrice}/>}
          </div>
        </div>
      </div>
    </div>
  );
}
