import React, { createContext,useState,useEffect, useContext } from 'react'
import Swal from 'sweetalert2'
import { AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'

export const VehicleGuideContext = createContext()

export default function VehicleGuideProvider({children}) {

const {loadingCounter, setLoadingCounter, authToken, loggedIn} = useContext(AuthContext)
const navigate = useNavigate()
   const [vehicleGuides, setVehicleGuides] = useState([])
   const [onadd, setOnadd] = useState(false)
   const [loading, setLoading] = useState(false)
   const [searchTerm, setSearchTerm] = useState('')
   const [vehicleGuide, setVehicleGuide] = useState({})
  const [searchResults, setSearchResults] = useState([])

   // ============================= GET / FETCH =======================

   // +++++++++++++ GET ALL VEHICLES ++++++++++++++++++

   useEffect(() => {
    if (authToken && searchTerm === '' && [7, 'veh'].includes(loadingCounter)) {
      const timer = setTimeout(() => {
        fetch('https://beshapp.backend.safarinetics.com/vehicles', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        })
        .then(res => res.json())
        .then(response => {
          setVehicleGuides(response);
          loadingCounter === 7 ? setLoadingCounter(8) : setLoadingCounter(null);
        })
        .catch(error => console.error("Error fetching vehicles:", error));
      }, 1000); // 1-second delay
  
      // Clean up the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  }, [onadd, authToken, searchTerm, loadingCounter, setLoadingCounter]);
  

   // +++++++++++++ GET VEHICLES GUIDE , MODEL AND PLATES ++++++++++++++++++
   useEffect(() => {
    if (authToken && searchTerm !== '') {
      fetch(`https://beshapp.backend.safarinetics.com/vehicles_by_guide/${searchTerm}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      .then(res => res.json())
      .then(response => {
        setSearchResults(response);
      })
      .catch(error => console.error("Error fetching vehicles by guide:", error));
    }
  }, [authToken, searchTerm]);
  




  //  add vehicle 
   function addVehicle(newVehicle){
    setLoading(true)
    const formData = new FormData();
    formData.append('newVehicle', JSON.stringify(newVehicle));

    fetch('https://beshapp.backend.safarinetics.com/vehicle/add', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: formData
    })
     .then(res => res.json())
     .then(response => {
      if(response.success){
      setOnadd(!onadd)
      setLoadingCounter('veh')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      if(loggedIn){
        navigate(`/vehicles/${response.id}/details`)
      }
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
       
     })
     .catch((error) => console.error(error))
     .finally(() => setLoading(false))
   }
   


  //  update 
   function updateVehicle(id,updatedVehicle){
    const formData = new FormData();
    formData.append('updatedVehicle', JSON.stringify(updatedVehicle))
    formData.append('image', updatedVehicle?.image)
    fetch(`https://beshapp.backend.safarinetics.com/vehicle/update/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken && authToken}`
      },
      body: formData
    })
     .then(res => res.json())
     .then(response => {
      if(response.success){
      setOnadd(!onadd)
      setLoadingCounter('veh')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      if(loggedIn){
        navigate(`/vehicles/${id}/details`)
      }
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
       
     })
   }


    // delete 
   function deleteVehicle(id){
    fetch(`https://beshapp.backend.safarinetics.com/delete_vehicle/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
     .then(res => res.json())
     .then(response => {
      if(response.success){
      setOnadd(!onadd)
      setLoadingCounter('veh')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
      if(loggedIn){
        navigate(`/vehicles`)
      }
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
       
     })
     
   }



   const contextData = {
      vehicleGuides,addVehicle,updateVehicle,deleteVehicle,
      searchTerm,setSearchTerm,setVehicleGuide,vehicleGuide,
      searchResults, setSearchResults,
      loading, setLoading,

   }
  return (
    <VehicleGuideContext.Provider value = {contextData} >
      {children}
    </VehicleGuideContext.Provider>
  )
}
