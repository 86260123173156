import React from 'react';
// import TawkTo from './TawkTo';

const FAQs = () => {
  const faqData = [
    
    {
      question: "How do I add the company email to my gmail?",
      answers: [
        "Follow the instructions on the link below ."
      ],
      link:'/pages/email-setup'
    },
    {
      question: "What should I do if I forget my password?",
      answers: [
        "Click the 'Forgot Password?' link on the login page, and follow the instructions to reset your password."
      ]
    },
    {
      question: "Can I change my password?",
      answers: [
        "Yes, once logged in, go to the 'Profile' section in the Dashboard to change your password."
      ]
    },
    {
      question: "Is my data secure?",
      answers: [
        "Yes, we prioritize your data security and use industry-standard encryption to protect your information."
      ]
    },
    {
      question: "Can I access the app on mobile devices?",
      answers: [
        "Yes, our web app is optimized for mobile use, allowing you to manage your email communications on the go."
      ]
    }
  ];

  const styles = {
    faqs: {
      padding: '20px',
      backgroundColor: '#f9f9f9'
    },
    faqItem: {
      marginBottom: '10px'
    },
    summary: {
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'orange'
    },
    answer: {
      fontSize: '16px',
      color: 'beige',
      margin: '5px 0 0 0'
    },
    answerList: {
      listStyleType: 'square',
      paddingLeft: '20px'
    }
  };

  return (
    <div style={styles.faqs}>
      <h2 className='text-center fw-bold' style={{fontFamily:'junge',color:'forestgreen'}}
      >Frequently Asked Questions (FAQs)</h2>
      
      {faqData.map((faq, index) => (
        <details className='container m-4 p-4 rounded shadow ' key={index} style={{backgroundColor:'rgb(54,69,79)', border:'2px solid gold'}}>
          <summary className='' style={styles.summary}>{index+1}. {faq.question}</summary>
          <div>
          <ul style={styles.answerList}>
            {faq.answers.map((answer, idx) => (
              <li key={idx} style={styles.answer}>{answer}</li>
            ))}
          </ul>
          {faq?.link && (
            <a href={faq?.link} rel='noopener'>link</a>
          )}
          </div>
        </details>
      ))}
    <div style={{
      fontSize: '0.9rem',
      color: '#888',
      marginTop: '15px',
      fontStyle: 'italic',
    }}>
        <em>Note: incase of any other question or issue please email <a href='mailto:info@safarinetics.com'>info@safarinetics.com</a>.</em>
      </div>
    </div>
  );
};

export default FAQs;
