import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Viewer from 'react-viewer';
import ImageViewer from '../utils/ImageViewer'

export default function VehiclesDets({ packageInfo }) {
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  
    // ==================== IMAGES VIEWER =====================

    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
    useEffect(() => {
      // const images = 
      setCurrentImage([packageInfo?.itinerary?.overview?.vehicles.map(vehicle => vehicle.image)]);
    }, [packageInfo])
    

  
    // ==================== IMAGES VIEWER =====================
  const handleImageClick = (image) => {
    
    setVisible(true);
  };


  return (
    <div className="card-body border-0 shadow-sm col-auto rounded mt-3" style={{ color: 'wheat', backgroundColor: 'rgb(175, 238, 238,0)' }}>
      <h4 className="card-title mb-4" style={{ color: 'cream', fontFamily: 'junge' }}>Vehicles info</h4>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {packageInfo?.itinerary?.overview?.vehicles.map((vehicle, index) => (
          <div key={index} className="col">
            <motion.div
              className="card border-success"
              style={{ overflow: 'hidden', position: 'relative', height: "20em" }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => openModal(index)}
            >
             
              <img
                loading="lazy"
                src={vehicle?.image}
                className="card-img-top img-fluid"
                style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(70%)' }}
                alt={vehicle.model}
                onClick={()=> openModal(index)}
              />
              <div
                className="card-img-overlay d-flex flex-column justify-content-end"
                style={{ color: 'white' }}
              >
                <h5 className="card-title">{vehicle.guide.name}</h5>
                <small className="card-text">
                  Vehicle:<small className="ms-3">{vehicle.model} ({vehicle.category})</small>
                </small>
                <small className="card-text">
                  Capacity:<small className="ms-3">{vehicle.capacity} seater</small>
                </small>
              </div>
            </motion.div>
          </div>
        ))}
      </div>
      <ImageViewer 
                images={currentImage}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
    </div>
  );
}
