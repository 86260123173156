import React, { useContext, useState } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import Swal from 'sweetalert2';
import AddSinglePicture from './AddSinglePicture';
import { AuthContext } from '../context/AuthContext';
import { uploadImage } from '../images/ImageUploader';

export default function UpdatePictures({ accommodation, setEditPictures }) {
  const { currentUser } = useContext(AuthContext);
  const { updatePicture, deletePicture } = useContext(AccommodationContext);
  const [addPicture, setAddPicture] = useState(false);
  const [updatedPictures, setUpdatedPictures] = useState(
    accommodation?.pictures.map(picture => ({
      newImage: picture.image,
      newImageId: picture.image_id,
      newCaption: picture.caption,
      error: ''
    }))
  );

  // Function to validate the caption
  const validateCaption = (newCaption, currentIndex) => {
    return accommodation?.pictures.some((picture, index) =>
      index !== currentIndex && picture.caption.toLowerCase() === newCaption.toLowerCase()
    )
      ? 'This caption already exists.'
      : '';
  };

  // Handle caption change with real-time validation
  const handleCaptionChange = (event, index) => {
    const newCaption = event.target.value;
    setUpdatedPictures(prevState => {
      const updated = [...prevState];
      updated[index].newCaption = newCaption;
      updated[index].error = validateCaption(newCaption, index);
      return updated;
    });
  };

  const handleImageChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setUpdatedPictures(prevState => {
        const updated = [...prevState];
        updated[index].newImage = file;
        return updated;
      });
    }
  };

  const handleUpdate = async (event, id, index) => {
    event.preventDefault();
    const formData = new FormData();
    const { newCaption, newImage, newImageId, error } = updatedPictures[index];

    // Validate before proceeding with the update
    if (error) {
      Swal.fire({
        text: "Please correct the errors before submitting.",
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'white',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    formData.append('caption', newCaption);

    try {
      let imageUrl, imageId;

      if (newImage instanceof File) {
        const result = await uploadImage(newImage);
        imageUrl = result.url;
        imageId = result.id;
      } else {
        imageUrl = newImage;
        imageId = newImageId;
      }

      formData.append('image', imageUrl);
      formData.append('image_id', imageId);
      
      updatePicture(id, formData);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  function handleDelete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deletePicture(id);
      }
    });
  }

  return (
    <div className="container-fluid col-md-6 pt-2">
      <h3 className="text-center mb-4" style={{ fontFamily: 'inknut antiqua', color: 'wheat', position: 'relative' }}>
        Update Pictures
        <button
          className="btn btn-success text-white"
          onClick={() => setAddPicture(true)}
          style={{ position: 'absolute', top: '50%', right: '20%', transform: 'translateY(-50%)', cursor: 'pointer' }}
        >
          +
        </button>
        <button
          className="btn btn-danger text-white"
          onClick={() => setEditPictures(false)}
          style={{ position: 'absolute', top: '50%', right: '10%', transform: 'translateY(-50%)', cursor: 'pointer' }}
        >
          ×
        </button>
      </h3>
      {addPicture ? (
        <AddSinglePicture accommodation={accommodation} setAddPicture={setAddPicture} />
      ) : (
        <div className="row row-cols-1 g-4">
          {accommodation?.pictures.map((picture, index) => (
            <div key={index} className="col">
              <details className='rounded px-2' style={{ backgroundColor: 'rgb(175, 238, 238,0.8)' }}>
                <summary className='d-flex justify-content-between align-items-center'>
                  <p className="mt-1 text-center text-dark">{picture.caption}</p>
                  {currentUser && currentUser?.position === 'ADMIN' && (
                    <button
                      onClick={() => handleDelete(picture.id)}
                      className='btn btn-sm btn-danger align-self-end mb-1'
                    >
                      Delete
                    </button>
                  )}
                </summary>
                <form onSubmit={(event) => handleUpdate(event, picture.id, index)}>
                  <div className="mb-3">
                    <label htmlFor={`newImage-${index}`} className="form-label">New Image</label>
                    <input
                      type="file"
                      className="form-control"
                      id={`newImage-${index}`}
                      accept=".png, .jpg, .jpeg"
                      onChange={(event) => handleImageChange(event, index)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor={`newCaption-${index}`} className="form-label">Caption</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`newCaption-${index}`}
                      value={updatedPictures[index].newCaption}
                      onChange={(event) => handleCaptionChange(event, index)}
                    />
                    {updatedPictures[index].error && (
                      <div className="text-danger">{updatedPictures[index].error}</div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary" disabled={!!updatedPictures[index].error}>
                    Submit
                  </button>
                </form>
              </details>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
