import React, { useContext, useState} from 'react'
import AddDetails from './AddDetails'
import AddDailyItineraries from './AddDailyItineraries'
import AddBudget from './AddBudget'
import { ItineraryContext } from '../context/ItineraryContext'
import LoadingComponent from '../components/LoadingComponent'

export default function AddMainItinerary() {
  const {addMainItinerary,loading} =  useContext(ItineraryContext)
  const [activeTab, setActiveTab] = useState('overview');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  if (loading){
    return(
      <LoadingComponent loadingText={"Uploading...."}/>
    )
  }
  
  return (
    <div className='card ' style={{ backgroundColor: 'rgb(8, 50, 32,1)',minHeight:'100%'}}>
    
      <h2 className="text-center mt-3 mb-4" style={{ fontFamily: 'inknut antiqua', fontWeight: 'bold', color: 'wheat' }}>
        Add New Main Itinerary
        </h2>

        <div className='container-fluid card-body border border-success rounded col-md-7'>
          
          <div className="col-lg-12 rounded" style={{ backgroundColor: 'rgb(34, 139, 34,0.6)', padding: '20px',}}>
          <div className="d-flex flex-wrap justify-content-around " >
          <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'overview' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('overview')}
            >
              Details
            </p>

            <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'itinerary' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('itinerary')}
            >
              Daily Itineraries
            </p>
            
            <p
              className='rounded px-3 '
              style={{ backgroundColor: activeTab === 'budget' ? 'lightgreen' : 'lightgrey', fontSize: '1rem', cursor: 'pointer' }}
              onClick={() => handleTabChange('budget')}
            >
              Budget
            </p>
            
            
            <div className=' text-center'>
        <button onClick={addMainItinerary} className='btn btn-primary btn-sm' >Create</button>
        </div>
      </div>
          <div className='overflow-auto pt-1 pb-3 mb-0 ' style={{ height: 'fit-content'}}>
            {activeTab === 'overview' && <AddDetails setActiveTab={setActiveTab}/>}
            {activeTab === 'itinerary' && <AddDailyItineraries />}
            {activeTab === 'budget' && <AddBudget />}
           
          </div>
          </div>
       
        </div>

    </div>
  )
}

 