import React, { useContext, useEffect, useState } from 'react';
import PackageRates from './PackageRates';
import PackageItinerary from './PackageItinerary';
import { PackageContext } from '../context/PackageContext';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ClientDets from './ClientDets';
import TransportationDetails from './TransportDetails';
import { FaCheckCircle } from 'react-icons/fa';
import { MdMenu } from 'react-icons/md';
import Swal from 'sweetalert2';
import UpdatePackage from './UpdatePackage';
import LoadingComponent from '../components/LoadingComponent';
import { motion } from 'framer-motion';
import AddReceipt from '../receipts/AddReceipt';
import PackageReceipt from '../receipts/PackageReceipt';
import ImageViewer from '../utils/ImageViewer'

export default function ViewPackage() {
  const { deletePackage, approvePackage, sendPackage, packageChange, loading, sendPackageComment } = useContext(PackageContext);
  const { loggedIn, currentUser } = useContext(AuthContext);
  const [packageInfo, setPackageInfo] = useState();

    // ==================== IMAGES VIEWER =====================
    // const images=[image1,2,3,4 ...]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================

  const { id, client, days, tour } = useParams();
  const navigate = useNavigate();
  const [updatePackage, setUpdatePackage] = useState(false);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (id && client && days && tour) {
      setFetching(true);
  
      fetch(`https://beshapp.backend.safarinetics.com/package_info/${id}/${client}/${days}/${tour}`)
        .then((res) => res.json())
        .then((response) => {
          
  
          if (!response.error) {
            // setFetching(false);
            if (response && (response?.inquiry?.tour === tour ||
                response?.inquiry?.client_name === client || 
                response?.inquiry?.id === id || 
                response?.inquiry?.days === days)) {

            setPackageInfo(response);

            }else {

              navigate('*');
            }
          } else {
            window.history.back();
            Swal.fire({
              title: "No package for this inquiry",
              text: "Would you like to create one?",
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, create package"
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/create/${days}/${id}/package/${tour}`);
              }
            });
          }
        })
        .catch((error) => {
          // setFetching(false);
          console.error(error);
        })
        .finally(()=> {
          setTimeout(() => {
            setFetching(false)
          }, 500);
        })
    }
  }, [id, client, days, tour, packageChange, navigate]);

  const currentUrl = window.location.href;

  function handleApproval() {
    Swal.fire({
      title: "Input email address",
      input: "email",
      inputLabel: "Your email address",
      inputPlaceholder: "Enter your email address",
      background: 'rgb(8, 50, 32,1)',
      color: '#B79F00'
    }).then((result) => {
      if (result.value) {
        const email = result.value;
        Swal.fire(`Entered email: ${email}`);
        approvePackage(id, client, email);
      }
    });
  }
  function handleComment() {
    Swal.fire({
      // title: "Input email address",
      input: "textarea",
      inputLabel: "comment",
      inputPlaceholder: "Enter your comment ....",
      background: 'rgb(210, 210, 210)',
      color: 'rgb(8, 50, 32,1)'
    }).then((result) => {
      if (result.value) {
        const comment = result.value;
        sendPackageComment(id,comment)
      }
    });
  }

  function handleDelete(packageId) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deletePackage(packageId);
      }
    });
  }
  if (fetching){
    const loadingText = 'Loading your package .....';
    return(
      <LoadingComponent loadingText={loadingText} />
    )
  }
  // console(packageInfo?.itinerary?.overview?.image)

  return (
    <>{updatePackage ? (
          <UpdatePackage packageInfo={packageInfo} setUpdatePackage={setUpdatePackage} />
        ) : (
          <div className='pb-4' style={{ backgroundColor: 'rgb(8, 50, 32,1)', overflowX: 'auto' }}>
            <motion.div
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '80vh',
                minHeight: '50vh',
                backgroundImage: `radial-gradient(circle, rgba(1, 50, 33, 0.38), rgba(1, 50, 33, 0.25), rgba(255, 255, 255, 0.3)), url(${packageInfo?.itinerary?.overview?.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: '0.5rem',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <ImageViewer 
                images={[packageInfo?.itinerary?.overview?.image]}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
              <img
                loading="lazy"
                src={packageInfo?.itinerary?.overview?.image}
                style={{ width: '100%', height: 'auto', display: 'block', visibility: 'hidden' }}
                alt={packageInfo?.inquiry?.tour}
                onClick={()=> openModal(currentImageIndex)}
              />
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'wheat',
                textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
                textAlign: 'center',
                width: '100%'
              }}>
                <motion.h1 className="fw-medium"
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 2.5 }}
                  style={{ fontFamily: 'junge', fontSize: 'clamp(1rem, 3.5vw + 1.5rem, 3.5rem)' }}>
                  {packageInfo?.inquiry?.tour}
                </motion.h1>
              </div>

              {(currentUser?.position === 'ADMIN' || (loggedIn && !packageInfo?.approved)) && (
                <div className='dropdown' style={{ position: 'absolute', top: '10px', right: '10px' }}>
                  <button className='btn btn-success btn-sm dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-expanded='false'>
                    <MdMenu style={{ fontSize: '2rem' }} />
                  </button>
                  <ul className='dropdown-menu' style={{ backgroundColor: 'rgba(210,210,210)' }} aria-labelledby='dropdownMenuButton'>
                    <li>
                      <Link onClick={() => setUpdatePackage(true)} className='dropdown-item fw-semibold' style={{ color: 'green' }}>Update</Link>
                    </li>
                    <li>
                      <button onClick={() => sendPackage(id,currentUrl)} className='dropdown-item fw-semibold' style={{ color: 'green' }} type='button'>Send</button>
                    </li>
                    {(currentUser?.position === 'ADMIN' && packageInfo?.approved) && (<AddReceipt packageInfo={packageInfo} />)}
                    {currentUser?.position === 'ADMIN' && (
                      <li>
                        <button onClick={() => handleDelete(packageInfo?.id)} className='dropdown-item fw-semibold text-danger' type='button'>Delete</button>
                      </li>
                    )}
                  </ul>
                </div>
              )}

              {/* <div style={{ width: '100%', position: 'relative', top: '70%' }}> */}
                <ClientDets packageInfo={packageInfo} />
                <PackageReceipt packageInfo={packageInfo} />
              {/* </div> */}
            </motion.div>
            <div className="container-fluid pt-3" style={{ width: '100%', height: 'fit-content', backgroundColor: 'rgb(8, 50, 32,1)', borderTop: '1px solid rgb(107, 142, 35)', borderBottom: '1px solid rgb(107, 142, 35)', color: 'rgb(210, 210, 210)' }}>
              
              <div className='container row mx-auto  text-center mt-3 justify-content-center' style={{height:'100%'}}>
                <h2 className="text-center mb-3">Overview</h2>
                <textarea 
                value={packageInfo?.itinerary?.overview?.overview}
                readOnly
                rows={10}
                className='fst-italic text-center container-fliud mx-2' 
                style={{backgroundColor: 'rgb(8, 50, 32,1)',color:'wheat',height:'100%',border:'none'}}/> 
                  
              </div>
              <TransportationDetails packageInfo={packageInfo} />
            </div>
            <PackageItinerary packageInfo={packageInfo} />
            <PackageRates packageInfo={packageInfo} />
            {!loggedIn && (
  <div className="container-fluid mx-auto justify-content-center row" style={{ width: 'fit-content', margin: '5px auto' }}>
    <div className="col-md-8 row border border-info p-4 rounded shadow-lg" style={{ backgroundColor: '(175, 238, 238,0.8)', borderColor: 'rgb(8, 50, 32)' }}>
      <p style={{ color: 'grey', fontSize: '16px' }} className="mb-3">
        If you have any questions or comments about the package, please feel free to share below, and we'll get back to you shortly.
      </p>
      <div className="mb-3 d-flex flex-wrap flex-row justify-content-around">
        <button className="btn btn-outline-info"  onClick={() => handleComment()}>Comment</button>
        {packageInfo && packageInfo.approved === false && (
          <>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-info me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <span style={{ color: 'rgb(128, 0, 32)', fontSize: '16px' }}>Approving...</span>
              </div>
            ) : (
              <button className="btn btn-outline-warning" onClick={handleApproval}>
                Approve <FaCheckCircle className="ms-1" />
              </button>
            )}
          </>
        )}
      </div>
      {/* {showCommentSection && (
        <PackageComments
          comment={comment} setComment={setComment}
        />
      )} */}
    </div>
  </div>
)}
            {packageInfo && packageInfo.approved === true && (
              <div style={{ position: 'absolute', top: '1%', left: '10%', zIndex: '9' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="rgb(183, 159, 0)" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.83 5.97l-4.5 4.5a.5.5 0 0 1-.71 0l-2-2a.5.5 0 1 1 .7-.7l1.29 1.3 3.79-3.8a.5.5 0 0 1 .7.7z" />
                </svg>
              </div>
            )}
          </div>
        )}
    </>
  );
}
