import React from 'react';
import logo from '../images/logo.png';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow border" style={{ backgroundColor:'lightgrey' }}>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        {/* Logo */}
        <img loading="lazy" src={logo} alt="Logo" style={{ maxHeight: '10vh' }} />

        {/* Title */}
        <span className="navbar-brand mx-auto" style={styles.title}>
          Besh African Adventures
        </span>
      </div>
    </nav>
  );
};

const styles = {
  title: {
    fontFamily: 'Inknut Antiqua',
    fontWeight: 'bold',
    fontSize: '4vw',  // Dynamic font size based on viewport width
    maxWidth: '100%', // Ensures the text doesn't overflow
    textAlign: 'center',
  },
  '@media (min-width: 768px)': {
    title: {
      fontSize: '3vw', // Adjust size for medium screens and above
    }
  },
  '@media (min-width: 992px)': {
    title: {
      fontSize: '2.5vw', // Further adjustment for large screens
    }
  },
  '@media (min-width: 1200px)': {
    title: {
      fontSize: '2vw', // Final adjustment for extra-large screens
    }
  }
};

export default Navbar;
