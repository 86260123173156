import React, { useContext, useState } from 'react';
import { FlightContext } from '../context/FlightContext';
import { useParams } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { uploadImage } from '../images/ImageUploader';

const UpdateFlights = ({ setUpdate, flightDetails }) => {
   const { updateFlight, flights } = useContext(FlightContext);
   const { flight_id } = useParams();
   const [formData, setFormData] = useState({
      id: flight_id,
      name: flightDetails?.name || '',
      notes: flightDetails?.notes || '',
      image_id: flightDetails?.image_id || '',
      image: flightDetails?.image || ''
   });

   const [nameError, setNameError] = useState('');

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         setFormData((prevState) => ({
            ...prevState,
            image: file,
         }));
      }
   };

   const handleChange = (e) => {
      const { name, value } = e.target;

      if (name === 'name') {
         // Real-time validation for name
         const nameExists = flights.some(flight => 
            flight.name.toLowerCase() === value.toLowerCase() && flight?.id !== flightDetails?.id
         );
         if (nameExists) {
            setNameError('Flight name already exists');
         } else {
            setNameError('');
         }
      }

      setFormData((prevState) => ({
         ...prevState,
         [name]: value
      }));
   };

   async function handleSubmit(e) {
      e.preventDefault();

      if (nameError) {
         return; // Prevent submission if there's an error
      }

      const newFormData = new FormData();
      newFormData.append('name', formData.name);
      newFormData.append('notes', formData.notes);

      if (formData.image instanceof File) { 
         try {
            const result = await uploadImage(formData.image);
            newFormData.append('image', result.url);
            newFormData.append('image_id', result.id);
         } catch (error) {
            console.error("Error uploading image:", error);
            return;
         }
      } else {
         newFormData.append('image', formData.image);
         newFormData.append('image_id', formData.image_id);
      }

      updateFlight(flight_id, newFormData);
      setUpdate(false);
   }

   return (
      <div className="col-md-6 mx-auto">
         <div className="card p-4" style={{ backgroundColor: 'rgb(1,50,32,1)', borderRadius: '10px' }}>
            <div className='card-header container' style={{ position: 'relative' }}>
               <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'inknut antiqua' }}>Update Flight</h2>
               <MdClose
                  color='red'
                  size={24}
                  style={{ position: "absolute", right: 0, top: 0 }}
                  onClick={() => setUpdate(false)}
               />
            </div>
            <form onSubmit={handleSubmit} className='text-white'>
               <div className='container-fluid row'>
                  <div className="mb-3">
                     <label htmlFor="name" className="form-label">Name:</label>
                     <input
                        type="text"
                        className={`form-control ${nameError ? 'is-invalid' : ''}`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                     />
                     {nameError && <div className="invalid-feedback">{nameError}</div>}
                  </div>
               </div>

               <div className='container-fluid row mb-4'>
                  <div className="mb-3">
                     <label htmlFor="notes" className="form-label">Notes:</label>
                     <textarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        value={formData.notes}
                        onChange={handleChange}
                        required
                        rows={3}
                        placeholder="Enter notes here..."
                        spellCheck
                        autoFocus
                        style={{ resize: "vertical" }}
                        aria-label="Enter notes"
                     />
                  </div>

                  <div className="mb-3">
                     <label htmlFor="image" className="form-label">Image:</label>
                     <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="image"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageChange}
                     />
                  </div>
               </div>
               <div className='card-footer d-flex justify-content-around'>
                  <button disabled={nameError} type="submit" className="btn btn-sm btn-primary">Submit</button>
                  <button onClick={() => setUpdate(false)} className="btn btn-sm btn-danger">Cancel</button>
               </div>
            </form>
         </div>
      </div>
   );
};

export default UpdateFlights;
