import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { FlightContext } from '../context/FlightContext';

export default function AddFlightDestination() {
  const { addFlightDestination, flightDestinations } = useContext(FlightContext);
  const [isLocationTaken, setIsLocationTaken] = useState(false);

  function handleAddDestination() {
    // Create a function to check if the location already exists
    const checkLocationExists = (location) => {
      return flightDestinations.some(destination => destination.location.toLowerCase() === location.toLowerCase());
    };

    Swal.fire({
      title: "Add Flight Destination",
      html:
        '<input id="location" class="swal2-input" placeholder="Enter the Location">' +
        '<input id="country" class="swal2-input" placeholder="Enter the Country">',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      background: 'rgb(8, 50, 32,1)',
      toast: true,
      color: 'wheat',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      scrollbarPadding: false, // Remove the scrollbar
      preConfirm: () => {
        const location = document.getElementById('location').value;
        const country = document.getElementById('country').value;

        if (!location || !country) {
          Swal.showValidationMessage('Location and Country are required');
        } else if (checkLocationExists(location)) {
          setIsLocationTaken(true);
          Swal.showValidationMessage('This location already exists');
        } else {
          setIsLocationTaken(false);
          return { location: location, country: country };
        }
      },
      onOpen: () => {
        // Enable real-time validation on input change
        const locationInput = document.getElementById('location');
        locationInput.addEventListener('input', (e) => {
          const location = e.target.value;
          if (checkLocationExists(location)) {
            setIsLocationTaken(true);
            Swal.getConfirmButton().setAttribute('disabled', 'true');
          } else {
            setIsLocationTaken(false);
            Swal.getConfirmButton().removeAttribute('disabled');
          }
        });
      }
    }).then((result) => {
      if (result.isConfirmed && !isLocationTaken) {
        const { location, country } = result.value;
        addFlightDestination(location, country);
      }
    });
  }

  return (
    <button 
      style={{ right: '10%' }} 
      onClick={handleAddDestination}
      className='btn btn-outline-primary btn-sm'
    >
      Add location
    </button>
  );
}
