import ImageKit from 'imagekit-javascript';

// SDK initialization
var imagekit = new ImageKit({
    publicKey: "public_vC5y7kSrXiR+qOT5pMVaWRWOInM=",
    urlEndpoint: "https://ik.imagekit.io/4rfgtafuc0",
    authenticationEndpoint: "https://beshapp.backend.safarinetics.com/auth/imagekit" // Update this to your Flask server's auth endpoint
});

// Fetch authentication parameters from the server
async function getAuthParameters() {
    const response = await fetch('https://beshapp.backend.safarinetics.com/auth/imagekit'); // Update this to your Flask server's auth endpoint
    const authParameters = await response.json();
    return authParameters;
}

// Upload function internally uses the ImageKit.io javascript SDK
async function upload(file) {
    const authParameters = await getAuthParameters();
    const timestamp = new Date().toISOString().replace(/[:.-]/g, ''); // Generate a unique timestamp
    const uniqueFileName = `${file.name.split('.')[0]}_${timestamp}.${file.name.split('.').pop()}`; // Add timestamp to filename

    return new Promise((resolve, reject) => {
        imagekit.upload({
            file: file,
            fileName: uniqueFileName,
            token: authParameters.token,
            signature: authParameters.signature,
            expire: authParameters.expire
        }, function (err, result) {
            if (err) {
                reject(err);
            } else {
                resolve({ url: result.url, id: result.fileId });
            }
        });
    });
}

export async function uploadImage(file) {
    try {
        const result = await upload(file);
        return { url: result.url, id: result.id };
    } catch (error) {
        console.error("Error uploading image:", error);
        return { error: "Upload failed" };
    }
}


        // try {
        //     // console(file);
        //     const result = await uploadImage(file);
        //     // console(`results, ${result.url}, ${result.id}`);
        // } catch (error) {
        //     console.error("Error uploading image:", error);
        // }
