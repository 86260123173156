import React, { useContext, useState, useEffect } from 'react';
import { DestinationContext } from '../context/DestinationContext';

export default function UpdateDestination({ destination, setUpdate }) {
  const { updateDestination, destinations } = useContext(DestinationContext);
  const [name, setName] = useState(destination.name || '');
  const [location, setLocation] = useState(destination.location || '');
  const [description, setDescription] = useState(destination.description || '');
  const [adults, setAdults] = useState(destination?.rates?.adults || '');
  const [children, setChildren] = useState(destination?.rates?.children || '');

  const [nameError, setNameError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // If there's a validation error, do not proceed with the update
    if (nameError) return;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('location', location);
    formData.append('description', description);
    formData.append('adults', adults);
    formData.append('children', children);

    updateDestination(destination.id, formData);
    setUpdate(false);
  };

  useEffect(() => {
    const validateName = () => {
      // Check if the name exists in destinations array (case insensitive)
      const nameExists = destinations.some(
        (dest) => dest.name.toLowerCase() === name.toLowerCase() && dest.id !== destination.id
      );

      if (nameExists) {
        setNameError('Name already exists.');
      } else {
        setNameError('');
      }
    };

    validateName();
  }, [name, destinations, destination.id]);

  return (
    <div className="container-fluid justify-content-center row pt-3">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 className="text-center" style={{ color: 'wheat', fontFamily: 'junge', flex: '1' }}>Update Destination</h3>
        <button onClick={() => setUpdate(false)} className="btn me-5 btn-sm text-white btn-danger">X</button>
      </div>

      <form onSubmit={handleSubmit} className="bg-light bg-opacity-50 container col-md-10 row rounded p-3 mb-3">
        <div className="container-fluid row">
          <div className="mb-3 col-md-6">
            <label htmlFor="name" className="form-label">Name</label>
            <input 
              type="text" 
              className="form-control" 
              id="name" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
            {nameError && <small className="text-danger">{nameError}</small>}
          </div>

          <div className="mb-3 col-md-6">
            <label htmlFor="location" className="form-label">Location</label>
            <input 
              type="text" 
              className="form-control" 
              id="location" 
              value={location} 
              onChange={(e) => setLocation(e.target.value)} 
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea 
            className="form-control" 
            id="description" 
            rows="5" 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
          />
        </div>

        <div className="row mx-auto">
          <h5 className="card-header text-success">Rates</h5>
          <div className="mb-3 col-md-4">
            <label htmlFor="adults" className="form-label">Adults($):</label>
            <input
              type="number"
              min={1}
              className="form-control"
              id="adults"
              name="adults"
              value={adults}
              onChange={(e) => setAdults(e.target.value)}
            />
          </div>
          <div className="mb-3 col-md-4">
            <label htmlFor="children" className="form-label">Children($):</label>
            <input
              type="number"
              min={1}
              className="form-control"
              id="children"
              name="children"
              value={children}
              onChange={(e) => setChildren(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-primary">Update</button>
        </div>
      </form>
    </div>
  );
}
