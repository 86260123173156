import React, { useState } from 'react';
import Viewer from 'react-viewer';
import { motion } from 'framer-motion';
import ImageViewer from '../utils/ImageViewer'

export default function FlightDets({ flight }) {
  // const flight = packageInfo?.itinerary?.overview?.flight;
// console(flight)
  // ==================== IMAGES VIEWER =====================
  // const images=[image1,2,3,4 ...]
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);
  // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1); // Reset scale when opening modal
    setIsOpen(true);
  };

  // ==================== IMAGES VIEWER =====================

const [visible,setVisible] = useState(false)

  return (
    <div className="card-body border-0 shadow-sm rounded col-auto" style={{color: 'rgb(1, 50, 32,1)'}}>
    <hr className='my-4' style={{ color: 'white', borderWidth: '3px' }} />
      <h2 className="card-title text-center   mb-4" style={{color:'white', fontFamily:'junge'}}>Flight Information</h2>
      <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
        <div className="col">
              <div className="card border-success" style={{ backgroundColor: 'rgb(175, 238, 238,0.8)',width:'100%',height:"15em" }}>
                <motion.img
                  src={flight?.image}
                  className="card-img-top img-fluid"
                  style={{height:"100%"}}
                  alt={flight.category}
                  onClick={()=> openModal(currentImageIndex)}
                  initial={{ scale: 1.0 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                />
                  <ImageViewer 
                images={[flight?.image]}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
                <div className="card-footer" style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <h5 className="card-title text-center text-info" style={{ textShadow: '1px 1px 2px rgba(175, 238, 238, 0.31)' }}>{flight.flight_name}</h5>
                </div>
              </div>
              <div className='text-info'>
                <small>From: <span className="fw-semibold">{flight?.origin?.location}, {flight?.origin?.country}</span></small><br/>
                <small>To: <span className="fw-semibold">{flight?.destination?.location}, {flight?.destination?.country}</span></small>
                {/* <p>Time: <small className='text-light'>Will be communicated then</small></p> */}
              </div>
          
        </div>

        <div className="col-md-7 ">
          <div className="card text-white border-info shadow-md" style={{ backgroundColor: 'rgb(175, 238, 238,0)', height: '100%'}}>
            <div className="card-body">
              <h6 className="card-title ps-4 mb-1 fs-5">Note:</h6>
              
              <textarea
              value={flight?.notes}
              className='ps-2'
              readOnly
              style={{backgroundColor: 'rgb(1, 50, 32,0.01)',color:'wheat',width:'100%',height:'100%',border:'none'}}/>             
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
