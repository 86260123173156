import React, { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import { uploadImage } from '../images/ImageUploader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LoadingComponent from '../components/LoadingComponent';

const AddVehicle = () => {
  const { addVehicle, vehicleGuides, loading } = useContext(VehicleGuideContext);
  const [formData, setFormData] = useState({
    category: '',
    capacity: '',
    model: '',
    rates: '',
    plates: '',
    image: '',
    image_id: '',
    name: '',
    email: '',
    phone: ''
  });
  const [errors, setErrors] = useState({});

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'plates'){
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
    }else{
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  };

  useEffect(() => {
    const validationErrors = {};

    // Email validation
    if (formData?.email) {
      const emailExists = Array.isArray(vehicleGuides) && vehicleGuides.length > 0 && 
  vehicleGuides.some(vehicle => vehicle?.guide?.email?.toLowerCase() === formData?.email?.toLowerCase());
      if (emailExists) {
        validationErrors && (validationErrors.email = 'Email already exists');
      }
    }

    // Phone validation
    if (formData?.phone) {
      const phoneExists = Array.isArray(vehicleGuides) && vehicleGuides.length > 0 && 
  vehicleGuides.some(vehicle => vehicle?.guide?.phone === formData?.phone);
      // console(phoneExists,formData?.phone)
      if (phoneExists) {
        validationErrors && (validationErrors.phone = 'Phone number already exists')
      }
      const isValid = formData?.phone?.length > 9 && formData?.phone?.length < 17;
      if (!isValid) {
        validationErrors && (validationErrors.phone = 'Invalid Phone number')
      }
    }

    // Name validation
    if (formData?.name) {
      const namePattern = /^[A-Za-z]+(\s[A-Za-z]+)+$/;
      if (!namePattern.test(formData?.name?.trim())) {
        validationErrors && (validationErrors.name = 'Please Enter Your full Name')
      }

    }

    // Plates validation
    if (formData?.plates) {
      const platesExists = Array.isArray(vehicleGuides) && vehicleGuides.length > 0 && 
  vehicleGuides.some(vehicle => vehicle?.plates?.toUpperCase() === formData?.plates?.toUpperCase());
      if (platesExists) {
        validationErrors && (validationErrors.plates = 'Plates already exist')
      }
    }

    setErrors(validationErrors);
  }, [formData, vehicleGuides]);

  async function handleSubmit(e) {
    e.preventDefault();

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        text: 'Please fix the errors before submitting',
        showConfirmButton: false,
        timer: 4000,
        icon: 'warning',
        color: 'black',
        background: 'orange',
        toast: true,
        position: 'top'
      });
      return;
    }

    try {
      const result = await uploadImage(formData.image);
      formData.image = result.url;
      formData.image_id = result.id;

      addVehicle(formData);
      setFormData({
        category: '',
        capacity: '',
        model: '',
        rates: '',
        plates: '',
        image: '',
        image_id: '',
        name: '',
        email: '',
        phone: ''
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
  const handlePhoneChange = (value) => {

    setFormData((prevState) => ({
      ...prevState,
      ['phone']: `+${value}`,
    }));
    
    };

    if(loading){
      return(
        <LoadingComponent loadingText ={'Adding vehicle and guide ...'} />
      )
    }

  return (
    <div className="container justify-content-center mt-5">
      <div className="col-md-6 mx-auto">
        <div className="card p-4" style={{ backgroundColor: 'rgb(1,50,32,1)', borderRadius: '10px' }}>
          <h2 className="text-center mb-4" style={{ color: 'wheat', fontFamily: 'inknut antiqua' }}>Add Vehicle and Guide</h2>
          <form onSubmit={handleSubmit} className='text-white'>
            <h5 style={{ color: 'wheat' }}>Guide details</h5>
            <div className="mb-3">
              <label htmlFor="guide_name" className="form-label">Name:</label>
              <input
                type="text"
                className={`form-control ${errors?.name ? 'is-invalid' : ''}`}
                id="name"
                name="name"
                placeholder="John Doe"
                value={formData?.name}
                onChange={handleChange}
                required
              />
              {errors?.name && <div className="invalid-feedback">{errors?.name}</div>}
            </div>
              <div className="mb-3 ">
                <label htmlFor="guide_email" className="form-label">Email:</label>
                <input
                  type="email"
                  placeholder="john@example.com"
                  className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  value={formData?.email}
                  onChange={handleChange}
                  required
                />
                {errors?.email && <div className="invalid-feedback">{errors?.email}</div>}
              </div>
              <div className="mb-3 ">
                <label htmlFor="guide_phone" className="form-label">Phone:</label>
                <PhoneInput
                    country={'ke'}
                    required
                    value={formData?.phone}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: 'fit-content' }}
                    // buttonStyle={{ height: '56px' }}
                    dropdownStyle={{ color: '#000' }}
                    dropdownClass="custom-dropdown"
                    className={` ${errors?.phone ? 'is-invalid' : ''}`}
                  />
                {errors?.phone && <div className="invalid-feedback">{errors?.phone}</div>}
              </div>

            <h5 style={{ color: 'wheat' }}>Vehicle details</h5>
            <div className='container-fluid row'>
              <div className="mb-3 col-md-6">
                <label htmlFor="model" className="form-label">Model (Type):</label>
                <input
                  placeholder='Land Cruiser (4x4)'
                  type="text"
                  className={`form-control ${errors?.model ? 'is-invalid' : ''}`}
                  id="model"
                  name="model"
                  value={formData?.model}
                  onChange={handleChange}
                  required
                />
                {errors?.model && <div className="invalid-feedback">{errors?.model}</div>}
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="category" className="form-label">Category:</label>
                <select
                  className="form-control"
                  id="category"
                  name="category"
                  value={formData?.category}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select a category</option>
                  <option value="safari">Safari</option>
                  <option value="transfer">Transfer</option>
                </select>
              </div>
            </div>

            <div className='container-fluid row mb-4'>
              <div className="mb-3 col-md-4">
                <label htmlFor="capacity" className="form-label">Capacity:</label>
                <input
                  type="number"
                  placeholder="5"
                  className="form-control"
                  min={3}
                  id="capacity"
                  name="capacity"
                  value={formData?.capacity}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="rates" className="form-label">Rates/day ($):</label>
                <input
                  type="number"
                  placeholder="100"
                  className="form-control"
                  min={10}
                  id="rates"
                  name="rates"
                  value={formData?.rates}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 col-md-4">
                <label htmlFor="plates" className="form-label">Plates:</label>
                <input
                  type="text"
                  placeholder="KDA 123A"
                  className={`form-control ${errors?.plates ? 'is-invalid' : ''}`}
                  id="plates"
                  name="plates"
                  value={formData?.plates}
                  onChange={handleChange}
                  required
                />
                {errors?.plates && <div className="invalid-feedback">{errors?.plates}</div>}
              </div>

              <div className="mb-3">
                <label htmlFor="image" className="form-label">Image:</label>
                <input
                  type="file"
                  className="form-control"
                  id="image"
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange}
                  required
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVehicle;
