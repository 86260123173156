
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthProvider from './context/AuthContext';
import Layout from './layout/Layout';
import Home from './pages/Home';
import Inquiries from './inquiries/Inquiries';
import LandingPage from './components/Landing';
import Login from './pages/Login'
import ResetPassword from './components/ResetPassword';
import InquiryProvider from './context/InquiryContext';
import InquiryDetails from './inquiries/InquiryDetails'
import CreatePackage from './package/CreatePackage';
import ItineraryProvider from './context/ItineraryContext';
import AccommodationProvider from './context/AccommodationContext';
import VehicleGuideProvider from './context/VehicleGuideContext';
import ViewPackage from './package/ViewPackage';
import PackageProvider from './context/PackageContext';
import AddInquiry from './inquiries/AddInquiry';
import Packages from './package/Packages';
import UpdateInquiry from './inquiries/UpdateInquiry';
import Vehicles from './vehicles/Vehicles';
import AddVehicle from './vehicles/AddVehicle';
import VehicleDetails from './vehicles/VehicleDetails';
import UpdateVehicle from './vehicles/UpdateVehicle';
import Accommodations from './accommodation/Accommodations';
import AccommodationDetails from './accommodation/AccommodationDetails';
import AddAccommodation from './accommodation/AddAccommodation';
import DestinationProvider from './context/DestinationContext';
import Destinations from './destinations/Destinations';
import DestinationDetails from './destinations/DestinationDetails';
import AddDestination from './destinations/AddDestination';
import FlightProvider from './context/FlightContext';
import Unauthorised from './pages/UnauthorisedPage';
import Flights from './flights/Flights';
import AddFlights from './flights/AddFlights';
import Itineraries from './itineraries/Itineraries';
import MainItineraryDetails from './itineraries/MainItineraryDetails';
import AddMainItinerary from './itineraries/AddMainItinerary';
import Profile from './Users/Profile';
import UserProvider from './context/UserContext';
import TermsAndConditions from './pages/TermsAndConditions';
import FlightDetails from './flights/FlightDetails';
import ReceiptProvider from './context/ReceiptContext';
import Receipts from './receipts/Receipts';
import EmailSetup from './pages/EmailSetup';
import ChangePassword from './Users/ChangePassword';
import ResetChangePassword from './Users/ResetChangePassword';
import FAQs from './components/FAQs';
import PricesCalculationFormula from './components/PricesCalculationFormula';


export default function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
      <InquiryProvider>
        <ItineraryProvider>
          <PackageProvider>
          <AccommodationProvider>
            <VehicleGuideProvider>
              <DestinationProvider>
                <FlightProvider>
                  <UserProvider>
                    <ReceiptProvider>

      <Routes>
        <Route path= '/' element={<Layout/>}>
          <Route index element={<LandingPage/>} />
          <Route path='*' element={<Unauthorised/>} />

          <Route path='/profile' element={<Profile/>} />
          <Route path='/price' element={<PricesCalculationFormula/>} />
          
          <Route path='/terms' element={<TermsAndConditions/>} /> 
          <Route path='/faqs' element={<FAQs/>} /> 
          <Route path='/pages/email-setup' element={<EmailSetup/>} /> 


          <Route path='/inquiries' element={<Inquiries/>} />
          <Route path='/inquiries/:id' element={<InquiryDetails/>} />
          <Route path='/inquiries/add' element={<AddInquiry/>} />
          <Route path='/inquiries/:id/update' element={<UpdateInquiry/>} />

          <Route path='/home' element={<Home/>} />
          <Route path='/auth/login' element={<Login/>} />
          <Route path='/auth/password/reset' element={<ResetPassword/>} />
          <Route path='/auth/change-password' element={<ChangePassword/>} />
          <Route path='/auth/password/:email/change' element={<ResetChangePassword/>} /> 
          {/* /auth/password/support@beshafricanadventures.co.ke/change */}
          
          <Route path='/packages' element={<Packages/>} />
          <Route path='/create/:days/:inquiryId/package/:tour' element={<CreatePackage/>} />
          <Route path='package/:id/view/:client/:days/:tour' element={<ViewPackage/>} />

          <Route path='/vehicles' element={<Vehicles/>} />
          <Route path='/vehicles/add' element={<AddVehicle/>} />
          <Route path='/vehicles/:id/details' element={<VehicleDetails/>} />
          <Route path='/vehicles/:id/update' element={<UpdateVehicle/>} />

          <Route path='/accommodations' element={<Accommodations/>} />
          <Route path='/accommodations/add' element={<AddAccommodation/>} />
          <Route path='/accommodations/:id' element={<AccommodationDetails/>} />

          <Route path='/destinations' element={<Destinations/>} />
          <Route path='/destinations/add' element={<AddDestination/>} />
          <Route path='/destinations/:id' element={<DestinationDetails/>} />

          <Route path='/flights' element={<Flights/>} />
          <Route path='/flights/add' element={<AddFlights/>} />
          <Route path='/flights/:flight_id/details' element={<FlightDetails/>} />


          <Route path='/itineraries' element={<Itineraries/>} />
          <Route path='/itineraries/add' element={<AddMainItinerary/>} />
          <Route path='/itineraries/:id/:title' element={<MainItineraryDetails/>} />

          <Route path='/receipts' element={<Receipts/>} />



        </Route>
      </Routes>
      </ReceiptProvider>
      </UserProvider>
      </FlightProvider>
      </DestinationProvider>
      </VehicleGuideProvider>
      </AccommodationProvider>
      </PackageProvider>
      </ItineraryProvider>
      </InquiryProvider>
      </AuthProvider>
    
    </BrowserRouter>
  )
}

