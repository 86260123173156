import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import Swal from 'sweetalert2'

export const ReceiptContext = createContext()
export default function ReceiptProvider({children}) {
   const {loadingCounter, setLoadingCounter, authToken} = useContext(AuthContext)
   const [receipts, setReceipts] = useState([])
   const [onReceiptChange, setOnReceiptChange] = useState(false)
   const [loading,setLoading] = useState(false)


   // ************************ CREATE/ADD ************************
//function to add and send package_receipt
function addPackageReceipt(id,formData){
   fetch(`https://beshapp.backend.safarinetics.com/package/${id}/payment/receipt/add`, {
     method: 'POST',
     headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         Authorization: `Bearer ${authToken && authToken}`
     },
     body: JSON.stringify(formData)
 })
 .then((res) => res.json())
 .then((response) => {
   setOnReceiptChange(!onReceiptChange)
   setLoadingCounter('rec')
   if (response.success){
     Swal.fire({
       icon:'success',
       toast:true,
       text:response.success,
       timer:4000,
       showConfirmButton:false,
       color:'white',
       background:'green',
       position:'top-end'
     });
   
 }else if (response.error){
   Swal.fire({
     icon:'error',
     toast:true,
     text:response.error,
     timer:4000,
     showConfirmButton:false,
     color:'white',
     background:'red',
     position:'top-end'
   });
 }
 })
 .catch((error) => console.error(error))
 }


   // ************************ READ/GET ************************
   useEffect(() => {
    if (authToken && [11, 'rec'].includes(loadingCounter)) {
      setLoading(true);
  
      const timer = setTimeout(() => {
        fetch(`https://beshapp.backend.safarinetics.com/payment/receipts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: `Bearer ${authToken && authToken}`
          }
        })
        .then((res) => res.json())
        .then((response) => {
          if (!response.error) {
            setReceipts(response);
            setLoading(false);
            if (loadingCounter === 11) {
              setLoadingCounter(12);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
      }, 1000); // 1-second delay
  
      // Clean up the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  
    setLoading(false);
  }, [onReceiptChange, authToken, loadingCounter, setLoadingCounter]);
  


   // ************************ UPDATE/EDIT ************************
//function to update package_receipt
function updatePackageReceipt(receiptId,formData){
   setLoading(true)
   fetch(`https://beshapp.backend.safarinetics.com/payment/receipt/${receiptId}/update`, {
     method: 'PUT',
     headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         Authorization: `Bearer ${authToken && authToken}`
     },
     body: JSON.stringify(formData)
 })
 .then((res) => res.json())
 .then((response) => {
   setOnReceiptChange(!onReceiptChange)
   setLoadingCounter('rec')
   if (response.success){
      setLoading(false)
     Swal.fire({
       icon:'success',
       toast:true,
       text:response.success,
       timer:4000,
       showConfirmButton:false,
       color:'white',
       background:'green',
       position:'top-end'
     });
   
 }else if (response.error){
   Swal.fire({
     icon:'error',
     toast:true,
     text:response.error,
     timer:4000,
     showConfirmButton:false,
     color:'white',
     background:'red',
     position:'top-end'
   });
 }
 setLoading(false)
 })
 .catch((error) => console.error(error))
 }



   // ************************ DELETE ************************
//function to send package_receipt
function deletePackageReceipt(receiptId){
   setLoading(true)
   fetch(`https://beshapp.backend.safarinetics.com/payment/receipt/${receiptId}/delete`, {
     method: 'DELETE',
     headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         Authorization: `Bearer ${authToken && authToken}`
     }
 })
 .then((res) => res.json())
 .then((response) => {
   setOnReceiptChange(!onReceiptChange)
   setLoadingCounter('rec')
   if (response.success){
      setLoading(false)
     Swal.fire({
       icon:'success',
       toast:true,
       text:response.success,
       timer:4000,
       showConfirmButton:false,
       color:'white',
       background:'green',
       position:'top-end'
     });
   
 }else if (response.error){
   Swal.fire({
     icon:'error',
     toast:true,
     text:response.error,
     timer:4000,
     showConfirmButton:false,
     color:'white',
     background:'red',
     position:'top-end'
   });
 }
 setLoading(false)
 })
 .catch((error) => console.error(error))
 }


   const contextData = {
      addPackageReceipt,
      receipts, setReceipts,
      loading,setLoading,
      updatePackageReceipt,
      deletePackageReceipt,
      onReceiptChange, setOnReceiptChange,
      

   }
  return (
   <ReceiptContext.Provider value={contextData}>
      {children}
   </ReceiptContext.Provider>
  )
}
