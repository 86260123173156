import React, { useContext, useState, useEffect } from 'react';
import { PackageContext } from '../context/PackageContext';
import { AccommodationContext } from '../context/AccommodationContext';
import { DestinationContext } from '../context/DestinationContext';
import { FlightContext } from '../context/FlightContext';
import { VehicleGuideContext } from '../context/VehicleGuideContext';
import { motion } from 'framer-motion';
import PricesCalculationFormula from '../components/PricesCalculationFormula';

export default function AddPrices({packageInfo , triggerPricesUpdate, setTriggerPricesUpdate}) {
  const {accommodations} = useContext(AccommodationContext)
  const {destinations} = useContext(DestinationContext)
  const {flights,routes} = useContext(FlightContext)
  const {vehicleGuides} = useContext(VehicleGuideContext)
  const {updatePrices} = useContext(PackageContext)
  const prices = packageInfo?.prices

// State variables to hold form data
const [parkAdults, setParkAdults] = useState(prices?.parkAdults || 0);
const [parkChildren, setParkChildren] = useState(prices?.parkChildren || 0);
const [hotelAdults, setHotelAdults] = useState(prices?.hotelAdults || 0);
const [hotelAdultsSharing, SetHotelAdultsSharing] = useState(prices?.hotelAdultsSharing || 0);
const [hotelChildren, setHotelChildren] = useState(prices?.hotelChildren || 0);
const [transport, setTransport] = useState(prices?.transport || 0);
const [commission, setCommission] = useState(prices?.commission || 0);
const currency= (prices?.currency||'')
const [transferDays, setTransferDays] = useState(prices?.transfer_days || 0)
const [safariDays, setSafariDays] = useState(prices?.safari_days )

const inquiry = packageInfo?.inquiry

 // Calculate totals based on conditions
 const adultsTotal = inquiry?.adults > 0 ? ((parkAdults + hotelAdults + transport) + ((commission / 100) * (parkAdults + hotelAdults + transport))).toFixed(2) : 0;
 const adultsSharingTotal = inquiry?.adults_sharing > 0 ? ((parkAdults + hotelAdultsSharing + transport) + ((commission / 100) * (parkAdults + hotelAdultsSharing + transport))).toFixed(2) : 0;
 const childrenTotal = inquiry?.children > 0 ? ((parkChildren + hotelChildren + transport) + ((commission / 100) * (parkChildren + hotelChildren + transport))).toFixed(2) : 0;

 const grandTotal = (((parseFloat(adultsTotal) * parseInt(inquiry?.adults || 0)) || 0) + 
                    ((parseFloat(adultsSharingTotal) * parseInt(inquiry?.adults_sharing || 0)) || 0) + 
                    ((parseFloat(childrenTotal) * parseInt(inquiry?.children || 0)) || 0)).toFixed(2);



useEffect(() => {
if(triggerPricesUpdate){

  handleSubmit()
  setTriggerPricesUpdate(false)
}

}, [triggerPricesUpdate,setTriggerPricesUpdate,handleSubmit ])


  // Function to handle form submission
  function handleSubmit(){
  // e.preventDefault();
  if(adultsTotal !==0 ){

    // Create an object with form data
    const formData = {
      parkAdults,
      parkChildren,
      hotelAdults,
      hotelAdultsSharing,
      hotelChildren,
      transport:parseFloat(transport),
      commission,
      adultsTotal: parseFloat(adultsTotal),
      adultsSharingTotal: parseFloat(adultsSharingTotal),
      childrenTotal: parseFloat(childrenTotal),
      currency: currency,
      safariDays,
      transferDays
    };
// console(packageInfo.id)
    updatePrices(packageInfo.id, formData)
    // setUpdatePackage(false)
  };}
  // console(prices)
  useEffect(() => {
    let totalAdultRates = 0;
    let totalAdultsSharingRates = 0;
    let totalChildrenRates = 0;
    let totalDestinationAdults = 0;
    let totalDestinationChildren = 0;
    let totalVehicles = 0;
    let totalTransfers = 0;
    let totalFlights = 0;

    packageInfo?.itinerary?.details.forEach(i => {
      // console(i)
      const matchedAccommodation =  accommodations && accommodations.length >0 && accommodations.find(acc => acc.accommodation === i?.accommodation?.name);
      if (matchedAccommodation) {
        totalAdultRates += matchedAccommodation?.rates?.adult_rates
        totalAdultsSharingRates += matchedAccommodation?.rates?.adults_sharing_rates
        totalChildrenRates += matchedAccommodation?.rates?.children_rates
      }

      const matchedDestination =  destinations && destinations.length >0 && destinations.find(des => des.name === i?.destination?.name);
      
      if (matchedDestination) {
        totalDestinationAdults += matchedDestination?.rates?.adults
        totalDestinationChildren += matchedDestination?.rates?.children
      }

      // console(i)
      const matchedRoute =  routes && routes.length >0 && routes.find(route => route.id === i?.flight?.id);
              
          if(matchedRoute){
        totalFlights += matchedRoute.rates
          }
      

  // console(totalFlights)
      
    
    });
    // set transport 
    const vehicles = packageInfo?.itinerary?.overview?.vehicles 
    vehicles.forEach(j => {
      const matchedVehicle = vehicleGuides && vehicleGuides.find(veh => veh.id === j?.id)

      if (matchedVehicle?.category === 'Safari'){
        // setSafariDays(prompt('days for numberSafaries'))
        totalVehicles += ((matchedVehicle?.rates)* (safariDays))/(packageInfo?.inquiry?.adults + packageInfo?.inquiry?.children + packageInfo?.inquiry?.adults_sharing)
        
      } else if (matchedVehicle?.category === 'Transfer'){
        totalTransfers += ((matchedVehicle?.rates)* (transferDays))/(packageInfo?.inquiry?.adults + packageInfo?.inquiry?.children + packageInfo?.inquiry?.adults_sharing)
      }
     
      
    })



    // console('v',totalVehicles)
    // console(totalTransfers)
    // Updating the variables 
   setHotelAdults(totalAdultRates)
   SetHotelAdultsSharing((packageInfo?.inquiry.adults_sharing !== 0 && packageInfo?.inquiry.adults_sharing!== "") ? totalAdultsSharingRates : 0)
   setHotelChildren((packageInfo?.inquiry.children !== 0 && packageInfo?.inquiry.children !== "") ? totalChildrenRates :0 )

   setParkAdults(totalDestinationAdults)
   setParkChildren((packageInfo?.inquiry.children !== 0 && packageInfo?.inquiry.children !== "") ? totalDestinationChildren : 0)

  // console(totalFlights)
   setTransport(totalFlights + totalTransfers + totalVehicles)
  
  }, [packageInfo, accommodations, destinations, routes,vehicleGuides,flights,transferDays,safariDays,commission,transport]);
  


  return (
    <div className="container mt-2">
      <div className="row justify-content-center">
        {/* <div className=""> */}
        <h2 className="text-center fw-bold mb-2" style={{ color: 'wheat', fontFamily: 'junge' }}> Prices Updates</h2>
          <div className="card col-md-12 shadow-lg" style={{backgroundColor: 'rgb(175, 238, 238,0.4)',overflowX:'auto'}}>

            {/* start here */}
            <div className="container pt-3">
              <motion.div
                style={{ maxWidth: '90%', margin: 'auto' }}
                className="mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                  <table
                        className="table table-bordered rounded px-4"
                        style={{
                          backgroundColor: 'rgb(8, 50, 32,1)',
                          color: '#333',
                          borderColor: 'lightblue',
                          fontSize: 'calc(0.875rem + 0.2vw)', // Dynamic font size for table
                        }}
                      >
                    <thead>
                      <tr className='text-center'>
                        <th>
                          <h6 style={{ color: 'rgb(175, 238, 238)',fontFamily:'fantasy', fontSize: 'calc(1.25rem + 0.3vw)' }}>No. Vehicle Days</h6>
                        </th>
                        <th>
                          <h4 style={{ color:'rgb(175, 238, 238)',fontFamily:'fantasy', fontSize: 'calc(1.25rem + 0.3vw)', padding: '0.375rem 0.75rem' }}>Commission</h4>
                        </th>
                        <th>
                          <h4 style={{ color:'rgb(175, 238, 238)',fontFamily:'fantasy', fontSize: 'calc(1.475rem + 0.3vw)', padding: '0.375rem 0.75rem' }}>Grand Total</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-center'>
                        <td>
                          <table
                            className="table table-bordered rounded p-4"
                            style={{
                              backgroundColor: 'rgb(0,0,0,0)',
                              color: 'lightblue',
                              borderColor: '',
                              fontSize: 'calc(0.875rem + 0.2vw)', // Dynamic font size for table
                            }}
                          >
                            <thead>
                              <tr className='text-center'>
                              <th>safari</th>
                              <th>Transfer</th>
                              </tr>
                            </thead>
                            <tbody  className='text-center'>
                              <tr>
                                <td >
                                  <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    id="formSafariDays"
                                    name="safariDays"
                                    value={safariDays}
                                    onChange={(e) => setSafariDays(e.target.value)}
                                    placeholder="Enter number of safari days"
                                    min="0"
                                    max={inquiry?.days}
                                    style={{
                                    width: '80px', // Adjust width to fit 4 characters
                                    fontSize: 'calc(0.875rem + 0.2vw)',
                                    padding: '0.375rem 0.75rem',
                                    // marginRight: '5px' // Space between input and percentage sign
                                  }}

                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    id="formTransferDays"
                                    name="transferDays"
                                    value={transferDays}
                                    onChange={(e) => setTransferDays(e.target.value)}
                                    placeholder="Enter number of travel days"
                                    min="0"
                                    max={inquiry?.days}
                                    style={{
                                    width: '80px', // Adjust width to fit 4 characters
                                    fontSize: 'calc(0.875rem + 0.2vw)',
                                    padding: '0.375rem 0.75rem',
                                    // marginRight: '5px' // Space between input and percentage sign
                                  }}
                                  />

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan="3" style={{color:'white', fontSize: 'calc(0.875rem + 0.2vw)', display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                          <input
                            type="number"
                            className="form-control form-control-sm "
                            id="formCommission"
                            name="commission"
                            value={commission}
                            onChange={(e) => setCommission(e.target.value)}
                            placeholder="Enter commission percentage"
                            min="1"
                            max="100"
                            style={{
                              width: '80px', // Adjust width to fit 4 characters
                              fontSize: 'calc(0.875rem + 0.2vw)',
                              padding: '0.375rem 0.75rem',
                              marginRight: '5px' // Space between input and percentage sign
                            }}
                            
                          />
                          %
                        </td>
                        <td className='text-center m-auto align-items-center'>
                          <h4 className='fw-bold m-auto' style={{ fontFamily:'fantasy',color:'orangered',fontSize: 'calc(0.875rem + 0.5vw)', padding: '0.375rem 0.75rem' }}>{grandTotal?.toLocaleString()} $</h4>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <small className='text-dark'><strong>NOTE:</strong>The commission is adjustable in the Price Rates Breakdown table</small>

                  <h5 style={{color:'black',fontFamily:'monospace'}} className='fw-bold text-center mt-4'>Price Rates breakdown</h5>
                  <table
                    className="table table-bordered  mt-1"
                    style={{
                      backgroundColor: '#fff',
                      color: '#333',
                      borderColor: '#003d00',
                      fontSize: 'calc(0.875rem + 0.2vw)', // Dynamic font size for table
                    }}
                  >
                    <thead className="thead-dark" style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: '#fff' }}>
                      <tr className='text-center'>
                        <th scope="col">Fee</th>
                        <th scope="col">Adults</th>
                        <th scope="col">Adults Sharing</th>
                        <th scope="col">Children</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr style={{ backgroundColor: '#f4f4f4' }}>
                        <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Accommodations</th>
                        <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{hotelAdults?.toLocaleString()} $</td>
                        <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{hotelAdultsSharing?.toLocaleString()} $</td>
                        <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{hotelChildren?.toLocaleString()} $</td>
                      </tr>
                      <tr style={{ backgroundColor: '#fff' }}>
                        <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Destination(Site)</th>
                        <td colSpan="2" style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{parkAdults?.toLocaleString()} $</td>
                        <td style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{parkChildren?.toLocaleString()} $</td>
                      </tr>
                      <tr style={{ backgroundColor: '#f4f4f4' }}>
                        <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Transportation</th>
                        <td colSpan="3" style={{ fontSize: 'calc(0.875rem + 0.2vw)' }}>{transport?.toLocaleString()} $</td>
                      </tr>
                      <tr className='text-center' style={{ backgroundColor: '#fff' }}>
                        <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>Commission</th>
                        <td colSpan="3" style={{ fontSize: 'calc(0.875rem + 0.2vw)', display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                          <input
                            type="number"
                            className="form-control form-control-sm bg-info"
                            id="formCommission"
                            name="commission"
                            value={commission}
                            onChange={(e) => setCommission(e.target.value)}
                            placeholder="Enter commission percentage"
                            min="1"
                            max="100"
                            style={{
                              width: '80px', // Adjust width to fit 4 characters
                              fontSize: 'calc(0.875rem + 0.2vw)',
                              padding: '0.375rem 0.75rem',
                              marginRight: '5px' // Space between input and percentage sign
                            }}
                            
                          />
                          %
                        </td>
                      </tr>

                      <tr style={{ backgroundColor: '#f4f4f4' }}>
                        <th scope="row" style={{ backgroundColor: '#e0f2f1' }}>@ Total</th>
                        <td style={{ fontFamily:'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{adultsTotal?.toLocaleString()} $</td>
                        <td style={{ fontFamily:'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{adultsSharingTotal?.toLocaleString()} $</td>
                        <td style={{ fontFamily:'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{childrenTotal?.toLocaleString()} $</td>
                      </tr>
                      <tr style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: 'lightblue' }}>
                        <th scope="row">Grand Total</th>
                        <td colSpan="3" className='fs-4' style={{ color:'orangered',fontFamily:'cursive', fontSize: 'calc(0.875rem + 0.2vw)' }}>{grandTotal?.toLocaleString()} $</td>
                      </tr>
                    </tbody>
                  </table>

                  <details className='p-4 rounded border border-success' style={{backgroundColor:'lightblue', width:'100%',color:'rgb(8, 50, 32,1)'}}>
                    <summary>Formula info</summary>
                    <PricesCalculationFormula/>
                  </details>

              </motion.div>
            </div>
                    {/* end here  */}
                    
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}
