import React, { createContext, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { uploadImage } from '../images/ImageUploader';

export const DestinationContext = createContext();

export default function DestinationProvider({ children }) {
   const navigate = useNavigate()

  const {loadingCounter, setLoadingCounter, authToken } = useContext(AuthContext);
  const [destinations, setDestinations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [onDesChange, setOnDesChange] = useState(false);
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  //details
  const [details, setDetails] = useState(() => {
    const savedDetails = localStorage.getItem('details');
    return savedDetails ? JSON.parse(savedDetails) : {};
  });
  
  useEffect(() => {
    localStorage.setItem('details', JSON.stringify(details));
  }, [details]);
  
    // const [rates, setRates] = useState({})
    //rates
    const [rates, setRates] = useState(() => {
      const savedRates = localStorage.getItem('rates');
      return savedRates ? JSON.parse(savedRates) : {};
    });
    
    useEffect(() => {
      localStorage.setItem('rates', JSON.stringify(rates));
    }, [rates]);
  
  
    // const [pictures,setPictures] = useState([])
      //pictures
      const [pictures, setPictures] = useState(() => {
        const savedPictures = localStorage.getItem('pictures');
        return savedPictures ? JSON.parse(savedPictures) : [];
      });
      
      useEffect(() => {
        localStorage.setItem('pictures', JSON.stringify(pictures));
      }, [pictures]);
  

    //  function to add destination 
    async function addDestination() {
      setLoading(true)
      if (!details) {
          Swal.fire({
              icon: 'info',
              title: 'Add destination details',
              toast: true,
              showConfirmButton: false,
              color: 'white',
              background: 'lightblue',
              timer: 4000,
              position: 'top'
          });
          return;
      } else if (!rates) {
          Swal.fire({
              icon: 'info',
              title: 'Add destination rates',
              toast: true,
              showConfirmButton: false,
              color: 'white',
              background: 'lightblue',
              timer: 4000,
              position: 'top'
          });
          return;
      } else if (!pictures || pictures.length === 0) {
          Swal.fire({
              icon: 'info',
              title: 'Add at least 1 picture',
              toast: true,
              showConfirmButton: false,
              color: 'white',
              background: 'lightblue',
              timer: 4000,
              position: 'top'
          });
          return;
      }
  
      const images = [];
  
      for (const picture of pictures) {
          try {
              const result = await uploadImage(picture.image);
              images.push({ image: result.url, image_id: result.id, title: picture.title });
          } catch (error) {
              console.error("Error uploading image:", error);
              // Optionally show an alert here if needed
          }
      }
  
      const formData = new FormData();
      formData.append('details', JSON.stringify(details));
      formData.append('rates', JSON.stringify(rates));
      formData.append('pictures', JSON.stringify(images));
  
      try {
          const response = await fetch(`https://beshapp.backend.safarinetics.com/destinations/add`, {
              method: "POST",
              headers: {
                  Authorization: `Bearer ${authToken}`
              },
              body: formData
          });
  
          const result = await response.json();
  
          if (result.success) {
              Swal.fire({
                  icon: 'success',
                  toast: true,
                  text: result.success,
                  timer: 4000,
                  showConfirmButton: false,
                  color: 'white',
                  background: 'green',
                  position: 'top-end'
              });
              setOnDesChange(!onDesChange);
              setLoadingCounter('des');
              navigate(`/destinations/${result?.id}`);
  
              // Resetting state
              setDetails({});
              setRates({});
              setPictures([]);
              localStorage.removeItem('details');
              localStorage.removeItem('rates');
              localStorage.removeItem('pictures');
          } else {
              Swal.fire({
                  icon: 'error',
                  toast: true,
                  text: result.error,
                  timer: 4000,
                  showConfirmButton: false,
                  color: 'white',
                  background: 'red',
                  position: 'top-end'
              });
          }
      } catch (error) {
          console.error("Error during fetch:", error);
          Swal.fire({
              icon: 'error',
              title: 'Fetch error',
              text: 'There was an error while adding the destination.',
              color: 'white',
              background: 'red',
              timer: 4000,
              showConfirmButton: false,
              position: 'top-end'
          });
      }finally{
        setLoading(false)
      }
  }


  //================================ GET/FETCH ==============================

  // +++++++++++++++= GET ALL DESTINATIONS ++++++++++++++++++++++
  useEffect(() => {
    if (authToken && searchTerm === '' && [6, 'des'].includes(loadingCounter)) {
      const timer = setTimeout(() => {
        fetch('https://beshapp.backend.safarinetics.com/destinations', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        })
        .then(res => res.json())
        .then(response => {
          setLoadingCounter(7);
          if (!response.error) {
            setDestinations(response);
            loadingCounter === 6 ? setLoadingCounter(7) : setLoadingCounter(null);
          }
        })
        .catch((error) => console.error(error));
      }, 1000); // 1-second delay
  
      // Clean up the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  }, [onDesChange, authToken, searchTerm, loadingCounter, setLoadingCounter]);

  
  // +++++++++++++++++ SEARCH BY NAME AND LOCATION ++++++++++++++++++++

  useEffect(() => {
    if (authToken && searchTerm !== '') {
      fetch(`https://beshapp.backend.safarinetics.com/destinations/search/${searchTerm}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      .then(res => res.json())
      .then(response => {
        setSearchResults(response);
      })
      .catch((error) => console.error(error));
    }
  }, [authToken, searchTerm, setDestinations]);



  

  //  function to update destination 
  function updateDestination(id, updatedDestination){
   fetch(`https://beshapp.backend.safarinetics.com/destinations/update/${id}`, {
     method: "PUT",
     headers: {
       Authorization: `Bearer ${authToken && authToken}`
     },
     body: updatedDestination
   })
   .then((res) => res.json())
   .then(response => {
     if(response.success){
       setOnDesChange(!onDesChange)
       setLoadingCounter('des')
       Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
     }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
   })
   .catch((error) => console.error(error))
 }


 //  function to delete destination 
 function deleteDestination(id){
   fetch(`https://beshapp.backend.safarinetics.com/destinations/delete/${id}`, {
     method: "DELETE",
     headers: {
       Accept: "application/json",
       'Content-Type': 'application/json',
       Authorization: `Bearer ${authToken && authToken}`
     }
   })
   .then((res) => res.json())
   .then(response => {
     if(response.success){
       setOnDesChange(!onDesChange)
       setLoadingCounter('des')
       navigate('/destinations')
       Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
     }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
     }
   })
   .catch((error) => console.error(error))
 }


 //DESTINATION PICTURES

 //  function to add a single Destination picture 
 function addSinglePicture(id,addedPicture){
  fetch(`https://beshapp.backend.safarinetics.com/destination/pictures/add/${id}`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify(addedPicture)
  })
  .then((res) => res.json())
  .then(response => {
    if(response.success){
      setOnDesChange(!onDesChange)
      setLoadingCounter('des')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
  })
  .catch((error) => console.error(error))
}

 //  function to update destination picture 
 function updatePicture(id,updatedPicture){
  fetch(`https://beshapp.backend.safarinetics.com/destination/pictures/update/${id}`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken && authToken}`
    },
    body: JSON.stringify(updatedPicture)
  })
  .then((res) => res.json())
  .then(response => {
    if(response.success){
      setOnDesChange(!onDesChange)
      setLoadingCounter('des')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
  })
  .catch((error) => console.error(error))
}


//  function to delete a picture 
function deletePicture(id){
  fetch(`https://beshapp.backend.safarinetics.com/destination/pictures/delete/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken && authToken}`
    }
  })
  .then((res) => res.json())
  .then(response => {
    if(response.success){
      setOnDesChange(!onDesChange)
      setLoadingCounter('des')
      Swal.fire({
        icon:'success',
        toast:true,
        text:response.success,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'green',
        position:'top-end'
      });
    }else{
      Swal.fire({
        icon:'error',
        toast:true,
        text:response.error,
        timer:4000,
        showConfirmButton:false,
        color:'white',
        background:'red',
        position:'top-end'
      });
    }
  })
  .catch((error) => console.error(error))
}



  const contextData = {
    destinations,
    searchTerm,
    setSearchTerm,
    onDesChange,
    setOnDesChange,
    updateDestination,
    deleteDestination,
    addDestination,
    details,setDetails,
    rates, setRates,
    pictures, setPictures,
    addSinglePicture,updatePicture,deletePicture,
    loading, setLoading,
    searchResults,
  };

  return (
    <DestinationContext.Provider value={contextData}>
      {children}
    </DestinationContext.Provider>
  );
}
