import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

export const InquiryContext = createContext()

export default function InquiryProvider({children}) {

   const {loadingCounter, setLoadingCounter,authToken, loggedIn} = useContext(AuthContext)
   
   const [inquiries, setInquiries] = useState([])
   const [onchange, setOnchange] = useState(false)
   const [searchTerm, setSearchTerm] = useState('')
   const [loading, setLoading] = useState(false)
   const navigate = useNavigate()
   const [onsend,setOnsend] = useState(false)

  const [searchResults, setSearchResults] = useState([]);

   // fetch all  inquiries && search

   useEffect(() => {
    if (authToken && searchTerm === '' && [2, 'inq'].includes(loadingCounter)) {
      setLoading(true);
  
      // Add a 1-second delay before starting the fetch
      const timer = setTimeout(() => {
        fetch('https://beshapp.backend.safarinetics.com/inquiries', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
          .then((res) => res.json())
          .then((response) => {
            if (!response.error) {
              setInquiries(response);
              loadingCounter === 2 ? setLoadingCounter(3) : setLoadingCounter(null)
            }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }, 1000); // 1-second delay
  
      // Cleanup timer on unmount or before running effect again
      return () => clearTimeout(timer);
    }
  }, [onchange, searchTerm, authToken, onsend, loadingCounter, setLoadingCounter]);

  
useEffect(() => {
  if (authToken && searchTerm !== '') {
    setLoading(true);
    fetch(`https://beshapp.backend.safarinetics.com/search/${searchTerm}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setSearchResults(response);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  } else {
    setSearchResults([]); // Clear search results when searchTerm is empty
  }
}, [authToken, searchTerm]);

// update view
function updateView(id){
   fetch(`https://beshapp.backend.safarinetics.com/viewed/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken && authToken}`
      }
    })
      .then((res) => res.json())
      .then((response) => {
      setOnchange(!onchange)
      setLoadingCounter('inq')
      })
      .catch((error) => console.error(error))

}

//adding an inquiry
async function AddInquiry(formData) {
  setLoading(true); // Start loading

  try {
    const response = await fetch('https://beshapp.backend.safarinetics.com/inquiries/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    const result = await response.json();

    if (result.success) {
      setOnchange(prev => !prev);
      setLoadingCounter('inq');
      Swal.fire({
        icon: 'success',
        toast: true,
        text: 'Inquiry Added',
        timer: 4000,
        showConfirmButton: false,
        color: 'white',
        background: 'green',
        position: 'top-end',
      });

      if (loggedIn) {
        navigate('/inquiries');
      }
    } else {
      Swal.fire({
        icon: 'error',
        toast: true,
        text: result.error,
        timer: 4000,
        showConfirmButton: false,
        color: 'white',
        background: 'red',
        position: 'top-end',
      });
    }
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: 'error',
      toast: true,
      text: 'An unexpected error occurred.',
      timer: 4000,
      showConfirmButton: false,
      color: 'white',
      background: 'red',
      position: 'top-end',
    });
  } finally {
    setLoading(false); // End loading
  }
}


//updating an inquiry
function updateInquiry(id,formData){
  fetch(`https://beshapp.backend.safarinetics.com/update_inquiry/${id}`, {
     method: 'PUT',
     headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: `Bearer ${authToken && authToken}`
    },
     body:JSON.stringify(formData)
   })
     .then((res) => res.json())
     .then((response) => {
        if (response.success){
           setOnchange(!onchange)
           setLoadingCounter('inq')
           Swal.fire({
            icon:'success',
            toast:true,
            text:response.success,
            timer:4000,
            showConfirmButton:false,
            color:'white',
            background:'green',
            position:'top-end'
          });
           navigate(`/inquiries/${id}`) 
         }else{
          Swal.fire({
            icon:'error',
            toast:true,
            text:response.error,
            timer:4000,
            showConfirmButton:false,
            color:'white',
            background:'red',
            position:'top-end'
          });
          }
     })
     .catch((error) => console.error(error))
    

}




   const contextData = {
      inquiries,
      updateView,
      searchTerm, setSearchTerm,
      loading,AddInquiry,updateInquiry,
      setOnsend,
      searchResults,
      setSearchResults,

   }

  return (
   < InquiryContext.Provider value={contextData} >
      {children}
   </InquiryContext.Provider>
  )
}
