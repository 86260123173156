import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { ReceiptContext } from '../context/ReceiptContext';

export default function AddReceipt({ packageInfo }) {
  const { addPackageReceipt } = useContext(ReceiptContext);

  function sendReceipt(id) {
    Swal.fire({
      title: 'Add Package Receipt',
      html:
        '<input id="amount" type="number" class="swal2-input" placeholder="Enter the Amount Received">' +
        '<input id="receiptDate" type="date" class="swal2-input" placeholder="Enter the Receipt Date">',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      background: 'rgb(8, 50, 32,1)',
      toast: true,
      color: 'wheat',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      scrollbarPadding: false, // Remove the scrollbar
      preConfirm: () => {
        const amount = document.getElementById('amount').value;
        const receiptDate = document.getElementById('receiptDate').value;
        // Validate inputs
        if (!amount || !receiptDate) {
          Swal.showValidationMessage('Amount and Receipt Date are required');
        }

        // Validate receiptDate
        const today = new Date().toISOString().split('T')[0];
        if (receiptDate > today) {
          Swal.showValidationMessage('Receipt Date cannot be a future date');
        }

        return { amount: amount, receiptDate: receiptDate };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { amount, receiptDate } = result.value;
        // Perform further actions, like submitting the receipt data
        addPackageReceipt(id, { amount, receiptDate });
      }
    });
  }

  return (
    <li>
      <button
        onClick={() => sendReceipt(packageInfo && packageInfo.id)}
        className='dropdown-item fw-semibold'
        style={{ color: 'forestgreen' }}
        type='button'
      >
        Receipt
      </button>
    </li>
  );
}
