import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ItineraryContext } from '../context/ItineraryContext';

export default function ActionButtons({ id, inquiry }) {
  const { checkPackageExists } = useContext(ItineraryContext);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function handleViewPackage(inquiry) {
    if (id && inquiry.client && inquiry.client.name && inquiry?.tour && inquiry?.days) {
      navigate(`/package/${id}/view/${inquiry?.client?.name}/${inquiry?.days}/${inquiry?.tour}`);
    }
  }

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  return (
    <div className="card-footer d-flex flex-wrap justify-content-around">
      <div className="btn-group d-none d-md-inline-flex">
        {!inquiry?.package_exists && (
        <button onClick={() => checkPackageExists(id, inquiry?.days, inquiry?.tour, inquiry?.client?.name)} className="m-3 btn btn-success">
          Create Package
        </button>
        )}
        {!inquiry?.package_exists && (
        <Link to="update" className="m-3 btn btn-success">
          Update Inquiry
        </Link>
        )}
        {inquiry?.package_exists && (
        <button onClick={() => handleViewPackage(inquiry)} className="m-3 btn btn-success">
          View Package
        </button>
        )}
      </div>
      <div className="dropdown d-md-none dropleft mb-5 pb-2">
        <button
          className="btn btn-primary dropdown-toggle"
          type="button"
          onClick={toggleDropdown}
          aria-haspopup="true"
          aria-expanded={dropdownOpen ? 'true' : 'false'}
        >
          Actions
        </button>
        <div className={`dropdown-menu${dropdownOpen ? ' show' : ''} bg-info bg-opacity-50`} aria-labelledby="dropdownMenuButton">
        {!inquiry?.package_exists && (
          <a className="dropdown-item" onClick={() => checkPackageExists(id, inquiry?.days, inquiry?.tour, inquiry?.client?.name)}>
            Create Package
          </a>
          )}
          {!inquiry?.package_exists && (
          <Link className="dropdown-item" to="update">
            Update Inquiry
          </Link>
          )}
          {inquiry?.package_exists && (
          <a className="dropdown-item" onClick={() => handleViewPackage(inquiry)}>
            View Package
          </a>
          )}
        </div>
      </div>
    </div>
  );
}
