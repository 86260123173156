import React, { useContext, useState } from 'react';
import { AccommodationContext } from '../context/AccommodationContext';
import { uploadImage } from '../images/ImageUploader';
import LoadingComponent from '../components/LoadingComponent';

export default function AddSinglePicture({ accommodation, setAddPicture }) {
  const { addSinglePicture } = useContext(AccommodationContext);
  const [image, setImage] = useState(null);
  const [caption, setCaption] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)

  // Function to validate if the caption already exists
  const validateCaption = (newCaption) => {
    return accommodation?.pictures.some(picture =>
      picture.caption.toLowerCase() === newCaption.toLowerCase()
    )
      ? 'This caption already exists.'
      : '';
  };

  // Handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Handle add picture
  async function handleAdd(e) {
    e.preventDefault();
    setLoading(true)

    // Validate the caption before proceeding
    const captionError = validateCaption(caption);
    if (captionError) {
      setError(captionError);
      return;
    }

    const formData = new FormData();
    formData.append('caption', caption);

    try {
      const result = await uploadImage(image);
      formData.append('image', result.url);
      formData.append('image_id', result.id);

      // Call addSinglePicture only after successfully uploading the image
      await addSinglePicture(accommodation.id, formData);

      // Resetting state after successful addition
      setImage(null);
      setCaption('');
      setError('');
    } catch (error) {
      console.error("Error uploading image:", error);
      // Optionally show an alert or message to the user here
    } finally {
      setAddPicture(false)
      setLoading(false); // Ensure loading state is reset in case of an error
    }
  }

  if(loading){
    return(<LoadingComponent loadingText={'Adding Picture ....'}/>)
  }

  return (
    <div className="row row-cols-1 g-4">
      <form
        onSubmit={(event) => handleAdd(event)}
        style={{ backgroundColor: 'rgb(175, 238, 238,0.8)' }}
        className='container border-info rounded col-md-11 mt-3 p-3'
      >
        <h4 className='pb-2'>Add New Picture</h4>
        <div className="mb-3">
          <label htmlFor={`newImage`} className="form-label">New Image</label>
          <input
            type="file"
            className="form-control"
            id={`newImage`}
            accept=".png, .jpg, .jpeg"
            onChange={(event) => handleImageChange(event)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor={`newCaption`} className="form-label">New Caption</label>
          <input
            type="text"
            className="form-control"
            id={`newCaption`}
            value={caption}
            onChange={(event) => {
              setCaption(event.target.value);
              setError(validateCaption(event.target.value));
            }}
            required
          />
          {error && <div className="text-danger">{error}</div>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={!!error}>
          Add
        </button>
        <button onClick={() => setAddPicture(false)} className="btn btn-danger ms-5">
          Cancel
        </button>
      </form>
    </div>
  );
}
