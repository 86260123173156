import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import FlightRoutes from './FlightRoutes';
import Viewer from 'react-viewer';
import { FlightContext } from '../context/FlightContext';
import LoadingComponent from '../components/LoadingComponent'
import UpdateFlights from './UpdateFlight';
import Swal from 'sweetalert2';
import ImageViewer from '../utils/ImageViewer'


const FlightDetails = () => {
  const { flight_id } = useParams();
  const [flightDetails, setFlightDetails] = useState(null);
  const {onChange, deleteFlight} = useContext(FlightContext)
  const [update, setUpdate] = useState(false)
  const [visible,setVisible] = useState(false)
  const [image,setImage] = useState([])


    // ==================== IMAGES VIEWER =====================
    // const images=[image1,2,3,4 ...]
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [scale, setScale] = useState(1);
    // isOpen, setIsOpen,currentImageIndex, setCurrentImageIndex, scale, setScale,openModal
    const openModal = (index) => {
      setCurrentImageIndex(index);
      setScale(1); // Reset scale when opening modal
      setIsOpen(true);
    };
  
    // ==================== IMAGES VIEWER =====================



  useEffect(() => {
    if (flight_id) {
      fetch(`https://beshapp.backend.safarinetics.com/flights/details/${flight_id}`)
        .then(res => res.json())
        .then(response => {
          // console(response);
          if(!response.error){
          setFlightDetails(response);
          setImage([{
            src:response?.image,
            alt:response?.name
          }])
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [flight_id,onChange]);

  
 function handleDelete(id) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });
  swalWithBootstrapButtons
    .fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        deleteFlight(id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: 'Cancelled',
          text: 'Your flight is safe :)',
          icon: 'info',
        });
      }
    });
}
 const loadingText = 'Loading flight details...'

  return (
    <div className="container-fluid py-4" style={{ backgroundColor: 'rgb(210, 210, 210,0)', minHeight: '100vh' }}>
      <div className="row justify-content-center">
        <h1 className='text-dark text-center' style={{fontFamily:'junge'}}>{flightDetails?.name}</h1>
        {update ?
         (<UpdateFlights setUpdate={setUpdate} flightDetails={flightDetails}/>)
        :
        (
        <div className="col-md-6">
          <div>
          {flightDetails ? (
            <div className="card mb-3" style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
              <div className="card-header" style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: 'white' }}>
                <h5 className="card-title mb-0">{flightDetails?.name}</h5>
              </div>
              <div className="card-body bg-secondary bg-opacity-25">
                <img 
                src={flightDetails?.image}
                onClick={()=> openModal(currentImageIndex)}
                style={{minWidth:'30em',align:'center'}}
                className="img-fluid mb-4 " alt={flightDetails?.name} />


                <ImageViewer 
                images={[flightDetails?.image]}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                scale={scale}
                setScale={setScale}
              />
                {/* <Viewer
                  visible={visible}
                  onClose={() => setVisible(false)}
                  images={image}/> */}
                <div className="mb-4">
                  <h6 className="mb-2">Notes:</h6>
                  <textarea className="form-control" rows="4" readOnly value={flightDetails.notes}></textarea>
                </div>

                
              </div>
              <div className='card-footer d-flex justify-content-around flex-wrap' style={{ backgroundColor: 'rgb(8, 50, 32,1)', color: 'white' }}>
                  <button 
                  onClick={() => setUpdate(true)}
                   className='btn btn-info btn-sm'
                   >update</button>
                  <button onClick={()=> handleDelete(flightDetails?.id)} className='btn btn-danger btn-sm'>delete</button>
                </div>
              <div className="text-muted d-flex justify-content-between flex-wrap" >
                <small>added at: {flightDetails.added_at}</small>
                <small>Last updated: {flightDetails.updated_at|| 'null'}</small>
              </div>
            </div>
          ) : (
            <LoadingComponent loadingText={loadingText}/>
          )}
        </div>
        </div>
        )}
        <FlightRoutes/>
        

      </div>
    </div>
  );
};

export default FlightDetails;
